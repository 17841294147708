import React from "react";

import { Container, Logo, BackgroundImage } from "./SideComponent.style";

const logo = require("../../images/pt-logo.svg");
const backgroundImage = require("../../images/dotted_background.png");

const SideComponent = () => {
  return (
    <Container>
      <BackgroundImage src={backgroundImage} />
      <Logo src={logo} />
    </Container>
  );
};

export default SideComponent;
