import gql from "graphql-tag";

export const GET_USER_ROLES = gql`
  query getUserRoles(
    $order: UserRoleOrderByInput
    $first: Int!
    $skip: Int!
    $where: UserRoleWhereInput!
  ) {
    userRoles(orderBy: $order, first: $first, skip: $skip, where: $where) {
      title
      status
      description
      id
    }
    userRolesCount: userRoles(where: $where) {
      id
    }
  }
`;
export const GET_USER_ROLES_EXPORT = gql`
  query getUserRoles(
    $order: UserRoleOrderByInput
    $where: UserRoleWhereInput!
  ) {
    userRoles(orderBy: $order, where: $where) {
      title
      status
      description
      id
    }
  }
`;

export const GET_ALL_ACTIVE_USER_ROLES = gql`
  query {
    userRoles(where: { status: ACTIVE }) {
      title
      id
    }
  }
`;

export const GET_USER_ROLE = gql`
  query getUserRole($id: ID) {
    userRole(where: { id: $id }) {
      title
      description
      permissions
      status
      id
    }
  }
`;

export const GET_AFFECTED_USERS = gql`
  query getPositionRole($id: ID) {
    adminUsers(where: { roles: { id: $id } }) {
      id
      firstName
      lastName
    }
  }
`;

export default {
  GET_USER_ROLES,
  GET_USER_ROLE,
  GET_ALL_ACTIVE_USER_ROLES,
  GET_USER_ROLES_EXPORT,
  GET_AFFECTED_USERS,
};
