/**
 * This document will handle all the routes.
 * All files in the pages folder can be seen as a route
 */

import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ForgotPassword/ResetPassword";
import ConfirmAccount from "../pages/ConfirmAccount/ConfirmAccount";

const ProTouchStaffRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/confirm-account/:token",
    component: ConfirmAccount,
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/",
    redirect: "/login",
  },
];

export default ProTouchStaffRoutes;
