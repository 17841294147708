import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import { sign } from "jsonwebtoken";

const STAGE = process.env.REACT_APP_STAGE;
const PRISMA_API = process.env.REACT_APP_PRISMA_API;
const GRAPHQL_API = process.env.REACT_APP_GRAPHQL_API;
const PRISMA_MANAGEMENT_API_SECRET =
  process.env.REACT_APP_PRISMA_MANAGEMENT_API_SECRET;

/**
 * Generate a JWT token to use with the Prisma API.
 * Incase you need to access it.
 * @returns {string} json web token
 */
export const generateToken = () => {
  return sign(
    {
      data: {
        service: `${STAGE}@default`,
      },
    },
    PRISMA_MANAGEMENT_API_SECRET,
    {
      expiresIn: "24h",
    }
  );
};

/**
 * Creates a instance of apollo client with websocket support.
 * @returns {import('apollo-client').ApolloClient} ApolloClient instance to use in your AppoloProvider.
 */
export const createClient = () => {
  //const graphqlUrl = "http://localhost:3000";
  const graphqlUrl = `${GRAPHQL_API}`;
  const prismaUrl = `${PRISMA_API}/${STAGE}`;

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("jwt");
    // return the headers to the context so httpLink can read them

    return {
      headers: {
        ...headers,
        authorization: token || "",
      },
    };
  });

  const httpLink = new HttpLink({
    uri: graphqlUrl,
  });

  const token = generateToken();

  const wsLink = new WebSocketLink({
    uri: `${prismaUrl.replace("https", "wss")}`,
    options: {
      reconnect: true,
      connectionParams: {
        authorization: token,
      },
    },
  });

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return client;
};

/**
 * Format a grapgql error and remove the GraphqlError string in front.
 * @param {import('apollo-client').ApolloError} err - Error from apollo client network request.
 * @returns {string} Formatted string without the graphql error string.
 */
export const formatGraphqlError = (err) => {
  return err.message.replace("GraphQL error:", "");
};
