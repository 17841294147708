import React, { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { AiOutlineEye } from "react-icons/ai";
import styled from "styled-components";

import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
// GQL
import { GET_REFERRALS } from "../../../graphql/queries/finances.query";
import Modal from "../../../components/Modal/Modal.component";
import {
  H1,
  H2,
  Span,
} from "../../../components/Typography/Typography.component";
import { Row, Col } from "../../../components/Grid/Grid.new.component";

const Card = styled.div`
  border: 1px solid #393b3c;
  border-radius: 4px;
  padding: 15px;
`;

const Referrals = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_REFERRALS, {
    variables: { order: "title_ASC" },
    fetchPolicy: "no-cache",
  });
  const [variables, setVariables] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [activeReferral, setActiveReferral] = useState(false);

  useEffect(() => {
    setActiveSideNav("billing");
    setPageTitle("View Referrals");
  });

  const HeaderInfo = [
    {
      Header: "App User",
      accessor: (row) =>
        row.staff.appUser
          ? `${row.appUser.firstName} ${row.appUser.lastName}`
          : "not set",
      id: "appUser",
      search: true,
    },
    {
      Header: "Total Potential Referrals",
      id: "totalPotentialReferrals",
      accessor: (row) => {
        return row.referrals.filter(
          (referral) => referral.status === "POTENTIAL"
        ).length;
      },
    },
    {
      Header: "Total Completed Referrals",
      id: "totalCompletedReferrals",
      accessor: (row) => {
        return row.referrals.filter(
          (referral) => referral.status === "COMPLETED"
        ).length;
      },
    },
    {
      Header: "Total Paid Referrals",
      id: "totalPaidReferrals",
      accessor: (row) => {
        return row.referrals.filter((referral) => referral.status === "PAID")
          .length;
      },
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <AiOutlineEye
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            onClick={() => {
              setShowModal(true);
              setActiveReferral(row.original);
            }}
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
  ];

  return (
    <>
      <PageHeader title="View Referrals" />
      <Table
        columns={HeaderInfo}
        data={(data && data.groupedReferrals) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.groupedReferrals.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Referrals"
        tableOptions={["columns", "filter"]}
        filters={filters}
        fileName="Referrals"
      />
      <Modal
        open={showModal}
        showClose
        close={() => {
          setShowModal(false);
        }}
      >
        <Row style={{ marginBottom: "0" }}>
          <Col sm={12} md={12} lg={12} sub>
            <H1>Referrals Overview</H1>
          </Col>
        </Row>
        {activeReferral && (
          <>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={6} md={6} lg={6} sub>
                <H2 style={{ marginBottom: "7.5px" }}>App User</H2>
                <Span>
                  {`${activeReferral.appUser.firstName} ${activeReferral.appUser.lastName}`}
                </Span>
              </Col>
              <Col sm={6} md={6} lg={6} sub>
                <H2 style={{ marginBottom: "7.5px" }}>Total Referrals made</H2>
                <Span>{activeReferral.referrals.length}</Span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={12} md={12} lg={12} sub>
                <H2 style={{ marginBottom: "7.5px" }}>All Referrals</H2>
              </Col>
              {activeReferral.referrals.map((referral) => (
                <Col
                  sm={6}
                  md={6}
                  lg={6}
                  sub
                  key={referral.id}
                  padding="8px 17px"
                >
                  <Card>
                    <Row style={{ marginBottom: "0" }}>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Referee:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {`${referral.referee.firstName} ${referral.referee.lastName}`}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Status:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {referral.status}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default Referrals;
