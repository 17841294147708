import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./User.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  UPDATE_ADMIN_USER,
  DELETE_ADMIN_USER,
} from "../../../graphql/mutations/adminUser.mutation";
import { GET_ADMIN_USER } from "../../../graphql/queries/adminUser.query";
import { formatGraphqlError } from "../../../utils/api";

const AdminUserEdit = () => {
  const { setActiveSideNav, setPageTitle, superAdmin } = useContext(
    APP_CONTEXT
  );
  const [adminUserData, setAdminUserData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    cellphone: "",
    roles: "",
    superAdmin: false,
    status: "ACTIVE",
  });
  const [updateAdminUser, { loading }] = useMutation(UPDATE_ADMIN_USER);
  const [deleteAdminUser] = useMutation(DELETE_ADMIN_USER);
  const { id } = useParams();
  const getAdminUserData = useQuery(GET_ADMIN_USER, {
    fetchPolicy: "no-cache",
    variables: { id },
  });

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Admin User");
  });

  const { register, handleSubmit, errors, setValue } = useForm();

  const changeStatus = (e) => {
    const tempAdminUserData = { ...adminUserData };
    tempAdminUserData.status = e.target.value;
    setAdminUserData(tempAdminUserData);
  };

  const getColor = () => {
    switch (adminUserData.status) {
      case "ACTIVE":
        return "#39C568";
      case "NEW":
        return "#1a0388";
      case "ARCHIVE":
        return "#FF003A";
      default:
        return "#707172";
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteAdminUser({
          variables: {
            id: deleteAdminUser.id,
          },
        })
          .then(() => {
            Swal.fire("Deleted!", "Admin has been deleted.", "success").then(
              () => {
                history.push("/app/user-management/admin");
              }
            );
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Admin Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const onSubmit = (values) => {
    /**
     * Update Admin
     * push to list all admin users
     */

    const submitValues = values;
    submitValues.id = adminUserData.id;

    updateAdminUser({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({ title: "Update Admin Succeeded", message: "" });
        history.push("/app/user-management/admin");
      })
      .catch((err) =>
        Notification.error({
          title: "Update Admin Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  if (getAdminUserData.loading) {
    return "loading";
  }

  return (
    <>
      <PageHeader
        title={`Edit Admin: ${adminUserData.firstName} ${adminUserData.lastName}`}
        back
      />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            adminUserData={adminUserData}
            setAdminUserData={setAdminUserData}
            superAdmin={superAdmin}
            adminUserEditData={
              getAdminUserData.data && getAdminUserData.data.adminUser
            }
            setValue={setValue}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={adminUserData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "NEW", text: "New" },
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a User Type ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={() => handleDelete()}
            type="button"
            value="Archive"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default AdminUserEdit;
