import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { EditorState } from "draft-js";
import { useHistory } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { useMutation } from "@apollo/react-hooks";
import { stateToHTML } from "draft-js-export-html";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./CM.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";
import UploadFeaturedImage from "../../../components/UploadFeaturedImage/UploadFeaturedImage.component";

// GQL
import { CREATE_CMS_ENTRY } from "../../../graphql/mutations/cmsEntry.mutation";
import { formatGraphqlError } from "../../../utils/api";

const CMSEntryNew = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [cmsEntryData, setCmsEntryData] = useState({
    id: "",
    title: "",
    body: "",
    featuredImage: "",
    expiresAt: null,
    publishAt: moment(),
    status: "ACTIVE",
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [createCmsEntry, { loading }] = useMutation(CREATE_CMS_ENTRY);

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("cm");
    setPageTitle("Create New Content");
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const changeStatus = (e) => {
    const tempCmsEntryData = { ...cmsEntryData };
    tempCmsEntryData.status = e.target.value;
    setCmsEntryData(tempCmsEntryData);
  };

  const setImage = (featuredImage) => {
    setCmsEntryData({
      ...cmsEntryData,
      featuredImage,
    });
  };

  const getColor = () => {
    switch (cmsEntryData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Content
     * push to list all content
     */

    createCmsEntry({
      variables: {
        ...values,
        featuredImage: cmsEntryData.featuredImage,
        publishAt: cmsEntryData.publishAt,
        expiresAt: cmsEntryData.expiresAt,
        body: stateToHTML(editorState.getCurrentContent()),
      },
    })
      .then(() => {
        Notification.success({
          title: "Create Content Succeeded",
          message: "",
        });
        history.push("/app/cms/content-management");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Content Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <PageHeader title="Create New Content" back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            cmsEntryData={cmsEntryData}
            setCmsEntryData={setCmsEntryData}
            setValue={setValue}
            getValues={getValues}
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </Column>
        <Column width="30%">
          <UploadFeaturedImage
            header="Featured Image"
            image={cmsEntryData.featuredImage}
            setImage={setImage}
          />
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={cmsEntryData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="0px 0px 120px 0px"
            required={{
              required: "please enter a Content ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Create Content"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => history.goBack()}
            type="button"
            value="Cancel"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default CMSEntryNew;
