import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { AnimatePresence, motion } from "framer-motion";
import {
  useSubscription,
  useMutation,
  useLazyQuery,
} from "@apollo/react-hooks";
import { FiBell } from "react-icons/fi";
import Notification from "../../Toast/Toast.component";
import {
  NotificationSection,
  NotificationDropDownHolder,
  NotificationDropDown,
  None,
  Notification as NotificationBlock,
  Message,
  Date as DateBlock,
  Dissmiss,
  Icon,
  NewNotification,
  Dot,
  ViewAllNotifications,
} from "./Notifications.style";
// import PP from '../../images/face-placeholder.gif';
import { GET_NOTIFICATIONS } from "../../../graphql/queries/notifications.query";
import { SUBSCRIBE_TO_NOTIFICATIONS } from "../../../graphql/subscriptions/notification.subscription";
import READ_NOTIFICATION from "../../../graphql/mutations/notification.mutation";

const variants = {
  enter: {
    height: "auto",
    opacity: 1,
  },
  full: {
    height: "65vh",
    opacity: 1,
  },
  exit: {
    height: "0",
    opacity: 0,
  },
};

const viewMore = {
  enter: {
    height: "auto",
    opacity: 1,
  },
  exit: {
    height: "0",
    opacity: 0,
  },
};

const getUrl = (notificationType) => {
  switch (notificationType) {
    case "SUGGESTION":
      return "/app/suggestions";
    case "SUPPORT":
      return "/app/support";
    case "OFFER":
      return "/app/suggestions";
    case "EVENT":
      return "/app/events";
    case "EXPENSECLAIM":
      return "/app/finances/expense-claims";
    default:
      return "/app/";
  }
};

const Notifications = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [newNotifications, setNewNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [state, setState] = useState({
    id: userId,
    first: 3,
    skip: 0,
    viewAllNotifications: false,
  });
  const [refetch, { data, loading, called }] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: "network-only",
    variables: {
      id: userId,
      first: 3,
      skip: 0,
    },
  });
  const { data: notification } = useSubscription(SUBSCRIBE_TO_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
    shouldResubscribe: true,
    variables: {
      id: userId,
    },
  });

  const [readNotification] = useMutation(READ_NOTIFICATION);

  const hasUnread = () => {
    let retVal = false;
    if (newNotifications) {
      newNotifications.forEach((i) => {
        if (!i.read) {
          retVal = true;
        }
      });
    }

    return retVal;
  };

  const sortNotifications = (notificationData) => {
    return notificationData.sort((a, b) => {
      const objA = new Date(a.createdAt);
      const objB = new Date(b.createdAt);

      return objB - objA;
    });
  };

  const closeNotifications = () => {
    setOpen(false);
    setState({
      first: 3,
      skip: 0,
      viewAllNotifications: false,
    });
    if (newNotifications.length > 3) {
      setNewNotifications(newNotifications.slice(0, 3));
    }
  };

  const read = (id) => {
    closeNotifications();
    readNotification({
      variables: {
        id,
      },
    });
  };

  const bellClick = () => {
    if (open) {
      closeNotifications();
    } else {
      setOpen(true);
    }
  };

  const getMoreNotifications = () => {
    const newState = { ...state };
    newState.skip = newState.skip === 0 ? state.first : newState.skip + 10;
    newState.first = 10;
    newState.viewAllNotifications = true;
    refetch({
      variables: {
        skip: newState.skip,
        first: newState.first,
        id: userId,
      },
    });
    setState(newState);
  };

  const getAnimation = () => {
    if (open) {
      if (state.viewAllNotifications && !loading) {
        return "full";
      }
      return "enter";
    }
    return "exit";
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loading && newNotifications.length < notificationCount) {
      getMoreNotifications();
    }
  };

  useEffect(() => {
    if (notification) {
      const addNotification = (newNotification) => {
        if (
          newNotification &&
          newNotification.notification &&
          newNotification.notification.node
        ) {
          const { node, mutation } = { ...newNotification.notification };

          setNewNotifications((prevState) => {
            const tempNotifications = Array.from(prevState);
            if (mutation === "CREATED") {
              if (!state.viewAllNotifications) {
                tempNotifications.pop();
              }
              tempNotifications.push(node);
              if (!open) {
                Notification.info({
                  title: "New notification",
                  message: node.message,
                });
              }
              return tempNotifications;
            }
            const retVal = tempNotifications.map((not) => {
              if (not.id === node.id) {
                return node;
              }
              return not;
            });
            return retVal;
          });
        }
      };
      addNotification(notification);
    }
  }, [notification, setNewNotifications, open, state.viewAllNotifications]);

  useEffect(() => {
    if (!loading && data) {
      if (state.viewAllNotifications) {
        setNewNotifications((prevState) => {
          return prevState.concat(data.notifications);
        });
      } else {
        setNewNotifications(sortNotifications(data.notifications));
      }
      setNotificationCount(data.notificationCount.length);
    }
    if (!called) {
      refetch();
    }
  }, [
    data,
    loading,
    setNotificationCount,
    state.viewAllNotifications,
    refetch,
    called,
  ]);

  useEffect(() => {
    return () => {
      // remove all event listeners
    };
  }, []);

  return (
    <NotificationSection>
      <Icon>
        <FiBell onClick={bellClick} />
        {hasUnread() && <NewNotification />}
      </Icon>

      {open && <Dissmiss onClick={closeNotifications} />}
      <NotificationDropDownHolder
        initial="exit"
        animate={getAnimation()}
        variants={variants}
        viewAllNotifications={state.viewAllNotifications}
        onScroll={handleScroll}
      >
        <NotificationDropDown>
          {newNotifications.length === 0 && <None>No notifications</None>}
          <AnimatePresence>
            {newNotifications.map((item) => {
              if (item.notificationType === "GENERAL") {
                return (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <NotificationBlock to="#" onClick={() => read(item.id)}>
                      <Dot read={item.read} className="unread" />
                      <Message>{parse(item.message)}</Message>
                      <DateBlock>{moment(item.createdAt).fromNow()}</DateBlock>
                    </NotificationBlock>
                  </motion.div>
                );
              }
              return (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <NotificationBlock
                    to={getUrl(item.notificationType)}
                    onClick={() => read(item.id)}
                  >
                    <Dot read={item.read} className="unread" />
                    <Message>{parse(item.message)}</Message>
                    <DateBlock>{moment(item.createdAt).fromNow()}</DateBlock>
                  </NotificationBlock>
                </motion.div>
              );
            })}
          </AnimatePresence>
          <ViewAllNotifications
            initial="exit"
            animate={loading ? "enter" : "exit"}
            variants={viewMore}
          >
            <span>Loading More</span>
          </ViewAllNotifications>
          <ViewAllNotifications
            initial="exit"
            animate={
              newNotifications.length > 0 && !state.viewAllNotifications
                ? "enter"
                : "exit"
            }
            variants={viewMore}
            onClick={getMoreNotifications}
          >
            <span>View All Notifications</span>
          </ViewAllNotifications>
        </NotificationDropDown>
      </NotificationDropDownHolder>
    </NotificationSection>
  );
};

Notifications.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default Notifications;
