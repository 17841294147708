import gql from "graphql-tag";

export const UPDATE_FEEDBACK = gql`
  mutation updateFeedback(
    $data: FeedbackUpdateInput!
    $where: FeedbackWhereUniqueInput!
  ) {
    updateFeedback(data: $data, where: $where) {
      id
    }
  }
`;

export default {
  UPDATE_FEEDBACK,
};
