import gql from "graphql-tag";

export const CREATE_SPORT_CATEGORY = gql`
  mutation createSportCategory(
    $title: String!
    $description: String!
    $status: GeneralStatus!
  ) {
    createSportCategory(
      data: { title: $title, description: $description, status: $status }
    ) {
      id
    }
  }
`;

export const UPDATE_SPORT_CATEGORY = gql`
  mutation updateUserType(
    $title: String!
    $description: String!
    $status: GeneralStatus!
    $id: ID!
  ) {
    updateSportCategory(
      data: { title: $title, description: $description, status: $status }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_SPORT_CATEGORY = gql`
  mutation deleteSportCategory($id: ID!) {
    deleteSportCategory(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_SPORT_CATEGORY,
  UPDATE_SPORT_CATEGORY,
  DELETE_SPORT_CATEGORY,
};
