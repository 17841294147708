import gql from "graphql-tag";

export const GET_CONTACT_INFORMATIONS = gql`
  query getContactInformations(
    $order: ContactInformationOrderByInput
    $first: Int!
    $skip: Int!
    $where: ContactInformationWhereInput!
  ) {
    contactInformations(
      orderBy: $order
      first: $first
      skip: $skip
      where: $where
    ) {
      name
      options
      status
      description
      id
    }
    contactInformationsCount: contactInformations(where: $where) {
      id
    }
  }
`;

export const GET_CONTACT_INFORMATIONS_EXPORT = gql`
  query getContactInformations(
    $order: ContactInformationOrderByInput
    $where: ContactInformationWhereInput!
  ) {
    contactInformations(orderBy: $order, where: $where) {
      name
      options
      status
      description
      id
    }
  }
`;

export const GET_CONTACT_INFORMATION = gql`
  query getContactInformation($id: ID) {
    contactInformation(where: { id: $id }) {
      name
      options
      status
      description
      id
    }
  }
`;

export const GET_ALL_ACTIVE_CONTACT_INFORMATIONS = gql`
  query {
    contactInformations(where: { status: ACTIVE }) {
      name
      id
    }
  }
`;

export default {
  GET_CONTACT_INFORMATIONS,
  GET_CONTACT_INFORMATIONS_EXPORT,
  GET_CONTACT_INFORMATION,
};
