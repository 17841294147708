import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { AnimatePresence } from "framer-motion";
import { APP_CONTEXT } from "../../../utils/context";

// GQL
import { GET_STAFF_PROFILE } from "../../../graphql/queries/staff.query";
import Tabs from "../../../components/Tabs/Tabs.component";
import StaffDashboard from "./User.dashboard";
import StaffProfile from "./User.edit";
import StaffCalendar from "./User.calendar";
import StaffReview from "./User.reviews";
import { MountTransition } from "../../../components/Animation/AnimatedRoutes/MountTransition.component";
import { Holder } from "./Staff.style";

const StaffView = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { id } = useParams();
  const [refetch, getStaffData] = useLazyQuery(GET_STAFF_PROFILE, {
    fetchPolicy: "no-cache",
    variables: { id },
  });
  const [activeComponent, setActiveComponent] = useState("dashboard");

  const tabs = [
    {
      title: "Overview / Summary",
      id: "dashboard",
    },
    {
      title: "Profile",
      id: "profile",
    },
    {
      title: "Calendar",
      id: "calendar",
    },
    {
      title: "Reviews",
      id: "reviews",
    },
  ];
  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Staff User");
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if ((getStaffData.loading && !getStaffData.called) || !getStaffData.data) {
    return "loading";
  }

  return (
    <>
      <Tabs
        componentTabs={tabs}
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
        back
      />
      <Holder>
        <AnimatePresence>
          {activeComponent === "dashboard" && (
            <MountTransition slide={25} key="dashboard">
              <StaffDashboard staffData={getStaffData.data.appUser} />
            </MountTransition>
          )}
          {activeComponent === "profile" && (
            <MountTransition slide={25} key="profile">
              <StaffProfile
                refetch={refetch}
                staffData={getStaffData.data.appUser}
              />
            </MountTransition>
          )}
          {activeComponent === "calendar" && (
            <MountTransition slide={25} key="profile">
              <StaffCalendar staffData={getStaffData.data.appUser} />
            </MountTransition>
          )}
          {activeComponent === "reviews" && (
            <MountTransition slide={25} key="profile">
              <StaffReview staffData={getStaffData.data.appUser} />
            </MountTransition>
          )}
        </AnimatePresence>
      </Holder>
    </>
  );
};

export default StaffView;
