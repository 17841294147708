import gql from "graphql-tag";

export const PAYMENT_METHODS = gql`
  query PaymentMehtods($stripeId: String!) {
    paymentMethods(data: { stripeId: $stripeId })
  }
`;
export default {
  PAYMENT_METHODS,
};
