import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { Wrapper, Title, Children } from "./PageHeader.style";
import { H1 } from "../Typography/Typography.component";

const PageHeader = (props) => {
  const { back, backUrl, title, children } = props;
  const history = useHistory();
  return (
    <Wrapper>
      <Title>
        {back && (
          <FiArrowLeft
            onClick={
              back
                ? () => {
                    if (backUrl !== "") {
                      history.push(backUrl);
                    } else {
                      history.goBack();
                    }
                  }
                : null
            }
            style={{ marginRight: "16px", fontSize: "20px", cursor: "pointer" }}
          />
        )}
        <H1>{title}</H1>
      </Title>
      <Children>{children}</Children>
    </Wrapper>
  );
};

PageHeader.defaultProps = {
  back: false,
  backUrl: "",
  children: null,
};
PageHeader.propTypes = {
  back: PropTypes.bool,
  backUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageHeader;
