import React from "react";
import PropTypes from "prop-types";
import { COLOR_GREY2 } from "../Colors";

// #region Pagination Component
const PaginationFirst = ({ color }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7071 13.7071C11.3466 14.0676 10.7794 14.0953 10.3871 13.7903L10.2929 13.7071L4.29289 7.70711C3.93241 7.34662 3.90468 6.77939 4.2097 6.3871L4.29289 6.29289L10.2929 0.292893C10.6834 -0.0976305 11.3166 -0.0976305 11.7071 0.292893C12.0676 0.653378 12.0953 1.22061 11.7903 1.6129L11.7071 1.70711L6.415 7L11.7071 12.2929C12.0676 12.6534 12.0953 13.2206 11.7903 13.6129L11.7071 13.7071Z"
      fill={color}
    />
    <path
      d="M1 0C1.51284 0 1.93551 0.38604 1.99327 0.883379L2 1V13C2 13.5523 1.55228 14 1 14C0.487164 14 0.0644928 13.614 0.00672773 13.1166L0 13V1C0 0.447715 0.447715 0 1 0Z"
      fill={color}
    />
  </svg>
);

const PaginationLast = ({ color }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.292893 0.292893C0.653377 -0.0675907 1.22061 -0.0953203 1.6129 0.209705L1.70711 0.292893L7.70711 6.29289C8.06759 6.65338 8.09532 7.22061 7.7903 7.6129L7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0675907 13.3466 -0.0953203 12.7794 0.209705 12.3871L0.292893 12.2929L5.585 7L0.292893 1.70711C-0.0675907 1.34662 -0.0953203 0.779392 0.209705 0.387101L0.292893 0.292893Z"
      fill={color}
    />
    <path
      d="M11 14C10.4872 14 10.0645 13.614 10.0067 13.1166L10 13L10 1C10 0.447715 10.4477 0 11 0C11.5128 0 11.9355 0.386041 11.9933 0.883379L12 1L12 13C12 13.5523 11.5523 14 11 14Z"
      fill={color}
    />
  </svg>
);

const PaginationNext = ({ color }) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.292893 0.292893C0.653377 -0.0675907 1.22061 -0.0953203 1.6129 0.209705L1.70711 0.292893L7.70711 6.29289C8.06759 6.65338 8.09532 7.22061 7.7903 7.6129L7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0675907 13.3466 -0.0953203 12.7794 0.209705 12.3871L0.292893 12.2929L5.585 7L0.292893 1.70711C-0.0675907 1.34662 -0.0953203 0.779392 0.209705 0.387101L0.292893 0.292893Z"
      fill={color}
    />
  </svg>
);

const PaginationPrevious = ({ color }) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70711 13.7071C7.34662 14.0676 6.77939 14.0953 6.3871 13.7903L6.29289 13.7071L0.292893 7.70711C-0.0675907 7.34662 -0.0953207 6.77939 0.209704 6.3871L0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976305 7.31658 -0.0976305 7.70711 0.292893C8.06759 0.653378 8.09532 1.22061 7.7903 1.6129L7.70711 1.70711L2.415 7L7.70711 12.2929C8.06759 12.6534 8.09532 13.2206 7.7903 13.6129L7.70711 13.7071Z"
      fill={color}
    />
  </svg>
);
// #endregion Pagination Component

// #region Pagination Prop Types
PaginationFirst.propTypes = {
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};

PaginationLast.propTypes = {
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};

PaginationNext.propTypes = {
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};

PaginationPrevious.propTypes = {
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};
// #endregion Pagination Prop Types

// #region Pagination Default Prop
PaginationFirst.defaultProps = {
  color: "#393B3C",
};

PaginationLast.defaultProps = {
  color: "#393B3C",
};

PaginationNext.defaultProps = {
  color: COLOR_GREY2,
};

PaginationPrevious.defaultProps = {
  color: "#393B3C",
};
// #endregion Pagination Default Prop

export { PaginationFirst, PaginationLast, PaginationNext, PaginationPrevious };
