import gql from "graphql-tag";

export const GET_FAQS = gql`
  query getFaqs(
    $order: FaqOrderByInput
    $first: Int!
    $skip: Int!
    $where: FaqWhereInput
  ) {
    faqs(orderBy: $order, first: $first, skip: $skip, where: $where) {
      question
      answer
      status
      id
    }
    faqCount: faqs(where: $where) {
      id
    }
  }
`;

export const GET_FAQS_EXPORT = gql`
  query getFaqs($order: FaqOrderByInput, $where: FaqWhereInput) {
    faqs(orderBy: $order, first: $first, skip: $skip, where: $where) {
      question
      answer
      status
      id
    }
  }
`;

export const GET_FAQ = gql`
  query getFaq($id: ID) {
    faq(where: { id: $id }) {
      question
      answer
      status
      id
    }
  }
`;

export default {
  GET_FAQS,
  GET_FAQS_EXPORT,
  GET_FAQ,
};
