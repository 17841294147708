import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { APP_CONTEXT } from "../../utils/context";
// GQL
import { GET_SERVICE_AGREEMENT } from "../../graphql/queries/serviceAgreement.query";
import Tabs from "../../components/Tabs/Tabs.component";
import { MountTransition } from "../../components/Animation/AnimatedRoutes/MountTransition.component";
import ServiceAgreement from "./ServiceAgreement.component";
import { Column } from "../../components/Grid/Grid.component";
import Accordion from "../../components/Accordion/Accordion.component";

const Holder = styled.div`
  position: relative;
  width: 100%;
`;

const ServiceAgreementView = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { id } = useParams();
  const serviceAgreementData = useQuery(GET_SERVICE_AGREEMENT, {
    fetchPolicy: "no-cache",
    variables: { id },
  });
  const [activeComponent, setActiveComponent] = useState("serviceAgreement");

  const tabs = [
    {
      title: "Service Agreement",
      id: "serviceAgreement",
    },
    {
      title: "Previous Versions",
      id: "previousVersions",
    },
  ];
  useEffect(() => {
    setActiveSideNav("serviceAgreement");
    setPageTitle("View Service Agreement");
  });

  if (serviceAgreementData.loading || !serviceAgreementData.data) {
    return "loading";
  }

  return (
    <>
      <Tabs
        componentTabs={tabs}
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
        back
      />
      <Holder>
        <AnimatePresence>
          {activeComponent === "serviceAgreement" && (
            <MountTransition slide={25} key="serviceAgreement">
              <Column style={{ marginTop: "30px", height: "auto" }} sub>
                <ServiceAgreement
                  serviceAgreement={serviceAgreementData.data.serviceAgreement}
                />
              </Column>
            </MountTransition>
          )}
          {activeComponent === "previousVersions" && (
            <MountTransition slide={25} key="previousVersions">
              <Column style={{ marginTop: "30px", height: "auto" }} sub>
                {serviceAgreementData.data.serviceAgreement.versions.map(
                  (serviceAgreement) => (
                    <Accordion
                      title={`Version: ${serviceAgreement.currentVersion}`}
                      maxHeight="75vh"
                    >
                      <ServiceAgreement
                        serviceAgreement={serviceAgreement}
                        oldVersion
                      />
                    </Accordion>
                  )
                )}
              </Column>
            </MountTransition>
          )}
        </AnimatePresence>
      </Holder>
    </>
  );
};

export default ServiceAgreementView;
