import styled from "styled-components";

export const ErrorMessageWrapper = styled.div`
  width: 100%;
  color: red;
  font-size: 12px;
  line-height: 0;
  margin: 0;
`;

export default {
  ErrorMessageWrapper,
};
