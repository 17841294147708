/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { AnimatePresence } from "framer-motion";
import { FiX } from "react-icons/fi";
import {
  Container,
  InputHeader,
  StyledInput,
  Icon,
  Input,
  Validation,
  ChipsHolder,
  Chip,
  ChipInput,
  Suggestions,
  Suggestion,
} from "./AutoCompleteChips.style";

const AutoCompleteChips = ({
  header,
  name,
  label,
  register,
  required,
  type,
  error,
  icon,
  validationLabel,
  suggestions,
  selected,
  setSelected,
  addExtra,
  accessorTitle,
  accessorValue,
  disabled,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestion, setFilteredSuggestion] = useState(suggestions);
  const [search, setSearch] = useState("");
  const isInArr = (val) => {
    const found = selected.filter((option) => {
      if (accessorTitle) {
        return option[accessorTitle] === val[accessorTitle];
      }
      return option === val;
    });
    if (found.length > 0) {
      return true;
    }
    return false;
  };

  const onChangeSearch = (event) => {
    const val = event.currentTarget.value;

    const tempSuggestions = suggestions.filter((suggestion) => {
      if (accessorTitle) {
        return (
          suggestion[accessorTitle].toLowerCase().indexOf(val.toLowerCase()) >
          -1
        );
      }
      return suggestion.toLowerCase().indexOf(val.toLowerCase()) > -1;
    });

    const filteredSuggestions = tempSuggestions.filter(
      (suggestion) => !isInArr(suggestion)
    );

    setSearch(val);
    setFilteredSuggestion(filteredSuggestions);
    setShowSuggestions(true);
  };

  const onClickEvent = (item) => {
    selected.push(item);
    setSelected(selected);
    setFilteredSuggestion([]);
    setShowSuggestions(false);
    setSearch("");
  };

  const removeItem = (item) => {
    const items = selected;
    const index = items.indexOf(item);
    if (items.length === 0) {
      setSelected([]);
    } else if (index > -1) {
      items.splice(index, 1);
      setSelected(items);
    }
  };

  const onFocusIn = () => {
    if (search !== "") {
      setShowSuggestions(true);
    }
  };
  const onFocusOut = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 250);
  };

  return (
    <Container>
      <InputHeader>{header}</InputHeader>
      {!disabled && (
        <Input>
          <StyledInput
            label={label}
            placeholder={label}
            type={type}
            onChange={onChangeSearch}
            value={search}
            onFocus={onFocusIn}
            onBlur={onFocusOut}
          />
          {icon && <Icon>{icon}</Icon>}
          {showSuggestions && (
            <Suggestions>
              {filteredSuggestion.map((sug) => {
                return (
                  <Suggestion key={sug} onClick={() => onClickEvent(sug)}>
                    {accessorTitle ? sug[accessorTitle] : sug}
                  </Suggestion>
                );
              })}
              {filteredSuggestion.length === 0 && addExtra && (
                <Suggestion
                  onClick={() =>
                    addExtra(search, () => {
                      setSearch("");
                    })
                  }
                >
                  Oops nothing found. Do you want to add a new selection?
                </Suggestion>
              )}
            </Suggestions>
          )}
        </Input>
      )}
      <ChipsHolder>
        <AnimatePresence>
          {selected.map((item, i) => {
            let key;
            if (accessorValue) {
              key = `${item[accessorValue]}`;
            } else {
              key = `${item.replace(" ")}-${i}`;
            }
            return (
              <Chip
                key={key}
                onClick={() => removeItem(item)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {accessorTitle ? item[accessorTitle] : item}
                <ChipInput
                  value={accessorValue ? item[accessorValue] : item}
                  readOnly
                  name={
                    accessorValue
                      ? `${name}[${i}].${accessorValue}`
                      : `${name}[${i}]`
                  }
                  ref={register(required)}
                />
                <FiX />
              </Chip>
            );
          })}
        </AnimatePresence>
      </ChipsHolder>
      {!error && validationLabel && <Validation>*
{validationLabel}
</Validation>}
      <div>{error}</div>
    </Container>
  );
};

AutoCompleteChips.defaultProps = {
  header: "",
  label: PropTypes.string,
  required: {},
  type: "text",
  error: undefined,
  suggestions: [],
  icon: undefined,
  addExtra: undefined,
  validationLabel: "",
  accessorTitle: "",
  accessorValue: "",
  disabled: false,
};

AutoCompleteChips.propTypes = {
  // input field header
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // react-hook-form validation object
  register: PropTypes.func.isRequired,
  required: PropTypes.object,
  type: PropTypes.string,
  // error object
  error: PropTypes.object,
  suggestions: PropTypes.array,
  icon: PropTypes.element,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  addExtra: PropTypes.func,
  // specify type of input expected below input field
  validationLabel: PropTypes.string,
  accessorTitle: PropTypes.string,
  accessorValue: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AutoCompleteChips;
