/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";
import MultiSelectReact from "react-multi-select-component";

// import styling of the component:
import { Container, InputHeader, Input, Validation } from "./MultiSelect.style";

const MultiSelect = ({
  control,
  name,
  required,
  header,
  options,
  label,
  hasSelectAll,
  defaultValue,
  error,
  loading,
  validationLabel,
}) => {
  if (loading) {
    return "Loading please wait";
  }
  return (
    <Container>
      <InputHeader>{header}</InputHeader>
      <Input>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={required}
          render={({ onChange, value }) => (
            <MultiSelectReact
              className="multiSelectComponent"
              options={options}
              value={value}
              onChange={onChange}
              labelledBy={label}
              hasSelectAll={hasSelectAll}
            />
          )}
        />
      </Input>
      {!error && validationLabel && <Validation>*{validationLabel}</Validation>}
      <div>{error}</div>
    </Container>
  );
};

MultiSelect.defaultProps = {
  hasSelectAll: true,
  loading: false,
  header: "",
  validationLabel: "",
  error: null,
  defaultValue: [],
  required: {},
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.object,
  control: PropTypes.any.isRequired,
  defaultValue: PropTypes.array,
  label: PropTypes.string.isRequired,
  header: PropTypes.string,
  options: PropTypes.array.isRequired,
  hasSelectAll: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
  validationLabel: PropTypes.string,
};

export default MultiSelect;
