import React, { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import styled from "styled-components";

import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
// GQL
import { GET_EXPENSE_CLAIMS } from "../../../graphql/queries/finances.query";
import Modal from "../../../components/Modal/Modal.component";
import {
  H1,
  H2,
  Span,
} from "../../../components/Typography/Typography.component";
import { Row, Col } from "../../../components/Grid/Grid.new.component";
import { sumObjectArray } from "../../../utils";

const Card = styled.div`
  border: 1px solid #393b3c;
  border-radius: 4px;
  padding: 15px;
`;

const ExpenseClaim = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_EXPENSE_CLAIMS, {
    variables: { order: "title_ASC" },
    fetchPolicy: "no-cache",
  });
  const [variables, setVariables] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [activeClaim, setActiveClaim] = useState(false);

  useEffect(() => {
    setActiveSideNav("billing");
    setPageTitle("View Expense Claims");
  });

  const filterData = (expenseClaims, status) => {
    return sumObjectArray(
      expenseClaims.filter((claim) => claim.status === status),
      "amount"
    );
  };

  const HeaderInfo = [
    {
      Header: "Staff",
      accessor: (row) =>
        row.staff.appUser
          ? `${row.staff.appUser.firstName} ${row.staff.appUser.lastName}`
          : "Not set",
      id: "staff",
      search: true,
    },
    {
      Header: "Total Pending Expense Claims",
      id: "totalPendingExpenseClaims",
      accessor: (row) => filterData(row.expenseClaims, "PENDING"),
    },
    {
      Header: "Total Approved Expense Claims",
      id: "totalApprovedExpenseClaims",
      accessor: (row) => filterData(row.expenseClaims, "APPROVED"),
    },
    {
      Header: "Total Declined Expense Claims",
      id: "totalDeclinedExpenseClaims",
      accessor: (row) => filterData(row.expenseClaims, "DECLINED"),
    },
    {
      Header: "Total Paid Expense Claims",
      id: "totalPaidExpenseClaims",
      accessor: (row) => filterData(row.expenseClaims, "PAID"),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <AiOutlineEye
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            onClick={() => {
              setShowModal(true);
              setActiveClaim(row.original);
            }}
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
  ];

  return (
    <>
      <PageHeader title="View Expense Claims" />
      <Table
        columns={HeaderInfo}
        data={(data && data.groupedExpenseClaims) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.groupedExpenseClaims.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Expense Claims"
        tableOptions={["columns", "filter"]}
        filters={filters}
        fileName="ExpenseClaims"
      />
      <Modal
        open={showModal}
        showClose
        close={() => {
          setShowModal(false);
        }}
      >
        <Row style={{ marginBottom: "0" }}>
          <Col sm={12} md={12} lg={12} sub>
            <H1>Expense Claims Overview</H1>
          </Col>
        </Row>
        {activeClaim && (
          <>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={6} md={6} lg={6} sub>
                <H2 style={{ marginBottom: "7.5px" }}>App User</H2>
                <Span>
                  {`${activeClaim.staff.appUser.firstName} ${activeClaim.staff.appUser.lastName}`}
                </Span>
              </Col>
              <Col sm={6} md={6} lg={6} sub>
                <H2 style={{ marginBottom: "7.5px" }}>Total claims made</H2>
                <Span>{activeClaim.expenseClaims.length}</Span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={12} md={12} lg={12} sub>
                <H2 style={{ marginBottom: "7.5px" }}>All Claims</H2>
              </Col>
              {activeClaim.expenseClaims.map((claim) => (
                <Col sm={6} md={6} lg={6} sub key={claim.id} padding="8px 17px">
                  <Card>
                    <Row style={{ marginBottom: "0" }}>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Company:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {claim.employer.companyName}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Amount:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {claim.amount}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Status:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {claim.status}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Position:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {claim.position.title}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Invoice:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        <a
                          href={claim.invoice}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Invoice
                        </a>
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Submition Date:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {moment(claim.createdAt).format("YYYY/MM/DD HH:mm:SS")}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default ExpenseClaim;
