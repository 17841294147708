import gql from "graphql-tag";

export const CREATE_EVENT = gql`
  mutation createEvent($data: EventCreateInput!) {
    createEvent(data: $data) {
      id
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $data: EventUpdateInput!
    $where: EventWhereUniqueInput!
  ) {
    updateEvent(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
};
