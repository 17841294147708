import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { FiImage, FiUploadCloud, FiShare } from "react-icons/fi";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Modal from "../Modal/Modal.component";
import Notification from "../Toast/Toast.component";
import Button from "../Button/Button.component";
import { fileToBase64 } from "../../utils";

import {
  UploadFeaturedImageHolder,
  Img,
  ImgHolder,
  NoImg,
  Header,
  UploadButton,
  DropZone,
  CropWrapper,
  Crop,
} from "./UploadFeaturedImage.style";

const AWS_FILE_SERVICE =
  process.env.AWS_FILE_SERVICE ||
  "https://m7o75qswfb.execute-api.eu-west-1.amazonaws.com/uat/";

const AWS_FILE_BUCKET = process.env.AWS_FILE_BUCKET || "test";

const UploadFeaturedImage = ({ image, header, setImage }) => {
  const [loading, setLoading] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [fileType, setFileType] = useState("");
  const [crop, setCrop] = useState({
    aspect: 16 / 9,
    x: 0,
    y: 0,
    width: 500,
    height: 281.25,
    unit: "px",
  });
  const uploadAsset = (asset) => {
    setLoading(true);
    const assetData = {
      type: fileType,
      folder: AWS_FILE_BUCKET,
      content: asset.split(",")[1],
    };
    axios
      .request({
        method: "post",
        url: AWS_FILE_SERVICE,
        data: assetData, // base64
        onUploadProgress: (progress) => {
          const percentComplete = Math.round(
            (progress.loaded * 100) / progress.total
          );
          if (percentComplete !== 100) {
            return setLoading(true);
          }
          if (percentComplete === 100) {
            setLoading(false);
          }
          return "";
        },
      })
      .then((res) => {
        setLoading(false);
        setImage(res.data.url);
        setUploadModal(false);
        // returns url
      })
      .then(() => {
        setLoading(false);
        // close off
      });
  };

  // Dropzone START
  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length > 1) {
      Notification.warning({
        title: "Too many files",
        message: "Only the first image will be selected",
      });
    }
    const img = await fileToBase64(acceptedFiles[0]);
    setFileType(acceptedFiles[0].type);
    setUploadImage(img);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });
  // Dropzone END

  // CROP IMAGE START
  const cropImageComplete = async (file) => {
    const base64 = await fileToBase64(file);
    uploadAsset(base64);
  };

  const createCroppedImage = async () => {
    const img = imageRef || new Image();
    const newCrop = crop;

    const canvas = document.createElement("canvas");
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    canvas.width = newCrop.width || 0;
    canvas.height = newCrop.height || 0;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      if (img) {
        ctx.drawImage(
          img,
          (newCrop.x || 0) * scaleX,
          (newCrop.y || 0) * scaleY,
          (newCrop.width || 0) * scaleX,
          (newCrop.height || 0) * scaleY,
          0,
          0,
          newCrop.width || 0,
          newCrop.height || 0
        );
      }
    }
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        cropImageComplete(blob);
      }, fileType);
    });
  };
  // CROP IMAGE END

  return (
    <UploadFeaturedImageHolder>
      <Header>{header}</Header>
      <ImgHolder onClick={() => setUploadModal(true)}>
        {image !== "" && <Img src={image} />}
        {image === "" && (
          <NoImg>
            <FiImage />
          </NoImg>
        )}
        <UploadButton className="upload">
          <FiUploadCloud />
        </UploadButton>
      </ImgHolder>
      <Modal
        open={uploadModal}
        showClose
        close={() => {
          setUploadModal(false);
        }}
      >
        <Header>Upload Featured Image</Header>
        {!uploadImage && (
          <DropZone {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                <FiShare className="icon" />
                <p>
                  Drag files here to upload or
                  <span className="red">select a file</span>
                  from your device
                </p>
              </>
            )}
          </DropZone>
        )}
        {uploadImage && (
          <Crop>
            <CropWrapper>
              <ReactCrop
                src={uploadImage}
                crop={crop}
                onImageLoaded={(img) => setImageRef(img)}
                onChange={(newCrop) => setCrop(newCrop)}
              />
            </CropWrapper>
            <Button
              width="calc(50% - 5px)"
              onClick={() => setUploadImage(null)}
              type="button"
              value="Change Image"
              loading={loading}
              margin="0 5px 0 0"
            />
            <Button
              width="calc(50% - 5px)"
              onClick={createCroppedImage}
              type="button"
              kind="secondary"
              value="Confirm crop & Upload"
              margin="0 0 0 5px"
              loading={loading}
            />
          </Crop>
        )}
      </Modal>
    </UploadFeaturedImageHolder>
  );
};

UploadFeaturedImage.propTypes = {
  image: PropTypes.string.isRequired,
  header: PropTypes.string,
  setImage: PropTypes.func.isRequired,
};
UploadFeaturedImage.defaultProps = {
  header: "",
};

export default UploadFeaturedImage;
