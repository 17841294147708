/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  SelectHeader,
  StyledSelect,
  Icon,
  Select,
  Validation,
} from "./BorderSelect.style";

const BorderSelect = ({
  header,
  name,
  label,
  register,
  required,
  error,
  value,
  icon,
  options,
  onChange,
  validationLabel,
  margin,
  readOnly,
}) => {
  return (
    <Container margin={margin}>
      <SelectHeader>{header}</SelectHeader>
      <Select>
        <StyledSelect
          name={name}
          label={label}
          placeholder={label}
          ref={register ? register(required) : null}
          defaultValue={value}
          onChange={onChange}
          indent={icon ? "30px" : "0"}
          disabled={readOnly}
        >
          {label ? <option value="">{label}</option> : null}
          {options.map((option) => {
            return (
              <option
                value={typeof option !== "string" ? option.value : option}
                key={typeof option !== "string" ? option.value : option}
              >
                {typeof option !== "string" ? option.text : option}
              </option>
            );
          })}
        </StyledSelect>
        {icon ? <Icon>{icon}</Icon> : null}
      </Select>
      {!error && validationLabel && <Validation>*{validationLabel}</Validation>}
      <div>{error}</div>
    </Container>
  );
};

BorderSelect.defaultProps = {
  header: "",
  name: "",
  label: "",
  icon: undefined,
  register: undefined,
  required: undefined,
  error: undefined,
  onChange: undefined,
  margin: undefined,
  validationLabel: undefined,
  readOnly: false,
};

BorderSelect.propTypes = {
  // Select field header
  header: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  icon: PropTypes.element,
  readOnly: PropTypes.bool,
  // react-hook-form validation object
  register: PropTypes.func,
  required: PropTypes.object,
  // error object
  error: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  // specify type of Select expected below Select field
  validationLabel: PropTypes.string,
};

export default BorderSelect;
