import gql from "graphql-tag";

export const UPDATE_APP_USER = gql`
  mutation updateAppUser($data: AppUserUpdateInput!, $id: ID!) {
    updateAppUser(data: $data, where: { id: $id }) {
      id
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation requestAppPasswordReset($email: String!) {
    requestAppPasswordReset(data: { email: $email }) {
      result
      data
    }
  }
`;

export default {
  UPDATE_APP_USER,
  FORGOT_PASSWORD,
};
