import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Error from "../../../components/Error/ErrorMessage.component";
import CheckBox from "../../../components/Form/CheckBox/CheckBox.component";
import { GET_ALL_ACTIVE_USER_ROLES } from "../../../graphql/queries/userType.query";

const AdminUserForm = (props) => {
  const { data, loading } = useQuery(GET_ALL_ACTIVE_USER_ROLES, {
    fetchPolicy: "no-cache",
  });

  const {
    register,
    errors,
    adminUserEditData,
    adminUserData,
    setAdminUserData,
    superAdmin,
    setValue,
  } = props;

  useEffect(() => {
    if (adminUserEditData && adminUserData.firstName === "") {
      if (adminUserEditData.roles === null) {
        adminUserEditData.roles = "";
      }
      setValue("superAdmin", adminUserEditData.superAdmin);
      if (adminUserEditData.roles) {
        setValue("roles.connect.id", adminUserEditData.roles.id);
      } else {
        setValue("roles.connect.id", "");
      }
      setAdminUserData(adminUserEditData);
    }
  });

  const handleChange = (e) => {
    const tempAdminUserData = { ...adminUserData };
    tempAdminUserData.roles = e.target.value;
    setAdminUserData(tempAdminUserData);
  };

  const handleChecked = (e, name) => {
    const tempAdminUserData = { ...adminUserData };
    tempAdminUserData[name] = e.target.checked;
    setAdminUserData(tempAdminUserData);
  };

  const getUserTypes = () => {
    return data.userRoles.map((type) => {
      return {
        value: type.id,
        text: type.title,
      };
    });
  };

  return (
    <Column sub noPad>
      <Column sub noPad>
        <Row>
          <Column width="100%" sub>
            <H2>User Details</H2>
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="First Name"
              name="firstName"
              label=""
              type="text"
              value={adminUserData.firstName}
              register={register}
              required={{
                required: "please enter a first name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.firstName && <Error message={errors.firstName.message} />
              }
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Last Name"
              name="lastName"
              label=""
              type="text"
              value={adminUserData.lastName}
              register={register}
              required={{
                required: "please enter a last name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.lastName && <Error message={errors.lastName.message} />
              }
            />
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="Contact Number"
              name="cellphone"
              label=""
              type="text"
              value={adminUserData.cellphone}
              register={register}
              required={{
                required: "please enter a valid cell phone number",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.cellphone && <Error message={errors.cellphone.message} />
              }
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Email Address"
              name="email"
              label=""
              type="email"
              value={adminUserData.email}
              register={register}
              required={{
                required: "please enter a valid email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "please enter a valid email",
                },
              }}
              error={errors.email && <Error message={errors.email.message} />}
            />
          </Column>
        </Row>
        {loading === false && !adminUserData.superAdmin && (
          <Row>
            <Column width="50%" sub>
              <BorderSelect
                header="User Type"
                name="roles.connect.id"
                label="Please select a user type"
                value={adminUserData.roles ? adminUserData.roles.id : ""}
                register={register}
                onChange={handleChange}
                options={loading ? ["Loading please wait"] : getUserTypes()}
                required={{
                  required: "please select a User Type",
                }}
                error={
                  errors.roles && (
                    <Error message={errors.roles.connect.id.message} />
                  )
                }
              />
            </Column>
          </Row>
        )}
        {superAdmin && adminUserData.roles === "" && (
          <Row>
            <Column width="50%" sub>
              <CheckBox
                type="checkbox"
                defaultChecked={adminUserData.superAdmin}
                onChange={(e) => handleChecked(e, "superAdmin")}
                label="Super Admin"
                name="superAdmin"
                required={{
                  required: "please select a User Type or a super admin",
                }}
                register={register}
                error={
                  errors.superAdmin && (
                    <Error message={errors.superAdmin.message} />
                  )
                }
              />
            </Column>
          </Row>
        )}
      </Column>
    </Column>
  );
};

AdminUserForm.defaultProps = {
  errors: {},
  adminUserEditData: undefined,
  superAdmin: false,
};

AdminUserForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  adminUserEditData: PropTypes.object,
  adminUserData: PropTypes.object.isRequired,
  setAdminUserData: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  superAdmin: PropTypes.bool,
};

export default AdminUserForm;
