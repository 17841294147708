import gql from "graphql-tag";

export const SEND_PUSH_NOTIFICATION = gql`
  mutation sendPushnotification($data: pushNotificationInput!) {
    sendPushnotification(
      data: $data 
    ) 
  }
`;

export default {
    SEND_PUSH_NOTIFICATION,
};
