import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "react-apollo";
import { Container } from "./Employer.style";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import {
  GET_POSITIONS,
  GET_SERVICE_AGREEMENTS,
  GET_EXPENSE_CLAIMS,
} from "../../../graphql/queries/employer.query";

const positionStatusEnum = [
  "DRAFT",
  "PUBLISHED",
  "CLOSED_FOR_APPLICATIONS",
  "FINISHED",
  "ARCHIVED",
];

const serviceAgreementStatusEnum = [
  "DECLINED",
  "DRAFT",
  "APPROVED_BY_EMPLOYER",
  "APPROVED_BY_STAFF",
  "ACTIVE",
  "CANCELLED",
  "COMPLETED",
];

const expenseClaimStatusEnum = ["PENDING", "APPROVED", "DECLINED", "PAID"];

const EmployerDashboard = ({ employerData }) => {
  const { data: positionStatusData } = useQuery(GET_POSITIONS, {
    fetchPolicy: "no-cache",
    variables: { where: { author: { id: employerData.id } } },
  });
  const { data: serviceAgreementStatusData } = useQuery(
    GET_SERVICE_AGREEMENTS,
    {
      fetchPolicy: "no-cache",
      variables: { where: { employer: { id: employerData.id } } },
    }
  );
  const { data: expenseClaimsStatusData } = useQuery(GET_EXPENSE_CLAIMS, {
    fetchPolicy: "no-cache",
    variables: { where: { employer: { id: employerData.id } } },
  });
  const [positionStatus, setPositionStatus] = useState({
    labels: ["Draft", "Published", "Closed", "Finished", "Archived"],
    datasets: [
      {
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
          "#B9B9C2",
          "#07002F",
          "#5086F4",
          "#66C272",
          "#F53544",
        ],
      },
    ],
  });
  const [serviceAgreementStatus, setServiceAgreementStatus] = useState({
    labels: [
      "Declined",
      "Draft",
      "Approved By Employer",
      "Approved By Staff",
      "Active",
      "Cancelled",
      "Completed",
    ],
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: [
          "#f535c5",
          "#B9B9C2",
          "#07002F",
          "#5086F4",
          "#66C272",
          "#5a151b",
          "#17aa2b",
        ],
      },
    ],
  });
  const [expenseClaimStatus, setExpenseClaimStatus] = useState({
    labels: ["Pending", "Approved", "Declined", "Paid"],
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: ["#B9B9C2", "#66C272", "#F53544", "#07002F"],
      },
    ],
  });

  const GetTotal = (arr, accessor, compare) => {
    return arr.filter((obj) => obj[accessor] === compare).length;
  };

  useEffect(() => {
    if (positionStatusData) {
      const datasets = [
        {
          data: positionStatusEnum.map((val) => {
            return GetTotal(
              positionStatusData.positions,
              "positionStatus",
              val
            );
          }),
          backgroundColor: [
            "#B9B9C2",
            "#07002F",
            "#5086F4",
            "#66C272",
            "#F53544",
          ],
        },
      ];
      setPositionStatus((prevState) => {
        return {
          ...prevState,
          datasets,
        };
      });
    }
  }, [positionStatusData]);

  useEffect(() => {
    if (expenseClaimsStatusData) {
      const datasets = [
        {
          data: expenseClaimStatusEnum.map((val) => {
            return GetTotal(
              expenseClaimsStatusData.expenseClaims,
              "status",
              val
            );
          }),
          backgroundColor: ["#B9B9C2", "#66C272", "#F53544", "#07002F"],
        },
      ];
      setExpenseClaimStatus((prevState) => {
        return {
          ...prevState,
          datasets,
        };
      });
    }
  }, [expenseClaimsStatusData]);

  useEffect(() => {
    if (serviceAgreementStatusData) {
      const datasets = [
        {
          data: serviceAgreementStatusEnum.map((val) => {
            return GetTotal(
              serviceAgreementStatusData.serviceAgreements,
              "status",
              val
            );
          }),
          backgroundColor: [
            "#f535c5",
            "#B9B9C2",
            "#07002F",
            "#5086F4",
            "#66C272",
            "#5a151b",
            "#17aa2b",
          ],
        },
      ];
      setServiceAgreementStatus((prevState) => {
        return {
          ...prevState,
          datasets,
        };
      });
    }
  }, [serviceAgreementStatusData]);

  return (
    <Column style={{ marginTop: "15px", height: "auto" }}>
      <Container>
        <Row>
          <Column sub>
            <Row>
              <Column sub>
                <H2 style={{ textAlign: "center" }}>Total Positions</H2>
              </Column>
            </Row>
            <Row>
              <Column sub noPad>
                <Doughnut data={positionStatus} />
              </Column>
            </Row>
          </Column>
          <Column sub>
            <Row>
              <Column sub>
                <H2 style={{ textAlign: "center" }}>
                  Total Service Agreements
                </H2>
              </Column>
            </Row>
            <Row>
              <Column sub noPad>
                <Doughnut data={serviceAgreementStatus} />
              </Column>
            </Row>
          </Column>
          <Column sub>
            <Row>
              <Column sub>
                <H2 style={{ textAlign: "center" }}>Total Expense Claims</H2>
              </Column>
            </Row>
            <Row>
              <Column sub noPad>
                <Doughnut data={expenseClaimStatus} />
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </Column>
  );
};

EmployerDashboard.propTypes = {
  employerData: PropTypes.object.isRequired,
};

export default EmployerDashboard;
