import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import ReactStars from "react-rating-stars-component";
import { RiStarHalfLine, RiStarLine } from "react-icons/ri";

import { Container } from "./Staff.style";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import { GET_RATINGS } from "../../../graphql/queries/staff.query";
import { COLOR_PRIMARY } from "../../../common/styles/Colors";

const Rating = styled.div`
  flex: 0 0 33.33%;
  float: left;
  padding: 0 7.5px;
  margin-bottom: 15px;
  .holder {
    width: 100%;
    padding: 18px;
    border: 1px solid ${COLOR_PRIMARY};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-flow: column;
  }
  .rating {
    margin-bottom: 8px;
  }
  .ratedBy {
    display: flex;
  }
`;

const StaffReviews = ({ staffData }) => {
  const { data: ratingsData, loading } = useQuery(GET_RATINGS, {
    fetchPolicy: "no-cache",
    variables: {
      where: {
        ratedStaff: { id: staffData.staff.id },
        ratedProfile: "STAFF",
      },
    },
  });

  if (loading) {
    return "Loading...";
  }

  return (
    <Column style={{ marginTop: "15px", height: "auto" }}>
      <Container>
        <Row>
          <Column sub style={{ flexFlow: "row", flexWrap: "wrap" }}>
            {ratingsData.ratings.length === 0 ? (
              <H2>No reviews yet.</H2>
            ) : (
              ratingsData.ratings.map((rating) => {
                return (
                  <Rating key={rating.id}>
                    <div className="holder">
                      <div className="rating">
                        <ReactStars
                          count={5}
                          size={24}
                          isHalf
                          edit={false}
                          value={rating.numberRating}
                          activeColor={COLOR_PRIMARY}
                          fullIcon={<RiStarLine />}
                          halfIcon={<RiStarHalfLine />}
                          emptyIcon={<RiStarLine />}
                        />
                      </div>
                      <div className="ratedBy">
                        {`${rating.author.firstName} ${rating.author.lastName} (${rating.author.employer.companyName})`}
                      </div>
                      {rating.ratingText && (
                        <div className="message">{rating.ratingText}</div>
                      )}
                    </div>
                  </Rating>
                );
              })
            )}
          </Column>
        </Row>
      </Container>
    </Column>
  );
};

StaffReviews.propTypes = {
  staffData: PropTypes.object.isRequired,
};

export default StaffReviews;
