import styled from "styled-components";
import { COLOR_PRIMARY } from "../../common/styles/Colors";

export const CalendarWrapper = styled.div`
  .fc {
    .fc-button-primary {
      background-color: ${COLOR_PRIMARY};
      border-color: ${COLOR_PRIMARY};
      &:focus {
        box-shadow: none;
      }
    }
    .fc-daygrid-event-harness {
      cursor: pointer;
    }
  }
`;

export default {
  CalendarWrapper,
};
