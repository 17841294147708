import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import styled from "styled-components";
import moment from "moment";
import DashboardCard from "../../components/DashboardCard/DashboardCard.component";
import { H3, H4 } from "../../components/Typography/Typography.component";
import { GET_FEEDBACK } from "../../graphql/queries/feedback.query";
import { COLOR_PRIMARY } from "../../common/styles/Colors";
import Button from "../../components/Button/Button.component";
import { UPDATE_FEEDBACK } from "../../graphql/mutations/Feedback.mutation";

export const FeedbackHolder = styled.div`
  display: block;
  width: 100%;
  background: white;
`;

export const FeedbackItem = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid rgb(7 0 47 / 0.2);
  svg {
    margin-right: 10px;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const Left = styled.div`
  display: inline-block;
  width: 20%;
`;

export const Right = styled.div`
  display: inline-flex;
  width: 80%;
  text-align: left;
  align-items: center;
  justify-items: flex-start;
  justify-content: space-between;
`;
export const Body = styled.span`
  font-size: 14px;
  width: 60%;
  color: ${COLOR_PRIMARY};
  font-weight: 400;
  margin: 0;
`;
export const ReadText = styled.span`
  font-size: 14px;
  width: 20%;
  color: ${COLOR_PRIMARY};
  font-weight: 400;
  margin: 0;
`;

const Feedback = () => {
  const [feedbacks, setFeedback] = useState([]);

  const [getFeedback, { loading }] = useLazyQuery(GET_FEEDBACK, {
    onCompleted: (res) => {
      setFeedback(res.feedbacks);
    },
    variables: { orderBy: "createdAt_DESC" },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getFeedback();
  }, [getFeedback]);

  const [updateFeedback] = useMutation(UPDATE_FEEDBACK);

  return (
    <DashboardCard title="Feedback">
      <div style={{ width: "100%", background: "white" }}>
        {loading ? (
          <p>loading</p>
        ) : (
          <FeedbackHolder>
            {feedbacks &&
              feedbacks.length > 0 &&
              feedbacks.map((feedback) => (
                <>
                  <FeedbackItem>
                    <Left>
                      <H3 style={{ marginBottom: "10px" }}>
                        {`From : ${feedback.appUser.email}`}
                      </H3>
                      <H4>
                        {`${moment(feedback.createdAt).format("DD MMMM")}`}
                      </H4>
                    </Left>
                    <Right>
                      <Body>{feedback.body}</Body>
                      <H4>{feedback.type}</H4>
                      {!feedback.read ? (
                        <Button
                          width="20%"
                          onClick={() => {
                            updateFeedback({
                              variables: {
                                where: { id: feedback.id },
                                data: {
                                  read: true,
                                },
                              },
                            }).then(() => getFeedback());
                          }}
                          type="button"
                          kind="secondary"
                          value="Mark as read"
                        />
                      ) : (
                        <ReadText>Already read</ReadText>
                      )}
                    </Right>
                  </FeedbackItem>
                </>
              ))}
            {feedbacks && feedbacks.length === 0 && (
              <H3 style={{ padding: "18px 0" }}>No feedback</H3>
            )}
          </FeedbackHolder>
        )}
      </div>
    </DashboardCard>
  );
};

export default Feedback;
