import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: ${(props) => (props.sub ? "column" : "row")};
  background: white;
  z-index: ${(props) => (props.zIndex ? props.zIndex : "unset")};

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export default Container;
