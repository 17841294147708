import styled from "styled-components";

export const StyledInput = styled.textarea`
  min-height: 140px;
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc2ca;
  border-radius: 4px;
  color: #63636d;
  font-size: 16px;
  font-family: Lato, sans-serif;
`;

export const InputHeader = styled.div`
  margin-bottom: 10px;
  color: #63636d;
  font-size: 14px;
  font-weight: 400;
`;

export const Container = styled.div`
  margin: 0;
`;

export const Icon = styled.span`
  color: #63636d;
  margin: 0 5px;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
`;

export const Validation = styled.div`
  width: 100%;
  color: grey;
  font-size: 12px;
  line-height: auto;
  margin: 0;
  padding-left: 5px;
  margin-bottom: 1%;
  overflow: visible;
  white-space: wrap;
`;
