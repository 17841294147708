import styled from "styled-components";
import { motion } from "framer-motion";

export const ContactBlock = styled(motion.div)`
  padding: 10px;
  background-color: white;
  box-shadow: 0px 4px 10px -6px #8386a3;
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
`;

export const ContactHeaderImageHolder = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  overflow: hidden;
`;

export const ContactHeaderImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ContactDetails = styled.div`
  width: 100%;
  height: auto;
`;

export const ContactHeaderFullName = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  margin-left: 16px;
  margin-bottom: 5px;
`;

export const ContactLastMessage = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  margin-left: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Date = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #adacb6;
  font-size: 14px;
`;
