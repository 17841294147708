import React from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete from "react-places-autocomplete";
import { Controller } from "react-hook-form";
import {
  Container,
  InputHeader,
  StyledInput,
  PlacesWrapper,
} from "./GooglePlaces.style";

const GooglePlaces = ({
  control,
  name,
  defaultValue,
  label,
  header,
  required,
}) => {
  const handleSelect = (onChange, address) => {
    onChange(address);
  };
  return (
    <Container>
      <InputHeader>{header}</InputHeader>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={required}
        render={({ onChange, value }) => (
          <PlacesAutocomplete
            value={value}
            onChange={onChange}
            onSelect={(address) => handleSelect(onChange, address)}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <PlacesWrapper>
                <StyledInput
                  {...getInputProps({
                    placeholder: label || "Search Places ...",
                    className: "location-search-input",
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          backgroundColor: "#c0c0c0",
                          cursor: "pointer",
                          padding: "15px 5px",
                          borderBottom: "1px solid #e2e2e2",
                        }
                      : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                          padding: "15px 5px",
                          borderBottom: "1px solid #e2e2e2",
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </PlacesWrapper>
            )}
          </PlacesAutocomplete>
        )}
      />
    </Container>
  );
};

GooglePlaces.defaultProps = {
  header: "",
  required: {},
  defaultValue: null,
};

GooglePlaces.propTypes = {
  // input field header
  name: PropTypes.string.isRequired,
  header: PropTypes.string,
  required: PropTypes.object,
  label: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  defaultValue: PropTypes.any,
};

export default GooglePlaces;
