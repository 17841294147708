import gql from "graphql-tag";

export const GET_EXPENSE_CLAIM_TYPES = gql`
  query getExpenseClaimTypes(
    $order: ExpenseClaimTypeOrderByInput
    $first: Int!
    $skip: Int!
    $where: ExpenseClaimTypeWhereInput!
  ) {
    expenseClaimTypes(
      orderBy: $order
      first: $first
      skip: $skip
      where: $where
    ) {
      title
      description
      status
      id
    }
    expenseClaimTypesCount: expenseClaimTypes(where: $where) {
      id
    }
  }
`;

export const GET_EXPENSE_CLAIM_TYPES_EXPORT = gql`
  query getExpenseClaimTypes(
    $order: ExpenseClaimTypeOrderByInput
    $where: ExpenseClaimTypeWhereInput!
  ) {
    expenseClaimTypes(orderBy: $order, where: $where) {
      title
      description
      status
      id
    }
  }
`;

export const GET_EXPENSE_CLAIM_TYPE = gql`
  query getExpenseClaimType($id: ID) {
    expenseClaimType(where: { id: $id }) {
      title
      description
      status
      id
    }
  }
`;

export const GET_ALL_ACTIVE_EXPENSE_CLAIM_TYPES = gql`
  query {
    expenseClaimTypes(where: { status: ACTIVE }) {
      title
      id
    }
  }
`;

export default {
  GET_EXPENSE_CLAIM_TYPES,
  GET_EXPENSE_CLAIM_TYPES_EXPORT,
  GET_EXPENSE_CLAIM_TYPE,
};
