import gql from "graphql-tag";

export const UPDATE_DEFAULT_PAYMENT_METHOD = gql`
  mutation updateDefaultPaymentMethod(
    $stripeId: String!
    $paymentMethodId: String!
  ) {
    updateDefaultPaymentMethod(
      data: { stripeId: $stripeId, paymentMethodId: $paymentMethodId }
    )
  }
`;

export default {
  UPDATE_DEFAULT_PAYMENT_METHOD,
};
