/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Controller } from "react-hook-form";
import { SingleDatePicker as ReactSingleDatePicker } from "react-dates";
import {
  Container,
  InputHeader,
  StyledWrapper,
} from "./SingleDatePicker.style";

const SingleDatePicker = ({
  control,
  name,
  defaultValue,
  label,
  header,
  required,
  openDirection,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <Container>
      <InputHeader>{header}</InputHeader>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue ? moment(defaultValue) : defaultValue}
        rules={required}
        render={({ onChange, value }) => (
          <StyledWrapper>
            <ReactSingleDatePicker
              block
              numberOfMonths={1}
              placeholder={label}
              focused={focus}
              openDirection={openDirection}
              onFocusChange={({ focused }) => {
                setFocus(focused);
              }}
              date={value}
              onDateChange={(date) => onChange(date)}
            />
          </StyledWrapper>
        )}
      />
    </Container>
  );
};

SingleDatePicker.defaultProps = {
  header: "",
  required: {},
  openDirection: "down",
  defaultValue: null,
};

SingleDatePicker.propTypes = {
  // input field header
  name: PropTypes.string.isRequired,
  header: PropTypes.string,
  required: PropTypes.object,
  label: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  defaultValue: PropTypes.any,
  openDirection: PropTypes.oneOf(["down", "up"]),
};

export default SingleDatePicker;
