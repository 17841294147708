/* eslint-disable react/forbid-prop-types */
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
// import { RiCheckDoubleLine } from "react-icons/ri";
import { AnimatePresence } from "framer-motion";

import {
  ContactBlock,
  ContactHeaderImageHolder,
  ContactHeaderImage,
  ContactHeaderFullName,
  ContactLastMessage,
  ContactDetails,
  Date as DateComponent,
} from "./Contacts.style";

const Contacts = ({ contacts, setActiveChat, search }) => {
  const sortContacts = () => {
    let retVal = contacts.filter((c) => c.messages.length > 0);

    retVal = retVal.sort((a, b) => {
      if (a.messages.length === 0) {
        return 0;
      }
      if (b.messages.length === 0) {
        return 0;
      }

      const objA = new Date(a.messages[a.messages.length - 1].createdAt);
      const objB = new Date(b.messages[b.messages.length - 1].createdAt);

      return objB - objA;
    });

    if (search !== "") {
      retVal = retVal.filter(
        (contact) =>
          contact.createdBy.firstName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          contact.createdBy.lastName
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    return retVal;
  };

  return (
    <>
      <AnimatePresence>
        {sortContacts().map((contact) => {
          return (
            <ContactBlock
              key={contact.id}
              onClick={() => setActiveChat(contact)}
              layoutTransition
              initial={{ opacity: 0, y: -25 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -25 }}
              transition={{ duration: 0.5 }}
            >
              <ContactHeaderImageHolder>
                <ContactHeaderImage src="https://img.icons8.com/bubbles/100/000000/man-in-white-shirt.png" />
              </ContactHeaderImageHolder>
              <ContactDetails>
                <ContactHeaderFullName>
                  {`${contact.createdBy.firstName} ${contact.createdBy.lastName}`}
                </ContactHeaderFullName>
                <ContactLastMessage>
                  {contact.messages.length > 0
                    ? contact.messages[contact.messages.length - 1].message
                    : "No messages yet"}
                </ContactLastMessage>
                <DateComponent>
                  {contact.messages.length > 0
                    ? moment(
                        contact.messages[contact.messages.length - 1].createdAt
                      ).fromNow()
                    : moment().fromNow()}
                </DateComponent>
              </ContactDetails>
            </ContactBlock>
          );
        })}
      </AnimatePresence>
    </>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  setActiveChat: PropTypes.func.isRequired,
};

export default Contacts;
