import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { useMutation } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./User.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";
import { generatePassword } from "../../../utils";

// GQL
import { CREATE_ADMIN_USER } from "../../../graphql/mutations/adminUser.mutation";
import { formatGraphqlError } from "../../../utils/api";

const AdminUserNew = () => {
  const { setActiveSideNav, setPageTitle, superAdmin } = useContext(
    APP_CONTEXT
  );
  const [adminUserData, setAdminUserData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    cellphone: "",
    roles: "",
    superAdmin: false,
    status: "NEW",
  });
  const [createAdminUser, { loading }] = useMutation(CREATE_ADMIN_USER);

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Create New Admin User");
  });

  const { register, handleSubmit, errors, setValue } = useForm();

  const changeStatus = (e) => {
    const tempAdminUserData = { ...adminUserData };
    tempAdminUserData.status = e.target.value;
    setAdminUserData(tempAdminUserData);
  };

  const getColor = () => {
    switch (adminUserData.status) {
      case "ACTIVE":
        return "#39C568";
      case "NEW":
        return "#1a0388";
      case "ARCHIVE":
        return "#FF003A";
      default:
        return "#1a0388";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Admin
     * push to list all user types
     */
    const submitValues = values;
    const pw = generatePassword();
    submitValues.password = pw;

    createAdminUser({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({ title: "Create Admin Succeeded", message: "" });
        history.push("/app/user-management/admin");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Admin Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <PageHeader title="Create New Admin" back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            adminUserData={adminUserData}
            setAdminUserData={setAdminUserData}
            superAdmin={superAdmin}
            setValue={setValue}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={adminUserData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "NEW", text: "New" },
              { value: "ACTIVE", text: "Active" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please select a Status",
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Create User"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => history.goBack()}
            type="button"
            value="Cancel"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default AdminUserNew;
