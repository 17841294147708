import React from "react";
import PropTypes from "prop-types";
import { Container, CheckBox, CheckMark } from "./CheckBox.style";

const Checkbox = ({
  width,
  margin,
  label,
  defaultChecked,
  register,
  required,
  name,
  onChange,
  error,
}) => {
  return (
    <Container width={width} margin={margin}>
      {label}
      <CheckBox
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={onChange}
        name={name}
        ref={register ? register(required) : undefined}
      />
      <CheckMark className="checkMark" />
      <div>{error}</div>
    </Container>
  );
};

Checkbox.defaultProps = {
  width: "",
  margin: "",
  defaultChecked: undefined,
  register: undefined,
  onChange: undefined,
  required: {},
  error: undefined,
};

Checkbox.propTypes = {
  width: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  register: PropTypes.func,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  required: PropTypes.any,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
};

export default Checkbox;
