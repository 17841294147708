import React from "react";
import { GET_CHAT_GROUPS } from "../../graphql/queries/chatGroups.query";
import { SUBSCRIBE_TO_CHAT_MESSAGES_STAFF } from "../../graphql/subscriptions/chat.subscription";
import ChatHolder from "./ChatHolder";

const StaffChat = () => {
  return (
    <ChatHolder
      pageTitle="Staff Chat"
      activeSideNav="support"
      GET_CHAT_GROUPS={GET_CHAT_GROUPS}
      SUBSCRIBE_TO_CHAT_MESSAGES={SUBSCRIBE_TO_CHAT_MESSAGES_STAFF}
      variables={{ userGroup: "STAFF" }}
    />
  );
};

export default StaffChat;
