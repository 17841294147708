/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { FiSend } from "react-icons/fi";
import { AnimatePresence } from "framer-motion";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { GET_CHAT_MESSAGES } from "../../graphql/queries/chat.query";
import { SEND_CHAT_MESSAGE } from "../../graphql/mutations/sendChatMessage.mutation";
import { formatGraphqlError } from "../../utils/api";

import {
  ChatHeader,
  ChatHeaderImageHolder,
  ChatHeaderImage,
  ChatHeaderFullName,
  ChatHolder,
  ChatBox,
  Input,
  StyledInput,
  Icon,
  ChatMessages,
  ChatMessage,
  UserName,
  Date as DateComponent,
} from "./Chat.style";
import Notification from "../Toast/Toast.component";

const Chat = ({
  activeChat,
  newMessageFromSocket,
  setNewMessageFromSocket,
}) => {
  const [refetch, { data, loading }] = useLazyQuery(GET_CHAT_MESSAGES, {
    fetchPolicy: "no-cache",
  });
  const [sendChatMessage] = useMutation(SEND_CHAT_MESSAGE);
  const [newMessage, setNewMessage] = useState([]);
  const [messages, setMessages] = useState([]);

  const send = () => {
    const message = newMessage;
    setNewMessage("");
    sendChatMessage({
      variables: {
        message,
        chatGroupId: activeChat.id,
      },
    }).catch((err) =>
      Notification.error({
        title: "Failed to send message",
        message: formatGraphqlError(err),
      })
    );
  };

  const chatMessagesRef = useRef(null);

  const checkShiftEnter = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      e.preventDefault();
      send();
    }
  };

  const getDate = (d) => {
    const today = moment().startOf("day");
    const date = moment(d).startOf("day");
    const diff = today.diff(date, "days");
    if (diff === 0) {
      return moment(d).format("HH:mm");
    }
    return moment(d).format("YYYY/MM/DD HH:mm");
  };

  const getChatData = () => {
    const retVal = messages.sort((a, b) => {
      const objA = new Date(a.createdAt);
      const objB = new Date(b.createdAt);

      return objB - objA;
    });
    return retVal.reverse();
  };

  useEffect(() => {
    if (activeChat) {
      setMessages([]);
      refetch({
        variables: {
          id: activeChat.id,
        },
      });
    }
  }, [activeChat, refetch]);

  useEffect(() => {
    if (!loading && data) {
      setMessages(data.chats);
      setTimeout(() => {
        chatMessagesRef.current.scroll({
          top: chatMessagesRef.current.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 50);
    }
  }, [data, loading]);

  useEffect(() => {
    if (newMessageFromSocket) {
      const { id } = activeChat;
      if (newMessageFromSocket.chatGroup.id === id) {
        const newMessages = Array.from(messages);
        newMessages.push(newMessageFromSocket);
        setNewMessageFromSocket(null);
        setMessages(newMessages);
        setTimeout(() => {
          chatMessagesRef.current.scroll({
            top: chatMessagesRef.current.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
        }, 50);
      }
    }
  }, [newMessageFromSocket, setNewMessageFromSocket, activeChat, messages]);

  if (activeChat) {
    return (
      <>
        <ChatHeader>
          <ChatHeaderImageHolder>
            <ChatHeaderImage src="https://img.icons8.com/bubbles/100/000000/man-in-white-shirt.png" />
          </ChatHeaderImageHolder>
          <ChatHeaderFullName>{`${activeChat.createdBy.firstName} ${activeChat.createdBy.lastName}`}</ChatHeaderFullName>
        </ChatHeader>
        <ChatHolder>
          <ChatMessages ref={chatMessagesRef}>
            <AnimatePresence>
              {getChatData().map((m, i) => {
                return (
                  <ChatMessage
                    key={m.id}
                    position={m.adminUser ? "right" : "left"}
                    initial={
                      m.adminUser
                        ? { opacity: 0, x: 25 }
                        : { opacity: 0, x: -25 }
                    }
                    animate={{ opacity: 1, x: 0 }}
                    exit={
                      m.adminUser
                        ? { opacity: 0, x: 25 }
                        : { opacity: 0, x: -25 }
                    }
                    transition={{ duration: 0.5, delat: i * 0.2 }}
                  >
                    {m.adminUser && (
                      <UserName>
                        {`${m.adminUser.firstName} ${m.adminUser.lastName}`}
                      </UserName>
                    )}
                    {m.message}

                    <DateComponent>{getDate(m.createdAt)}</DateComponent>
                  </ChatMessage>
                );
              })}
            </AnimatePresence>
          </ChatMessages>
          <ChatBox>
            <Input>
              <StyledInput
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyUp={checkShiftEnter}
              />
              <Icon>
                <FiSend onClick={send} />
              </Icon>
            </Input>
          </ChatBox>
        </ChatHolder>
      </>
    );
  }
  return (
    <>
      <ChatHeader>
        <ChatHeaderImageHolder>
          <ChatHeaderImage src="https://img.icons8.com/bubbles/100/000000/man-in-white-shirt.png" />
        </ChatHeaderImageHolder>
        <ChatHeaderFullName>Click on a contact to chat</ChatHeaderFullName>
      </ChatHeader>
    </>
  );
};

Chat.defaultProps = {
  activeChat: null,
  newMessageFromSocket: null,
};
Chat.propTypes = {
  activeChat: PropTypes.object,
  newMessageFromSocket: PropTypes.object,
  setNewMessageFromSocket: PropTypes.func.isRequired,
};

export default Chat;
