import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import moment from "moment";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./NotificationManager.form";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  UPDATE_AUTOMATIC_NOTIFICATION,
  DELETE_AUTOMATIC_NOTIFICATION,
} from "../../../graphql/mutations/automaticNotification.mutation";
import { GET_AUTOMATIC_NOTIFICATION } from "../../../graphql/queries/automaticNotification.query";

import { formatGraphqlError } from "../../../utils/api";
import { H2 } from "../../../components/Typography/Typography.component";

const AutomaticNotificationEdit = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [automaticNotificationData, setAutomaticNotificationData] = useState({
    id: "",
    name: "",
    description: "",
    message: "",
    status: "ON",
    endDate: null,
    frequency: {
      notificationType: "ONCEOFF",
      repeatEveryOption: "Week",
      repeatEveryNumber: "1",
      repeatCounter: "0",
      dom: "1",
      startDate: moment(),
      dow: {
        SU: true,
        MO: false,
        TU: false,
        WE: false,
        TH: false,
        FR: false,
        SA: false,
      },
    },
  });
  const [updateAutomaticNotification, { loading }] = useMutation(
    UPDATE_AUTOMATIC_NOTIFICATION
  );
  const [deleteAutomaticNotification] = useMutation(
    DELETE_AUTOMATIC_NOTIFICATION
  );
  const { id } = useParams();
  const automaticNotification = useQuery(GET_AUTOMATIC_NOTIFICATION, {
    fetchPolicy: "no-cache",
    variables: { id },
  });

  const { data, loading: loadingData } = automaticNotification;

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Manage Automatic Notification");
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
    control,
  } = useForm();

  const changeStatus = (e) => {
    const tempAutomaticNotificationData = { ...automaticNotificationData };
    tempAutomaticNotificationData.status = e.target.value;
    setAutomaticNotificationData(tempAutomaticNotificationData);
  };

  const getColor = () => {
    switch (automaticNotificationData.status) {
      case "ON":
        return "#39C568";
      case "OFF":
        return "#FF003A";
      case "ARCHIVE":
        return "#FF003A";
      default:
        return "#FF003A";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new AutomaticNotification
     * push to list all automaticNotifications
     */

    updateAutomaticNotification({
      variables: {
        data: values,
        where: {
          id: automaticNotificationData.id,
        },
      },
    })
      .then(() => {
        Notification.success({
          title: "Saved successfully",
          message: "You updated a Automatic Notification",
        });
        history.push("/app/system-configuration/notification-manager");
      })
      .catch((err) =>
        Notification.error({
          title: "Update Automatic Notification Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteAutomaticNotification({
          variables: {
            id: automaticNotificationData.id,
          },
        })
          .then(() => {
            Swal.fire(
              "Deleted!",
              "Automatic Notification has been deleted.",
              "success"
            ).then(() => {
              history.push("/app/system-configuration/notification-manager");
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Automatic Notification Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  if (automaticNotification.loading) {
    return "Loading...";
  }

  return (
    <>
      <PageHeader
        title={`Edit Automatic Notification: ${automaticNotificationData.name}`}
        back
      />
      <Row>
        <Column width="70%" noPad>
          {!loadingData ? (
            <Form
              register={register}
              errors={errors}
              automaticNotificationData={automaticNotificationData}
              setAutomaticNotificationData={setAutomaticNotificationData}
              automaticNotificationEditData={data && data.automaticNotification}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              control={control}
            />
          ) : (
            <H2>Loading data...</H2>
          )}
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={automaticNotificationData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ON", text: "On" },
              { value: "OFF", text: "Off" },
              { value: "ARCHIVE", text: "Archive" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a AutomaticNotification ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={() => handleDelete()}
            type="button"
            value="Archive"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default AutomaticNotificationEdit;
