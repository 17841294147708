import React from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { CalendarWrapper } from "./Calendar.style";
/**
 * Calendar component for generic use in project.
 */

const Calendar = ({ events, handleDateClick }) => {
  return (
    <CalendarWrapper>
      <FullCalendar
        contentHeight="68vh"
        windowResizeDelay={400}
        nextDayThreshold="00:00:01"
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventClick={handleDateClick}
        firstDay={1}
      />
    </CalendarWrapper>
  );
};

Calendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string,
      backgroundColor: PropTypes.string.isRequired,
      borderColor: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleDateClick: PropTypes.func.isRequired,
};

export default Calendar;
