import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { GoPrimitiveDot } from "react-icons/go";
import { useLazyQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
import {
  SmallImageHolder,
  SmallImage,
  CompanyHolder,
  CompanyName,
} from "./Employer.style";

// GQL
import {
  GET_EMPLOYERS,
  GET_EMPLOYERS_EXPORT,
} from "../../../graphql/queries/employer.query";

const UserAdminAll = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_EMPLOYERS, {
    variables: { order: "createdAt_DESC" },
    fetchPolicy: "no-cache",
  });
  const [getAdminUsersExport, employerProfilesExport] = useLazyQuery(
    GET_EMPLOYERS_EXPORT
  );
  const [variables, setVariables] = useState({
    where: { AND: [{ NOT: { companyName: null } }] },
  });

  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Employers");
  });

  const getColor = (value) => {
    if (value) {
      return "#FF003A";
    }
    return "#39C568";
  };

  const HeaderInfo = [
    {
      Header: "Name",
      accessor: "companyName",
      search: true,
      id: "companyName",
      Cell: ({ row }) => {
        return (
          <CompanyHolder>
            <SmallImageHolder>
              <SmallImage src={row.original.companyLogo} />
            </SmallImageHolder>
            <CompanyName>{row.original.companyName}</CompanyName>
          </CompanyHolder>
        );
      },
    },
    {
      Header: "Contact Name",
      accessor: "nameHR",
      id: "ceoOrManagerName",
    },
    {
      Header: "Contact Mobile",
      accessor: "mobileContactPerson",
      id: "mobileContactPerson",
    },
    {
      Header: "Contact Phone",
      accessor: "phoneNumberContactPerson",
      id: "phoneNumberContactPerson",
    },
    {
      Header: "Contact Email",
      accessor: "emailHR",
      id: "ceoOrManagerEmail",
    },
    {
      Header: "Status",
      accessor: "suspended",
      id: "suspended",
      Cell: ({ row }) => (
        <TableIcons>
          <GoPrimitiveDot
            style={{
              fontSize: "25px",
              color: getColor(row.original.suspended),
            }}
          />
          <span>{row.original.suspended ? "suspended" : "active"}</span>
        </TableIcons>
      ),
    },
    {
      Header: "Subscription",
      accessor: "subscriptionId",
      id: "subscriptionId",
      Cell: ({ row }) => {
        return (
          <TableIcons>
            <GoPrimitiveDot
              style={{
                fontSize: "25px",
                color: getColor(!row.original.subscriptionId),
              }}
            />
            <span>{!row.original.subscriptionId ? "None" : "Active"}</span>
          </TableIcons>
        );
      },
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <Link to={`/app/user-management/company/${row.original.id}`}>
            <FiEye
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          </Link>
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
    {
      header: "Status:",
      accessor: "status",
      placeholder: "Please select a Status",
      type: "select",
      options: [
        { value: "ACTIVE", text: "Active" },
        { value: "NEW", text: "New" },
      ],
    },
  ];

  return (
    <>
      <PageHeader title="Manage Companies" />
      <Table
        columns={HeaderInfo}
        data={(data && data.employerProfiles) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.employerProfilesCount.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Manage Companies"
        tableOptions={["search", "download", "columns", "filter"]}
        filters={filters}
        downloadData={employerProfilesExport}
        getDownloadData={getAdminUsersExport}
        downloadAccessor={
          employerProfilesExport.data
            ? employerProfilesExport.data.employerProfiles
            : []
        }
        fileName="EmployerProfiles"
      />
    </>
  );
};

export default UserAdminAll;
