import gql from "graphql-tag";

export const SUBSCRIBE_TO_CHAT_MESSAGES_EMPLOYER = gql`
  subscription {
    chat(
      where: {
        node: { chatGroup: { type: SUPPORT, userGroup: EMPLOYER } }
        mutation_in: [CREATED, UPDATED]
      }
    ) {
      node {
        id
        message
        createdAt
        appUser {
          id
          firstName
          lastName
        }
        adminUser {
          id
          firstName
          lastName
        }
        read
        chatGroup {
          id
          createdAt
          createdBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const SUBSCRIBE_TO_CHAT_MESSAGES_STAFF = gql`
  subscription {
    chat(
      where: {
        node: { chatGroup: { type: SUPPORT, userGroup: STAFF } }
        mutation_in: [CREATED, UPDATED]
      }
    ) {
      node {
        id
        message
        createdAt
        appUser {
          id
          firstName
          lastName
        }
        adminUser {
          id
          firstName
          lastName
        }
        read
        chatGroup {
          id
          createdAt
          createdBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default {
  SUBSCRIBE_TO_CHAT_MESSAGES_EMPLOYER,
  SUBSCRIBE_TO_CHAT_MESSAGES_STAFF,
};
