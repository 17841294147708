/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, SelectHeader, StyledSelect, Icon, Select } from './BorderSelect.style';

const BorderSelect = ({ header, name, label, value, icon, options, onChange, margin }) => {
  return (
    <Container margin={margin}>
      <SelectHeader>{header}</SelectHeader>
      <Select>
        <StyledSelect
          name={name}
          label={label}
          placeholder={label}
          defaultValue={value}
          onChange={onChange}
          indent={icon ? '30px' : '0'}
        >
          {label ? <option value="">{label}</option> : null}
          {options.map((option) => {
            return (
              <option
                value={typeof option !== 'string' ? option.value : option}
                key={typeof option !== 'string' ? option.value : option}
              >
                {typeof option !== 'string' ? option.text : option}
              </option>
            );
          })}
        </StyledSelect>
        {icon ? <Icon>{icon}</Icon> : null}
      </Select>
    </Container>
  );
};

BorderSelect.defaultProps = {
  header: '',
  name: '',
  label: '',
  icon: undefined,
  onChange: undefined,
  margin: undefined,
};

BorderSelect.propTypes = {
  // Select field header
  header: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  icon: PropTypes.element,
  // error object
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  margin: PropTypes.string,
};

export default BorderSelect;
