import gql from "graphql-tag";

export const CREATE_AUTOMATIC_NOTIFICATION = gql`
  mutation createAutomaticNotification(
    $data: AutomaticNotificationCreateInput!
  ) {
    createAutomaticNotification(data: $data) {
      id
    }
  }
`;

export const UPDATE_AUTOMATIC_NOTIFICATION = gql`
  mutation updateAutomaticNotification(
    $data: AutomaticNotificationUpdateInput!
    $where: AutomaticNotificationWhereUniqueInput!
  ) {
    updateAutomaticNotification(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_AUTOMATIC_NOTIFICATION = gql`
  mutation deleteAutomaticNotification($id: ID!) {
    deleteAutomaticNotification(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_AUTOMATIC_NOTIFICATION,
  UPDATE_AUTOMATIC_NOTIFICATION,
  DELETE_AUTOMATIC_NOTIFICATION,
};
