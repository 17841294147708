import styled from "styled-components";
import {
  COLOR_GREY3,
  COLOR_GREY2,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
} from "../../common/styles/Colors";

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const NoImg = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR_GREY3};
  color: ${COLOR_PRIMARY};
  font-size: 35px;
`;

const ImgHolder = styled.div`
  display: flex;
  width: 100%;
  background: white;
  border-radius: 3px;
  margin: 15px 0;
  flex-direction: column;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:hover {
    .upload {
      bottom: 0;
      opacity: 1;
    }
  }
`;

const UploadFeaturedImageHolder = styled.div`
  width: 100%;
`;

const UploadButton = styled.div`
  width: 100%;
  background-color: ${COLOR_GREY2};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: 24px;
  bottom: -100px;
  position: absolute;
  opacity: 0;
  transition: 0.2s ease-in-out;
`;

const Header = styled.div`
  margin-bottom: 10px;
  color: ${COLOR_PRIMARY};
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 23px;
`;

const Crop = styled.div``;

const DropZone = styled.div`
  width: 100%;
  height: 150px;
  border: 1px solid ${COLOR_GREY3};
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  color: ${COLOR_PRIMARY};
  cursor: pointer;
  .icon {
    font-size: 24px;
  }
  .red {
    color: ${COLOR_SECONDARY};
    text-decoration: underline;
    margin: 0 4px;
  }
`;

const CropWrapper = styled.div`
  width: 100%;
  max-height: 55vh;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;

export {
  UploadFeaturedImageHolder,
  Img,
  ImgHolder,
  NoImg,
  Header,
  UploadButton,
  DropZone,
  CropWrapper,
  Crop,
};
