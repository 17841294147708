import PropTypes, { string } from "prop-types";
import React, { useState } from "react";
import {
  StyledAccordion,
  AccordionHeader,
  AccordionBody,
  AccordionChildren,
} from "./Accordion.style";
/**
 * Accordion component for generic use in project.
 */

const variants = {
  open: {
    height: "auto",
  },
  close: {
    height: 0,
  },
};

const Accordion = ({ title, children, maxHeight, style }) => {
  const [open, setOpen] = useState(false);
  return (
    <StyledAccordion style={style}>
      <AccordionHeader onClick={() => setOpen(!open)}>{title}</AccordionHeader>
      <AccordionBody
        variants={variants}
        initial="close"
        animate={open ? "open" : "close"}
      >
        <AccordionChildren maxHeight={maxHeight}>{children}</AccordionChildren>
      </AccordionBody>
    </StyledAccordion>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  maxHeight: string,
  style: PropTypes.object,
};

Accordion.defaultProps = {
  maxHeight: null,
  style: {},
};

export default Accordion;
