import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { AnimatePresence } from "framer-motion";
import { APP_CONTEXT } from "../../../utils/context";

// GQL
import { GET_EMPLOYER } from "../../../graphql/queries/employer.query";
import Tabs from "../../../components/Tabs/Tabs.component";
import EmployerDashboard from "./User.dashboard";
import EmployerProfile from "./User.edit";
import EmployerCalendar from "./User.calendar";
import EmployerReviews from "./User.reviews";
import { MountTransition } from "../../../components/Animation/AnimatedRoutes/MountTransition.component";
import { Holder } from "./Employer.style";

const EmployerEdit = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { id } = useParams();
  const [refetch, getEmployerData] = useLazyQuery(GET_EMPLOYER, {
    fetchPolicy: "no-cache",
    variables: { id },
  });
  const [activeComponent, setActiveComponent] = useState("dashboard");

  const tabs = [
    {
      title: "Overview / Summary",
      id: "dashboard",
    },
    {
      title: "Profile",
      id: "profile",
    },
    {
      title: "Calendar",
      id: "calendar",
    },
    {
      title: "Reviews",
      id: "reviews",
    },
  ];
  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Companies User");
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (
    (getEmployerData.loading && !getEmployerData.called) ||
    !getEmployerData.data
  ) {
    return "loading";
  }

  return (
    <>
      <Tabs
        componentTabs={tabs}
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
        back
      />
      <Holder>
        <AnimatePresence>
          {activeComponent === "dashboard" && (
            <MountTransition slide={25} key="dashboard">
              <EmployerDashboard
                employerData={getEmployerData.data.employerProfile}
              />
            </MountTransition>
          )}
          {activeComponent === "profile" && (
            <MountTransition slide={25} key="profile">
              <EmployerProfile
                refetch={refetch}
                employerData={getEmployerData.data.employerProfile}
              />
            </MountTransition>
          )}
          {activeComponent === "calendar" && (
            <MountTransition slide={25} key="calendar">
              <EmployerCalendar
                employerData={getEmployerData.data.employerProfile}
              />
            </MountTransition>
          )}
          {activeComponent === "reviews" && (
            <MountTransition slide={25} key="reviews">
              <EmployerReviews
                employerData={getEmployerData.data.employerProfile}
              />
            </MountTransition>
          )}
        </AnimatePresence>
      </Holder>
    </>
  );
};

export default EmployerEdit;
