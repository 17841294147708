import styled from "styled-components";
import { motion } from "framer-motion";
import {
  COLOR_GREY3,
  COLOR_GREY2,
  COLOR_PRIMARY,
} from "../../../common/styles/Colors";

export const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc2ca;
  border-radius: 4px;
  color: #63636d;
  font-size: 16px;
`;

export const InputHeader = styled.div`
  margin-bottom: 10px;
  color: #63636d;
  font-size: 14px;
  font-weight: 400;
`;

export const Hint = styled.div`
  margin-top: 5px;
  color: #63636d;
  font-size: 10px;
  font-weight: 400;
`;

export const Container = styled.div`
  margin: 0;
`;

export const ChipsHolder = styled.div`
  margin: 0;
`;

export const ChipInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
  border: 0;
  outline: 0;
  padding: 0;
`;

export const Chip = styled(motion.div)`
  border-radius: 50px;
  background-color: ${COLOR_GREY2};
  padding: 5px 15px;
  font-size: 14px;
  display: inline-flex;
  margin: 5px;
  color: white;
  cursor: pointer;
  align-items: center;
  &:first-child {
    margin-left: 0;
  }
  svg {
    margin-left: 5px;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Icon = styled.span`
  color: #63636d;
  margin: 0 5px;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Suggestions = styled.div`
  position: absolute;
  top: 101%;
  left: 0%;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  background-color: white;
  border: 1px solid ${COLOR_GREY3};
`;

export const Suggestion = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${COLOR_GREY3};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${COLOR_PRIMARY};
    color: white;
  }
  &:last-child {
    border-bottom: 0;
  }
`;

export const Validation = styled.div`
  width: 100%;
  color: grey;
  font-size: 12px;
  line-height: auto;
  margin: 0;
  padding-left: 5px;
  margin-bottom: 1%;
  overflow: visible;
  white-space: wrap;
`;
