/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import AutoCompleteChips from "../../../components/Form/AutoCompleteChips/AutoCompleteChips.component";
// import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Error from "../../../components/Error/ErrorMessage.component";
import Modal from "../../../components/Modal/Modal.component";
import Button from "../../../components/Button/Button.component";
import { GET_ALL_ACTIVE_SPORT_CATEGORIES } from "../../../graphql/queries/sportCategory.query";
import { GET_ALL_ACTIVE_SPORT_TYPES } from "../../../graphql/queries/sportType.query";
import { GET_ALL_ACTIVE_SKILLS } from "../../../graphql/queries/skill.query";
import { CREATE_SKILL } from "../../../graphql/mutations/skill.mutation";
import { formatGraphqlError } from "../../../utils/api";
import Notification from "../../../components/Toast/Toast.component";
import MultiSelect from "../../../components/Form/MultiSelect/MultiSelect.component";

const PositionRoleForm = (props) => {
  const {
    register,
    errors,
    positionRoleEditData,
    positionRoleData,
    setPositionRoleData,
    setSkills,
    control,
    watch,
    setValue,
  } = props;
  const { data: sportCategories, loading: sportCategoriesLoading } = useQuery(
    GET_ALL_ACTIVE_SPORT_CATEGORIES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: skills, loading: skillsLoading } = useQuery(
    GET_ALL_ACTIVE_SKILLS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: sportTypes, loading: sportTypesLoading } = useQuery(
    GET_ALL_ACTIVE_SPORT_TYPES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [createSkill, { loading }] = useMutation(CREATE_SKILL);
  const {
    register: registerSkill,
    handleSubmit: handleSubmitSkill,
    errors: errorSkill,
    setValue: setValueSkill,
  } = useForm();

  const createSkillSubmit = (values) => {
    createSkill({
      variables: {
        ...values,
        status: "ACTIVE",
      },
    })
      .then(() => {
        Notification.success({
          title: "Create Skill Succeeded",
          message: "",
        });
      })
      .catch((err) =>
        Notification.error({
          title: "Create Skill Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  const [showAddSkill, setShowAddSkill] = useState(false);
  const selectedCategories = watch("sportCategories");

  useEffect(() => {
    if (positionRoleEditData && positionRoleData.title === "") {
      const tempPositionRoleData = { ...positionRoleEditData };
      const defaultSportCategories = positionRoleEditData.sportCategories.map(
        (type) => {
          return {
            value: type.id,
            label: type.title,
          };
        }
      );
      const defaultSportTypes = positionRoleEditData.sportTypes.map((type) => {
        return {
          value: type.id,
          label: type.title,
        };
      });
      tempPositionRoleData.sportCategories = defaultSportCategories;
      tempPositionRoleData.sportTypes = defaultSportTypes;
      setPositionRoleData(tempPositionRoleData);
      setValue("sportCategories.connect", defaultSportCategories);
      setValue("sportTypes.connect", defaultSportCategories);
    }
  });

  const getSportCategories = () => {
    return sportCategories.sportCategories.map((type) => {
      return {
        value: type.id,
        label: type.title,
      };
    });
  };
  const getSportTypes = () => {
    return sportTypes.sportTypes
      .filter((type) => {
        let retVal = false;
        selectedCategories.connect.forEach((cat) => {
          if (type.sportCategory.id === cat.value) {
            retVal = true;
          }
        });
        return retVal;
      })
      .map((type) => {
        return {
          value: type.id,
          label: type.title,
        };
      });
  };

  const addSkill = (value, cb) => {
    setShowAddSkill(true);
    if (value) {
      setTimeout(() => {
        setValueSkill("title", value);
        setValueSkill("description", value);
      }, 100);
    }
    if (cb) {
      cb();
    }
  };

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>Position Role Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="title"
            label=""
            type="text"
            value={positionRoleData.title}
            register={register}
            required={{
              required: "please enter a Position Role Name",
            }}
            error={errors.title && <Error message={errors.title.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderInput
            header="Description"
            name="description"
            label=""
            type="text"
            value={positionRoleData.description}
            register={register}
            error={
              errors.description && (
                <Error message={errors.description.message} />
              )
            }
          />
        </Column>
      </Row>
      <Row>
        {!sportCategoriesLoading && (
          <Column width="50%" sub>
            <MultiSelect
              header="Sport Category"
              label="Please select a sport category"
              defaultValue={positionRoleData.sportCategories}
              name="sportCategories.connect"
              loading={sportCategoriesLoading}
              control={control}
              options={
                sportCategoriesLoading
                  ? ["Loading please wait"]
                  : getSportCategories()
              }
              required={{
                required: "Please select at least 1 Sport Category",
                minLength: 1,
              }}
              error={
                errors.sportCategories &&
                errors.sportCategories.connect && (
                  <Error message={errors.sportCategories.connect.message} />
                )
              }
            />
          </Column>
        )}
        {!skillsLoading && (
          <Column width="50%" sub>
            <AutoCompleteChips
              header="Base Skills"
              name="baseSkills.connect"
              label="Search base skills"
              accessorTitle="title"
              accessorValue="id"
              selected={
                positionRoleData.baseSkills ? positionRoleData.baseSkills : []
              }
              addExtra={addSkill}
              setSelected={setSkills}
              register={register}
              suggestions={
                skillsLoading ? ["Loading please wait"] : skills.skills
              }
              required={{
                required: "please select at least one skill",
              }}
              error={
                errors.baseSkills && (
                  <Error message={errors.baseSkills.create.message} />
                )
              }
            />
            <Modal
              open={showAddSkill}
              showClose
              close={() => {
                setShowAddSkill(false);
              }}
            >
              <h2>Create Skill</h2>
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="Name"
                    name="title"
                    label=""
                    type="text"
                    value=""
                    register={registerSkill}
                    required={{
                      required: "please enter a Skill Name",
                    }}
                    error={
                      errorSkill.title && (
                        <Error message={errorSkill.title.message} />
                      )
                    }
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="Description"
                    name="description"
                    label=""
                    type="text"
                    value=""
                    register={registerSkill}
                    error={
                      errorSkill.description && (
                        <Error message={errorSkill.description.message} />
                      )
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column sub width="50%">
                  <Button
                    width="100%"
                    onClick={() => setShowAddSkill(false)}
                    type="button"
                    value="Cancel"
                    loading={loading}
                  />
                </Column>
                <Column sub width="50%">
                  <Button
                    width="100%"
                    onClick={handleSubmitSkill(createSkillSubmit)}
                    type="button"
                    kind="secondary"
                    value="Create Skill"
                    loading={loading}
                  />
                </Column>
              </Row>
            </Modal>
          </Column>
        )}
      </Row>
      <Row>
        {!sportTypesLoading &&
          selectedCategories &&
          selectedCategories.connect.length > 0 && (
            <Column width="50%" sub>
              <MultiSelect
                header="Sport Types"
                label="Please select a sport type"
                name="sportTypes.connect"
                defaultValue={positionRoleData.sportTypes}
                accessor="id"
                loading={sportTypesLoading}
                options={
                  sportTypesLoading ? ["Loading please wait"] : getSportTypes()
                }
                required={{
                  required: "Please select at least 1 Sport type",
                }}
                control={control}
                error={
                  errors.sportTypes &&
                  errors.sportTypes.connect && (
                    <Error message={errors.sportTypes.connect.message} />
                  )
                }
              />
            </Column>
          )}
      </Row>
    </Column>
  );
};

PositionRoleForm.defaultProps = {
  errors: {},
  positionRoleEditData: undefined,
};

PositionRoleForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  positionRoleEditData: PropTypes.any,
  positionRoleData: PropTypes.any.isRequired,
  setPositionRoleData: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  watch: PropTypes.any.isRequired,
  setSkills: PropTypes.func.isRequired,
};

export default PositionRoleForm;
