import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../utils/context";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../components/Grid/Grid.component";
import Button from "../../components/Button/Button.component";
import { H1, H2, XMP } from "../../components/Typography/Typography.component";
import { prettyDate } from "../../utils";

// GQL
import {
  GET_AUDIT_LOG,
  GET_RELATED_AUDIT_LOGS,
} from "../../graphql/queries/auditLogs.query";

const AdminUserNew = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { id } = useParams();
  const { data, loading } = useQuery(GET_AUDIT_LOG, {
    fetchPolicy: "no-cache",
    variables: { where: { id } },
  });
  const [getRelatedAuditLogsData, relatedAuditLogs] = useLazyQuery(
    GET_RELATED_AUDIT_LOGS
  );
  const [displayExtraInfo, setDisplayExtraInfo] = useState(false);

  useEffect(() => {
    setActiveSideNav("auditLogs");
    setPageTitle("View Audit Log");
  });

  const getRelatedAuditLogs = () => {
    if (!relatedAuditLogs.called) {
      getRelatedAuditLogsData({
        variables: {
          where: {
            dataID: data.auditLog.dataID,
          },
        },
      });
    }
  };

  const renderRelatedAuditLogs = () => {
    if (!relatedAuditLogs.called) {
      return null;
    }
    if (relatedAuditLogs.loading) {
      return null;
    }

    if (!relatedAuditLogs.data) {
      return null;
    }

    if (relatedAuditLogs.data.auditLogs.length === 1) {
      return null;
    }

    if (!displayExtraInfo) {
      setDisplayExtraInfo(true);
    }

    return relatedAuditLogs.data.auditLogs.map((link) => {
      return (
        <Link
          to={`/app/audit-logs/${link.id}`}
          key={link.id}
          style={{ textDecoration: "none", padding: "10px" }}
        >
          <Button
            onClick={() => {}}
            kind="secondary"
            value={`${link.resolver} ${
              link.id === data.auditLog.id ? "(Viewing)" : ""
            }`}
            width="100%"
            margin="0"
          />
        </Link>
      );
    });
  };

  if (loading) {
    return (
      <>
        <PageHeader title="View Audit Log" back />
        <Row style={{ marginBottom: "15px" }}>Loading...</Row>
      </>
    );
  }
  getRelatedAuditLogs();
  return (
    <>
      <PageHeader title="View Audit Log" back backUrl="/app/audit-logs" />
      <Row style={{ marginBottom: "15px" }}>
        <Column width={displayExtraInfo ? "75%" : "100%"}>
          <Row>
            <Column sub noPad>
              <H1 style={{ marginBottom: "15px" }}>
                {`${data.auditLog.resolver} @ ${prettyDate(
                  data.auditLog.createdAt
                )}`}
              </H1>
            </Column>
          </Row>
          <Row>
            <Column sub noPad width="17%">
              <H2>User Details:</H2>
              <br />
              {data.auditLog.userType === "ADMIN"
                ? `${data.auditLog.adminUser.firstName} ${data.auditLog.adminUser.lastName}`
                : "No User"}
            </Column>
            <Column sub noPad width="17%">
              <H2>Host:</H2>
              <br />
              {data.auditLog.host}
            </Column>
            {data.auditLog.IP ? (
              <Column sub noPad width="17%">
                <H2>User IP:</H2>
                <br />
                {data.auditLog.IP}
              </Column>
            ) : null}
            <Column sub noPad width="50%">
              <H2>User Agent: </H2>
              <br />
              {data.auditLog.userAgent}
            </Column>
          </Row>
          <Row>
            <Column sub style={{ padding: "0 15px 0 0" }} width="50%">
              <H2>Variables:</H2>
              <XMP style={{ fontFamily: "lato" }}>
                {JSON.stringify(data.auditLog.variables, undefined, 4)}
              </XMP>
            </Column>
            <Column sub style={{ padding: "0 15px 0 0" }} width="50%">
              <H2>Query:</H2>
              <XMP style={{ fontFamily: "lato" }}>{data.auditLog.query}</XMP>
            </Column>
          </Row>
        </Column>
        <Column
          width={displayExtraInfo ? "25%" : "0"}
          noPad={!displayExtraInfo}
          style={{
            overflow: "hidden",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          <H1 style={{ marginBottom: "15px" }}>Current Life Cycle</H1>
          {renderRelatedAuditLogs()}
        </Column>
      </Row>
    </>
  );
};

export default AdminUserNew;
