import gql from "graphql-tag";

export const RETRY_INVOICE = gql`
  mutation connectSubscription(
    $stripeId: String!
    $paymentMethodId: String!
    $userGroup: UserGroup!
    $invoiceId: String!
    $employerId: ID
  ) {
    connectSubscription(
      data: {
        stripeId: $stripeId
        paymentMethodId: $paymentMethodId
        userGroup: $userGroup
        invoiceId: $invoiceId
        employerId: $employerId
      }
    )
  }
`;

export default {
  RETRY_INVOICE,
};
