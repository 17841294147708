import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./ExpenseClaimType.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  UPDATE_EXPENSE_CLAIM_TYPE,
  DELETE_EXPENSE_CLAIM_TYPE,
} from "../../../graphql/mutations/expenseClaimType.mutation";
import { GET_EXPENSE_CLAIM_TYPE } from "../../../graphql/queries/expenseClaimType.query";

import { formatGraphqlError } from "../../../utils/api";

const ExpenseClaimTypeEdit = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [expenseClaimTypeData, setExpenseClaimTypeData] = useState({
    id: "",
    title: "",
    description: "",
    status: "ACTIVE",
  });
  const [updateExpenseClaimType, { loading }] = useMutation(
    UPDATE_EXPENSE_CLAIM_TYPE
  );
  const [deleteExpenseClaimType] = useMutation(DELETE_EXPENSE_CLAIM_TYPE);
  const { id } = useParams();
  const expenseClaim = useQuery(GET_EXPENSE_CLAIM_TYPE, {
    fetchPolicy: "no-cache",
    variables: { id },
  });

  const { data } = expenseClaim;

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Manage Expense Claim Type");
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const changeStatus = (e) => {
    const tempExpenseClaimTypeData = { ...expenseClaimTypeData };
    tempExpenseClaimTypeData.status = e.target.value;
    setExpenseClaimTypeData(tempExpenseClaimTypeData);
  };

  const getColor = () => {
    switch (expenseClaimTypeData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new ExpenseClaimType
     * push to list all expenseClaimTypes
     */

    const submitValues = values;
    submitValues.id = expenseClaimTypeData.id;
    updateExpenseClaimType({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({
          title: "Saved successfully",
          message: "You updated a Expense Claim Type",
        });
        history.push("/app/system-configuration/expense-claim-type");
      })
      .catch((err) =>
        Notification.error({
          title: "Update Expense Claim Type Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteExpenseClaimType({
          variables: {
            id: expenseClaimTypeData.id,
          },
        })
          .then(() => {
            Swal.fire(
              "Deleted!",
              "ExpenseClaimType has been deleted.",
              "success"
            ).then(() => {
              history.push("/app/system-configuration/expense-claim-type");
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete ExpenseClaimType Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  if (expenseClaim.loading) {
    return "Loading...";
  }

  return (
    <>
      <PageHeader
        title={`Edit Expense Claim Type: ${expenseClaimTypeData.title}`}
        back
      />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            expenseClaimTypeData={expenseClaimTypeData}
            setExpenseClaimTypeData={setExpenseClaimTypeData}
            expenseClaimTypeEditData={data && data.expenseClaimType}
            setValue={setValue}
            getValues={getValues}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={expenseClaimTypeData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a ExpenseClaimType ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={() => handleDelete()}
            type="button"
            value="Archive"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default ExpenseClaimTypeEdit;
