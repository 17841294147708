import styled from "styled-components";
import { COLOR_PRIMARY, COLOR_SECONDARY } from "../../common/styles/Colors";

export const ProfilePhoto = styled.div`
  border-radius: 50%;
  position: relative;
  height: 36px;
  width: 36px;
  margin: 0 15px;
  background: white;
  color: ${COLOR_PRIMARY};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ProfilePicture = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const Initials = styled.span`
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
`;

export const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 72px;
  padding: 5px;
  color: ${COLOR_PRIMARY};
  height: 60px;

  a {
    text-decoration: none;
  }
`;

export const Span = styled.span`
  font-size: 12px;
`;

export const Icons = styled.div`
  font-size: 24px;
  height: 24px;
  svg {
    cursor: pointer;
    transition: color 0.1s ease-in-out;
    &:hover {
      color: ${COLOR_SECONDARY};
    }
  }
`;
