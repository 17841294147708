/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Error from "../../../components/Error/ErrorMessage.component";
import { GET_ALL_ACTIVE_SPORT_CATEGORIES } from "../../../graphql/queries/sportCategory.query";
import InputChips from "../../../components/Form/InputChips/InputChips.component";

const SportTypeForm = (props) => {
  const {
    register,
    errors,
    sportTypeEditData,
    sportTypeData,
    setSportTypeData,
    setValue,
    control,
  } = props;
  const { data, loading } = useQuery(GET_ALL_ACTIVE_SPORT_CATEGORIES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (sportTypeEditData && sportTypeData.title === "") {
      if (sportTypeEditData.sportCategory) {
        setValue(
          "sportCategory.connect.id",
          sportTypeEditData.sportCategory.id
        );
      } else {
        setValue("sportCategory.connect.id", "");
      }
      setSportTypeData(sportTypeEditData);
    }
  });

  const handleChange = (e) => {
    const tempSportTypeData = { ...sportTypeData };
    tempSportTypeData.sportCategory = e.target.value;
    setSportTypeData(tempSportTypeData);
  };

  const getSportCategories = () => {
    return data.sportCategories.map((type) => {
      return {
        value: type.id,
        text: type.title,
      };
    });
  };

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>Sport Type Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="title"
            label=""
            type="text"
            value={sportTypeData.title}
            register={register}
            required={{
              required: "please enter a Sport Type Name",
            }}
            error={errors.title && <Error message={errors.title.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderInput
            header="Description"
            name="description"
            label=""
            type="text"
            value={sportTypeData.description}
            register={register}
            error={
              errors.description && (
                <Error message={errors.description.message} />
              )
            }
          />
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          {!loading && (
            <BorderSelect
              header="Sport Category"
              name="sportCategory.connect.id"
              label="Please select a sport category"
              value={
                sportTypeData.sportCategory
                  ? sportTypeData.sportCategory.id
                  : ""
              }
              register={register}
              onChange={handleChange}
              options={loading ? ["Loading please wait"] : getSportCategories()}
              required={{
                required: "please select a Sport Category",
              }}
              error={
                errors.sportCategory && (
                  <Error message={errors.sportCategory.connect.id.message} />
                )
              }
            />
          )}
        </Column>
        <Column width="50%" sub>
          <InputChips
            header="Sport Levels"
            name="levels.set"
            label="Press enter to add"
            defaultValue={sportTypeData.levels ? sportTypeData.levels : []}
            error={
              errors.levels && <Error message={errors.levels.set.message} />
            }
            register={register}
            required={{
              required: "please enter a Sport Type Name",
            }}
            control={control}
          />
        </Column>
      </Row>
    </Column>
  );
};

SportTypeForm.defaultProps = {
  errors: {},
  sportTypeEditData: undefined,
};

SportTypeForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  sportTypeEditData: PropTypes.any,
  sportTypeData: PropTypes.any.isRequired,
  setSportTypeData: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
};

export default SportTypeForm;
