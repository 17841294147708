import gql from "graphql-tag";

export const GET_CHAT_MESSAGES = gql`
  query getChatMessages($id: ID) {
    chats(where: { chatGroup: { id: $id } }, orderBy: createdAt_DESC) {
      id
      message
      createdAt
      appUser {
        id
        firstName
        lastName
      }
      adminUser {
        id
        firstName
        lastName
      }
      read
      chatGroup {
        id
      }
    }
  }
`;
export default {
  GET_CHAT_MESSAGES,
};
