import styled from "styled-components";
import { motion } from "framer-motion";
import { COLOR_GREY3, COLOR_PRIMARY } from "../../common/styles/Colors";

export const StyledAccordion = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 2px 2px 0px rgba(7, 0, 47, 0.14);
  margin-bottom: 15px;
`;

export const AccordionHeader = styled.div`
  padding: 17px;
  font-size: 20px;
  color: ${COLOR_PRIMARY};
  font-weight: 400;
  margin: 0;
  background-color: ${COLOR_GREY3};
  cursor: pointer;
`;

export const AccordionBody = styled(motion.div)`
  overflow: hidden;
`;
export const AccordionChildren = styled(motion.div)`
  padding: 17px;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "unset")};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;
