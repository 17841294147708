import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./Skill.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  UPDATE_SKILL,
  DELETE_SKILL,
} from "../../../graphql/mutations/skill.mutation";
import { GET_SKILL } from "../../../graphql/queries/skill.query";

import { formatGraphqlError } from "../../../utils/api";

const SkillEdit = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [skillData, setSkillData] = useState({
    id: "",
    title: "",
    description: "",
    status: "ACTIVE",
  });
  const [updateSkill, { loading }] = useMutation(UPDATE_SKILL);
  const [deleteSkill] = useMutation(DELETE_SKILL);
  const { id } = useParams();
  const skill = useQuery(GET_SKILL, {
    fetchPolicy: "no-cache",
    variables: { id },
  });

  const { data } = skill;

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Manage Skill");
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const changeStatus = (e) => {
    const tempSkillData = { ...skillData };
    tempSkillData.status = e.target.value;
    setSkillData(tempSkillData);
  };

  const getColor = () => {
    switch (skillData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      case "SUGGESTION":
        return "#ef6e2d";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Skill
     * push to list all skills
     */

    const submitValues = values;
    submitValues.id = skillData.id;
    updateSkill({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({
          title: "Saved successfully",
          message: "You updated a skill",
        });
        history.push("/app/system-configuration/skill");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Skill Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteSkill({
          variables: {
            id: skillData.id,
          },
        })
          .then(() => {
            Swal.fire("Deleted!", "Skill has been deleted.", "success").then(
              () => {
                history.push("/app/system-configuration/skill");
              }
            );
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Skill Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  if (skill.loading) {
    return "Loading...";
  }

  return (
    <>
      <PageHeader title={`Edit Skill: ${skillData.title}`} back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            skillData={skillData}
            setSkillData={setSkillData}
            skillEditData={data && data.skill}
            setValue={setValue}
            getValues={getValues}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={skillData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a Skill ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={() => handleDelete()}
            type="button"
            value="Archive"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default SkillEdit;
