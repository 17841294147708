/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import {
  Container,
  Header,
  Input,
  Validation,
  StyledSelect,
} from "./SingleDateTimePicker.style";

const SingleDateTimePicker = ({
  header,
  name,
  error,
  date,
  onDateChange,
  validationLabel,
}) => {
  const [focus, setFocus] = useState(false);
  const [hours, setHours] = useState([]);

  const handleTimeChange = (e) => {
    const hour = e.target.value.split(":")[0];
    const minute = e.target.value.split(":")[1];
    const newDate = moment(date)
      .utcOffset(0)
      .set({ hour, minute, second: 0, millisecond: 0 });
    onDateChange(newDate);
  };

  const getTime = () => {
    return `${`0${date.hours()}`.slice(-2)}:${`0${
      (date.minutes() / 30) * 30
    }`.slice(-2)}`;
  };

  useEffect(() => {
    const retVal = [];
    const x = 30; // minutes interval
    let tt = 0; // start time
    // loop to increment the time and push results in array
    for (let i = 0; tt < 24 * 60; i += 1) {
      const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      const mm = tt % 60; // getting minutes of the hour in 0-55 format
      retVal[i] = `${`0${hh}`.slice(-2)}:${`0${mm}`.slice(-2)}`;
      tt += x;
    }
    setHours(
      retVal.map((option) => {
        return (
          <option value={option} key={option}>
            {option}
          </option>
        );
      })
    );
  }, []);

  return (
    <Container>
      <Header>{header}</Header>
      <Input>
        <SingleDatePicker
          date={date}
          focused={focus}
          onFocusChange={({ focused }) => setFocus(focused)}
          onDateChange={onDateChange}
          id={name}
          block
          displayFormat="DD/MM/YYYY"
          numberOfMonths={1}
        />
        {date && (
          <StyledSelect value={getTime()} onChange={handleTimeChange}>{hours}</StyledSelect>
        )}
      </Input>
      {!error && validationLabel && <Validation>*{validationLabel}</Validation>}
      <div>{error}</div>
    </Container>
  );
};

SingleDateTimePicker.defaultProps = {
  header: "",
  error: undefined,
  validationLabel: "",
  date: null,
};

SingleDateTimePicker.propTypes = {
  // input field header
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
  // react-hook-form validation object
  // error object
  error: PropTypes.object,
  date: PropTypes.any,
  onDateChange: PropTypes.func.isRequired,
  // specify type of input expected below input field
  validationLabel: PropTypes.string,
};

export default SingleDateTimePicker;
