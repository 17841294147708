import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col } from "../../components/Grid/Grid.new.component";
import { H2, H4 } from "../../components/Typography/Typography.component";
import Accordion from "../../components/Accordion/Accordion.component";
import {
  ChatMessage,
  UserName,
  Date as DateComponent,
} from "../../components/Chat/Chat.style";

const ServiceAgreement = ({ serviceAgreement, oldVersion }) => {
  const getDate = (d) => {
    const today = moment().startOf("day");
    const date = moment(d).startOf("day");
    const diff = today.diff(date, "days");
    if (diff === 0) {
      return moment(d).format("HH:mm");
    }
    return moment(d).format("YYYY/MM/DD HH:mm");
  };
  return (
    <Row>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Start Date</H2>
        <H4>
          {serviceAgreement.startDate
            ? moment(serviceAgreement.startDate).format("YYYY/MM/DD | HH:mm")
            : "No start date yet"}
        </H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>End Date:</H2>
        <H4>
          {serviceAgreement.endDate
            ? moment(serviceAgreement.endDate).format("YYYY/MM/DD | HH:mm")
            : "No end date yet"}
        </H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Service Agreement Duration:</H2>
        <H4>{serviceAgreement.serviceAgreementDuration}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Current Version:</H2>
        <H4>{serviceAgreement.currentVersion}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Company Signature:</H2>
        {serviceAgreement.signatureEmployer ? (
          <>
            <img
              src={serviceAgreement.signatureEmployer}
              style={{ width: "100%", height: "auto" }}
              alt="Company Signature"
            />
            <H4>
              {moment(serviceAgreement.signatureDateEmployer).format(
                "YYYY/MM/DD | HH:mm"
              )}
            </H4>
          </>
        ) : (
          <H4>No signature yet</H4>
        )}
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Staff Signature:</H2>
        {serviceAgreement.signatureStaff ? (
          <>
            <img
              src={serviceAgreement.signatureStaff}
              style={{ width: "100%", height: "auto" }}
              alt="Staff Signature"
            />
            <H4>
              {moment(serviceAgreement.signatureDateStaff).format(
                "YYYY/MM/DD | HH:mm"
              )}
            </H4>
          </>
        ) : (
          <H4>No signature yet</H4>
        )}
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Rate Metric:</H2>
        <H4>{serviceAgreement.rateMetric}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Payment Metric:</H2>
        <H4>{serviceAgreement.paymentMetric}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Rate:</H2>
        <H4>{serviceAgreement.rate}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Travel Day Rate:</H2>
        <H4>{serviceAgreement.travelDayRate}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Per Diem:</H2>
        <H4>{serviceAgreement.perDiem}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Additional Terms:</H2>
        <H4>{serviceAgreement.additionalTerms}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Expense Policy:</H2>
        <H4>{serviceAgreement.expensePolicy}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Additional Comments Employer:</H2>
        <H4>{serviceAgreement.additionalCommentsEmployer}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Additional Comments Staff:</H2>
        <H4>{serviceAgreement.additionalCommentsStaff}</H4>
      </Col>
      <Col sm={12} md={6} lg={4} sub>
        <H2 style={{ marginBottom: "7.5px" }}>Other:</H2>
        <H4>{serviceAgreement.other}</H4>
      </Col>
      {!oldVersion && (
        <Col sm={12} md={12} lg={8} sub>
          <Accordion title="Messages:" maxHeight="500px">
            {serviceAgreement.chatGroup &&
            serviceAgreement.chatGroup.messages ? (
              serviceAgreement.chatGroup.messages.map((m, i) => {
                return (
                  <ChatMessage
                    key={m.id}
                    position={
                      m.appUser.id === serviceAgreement.staff.appUser.id
                        ? "right"
                        : "left"
                    }
                    initial={
                      m.appUser.id === serviceAgreement.staff.appUser.id
                        ? { opacity: 0, x: 25 }
                        : { opacity: 0, x: -25 }
                    }
                    animate={{ opacity: 1, x: 0 }}
                    exit={
                      m.appUser.id === serviceAgreement.staff.appUser.id
                        ? { opacity: 0, x: 25 }
                        : { opacity: 0, x: -25 }
                    }
                    transition={{ duration: 0.5, delat: i * 0.2 }}
                  >
                    <UserName>
                      {`${m.appUser.firstName} ${m.appUser.lastName}`} (
                      {m.appUser.id === serviceAgreement.staff.appUser.id
                        ? "Staff"
                        : "Company"}
                      )
                    </UserName>
                    {m.message}

                    <DateComponent>{getDate(m.createdAt)}</DateComponent>
                  </ChatMessage>
                );
              })
            ) : (
              <H2>No Messages to display</H2>
            )}
          </Accordion>
        </Col>
      )}
    </Row>
  );
};

ServiceAgreement.propTypes = {
  serviceAgreement: PropTypes.object.isRequired,
  oldVersion: PropTypes.bool,
};

ServiceAgreement.defaultProps = {
  oldVersion: false,
};

export default ServiceAgreement;
