import gql from "graphql-tag";

export const SEND_CHAT_MESSAGE = gql`
  mutation sendChatMessage($message: String!, $chatGroupId: ID) {
    sendChatMessage(
      data: { message: $message, chatGroup: { connect: { id: $chatGroupId } } }
    ) {
      id
    }
  }
`;

export default {
  SEND_CHAT_MESSAGE,
};
