import gql from "graphql-tag";

const LOGIN = gql`
  mutation loginAdmin($email: String!, $password: String!) {
    loginAdmin(data: { email: $email, password: $password }) {
      jwt
      isSuperAdmin
      roles {
        id
        title
        permissions
      }
      userId
    }
  }
`;

export default LOGIN;
