import styled from "styled-components";
import {
  COLOR_PRIMARY_CHECKBOX,
  COLOR_PRIMARY_CHECKBOX_HOVER,
} from "../../../common/styles/Colors";

export const Container = styled.div`
  margin: 0;
`;

export const Validation = styled.div`
  width: 100%;
  color: grey;
  font-size: 12px;
  line-height: auto;
  margin: 0;
  padding-left: 5px;
  margin-bottom: 1%;
  overflow: visible;
  white-space: wrap;
`;

export const InputHeader = styled.div`
  margin-bottom: 10px;
  color: #63636d;
  font-size: 14px;
  font-weight: 400;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
`;

export const DayOfTheWeek = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 12px;
  width: 32px;
  height: 32px;
  &:hover {
    input ~ .button {
      background-color: ${COLOR_PRIMARY_CHECKBOX_HOVER};
      color: #fff;
      &:after {
        opacity: 0.3;
      }
    }
  }
`;
export const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ .button {
    background-color: ${COLOR_PRIMARY_CHECKBOX} !important;
    color: #fff;
    &:after {
      opacity: 1 !important;
    }
  }
`;
export const Button = styled.div`
  position: absolute;
  top: 1.5px;
  left: 0;
  height: 32px;
  width: 32px;
  font-size: 12px;
  border: 1px solid ${COLOR_PRIMARY_CHECKBOX};
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;
