/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import BorderTextArea from "../../../components/Form/BorderTextArea/BorderTextArea.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import WeekDayPicker from "../../../components/Form/WeekDayPicker/WeekDayPicker.component";
import SingleDatePickerComponent from "../../../components/Form/SingleDatePicker/SingleDatePicker.component";

const AutomaticNotificationForm = (props) => {
  const {
    register,
    errors,
    automaticNotificationEditData,
    automaticNotificationData,
    setAutomaticNotificationData,
    control,
    watch,
    setValue,
  } = props;

  useEffect(() => {
    if (
      automaticNotificationEditData &&
      automaticNotificationData.name === ""
    ) {
      setValue("endDate", automaticNotificationEditData.endDate);
      setValue(
        "frequency.notificationType",
        automaticNotificationEditData.frequency.notificationType
      );
      setValue(
        "frequency.repeatEveryOption",
        automaticNotificationEditData.frequency.repeatEveryOption
      );
      setAutomaticNotificationData(automaticNotificationEditData);
    }
  });

  const notificationType = watch("frequency.notificationType");
  const repeatEveryOption = watch(
    "frequency.repeatEveryOption",
    automaticNotificationEditData
      ? automaticNotificationEditData.frequency.repeatEveryOption
      : "Week"
  );

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>Automatic Notification Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="name"
            label=""
            type="text"
            value={automaticNotificationData.name}
            register={register}
            required={{
              required: "please enter a AutomaticNotification Name",
            }}
            error={errors.name && <Error message={errors.name.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderInput
            header="Notification Time"
            name="time"
            label=""
            type="time"
            value={automaticNotificationData.time}
            register={register}
            error={errors.time && <Error message={errors.time.message} />}
          />
        </Column>
      </Row>
      <Row>
        <Column width="100%" sub>
          <BorderTextArea
            header="Message"
            name="message"
            label=""
            type="text"
            value={automaticNotificationData.message}
            register={register}
            required={{
              required: "please enter a message",
            }}
            error={errors.message && <Error message={errors.message.message} />}
          />
        </Column>
      </Row>
      <Row>
        <Column width="33.33%" sub>
          <BorderSelect
            header="Notification Type"
            name="frequency.notificationType"
            label="Please select notification type"
            value={automaticNotificationData.frequency.notificationType}
            register={register}
            options={[
              { value: "ONCEOFF", text: "Once Off" },
              { value: "REPEAT", text: "Repeat" },
            ]}
            required={{
              required: "please select a notification type",
            }}
            error={
              errors.frequency &&
              errors.frequency.notificationType && (
                <Error message={errors.frequency.notificationType.message} />
              )
            }
          />
        </Column>
        {notificationType === "REPEAT" && (
          <>
            <Column width="16.665%" sub>
              <BorderInput
                header="Repeat Every"
                name="frequency.repeatEveryNumber"
                label=""
                type="number"
                value={automaticNotificationData.frequency.repeatEveryNumber}
                register={register}
                injectProps={{
                  min: 0,
                }}
                error={
                  errors.frequency &&
                  errors.frequency.repeatEveryNumber && (
                    <Error
                      message={errors.frequency.repeatEveryNumber.message}
                    />
                  )
                }
              />
            </Column>
            <Column width="16.665%" sub>
              <BorderSelect
                name="frequency.repeatEveryOption"
                value={automaticNotificationData.frequency.repeatEveryOption}
                register={register}
                options={[
                  { value: "Day", text: "Day" },
                  { value: "Week", text: "Week" },
                  { value: "Month", text: "Month" },
                ]}
                margin="17px 0 0 0"
                error={
                  errors.frequency &&
                  errors.frequency.repeatEveryOption && (
                    <Error
                      message={errors.frequency.repeatEveryOption.message}
                    />
                  )
                }
              />
            </Column>
          </>
        )}
        {notificationType === "ONCEOFF" && (
          <>
            <Column width="33.33%" sub>
              <SingleDatePickerComponent
                control={control}
                name="frequency.startDate"
                label="Start Date"
                header="Start Date"
                defaultValue={automaticNotificationData.frequency.startDate}
                openDirection="up"
              />
            </Column>
          </>
        )}
      </Row>
      {notificationType === "REPEAT" && (
        <Row>
          {repeatEveryOption === "Week" && (
            <Column width="50%" sub>
              <WeekDayPicker
                header="Select day of the week"
                name="frequency.dow"
                defaultChecked={automaticNotificationData.frequency.dow}
                register={register}
              />
            </Column>
          )}
          {repeatEveryOption === "Month" && (
            <Column width="50%" sub>
              <BorderSelect
                header="Day of the month"
                name="frequency.dom"
                value={automaticNotificationData.frequency.dom}
                register={register}
                options={new Array(31).fill(0).map((val, i) => {
                  return (i + 1).toString();
                })}
                error={
                  errors.frequency &&
                  errors.frequency.dom && (
                    <Error message={errors.frequency.dom.message} />
                  )
                }
              />
            </Column>
          )}
          <Column width="33.33%" sub>
            <SingleDatePickerComponent
              control={control}
              name="endDate"
              label="End Date"
              header="End Date"
              defaultValue={automaticNotificationData.endDate || null}
              openDirection="up"
            />
            <BorderInput
              name="frequency.repeatCounter"
              label=""
              type="hidden"
              hidden
              value={automaticNotificationData.frequency.repeatCounter || "0"}
              register={register}
            />
          </Column>
        </Row>
      )}
    </Column>
  );
};

AutomaticNotificationForm.defaultProps = {
  errors: {},
  automaticNotificationEditData: undefined,
};

AutomaticNotificationForm.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  automaticNotificationEditData: PropTypes.any,
  automaticNotificationData: PropTypes.any.isRequired,
  setAutomaticNotificationData: PropTypes.func.isRequired,
};

export default AutomaticNotificationForm;
