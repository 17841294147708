import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AppWrapper from "./common/AppWrapper";

Bugsnag.start({
  apiKey: "15fc5d3a35bf9edaf8518f1ebe3aa515",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      {/* Wrap all the children in this component with the apollo client that will follow later. */}
      <AppWrapper />
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// import * as serviceWorker from './util/serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
