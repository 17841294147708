/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-dates";
import styled from "styled-components";
import BorderSelect from "../BorderSelect/BorderSelect.component";

const getHours = () => {
  const retVal = [];
  const x = 60; // minutes interval
  let tt = 0; // start time
  const ap = ["AM", "PM"]; // AM-PM

  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i += 1) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    retVal[i] = {
      text: `${`0${hh % 12}`.slice(-2)}:${`0${mm % 12}`.slice(-2)}${
        ap[Math.floor(hh / 12)]
      }`,
      value: `${i}`,
    }; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt += x;
  }
  return retVal;
};

const DateHolder = styled.div`
  .DateInput {
    width: 195px;
  }
`;

const DateTimeRangePicker = ({
  startDate,
  endDate,
  startDateId,
  endDateId,
  handleFocusChange,
  handleDatesChange,
  focusedInput,
  handleTimeChange,
}) => {
  const handleSelectChange = (e, sDate, eDate, type) => {
    if (type === "endDate") {
      eDate.utcOffset(0).set({
        hour: e.target.value * 1,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      handleTimeChange(eDate, type);
    } else {
      sDate.utcOffset(0).set({
        hour: e.target.value * 1,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      handleTimeChange(sDate, type);
    }
  };
  return (
    <DateHolder>
      <DateRangePicker
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId={startDateId} // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId={endDateId} // PropTypes.string.isRequired,
        onFocusChange={handleFocusChange}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        block
        minimumNights={0}
        numberOfMonths={2}
        displayFormat="YYYY/MM/DD"
        orientation="horizontal"
        anchorDirection="left"
        isOutsideRange={() => false}
      />
      {startDate && (
        <div
          style={{
            width: "calc(50% - 5px)",
            display: "inline-block",
            marginRight: "5px",
          }}
        >
          <BorderSelect
            header=""
            name="status"
            label="Start Time"
            value={startDate.format("H")}
            onChange={(e) => {
              handleSelectChange(e, startDate, endDate, "startDate");
            }}
            options={getHours()}
            stateValue
          />
        </div>
      )}
      {endDate && (
        <div
          style={{
            width: "calc(50% - 5px)",
            display: "inline-block",
            marginLeft: "5px",
          }}
        >
          <BorderSelect
            header=""
            name="status"
            label="End Time"
            value={endDate.format("H")}
            onChange={(e) => {
              handleSelectChange(e, startDate, endDate, "endDate");
            }}
            options={getHours()}
            stateValue
          />
        </div>
      )}
    </DateHolder>
  );
};

DateTimeRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
  focusedInput: null,
};

DateTimeRangePicker.propTypes = {
  // Select field header
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  startDateId: PropTypes.string.isRequired,
  endDateId: PropTypes.string.isRequired,
  handleFocusChange: PropTypes.func.isRequired,
  handleDatesChange: PropTypes.func.isRequired,
  focusedInput: PropTypes.func,
  handleTimeChange: PropTypes.func.isRequired,
};

export default DateTimeRangePicker;
