/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { string, element, object } from "prop-types";
import { Container, Icon } from "./Header.style";

const FormHeader = ({ title, icon, customStyle }) => {
  return (
    <Container {...customStyle}>
      <h3>{title}</h3>
      <Icon>{icon}</Icon>
    </Container>
  );
};

FormHeader.defaultProps = {
  icon: undefined,
  customStyle: undefined,
};

FormHeader.propTypes = {
  title: string.isRequired,
  icon: element,
  customStyle: object,
};

export default FormHeader;
