import React from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import Container from "../../components/Container/Container.component";
import SideComponent from "../../components/SideComponent/SideComponent.component";
import {
  AnimatedRoutes,
  RouteTransition,
} from "../../components/Animation/AnimatedRoutes/AnimatedRoutes.component";
import LoginRoutes from "../../routes/Login";
import { GlobalStyles } from "../styles/Global";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 2%;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 100vw;
    border: 2px solid red;
  }

  transition: 0.1s ease-in-out;
  border-radius: 8px;
`;

const LoginLayout = () => {
  if (window.innerWidth < 768) {
    return (
      <Div>
        <p>Cannot display on this device screen width</p>
      </Div>
    );
  }

  return (
    <Container>
      <GlobalStyles />
      <SideComponent />
      <Container sub zIndex="9">
        <AnimatedRoutes exitBeforeEnter initial={false}>
          {LoginRoutes.map((prop, index) => {
            const key = `route-${index}`;
            if (prop.redirect) {
              return (
                <RouteTransition path={prop.path} key={key} exact slideUp={25}>
                  <Redirect to={prop.redirect} />
                </RouteTransition>
              );
            }
            return (
              <RouteTransition path={prop.path} key={key} slideUp={25} exact>
                <Div>
                  <prop.component />
                </Div>
              </RouteTransition>
            );
          })}
        </AnimatedRoutes>
      </Container>
    </Container>
  );
};

export default LoginLayout;
