/* eslint-disable react/forbid-prop-types */
import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import Error from "../../../components/Error/ErrorMessage.component";
import CheckBox from "../../../components/Form/CheckBox/CheckBox.component";
import { PermissionGroups } from "./permissionsList";

const renderCheckBoxes = (
  userTypeData,
  group,
  options,
  changePermission,
  register
) => {
  if (options) {
    const keys = Object.keys(options);
    return keys.map((key) => (
      <Column
        width="10%"
        sub
        key={`cb-${group}-${key}`}
        style={{ padding: "0px 17px" }}
      >
        <CheckBox
          type="checkbox"
          defaultChecked={userTypeData.permissions[group][key]}
          onChange={(e) => {
            changePermission(e.target.checked, group, key);
          }}
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          name={`permissions.${group}.${key}`}
          register={register}
        />
      </Column>
    ));
  }
  return null;
};

const UserTypeForm = (props) => {
  const {
    register,
    errors,
    userTypeEditData,
    userTypeData,
    setUserTypeData,
    setValue,
    getValues,
  } = props;

  const mergePermissions = (permissions) => {
    const mergedPermissions = {};
    PermissionGroups.forEach((permission) => {
      if (permissions[permission.group]) {
        mergedPermissions[permission.group] = permissions[permission.group];
      } else {
        mergedPermissions[permission.group] = {
          all: false,
          create: false,
          edit: false,
          delete: false,
          view: false,
        };
      }
    });

    return mergedPermissions;
  };

  useEffect(() => {
    if (userTypeEditData && userTypeData.title === "") {
      const permissions = mergePermissions(userTypeEditData.permissions);
      userTypeEditData.oldPermissions = userTypeEditData.permissions;
      userTypeEditData.permissions = permissions;
      setUserTypeData(userTypeEditData);
      setValue("permissions", permissions);
    }
  });

  const changePermission = (value, group, permissionType) => {
    const ALL = getValues(`permissions.${group}.all`);
    const CREATE = getValues(`permissions.${group}.create`);
    const EDIT = getValues(`permissions.${group}.edit`);
    const DELETE = getValues(`permissions.${group}.delete`);
    const VIEW = getValues(`permissions.${group}.view`);

    let valuesChanged = false;

    if (permissionType === "all") {
      setValue("permissions", {
        [group]: {
          create: value,
          edit: value,
          delete: value,
          view: value,
        },
      });
      valuesChanged = true;
    }
    if (!value && ALL) {
      setValue("permissions", {
        [group]: {
          all: false,
        },
      });
      valuesChanged = true;
    }

    if (!valuesChanged) {
      if (!ALL && CREATE && EDIT && DELETE && VIEW) {
        setValue("permissions", {
          [group]: {
            all: true,
          },
        });
      }
    }
  };

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>User Type Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="title"
            label=""
            type="text"
            value={userTypeData.title}
            register={register}
            required={{
              required: "please enter a User Type Name",
            }}
            error={errors.title && <Error message={errors.title.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderInput
            header="Description"
            name="description"
            label=""
            type="text"
            value={userTypeData.description}
            register={register}
            error={
              errors.description && (
                <Error message={errors.description.message} />
              )
            }
          />
        </Column>
      </Row>
      <Row>
        <Column width="100%" sub>
          <H2>User Type Permissions</H2>
        </Column>
      </Row>
      {PermissionGroups.map((perm) => {
        return (
          <Fragment key={`permission-${perm.group}`}>
            <Row>
              <Column width="100%" sub>
                <H2>{perm.title}</H2>
              </Column>
            </Row>
            <Row>
              {renderCheckBoxes(
                userTypeData,
                perm.group,
                userTypeData.permissions[perm.group],
                changePermission,
                register
              )}
            </Row>
          </Fragment>
        );
      })}
    </Column>
  );
};

UserTypeForm.defaultProps = {
  errors: {},
  userTypeEditData: undefined,
};

UserTypeForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  userTypeEditData: PropTypes.any,
  userTypeData: PropTypes.any.isRequired,
  setUserTypeData: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
};

export default UserTypeForm;
