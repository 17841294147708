import React, { useContext, useEffect, useState } from "react";
import {
  FiUsers,
  FiShare,
  FiCalendar,
  FiShare2,
  FiMapPin,
} from "react-icons/fi";
import { useQuery } from "react-apollo";
import { Bar } from "react-chartjs-2";
import moment from "moment";

import { APP_CONTEXT } from "../../utils/context";
import { Row, Col } from "../../components/Grid/Grid.new.component";
import { H1, H3, H4 } from "../../components/Typography/Typography.component";
import DashboardCard from "../../components/DashboardCard/DashboardCard.component";
import {
  GET_APP_USERS,
  GET_POSITIONS,
  GET_EVENTS,
  GET_REFERRALS,
  GET_EMPLOYERS,
  GET_STAFF,
  GET_UPCOMING_EVENTS,
} from "../../graphql/queries/dashboard.query";
import { Counter, Left, Right, EventsHolder, Event } from "./Dashboard.style";

const today = new Date();

const Dashboard = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [employerAndStaff, setEmployerAndStaff] = useState({
    labels: [
      "Employers - Subscribed",
      "Employers - UnSubscribed",
      "Staff - Subscribed",
      "Staff - UnSubscribed",
    ],
    datasets: [
      {
        label: "Subscribed VS Unsubscribed",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [0, 0, 0, 0],
      },
    ],
  });

  useEffect(() => {
    setActiveSideNav("dashboard");
    setPageTitle("Dashboard");
  });

  const appUsers = useQuery(GET_APP_USERS, {
    fetchPolicy: "no-cache",
  });
  const positions = useQuery(GET_POSITIONS, {
    fetchPolicy: "no-cache",
  });
  const events = useQuery(GET_EVENTS, {
    fetchPolicy: "no-cache",
  });
  const referrals = useQuery(GET_REFERRALS, {
    fetchPolicy: "no-cache",
  });
  const employers = useQuery(GET_EMPLOYERS, {
    fetchPolicy: "no-cache",
  });
  const staff = useQuery(GET_STAFF, {
    fetchPolicy: "no-cache",
  });
  const upcomingEvents = useQuery(GET_UPCOMING_EVENTS, {
    variables: {
      orderBy: "startDate_ASC",
      where: {
        startDate_gte: today,
      },
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (employers.called && staff.called) {
      if (!employers.loading && !staff.loading) {
        if (employers.data && staff.data) {
          setEmployerAndStaff((prevState) => {
            const employerSubscribed = employers.data.employerProfiles.filter(
              (e) => e.subscriptionId
            ).length;
            const employerUnSubscribed = employers.data.employerProfiles.filter(
              (e) => !e.subscriptionId
            ).length;
            const staffSubscribed = staff.data.staffProfiles.filter(
              (e) => e.subscriptionId
            ).length;
            const staffUnSubscribed = staff.data.staffProfiles.filter(
              (e) => !e.subscriptionId
            ).length;

            const newData = { ...prevState };

            newData.datasets = [
              {
                label: "Subscribed VS Unsubscribed",
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "rgba(255,99,132,1)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(255,99,132,1)",
                data: [
                  employerSubscribed,
                  employerUnSubscribed,
                  staffSubscribed,
                  staffUnSubscribed,
                ],
              },
            ];
            return newData;
          });
        }
      }
    }
  }, [employers, staff]);

  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={6} sub>
          <H1>Dashboard</H1>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={3} padding="20px">
          <Counter>
            <Left>
              <H1 style={{ fontSize: "34px", marginBottom: "12px" }}>
                {!appUsers && appUsers.loading
                  ? "0"
                  : appUsers.data
                  ? appUsers.data.appUsers.length
                  : 0}
              </H1>
              <H3>Total App Users</H3>
            </Left>
            <Right>
              <FiUsers style={{ fontSize: "69px" }} />
            </Right>
          </Counter>
        </Col>
        <Col sm={12} md={6} lg={3} padding="20px">
          <Counter>
            <Left>
              <H1 style={{ fontSize: "34px", marginBottom: "12px" }}>
                {positions.loading
                  ? "0"
                  : positions.data
                  ? positions.data.positions.length
                  : 0}
              </H1>
              <H3>Total Roles</H3>
            </Left>
            <Right>
              <FiShare style={{ fontSize: "69px" }} />
            </Right>
          </Counter>
        </Col>
        <Col sm={12} md={6} lg={3} padding="20px">
          <Counter>
            <Left>
              <H1 style={{ fontSize: "34px", marginBottom: "12px" }}>
                {events.loading
                  ? "0"
                  : events.data
                  ? events.data.events.length
                  : 0}
              </H1>
              <H3>Total Events</H3>
            </Left>
            <Right>
              <FiCalendar style={{ fontSize: "69px" }} />
            </Right>
          </Counter>
        </Col>
        <Col sm={12} md={6} lg={3} padding="20px">
          <Counter>
            <Left>
              <H1 style={{ fontSize: "34px", marginBottom: "12px" }}>
                {referrals.loading
                  ? "0"
                  : referrals.data
                  ? referrals.data.referrals.length
                  : 0}
              </H1>
              <H3>Total Referrals</H3>
            </Left>
            <Right>
              <FiShare2 style={{ fontSize: "69px" }} />
            </Right>
          </Counter>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6} lg={6} padding="0">
          <DashboardCard title="Employer vs Staff">
            <Bar data={employerAndStaff} />
          </DashboardCard>
        </Col>
        <Col sm={12} md={6} lg={6} padding="0">
          <DashboardCard title="Upcoming Events">
            {!upcomingEvents.loading && (
              <>
                {upcomingEvents.data.events.length > 0 ? (
                  <EventsHolder>
                    {upcomingEvents.data.events.map((event) => (
                      <Event key={event.id}>
                        <Left>
                          <H3 style={{ marginBottom: "10px" }}>
                            {event.title}
                          </H3>
                          <H4>
                            {`${moment(event.startDate).format(
                              "DD MMMM"
                            )} - ${moment(event.endDate).format(
                              "DD MMMM YYYY"
                            )}`}
                          </H4>
                        </Left>
                        <Right>
                          <FiMapPin style={{ fontSize: "20px" }} />
                          <span style={{ textAlign: "left" }}>
                            {event.location}
                          </span>
                        </Right>
                      </Event>
                    ))}
                  </EventsHolder>
                ) : (
                  <H3 style={{ padding: "18px 0" }}>No upcoming events</H3>
                )}
              </>
            )}
          </DashboardCard>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
