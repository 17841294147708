import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { useLazyQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../utils/context";
import Table, {
  TableIcons,
  TableWrapper,
} from "../../components/Table/Table.component";
import { prettyDate } from "../../utils";
// GQL
import {
  GET_AUDIT_LOGS,
  GET_AUDIT_LOGS_EXPORT,
} from "../../graphql/queries/auditLogs.query";

const UserTypeAll = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_AUDIT_LOGS, {
    variables: { order: "createdAt_DESC" },
    fetchPolicy: "no-cache",
  });
  const [variables, setVariables] = useState();
  const [getAuditLogsExport, auditLogsExport] = useLazyQuery(
    GET_AUDIT_LOGS_EXPORT
  );
  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("auditLogs");
    setPageTitle("View Audit Logs");
  });

  const HeaderInfo = [
    {
      Header: "Date/Time",
      accessor: "createdAt",
      Cell: ({ row }) => {
        return prettyDate(row.original.createdAt);
      },
    },
    {
      Header: "Action Name",
      accessor: "resolver",
      search: true,
    },
    {
      Header: "Data ID",
      accessor: "dataID",
      search: true,
    },
    {
      Header: "User Name",
      id: "userName",
      Cell: ({ row }) => {
        if (row.original.adminUser) {
          return `${row.original.adminUser.firstName} ${row.original.adminUser.lastName}`;
        }

        if (row.original.user) {
          return `${row.original.user.firstName} ${row.original.user.lastName}`;
        }
        return "No User";
      },
    },
    {
      Header: "Actions",
      id: "view",
      Cell: () => (
        <TableIcons>
          <AiOutlineEye
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
          />
        </TableIcons>
      ),
    },
  ];

  const handleRowClick = (row) => {
    history.push(`/app/audit-logs/${row.original.id}`);
  };

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
  ];

  return (
    <>
      <TableWrapper>
        <Table
          columns={HeaderInfo}
          data={(data && data.auditLogs) || []}
          loading={loading}
          error={error}
          refetch={refetch}
          variables={variables}
          dataCount={(data && data.auditLogsCount.length) || 0}
          pageCount={5}
          setVariables={setVariables}
          tableTitle="Audit Logs"
          tableOptions={["search", "download", "columns", "filter"]}
          filters={filters}
          downloadData={auditLogsExport}
          getDownloadData={getAuditLogsExport}
          downloadAccessor={
            auditLogsExport.data ? auditLogsExport.data.auditLogs : []
          }
          fileName="AuditLogs"
          onRowClick={handleRowClick}
        />
      </TableWrapper>
    </>
  );
};

export default UserTypeAll;
