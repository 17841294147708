/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { FiSearch } from "react-icons/fi";
import { Container, StyledInput, Icon, Input } from "./Search.style";

const Search = ({ label, value, onChange }) => {
  return (
    <Container>
      <Input>
        <Icon>
          <FiSearch />
        </Icon>
        <StyledInput
          label={label}
          placeholder={label}
          value={value}
          onChange={onChange}
        />
      </Input>
    </Container>
  );
};

Search.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Search;
