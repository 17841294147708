import gql from "graphql-tag";

export const CREATE_ADMIN_USER = gql`
  mutation createAdminUser(
    $firstName: String!
    $lastName: String!
    $cellphone: String!
    $email: String!
    $roles: UserRoleCreateOneInput
    $superAdmin: Boolean
    $status: UserStatus
    $password: String!
  ) {
    createAdminUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        cellphone: $cellphone
        email: $email
        roles: $roles
        superAdmin: $superAdmin
        status: $status
        password: $password
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ADMIN_USER = gql`
  mutation updateAdminUser(
    $firstName: String!
    $lastName: String!
    $cellphone: String!
    $email: String!
    $roles: UserRoleUpdateOneInput
    $superAdmin: Boolean
    $status: UserStatus
    $id: ID!
  ) {
    updateAdminUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        cellphone: $cellphone
        email: $email
        roles: $roles
        superAdmin: $superAdmin
        status: $status
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_ADMIN_USER = gql`
  mutation deleteAdminUser($id: ID!) {
    deleteAdminUser(where: { id: $id }) {
      id
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation requestAdminPasswordReset($email: String!) {
    requestAdminPasswordReset(data: { email: $email }) {
      result
      data
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation requestAdminPasswordReset($authToken: ID!, $newPassword: String!) {
    resetAdminPassword(
      data: { authToken: $authToken, newPassword: $newPassword }
    )
  }
`;

export const CREATE_PASSWORD = gql`
  mutation createPassword($authToken: ID!, $newPassword: String!) {
    createPassword(data: { authToken: $authToken, newPassword: $newPassword })
  }
`;

export default {
  CREATE_ADMIN_USER,
  UPDATE_ADMIN_USER,
  DELETE_ADMIN_USER,
  CREATE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
};
