import styled from "styled-components";
import { COLOR_PRIMARY } from "../../common/styles/Colors";

export const StyledBurger = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 60px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  z-index: 10;
  top: 0;

  &:focus {
    outline: none;
  }
`;

export const Line = styled.div`
  width: 24px;
  height: 2px;
  background: ${COLOR_PRIMARY};
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  margin: 2px;
`;
