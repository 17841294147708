import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import { RiMastercardLine, RiVisaLine } from "react-icons/ri";
import CardSection from "./CardSection.componenet";
import { Row, Column } from "../Grid/Grid.component";
import {
  PaymentOptionWrapper,
  PaymentOption,
  Radio,
  CardDetails,
  CardExpiry,
} from "./ManageAccount.style";
import { H3, H1, H2 } from "../Typography/Typography.component";
import Button from "../Button/Button.component";
import { CONNECT_SUBSCRIPTION } from "../../graphql/mutations/stripe/connectSubscription.mutation";
import { CREATE_SUBSCRIPTION } from "../../graphql/mutations/stripe/createSubscription.mutation";
import { RETRY_INVOICE } from "../../graphql/mutations/stripe/retryInvoice.mutation";
import { formatGraphqlError } from "../../utils/api";
import Notification from "../Toast/Toast.component";

const CheckoutForm = ({ data, setSubscribed, paymentMethods }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [connectSubscription, connectSubscriptionRes] = useMutation(
    CONNECT_SUBSCRIPTION
  );
  const [createSubscription, createSubscriptionRes] = useMutation(
    CREATE_SUBSCRIPTION
  );
  const [retryInvoice, retryInvoiceRes] = useMutation(RETRY_INVOICE);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const [previousPaymentMethod, setPreviousPaymentMethod] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState("");
  let customerId;
  let user;
  if (data && data.accessAccount && data.accessAccount.user) {
    user = data.accessAccount.user;
    if (data.accessAccount.userGroup === "EMPLOYER") {
      customerId = user.employer.stripeId;
    } else if (user.staff) {
      customerId = user.staff.stripeId;
    }
  }

  function onSubscriptionComplete(result) {
    const retVal = result;
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (retVal && !retVal.subscription) {
      const subscription = { id: result.invoice.subscription };
      retVal.subscription = subscription;
      localStorage.clear();
    }
    setSubscribed(retVal.subscription);

    const { subscription, paymentMethodId } = retVal;

    const variables = {
      subscriptionId: subscription.id,
      paymentMethodId,
      profileId:
        data.accessAccount.userGroup === "EMPLOYER"
          ? user.employer.id
          : user.staff.id,
      userGroup: data.accessAccount.userGroup,
    };

    connectSubscription({
      variables,
    }).then((res) => {
      console.log(res);
    });

    return retVal;
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(retVal);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using retVal.subscription.price.product
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          }
          if (result.paymentIntent.status === "succeeded") {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.payment_succeeded. This webhook endpoint
            // returns an Invoice.

            return {
              priceId,
              subscription,
              invoice,
              paymentMethodId,
            };
          }

          return null;
        });
    }
    return { subscription, priceId, paymentMethodId };
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error("Your card was declined.");
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    const priceId = selected.id;
    const variables = {
      stripeId: customerId,
      paymentMethodId,
      invoiceId,
      userGroup: data.accessAccount.user.staff ? "STAFF" : "EMPLOYER",
      employerId: data.accessAccount.user.employer
        ? data.accessAccount.user.employer.id
        : undefined,
    };
    return (
      retryInvoice({ variables })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result.data.retryInvoice,
            paymentMethodId,
            priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .then(() => {
          Notification.success({
            title: `Subscribed`,
            message: "",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          // An error has happened. Display the failure to the user here.
          setErrorToDisplay(error && error.error && error.error.decline_code);
        })
    );
  }

  const createSubscriptionHandler = ({
    customerId: stripeId,
    paymentMethodId,
    priceId,
  }) => {
    const variables = {
      stripeId,
      paymentMethodId,
      priceId,
    };

    /**
     * HANDLE SUBSCRIPTION COMPLETE
     * STEP 1: Make call to API
     * STEP 2: handlePaymentThatRequiresCustomerAction
     * STEP 3: handleRequiresPaymentMethod
     * STEP 4: onSubscriptionComplete
     * STEP 5: Connect subscription to user
     * CATCH: Handle any errors that are discplayed.
     */

    createSubscription({
      variables,
    })
      .then((result) => {
        console.log(result);
        return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          subscription: result.data.createSubscription,
          paymentMethodId,
          priceId: selected.id,
        };
      })
      // Some payment methods require a customer to do additional
      // authentication with their financial institution.
      // Eg: 2FA for cards.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail. You will
      // get a requires_payment_method error.
      .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .then(() => {
        setLoading(false);
        Notification.success({
          title: `Subscribed`,
          message: "",
        });
      })
      .catch((err) => {
        if (err.message) {
          setLoading(false);
          Notification.error({
            title: "Failed to subscribe",
            message: formatGraphqlError(err),
          });
        } else if (err.error) {
          setLoading(false);
          Notification.error({
            title: "Failed to subscribe",
            message: err.error.payment_intent.last_payment_error.message,
          });
        } else {
          setLoading(false);
          Notification.error({
            title: "Failed to subscribe",
            message: "Oops something went wrong",
          });
        }
      });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const priceId = selected.id;
    let paymentMethodId;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    if (!previousPaymentMethod) {
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // If a previous payment was attempted, get the latest invoice
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (error) {
        Notification.error({
          title: "Failed to do payment",
          message: error.message,
        });
        setLoading(false);
      } else {
        paymentMethodId = paymentMethod.id;
      }
    } else {
      paymentMethodId = previousPaymentMethod.id;
    }

    if (paymentMethodId) {
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        "latestInvoicePaymentIntentStatus"
      );
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem("latestInvoiceId");
        retryInvoiceWithNewPaymentMethod({
          customerId,
          paymentMethodId,
          invoiceId,
          priceId,
        });
      } else {
        // Create the subscription

        createSubscriptionHandler({ customerId, paymentMethodId, priceId });
      }
    } else {
      console.log("NO PAYMENT METHOD");
    }
  };

  useEffect(() => {
    if (data) {
      const prod = data.accessAccount.products[0];
      if (prod.prices.length > 0) {
        setSelected(prod.prices[0]);
      }
    }
  }, [data, setSelected]);

  return (
    <form onSubmit={handleSubmit}>
      {data.accessAccount.products.map((prod) => {
        return (
          <Row key={prod.id}>
            <Column sub>
              <H1>{prod.name}</H1>
              <H3 style={{ margin: "10px 0" }}>{prod.description}</H3>
              {prod.prices.length > 1 && (
                <H2 style={{ marginBottom: "10px" }}>Options:</H2>
              )}
              <PaymentOptionWrapper>
                {prod.prices.map((price) => {
                  return (
                    <PaymentOption
                      key={price.id}
                      active={selected && selected.id === price.id}
                      onClick={() => setSelected(price)}
                    >
                      <Radio active={selected && selected.id === price.id} />
                      <H2>{price.nickname}</H2>
                      <H3 style={{ margin: "10px 0" }}>
                        {price.metadata.description}
                      </H3>
                      {`€ `}
                      {price.unit_amount / 100}
                    </PaymentOption>
                  );
                })}
              </PaymentOptionWrapper>
            </Column>
          </Row>
        );
      })}
      {paymentMethods &&
        paymentMethods.data &&
        paymentMethods.data.paymentMethods.list.data.length > 0 && (
          <PaymentOptionWrapper>
            <H2 style={{ marginBottom: "15px" }}>Select Payment method</H2>
            {paymentMethods.data.paymentMethods.list.data.map(
              (paymentMethod) => {
                return (
                  <PaymentOption
                    key={paymentMethod.id}
                    active={
                      previousPaymentMethod &&
                      previousPaymentMethod.id === paymentMethod.id
                    }
                    onClick={() => {
                      if (
                        previousPaymentMethod &&
                        previousPaymentMethod.id === paymentMethod.id
                      ) {
                        setPreviousPaymentMethod(null);
                      } else {
                        setPreviousPaymentMethod(paymentMethod);
                      }
                    }}
                  >
                    <Radio
                      active={
                        previousPaymentMethod &&
                        previousPaymentMethod.id === paymentMethod.id
                      }
                    />
                    <CardDetails>
                      {paymentMethod.card.brand === "visa" ? (
                        <RiVisaLine
                          style={{ fontSize: "30px", marginRight: "15px" }}
                        />
                      ) : (
                        <RiMastercardLine style={{ fontSize: "30px" }} />
                      )}
                      <span>{`•••• •••• •••• ${paymentMethod.card.last4}`}</span>
                    </CardDetails>
                    <CardExpiry>
                      {`Expiry ${
                        paymentMethod.card.exp_month < 10
                          ? `0${paymentMethod.card.exp_month}`
                          : paymentMethod.card.exp_month
                      }/${paymentMethod.card.exp_year.toString().slice(-2)}`}
                    </CardExpiry>
                  </PaymentOption>
                );
              }
            )}
            {!previousPaymentMethod && (
              <H2
                style={{
                  marginTop: "15px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                OR :
              </H2>
            )}
          </PaymentOptionWrapper>
        )}
      <Row>
        <Column sub>
          <CardSection />
        </Column>
      </Row>
      {errorToDisplay && (
        <Row>
          <Column sub>{errorToDisplay}</Column>
        </Row>
      )}
      <Row>
        <Column sub>
          <Button
            width="100%"
            type="submit"
            kind="secondary"
            value="Confirm order"
            loading={
              !stripe ||
              createSubscriptionRes.loading ||
              retryInvoiceRes.loading ||
              connectSubscriptionRes.loading ||
              loading
            }
          />
        </Column>
      </Row>
    </form>
  );
};

CheckoutForm.propTypes = {
  data: PropTypes.object.isRequired,
  setSubscribed: PropTypes.func.isRequired,
  paymentMethods: PropTypes.object.isRequired,
};

export default CheckoutForm;
