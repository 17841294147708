import gql from "graphql-tag";

export const ADD_PAYMENT_METHOD = gql`
  mutation addPaymentMethod($stripeId: String!, $paymentMethodId: String!) {
    addPaymentMethod(
      data: { stripeId: $stripeId, paymentMethodId: $paymentMethodId }
    )
  }
`;

export default {
  ADD_PAYMENT_METHOD,
};
