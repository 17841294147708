import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { FiShare, FiX } from "react-icons/fi";
import axios from "axios";

import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
import Modal from "../../../components/Modal/Modal.component";
import {
  H1,
  H2,
  H3,
  Span,
} from "../../../components/Typography/Typography.component";
import { Row, Col } from "../../../components/Grid/Grid.new.component";
import { fileToBase64, sumObjectArray } from "../../../utils";
import Button from "../../../components/Button/Button.component";
import {
  Table as ModalTable,
  TableHeader,
  TableRow,
  TableCol,
} from "./Total.style";
import CheckBox from "../../../components/Form/CheckBox/CheckBox.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import { DropZone } from "../../../components/UploadFeaturedImage/UploadFeaturedImage.style";
import Notification from "../../../components/Toast/Toast.component";
// GQL
import {
  GET_TOTAL_TO_PAY,
  GET_TOTAL_TO_PAY_EXPORT,
} from "../../../graphql/queries/finances.query";
import { CREATE_PAYMENT } from "../../../graphql/mutations/payment.mutation";
import { formatGraphqlError } from "../../../utils/api";
import Accordion from "../../../components/Accordion/Accordion.component";

const Card = styled.div`
  border: 1px solid #393b3c;
  border-radius: 4px;
  padding: 15px;
`;

const Line = styled.div`
  border-bottom: 0.5px solid #393b3c;
  width: 100%;
`;

const FileName = styled.div`
  border: 1px solid #393b3c;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  word-break: break-all;
  position: relative;
  .icon {
    position: absolute;
    font-size: 24px;
    top: 50%;
    left: 101%;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: translateY(-50%) rotate(180deg);
    }
  }
`;

const AWS_FILE_SERVICE =
  process.env.AWS_FILE_SERVICE ||
  "https://m7o75qswfb.execute-api.eu-west-1.amazonaws.com/uat/";

const AWS_FILE_BUCKET = process.env.AWS_FILE_BUCKET || "test";

const Total = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_TOTAL_TO_PAY, {
    variables: {
      where: {
        whereReferral: {
          status: "COMPLETED",
        },
        whereRates: {
          status: "PENDING",
        },
        whereExpenseClaims: {
          status: "APPROVED",
        },
      },
    },
    fetchPolicy: "no-cache",
  });
  const [refetchExport, exportData] = useLazyQuery(GET_TOTAL_TO_PAY_EXPORT, {
    variables: {
      where: {
        whereReferral: {
          status: "COMPLETED",
        },
        whereRates: {
          status: "PENDING",
        },
        whereExpenseClaims: {
          status: "APPROVED",
        },
      },
    },
    fetchPolicy: "no-cache",
  });

  const [createPayment, { loading: creatingPayment }] = useMutation(
    CREATE_PAYMENT
  );

  const [variables, setVariables] = useState({
    where: {
      whereReferral: {
        status: "COMPLETED",
      },
      whereRates: {
        status: "PENDING",
      },
      whereExpenseClaims: {
        status: "APPROVED",
      },
    },
  });
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [activeSummary, setActiveSummary] = useState(false);
  const [activePayment, setActivePayment] = useState(false);

  const [totalDue, setTotalDue] = useState(0);
  const [uploadPOP, setUploadPOP] = useState(0);
  const [uploading, setUploading] = useState(false);

  const { register, handleSubmit, watch } = useForm();

  const expenseClaims = watch("expenseClaims");
  const rates = watch("rates");
  const referrals = watch("referrals");

  const customRefetch = useCallback(
    (filters) => {
      const newVariables = { ...filters };

      if (newVariables.variables.where.AND) {
        newVariables.variables.where.whereExpenseClaims = {
          ...newVariables.variables.where.whereExpenseClaims,
          AND: newVariables.variables.where.AND,
        };
        newVariables.variables.where.whereRates = {
          ...newVariables.variables.where.whereRates,
          AND: newVariables.variables.where.AND,
        };
        newVariables.variables.where.whereReferral = {
          ...newVariables.variables.where.whereReferral,
          AND: newVariables.variables.where.AND,
        };

        delete newVariables.variables.where.AND;
      }
      refetch(newVariables);
    },
    [refetch]
  );

  // Dropzone START
  const uploadAsset = (asset, fileType, fileName) => {
    setUploading(true);
    const assetData = {
      type: fileType,
      folder: AWS_FILE_BUCKET,
      content: asset.split(",")[1],
    };
    axios
      .request({
        method: "post",
        url: AWS_FILE_SERVICE,
        data: assetData, // base64
        onUploadProgress: (progress) => {
          const percentComplete = Math.round(
            (progress.loaded * 100) / progress.total
          );
          if (percentComplete !== 100) {
            return setUploading(true);
          }
          if (percentComplete === 100) {
            setUploading(false);
          }
          return "";
        },
      })
      .then((res) => {
        setUploading(false);
        setUploadPOP({
          url: res.data.url,
          fileName,
        });
        console.log(res.data);
        // returns url
      })
      .then(() => {
        setUploading(false);
        // close off
      });
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length > 1) {
      Notification.warning({
        title: "Too many files",
        message: "Only the first file will be selected",
      });
    }
    const uploadFile = acceptedFiles[0];
    const img = await fileToBase64(uploadFile);
    uploadAsset(img, uploadFile.type, uploadFile.name);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  // Dropzone END

  const onSubmit = (values) => {
    if (!uploadPOP) {
      Notification.error({
        title: "No Proof Of Payment",
        message: "Please upload a proof of payment",
      });
      return;
    }

    const selectedExpenseClaims = values.expenseClaims
      ? values.expenseClaims.connect
          .filter((claim) => claim.selected)
          .map((claim) => ({
            id: claim.id,
          }))
      : [];

    const selectedRates = values.rates
      ? values.rates.connect
          .filter((rate) => rate.selected)
          .map((rate) => ({
            id: rate.id,
          }))
      : [];

    const selectedReferrals = values.referrals
      ? values.referrals.connect
          .filter((referral) => referral.selected)
          .map((referral) => ({
            id: referral.id,
          }))
      : [];

    if (
      selectedExpenseClaims.length === 0 &&
      selectedRates.length === 0 &&
      selectedReferrals.length === 0
    ) {
      Notification.error({
        title: "Nothing to pay",
        message:
          "Please make sure that you select the items that you want to pay",
      });
      return;
    }

    const submitValues = {
      proofOfPayment: uploadPOP.url,
      fileName: uploadPOP.fileName,
      amount: totalDue,
      staff: {
        connect: {
          id: activePayment.staff.id,
        },
      },
      expenseClaims: {
        connect: selectedExpenseClaims,
      },
      ratePayments: {
        connect: selectedRates,
      },
      referrals: {
        connect: selectedReferrals,
      },
    };

    // Create Payment for the selected values:
    createPayment({
      variables: {
        data: submitValues,
      },
    })
      .then(() => {
        Notification.success({ title: "Payment Succeeded", message: "" });
        setActivePayment(false);
        setShowPaymentModal(false);
        customRefetch({
          variables: {
            ...variables,
            first: 50,
            skip: 0,
          },
        });
      })
      .catch((err) =>
        Notification.error({
          title: "Payment Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  useEffect(() => {
    if (!showPaymentModal || !activePayment) {
      return;
    }

    let total = 0;

    if (expenseClaims) {
      expenseClaims.connect
        .filter((claim) => claim.selected)
        .forEach((claim) => {
          const val = activePayment.expenseClaims.find(
            (activeClaim) => activeClaim.id === claim.id
          );
          if (val) {
            total += val.amount;
          }
        });
    }
    if (rates) {
      rates.connect
        .filter((rate) => rate.selected)
        .forEach((rate) => {
          const val = activePayment.rates.find(
            (activeRate) => activeRate.id === rate.id
          );
          if (val) {
            total += val.amount;
          }
        });
    }
    if (referrals) {
      referrals.connect
        .filter((referral) => referral.selected)
        .forEach((referral) => {
          const val = activePayment.referrals.find(
            (activeReferral) => activeReferral.id === referral.id
          );
          if (val) {
            total += 5;
          }
        });
    }

    setTotalDue(total);
  }, [expenseClaims, rates, referrals, activePayment, showPaymentModal]);

  useEffect(() => {
    setActiveSideNav("billing");
    setPageTitle("View Payments Due");
  });

  const filterData = (totalToPay, status, type = "SUM") => {
    if (!totalToPay) {
      return 0;
    }

    if (type === "SUM") {
      return sumObjectArray(
        totalToPay.filter((claim) => claim.status === status),
        "amount"
      );
    }

    return totalToPay.filter((claim) => claim.status === status).length;
  };

  const HeaderInfo = [
    {
      Header: "Staff",
      accessor: (row) =>
        row.staff.appUser
          ? `${row.staff.appUser.firstName} ${row.staff.appUser.lastName}`
          : "Not set",
      id: "staff",
      search: true,
    },
    {
      Header: "Total Approved Expense Claims",
      id: "totalApprovedExpenseClaims",
      accessor: (row) => filterData(row.expenseClaims, "APPROVED"),
    },
    {
      Header: "Total Approved Referrals",
      id: "totalApprovedReferrals",
      accessor: (row) => filterData(row.referrals, "COMPLETED", "COUNT") * 5,
    },
    {
      Header: "Total Pending Rates",
      id: "totalPendingRates",
      accessor: (row) => filterData(row.rates, "PENDING"),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <AiOutlineEye
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            onClick={() => {
              setShowModal(true);
              setActiveSummary(row.original);
            }}
          />
          <Button
            onClick={() => {
              setShowPaymentModal(true);
              setActivePayment(row.original);
            }}
            kind="secondary"
            value="Pay Staff"
            width="auto"
            margin="10px 0 10px 10px"
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
  ];

  return (
    <>
      <PageHeader title="View Payments Due" />
      <Table
        columns={HeaderInfo}
        data={(data && data.groupedTotalToPay) || []}
        loading={loading}
        error={error}
        refetch={customRefetch}
        variables={variables}
        dataCount={(data && data.groupedTotalToPay.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Payments Due"
        tableOptions={["columns", "filter", "download"]}
        filters={filters}
        fileName="TotalToPay"
        downloadData={exportData}
        getDownloadData={refetchExport}
        downloadAccessor={
          exportData.data ? exportData.data.groupedTotalToPay : []
        }
        multiSheet
        getSheetName={(d) => {
          return `${d.staff.appUser.firstName} ${d.staff.appUser.lastName}`;
        }}
      />
      <Modal
        open={showModal}
        showClose
        close={() => {
          setShowModal(false);
        }}
      >
        <Row style={{ marginBottom: "0" }}>
          <Col sm={12} md={12} lg={12} sub>
            <H1>Payment Due Summary</H1>
          </Col>
        </Row>
        {activeSummary && (
          <>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={6} md={6} lg={6} sub>
                <H2 style={{ marginBottom: "12px" }}>App User</H2>
                <Span>
                  {`${activeSummary.staff.appUser.firstName} ${activeSummary.staff.appUser.lastName}`}
                </Span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={12} md={12} lg={12} sub>
                <H2 style={{ marginBottom: "7.5px" }}>All Rates</H2>
              </Col>

              {activeSummary.rates ? (
                activeSummary.rates.map((rate) => (
                  <Col
                    sm={6}
                    md={6}
                    lg={6}
                    sub
                    key={rate.id}
                    padding="8px 17px"
                  >
                    <Card>
                      <Row style={{ marginBottom: "0" }}>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Company:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {rate.serviceAgreement.employer.companyName}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Position:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {rate.serviceAgreement.position.title}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Status:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {rate.status}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Amount:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {rate.amount}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col sm={12} md={12} lg={12} sub>
                  No active rates
                </Col>
              )}
            </Row>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={12} md={12} lg={12} sub>
                <H2 style={{ marginBottom: "7.5px" }}>All Claims</H2>
              </Col>
              {activeSummary.expenseClaims ? (
                activeSummary.expenseClaims.map((claim) => (
                  <Col
                    sm={6}
                    md={6}
                    lg={6}
                    sub
                    key={claim.id}
                    padding="8px 17px"
                  >
                    <Card>
                      <Row style={{ marginBottom: "0" }}>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Company:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {claim.employer.companyName}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Amount:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {claim.amount}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Status:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {claim.status}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Position:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {claim.position.title}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Invoice:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          <Link to={claim.invoice} target="_blank">
                            View Invoice
                          </Link>
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Submition Date:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {moment(claim.createdAt).format(
                            "YYYY/MM/DD HH:mm:SS"
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col sm={12} md={12} lg={12} sub>
                  No active expense claims
                </Col>
              )}
            </Row>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={12} md={12} lg={12} sub>
                <H2 style={{ marginBottom: "7.5px" }}>All Referrals</H2>
              </Col>
              {activeSummary.referrals ? (
                activeSummary.referrals.map((referral) => (
                  <Col
                    sm={6}
                    md={6}
                    lg={6}
                    sub
                    key={referral.id}
                    padding="8px 17px"
                  >
                    <Card>
                      <Row style={{ marginBottom: "0" }}>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Referee:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {`${referral.referee.firstName} ${referral.referee.lastName}`}
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          Status:
                        </Col>
                        <Col
                          sm={6}
                          md={6}
                          lg={6}
                          sub
                          padding="10px 0"
                          style={{ marginBottom: "5px" }}
                        >
                          {referral.status}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col sm={12} md={12} lg={12} sub>
                  No active referrals
                </Col>
              )}
            </Row>
          </>
        )}
      </Modal>

      <Modal
        open={showPaymentModal}
        showClose
        close={() => {
          setShowPaymentModal(false);
        }}
        fullScreen
      >
        <Row style={{ marginBottom: "0" }}>
          <Col sm={12} md={8} lg={8} sub>
            <H1>Select items that needs to be paid</H1>
          </Col>
          <Col sm={12} md={4} lg={4} sub>
            <H3>{`Total: ${totalDue}`}</H3>
          </Col>
          <Col sm={12} md={8} lg={8} sub>
            <ModalTable>
              <thead>
                <TableRow>
                  <TableHeader>Type</TableHeader>
                  <TableHeader>Amount</TableHeader>
                  <TableHeader>Action</TableHeader>
                </TableRow>
              </thead>
              <tbody>
                {activePayment.rates &&
                  activePayment.rates.map((rate, index) => (
                    <TableRow key={rate.id}>
                      <TableCol>Rate</TableCol>
                      <TableCol>{rate.amount}</TableCol>
                      <TableCol>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={false}
                          label="Mark as paid"
                          name={`rates.connect[${index}].selected`}
                          register={register}
                        />
                        <BorderInput
                          hidden
                          name={`rates.connect[${index}].id`}
                          value={rate.id}
                          register={register}
                        />
                      </TableCol>
                    </TableRow>
                  ))}
                {activePayment.expenseClaims &&
                  activePayment.expenseClaims.map((claim, index) => (
                    <TableRow key={claim.id}>
                      <TableCol>Rate</TableCol>
                      <TableCol>{claim.amount}</TableCol>
                      <TableCol>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={false}
                          label="Mark as paid"
                          name={`expenseClaims.connect[${index}].selected`}
                          register={register}
                        />
                        <BorderInput
                          hidden
                          name={`expenseClaims.connect[${index}].id`}
                          value={claim.id}
                          register={register}
                        />
                      </TableCol>
                    </TableRow>
                  ))}
                {activePayment.referrals &&
                  activePayment.referrals.map((referral, index) => (
                    <TableRow key={referral.id}>
                      <TableCol>Referral</TableCol>
                      <TableCol>5</TableCol>
                      <TableCol>
                        <CheckBox
                          type="checkbox"
                          defaultChecked={false}
                          label="Mark as paid"
                          name={`referrals.connect[${index}].selected`}
                          register={register}
                        />
                        <BorderInput
                          hidden
                          name={`referrals.connect[${index}].id`}
                          value={referral.id}
                          register={register}
                        />
                      </TableCol>
                    </TableRow>
                  ))}
              </tbody>
            </ModalTable>
          </Col>
          <Col sm={12} md={4} lg={4} sub style={{ textAlign: "center" }}>
            <H3 style={{ marginBottom: "7.5px" }}>Upload Proof of Payment</H3>
            {!uploadPOP ? (
              <DropZone {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <>
                    <FiShare className="icon" />
                    <p>
                      Drag files here to upload or
                      <br />
                      <span className="red">select a file</span>
                      from your device
                    </p>
                  </>
                )}
              </DropZone>
            ) : (
              <FileName>
                {uploadPOP.fileName}
                <FiX
                  className="icon"
                  onClick={() => {
                    setUploadPOP(false);
                  }}
                />
              </FileName>
            )}
            <Accordion
              title="Banking Details:"
              maxHeight="300px"
              style={{ marginTop: "15px" }}
            >
              {activePayment && (
                <Row>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Account Name
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.accountName}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Not Own Name
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.notOwnName}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Address Of AccountHolder
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.accountName}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Account Currency
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.accountCurrency}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Bank Name
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.bankName}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Bank Address
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.bankAddress}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Bank Country
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.bankCountry}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    IBAN
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.IBAN}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    BICSWIFT
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.BICSWIFT}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Account Number
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.accountNumber}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    ABACode
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.ABACode}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Sort Code
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.sortCode}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                  <Col sub sm={5} padding="10px 0">
                    Additional Info
                  </Col>
                  <Col sub sm={7} padding="10px 0">
                    {activePayment.staff.bankingDetails.additionalInfo}
                  </Col>
                  <Col sub sm={12} padding="0">
                    <Line />
                  </Col>
                </Row>
              )}
            </Accordion>
            <Button
              onClick={handleSubmit(onSubmit)}
              kind="secondary"
              value="Pay Staff"
              width="auto"
              margin="15px auto"
              loading={uploading || creatingPayment}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Total;
