import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { FiRefreshCcw } from "react-icons/fi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import LineInput from "../../components/Form/LineInput/LineInput.component";
import Button from "../../components/Button/Button.component";
import Error from "../../components/Error/ErrorMessage.component";
import FormHeader from "../../components/Form/FormHeader/FormHeader.component";
import Notification from "../../components/Toast/Toast.component";

import { CREATE_PASSWORD } from "../../graphql/mutations/adminUser.mutation";
import { formatGraphqlError } from "../../utils/api";

const ConfirmAccount = () => {
  const { register, handleSubmit, errors, watch } = useForm();
  const { token } = useParams();
  const [passwordType, setPasswordType] = useState("password");
  const password = useRef({});
  password.current = watch("password", "");
  const history = useHistory();
  const [createPassword, { loading }] = useMutation(CREATE_PASSWORD);

  const onSubmit = (values) => {
    createPassword({
      variables: {
        authToken: token,
        newPassword: values.password,
      },
    })
      .then(() => {
        Notification.success({
          title: "Account Confirmed",
          message: `Account successfully created.`,
        });
        history.push("/login");
      })
      .catch((err) =>
        Notification.error({
          title: "Confirm account failed",
          message: formatGraphqlError(err),
        })
      );
  };

  const passwordReveal = () => {
    const setType = passwordType === "password" ? "text" : "password";
    setPasswordType(setType);
  };

  return (
    <>
      <FormHeader
        title="Confirm Account"
        icon={<FiRefreshCcw />}
        customStyle={{ justifyContent: "center" }}
      />
      <LineInput
        name="password"
        type={passwordType}
        label="Password"
        register={register}
        customStyle={{ width: "95%", alignItems: "center" }}
        icon={
          passwordType === "password" ? (
            <AiOutlineEye
              onClick={passwordReveal}
              style={{ cursor: "pointer", width: "1.2em", height: "1.2em" }}
            />
          ) : (
            <AiOutlineEyeInvisible
              onClick={passwordReveal}
              style={{ cursor: "pointer", width: "1.2em", height: "1.2em" }}
            />
          )
        }
        required={{
          required: "you must specify an password",
          minLength: {
            value: 6,
            message: "password must be atleast 8 characters",
          },
        }}
        error={errors.password && <Error message={errors.password.message} />}
      />

      <LineInput
        name="confirmPassword"
        type={passwordType}
        label="Confirm Password"
        register={register}
        customStyle={{ width: "95%", alignItems: "center" }}
        icon={
          passwordType === "password" ? (
            <AiOutlineEye
              onClick={passwordReveal}
              style={{ cursor: "pointer", width: "1.2em", height: "1.2em" }}
            />
          ) : (
            <AiOutlineEyeInvisible
              onClick={passwordReveal}
              style={{ cursor: "pointer", width: "1.2em", height: "1.2em" }}
            />
          )
        }
        required={{
          validate: (value) =>
            value === password.current || "Passwords don't match",
        }}
        error={
          errors.confirmPassword && (
            <Error message={errors.confirmPassword.message} />
          )
        }
      />
      <Button
        sub
        onClick={handleSubmit(onSubmit)}
        type="button"
        kind="secondary"
        value="Confirm Account"
        loading={loading}
      />
    </>
  );
};

export default ConfirmAccount;
