/**
 * Use the CSS tab above to style your Element's container.
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useMutation } from "react-apollo";
import "./CardSectionStyles.css";
import { Row, Column } from "../Grid/Grid.component";
import CardSection from "./CardSection.componenet";
import Button from "../Button/Button.component";
import Notification from "../Toast/Toast.component";

import { ADD_PAYMENT_METHOD } from "../../graphql/mutations/stripe/addPaymentMethod.mutation";

const AddNewCard = ({ stripeId, getPaymentMethods, setAddNewCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [addPaymentMethod] = useMutation(ADD_PAYMENT_METHOD);

  const createNewPaymentMethod = async () => {
    const cardElement = elements.getElement(CardElement);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // If a previous payment was attempted, get the latest invoice
    const {
      error: paymentError,
      paymentMethod,
    } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (paymentError) {
      setLoading(false);
    } else {
      addPaymentMethod({
        variables: {
          stripeId,
          paymentMethodId: paymentMethod.id,
        },
      })
        .then(() => {
          getPaymentMethods({
            variables: {
              stripeId,
            },
          });
          setLoading(false);
          setAddNewCard(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          Notification.error({
            title: "Something went wrong",
            message: "Card could not be added",
          });
        });
      console.log("[PaymentMethod]", paymentMethod);
    }
  };

  return (
    <>
      <Row>
        <Column sub noPad>
          <CardSection />
        </Column>
      </Row>
      <Row>
        <Column sub width="100%" noPad>
          <Button
            width="100%"
            onClick={() => createNewPaymentMethod()}
            type="button"
            value="Save Card"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => setAddNewCard(false)}
            type="button"
            value="Close"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

AddNewCard.propTypes = {
  stripePromise: PropTypes.object.isRequired,
  stripeId: PropTypes.string.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  setAddNewCard: PropTypes.func.isRequired,
};

export default AddNewCard;
