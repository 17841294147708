import gql from "graphql-tag";

export const GET_SPORT_CATEGORIES = gql`
  query getSportCategories(
    $order: SportCategoryOrderByInput
    $first: Int!
    $skip: Int!
    $where: SportCategoryWhereInput!
  ) {
    sportCategories(
      orderBy: $order
      first: $first
      skip: $skip
      where: $where
    ) {
      title
      description
      status
      id
    }
    sportCategoriesCount: sportCategories(where: $where) {
      id
    }
  }
`;

export const GET_SPORT_CATEGORIES_EXPORT = gql`
  query getSportCategories(
    $order: SportCategoryOrderByInput
    $where: SportCategoryWhereInput!
  ) {
    sportCategories(orderBy: $order, where: $where) {
      title
      description
      status
      id
    }
  }
`;

export const GET_SPORT_CATEGORY = gql`
  query getSportCategory($id: ID) {
    sportCategory(where: { id: $id }) {
      title
      description
      status
      id
    }
  }
`;

export const GET_ALL_ACTIVE_SPORT_CATEGORIES = gql`
  query {
    sportCategories(where: { status: ACTIVE }, orderBy: title_ASC) {
      title
      id
    }
  }
`;

export default {
  GET_SPORT_CATEGORIES,
  GET_SPORT_CATEGORIES_EXPORT,
  GET_SPORT_CATEGORY,
};
