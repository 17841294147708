import gql from "graphql-tag";

export const CREATE_POSITION_ROLE = gql`
  mutation createPositionRole(
    $title: String!
    $description: String!
    $status: GeneralStatus!
    $sportCategories: SportCategoryCreateManyInput!
    $sportTypes: SportTypeCreateManyInput
    $baseSkills: SkillCreateManyInput
  ) {
    createPositionRole(
      data: {
        title: $title
        description: $description
        status: $status
        sportTypes: $sportTypes
        sportCategories: $sportCategories
        baseSkills: $baseSkills
      }
    ) {
      id
    }
  }
`;

export const UPDATE_POSITION_ROLE = gql`
  mutation updateUserType(
    $title: String!
    $description: String!
    $status: GeneralStatus!
    $sportCategories: SportCategoryUpdateManyInput!
    $sportTypes: SportTypeUpdateManyInput
    $baseSkills: SkillUpdateManyInput
    $id: ID!
  ) {
    updatePositionRole(
      data: {
        title: $title
        description: $description
        status: $status
        sportTypes: $sportTypes
        sportCategories: $sportCategories
        baseSkills: $baseSkills
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_POSITION_ROLE = gql`
  mutation deletePositionRole($id: ID!) {
    deletePositionRole(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_POSITION_ROLE,
  UPDATE_POSITION_ROLE,
  DELETE_POSITION_ROLE,
};
