import styled from "styled-components";
import { motion } from "framer-motion";

export const ChatHeader = styled.div`
  width: 100%;
  background-image: linear-gradient(225deg, #9c0a0a, #f53544);
  height: 60px;
  color: white;
  padding: 18px 13px;
  display: flex;
  align-items: center;
`;

export const ChatHeaderImageHolder = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  overflow: hidden;
`;
export const ChatHeaderImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ChatHeaderFullName = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin-left: 16px;
`;

export const ChatHolder = styled.div`
  height: calc(100% - 60px);
  position: relative;
  padding: 10px 15px;
  padding-right: 0;
  padding-bottom: 75px;
`;

export const ChatBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 2.5%;
  width: 95%;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e3e3ee;
  background-color: white;
  border-radius: 4px;
`;

export const Icon = styled.span`
  color: #5086f4;
  margin: 0 5px;
  font-size: 20px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  right: 4px;
  cursor: pointer;
`;

export const StyledInput = styled.textarea`
  min-height: 60px;
  max-height: 300px;
  width: 97%;
  border-radius: 4px;
  color: #63636d;
  font-size: 16px;
  border: none;
  background-color: transparent;
  outline-color: transparent;
  outline: none;
  resize: none;
  padding: 15px;
  font-family: Lato, sans-serif;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;

export const ChatMessages = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(156, 10, 10, 1);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(156, 10, 10, 1); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;

export const ChatMessage = styled(motion.div)`
  background-color: ${(props) =>
    props.position === "left" ? "white" : "#FFE5E7"};
  border-radius: 2px;
  padding: 10px;
  padding-bottom: 20px;
  position: relative;
  max-width: 55%;
  margin: 10px 0;
  white-space: pre-wrap;
  margin-left: ${(props) => (props.position === "right" ? "auto" : "0")};
  margin-right: ${(props) => (props.position === "left" ? "auto" : "0")};
`;

export const UserName = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
`;

export const Read = styled.div`
  position: absolute;
  bottom: 0;
  left: 10px;
  color: ${(props) => (props.read ? "#3C88FC" : "#ADACB6")};
`;

export const Date = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #adacb6;
  font-size: 10px;
`;

export const ChatHeaderDropDownHolder = styled.div``;
