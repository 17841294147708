/**
 * This document will handle all the routes.
 * All files in the pages folder can be seen as a route
 */

import {
  FiActivity,
  FiBook,
  FiBriefcase,
  FiCalendar,
  FiCreditCard,
  FiMessageCircle,
  FiPieChart,
  FiSettings,
  FiUsers,
  FiCast
} from "react-icons/fi";
import AuditLog from "../pages/AuditLogs/AuditLog";
// AUDIT LOGS END
// AUDIT LOGS START
import AuditLogs from "../pages/AuditLogs/AuditLogs";
// ACCOUNTS & BILLING END
// SUPPORT START
import StaffChat from "../pages/Chat/StaffChat";
// POSITION CONFIGURATION END
// CONTENT MANAGEMENT START
import ContactInformationAll from "../pages/CMS/ContactInformation/ContactInformation.all";
import ContactInformationEdit from "../pages/CMS/ContactInformation/ContactInformation.edit";
import ContactInformationNew from "../pages/CMS/ContactInformation/ContactInformation.new";
import ContentManagementAll from "../pages/CMS/ContentManagement/CM.all";
import ContentManagementEdit from "../pages/CMS/ContentManagement/CM.edit";
import ContentManagementNew from "../pages/CMS/ContentManagement/CM.new";
import FAQAll from "../pages/CMS/FAQ/FAQ.all";
import FAQEdit from "../pages/CMS/FAQ/FAQ.edit";
import FAQNew from "../pages/CMS/FAQ/FAQ.new";
import PrivacyPolicyEdit from "../pages/CMS/PrivacyPolicy/PrivacyPolicy.edit";
import PrivacyPolicyPreview from "../pages/CMS/PrivacyPolicy/PrivacyPolicy.preview";
import TermsAndConditionsEdit from "../pages/CMS/TermsAndConditions/TermsAndConditions.edit";
import TermsAndConditionsPreview from "../pages/CMS/TermsAndConditions/TermsAndConditions.preview";
import Dashboard from "../pages/Dashboard/Dashboard";
// SUGGESTIONS END
// EVENTS START
import Events from "../pages/Events/Events";
// CONTENT MANAGEMENT END
// ACCOUNTS & BILLING START
import ExpenseClaims from "../pages/Finances/ExpenseClaims/ExpenseClaim";
import PaymentHistory from "../pages/Finances/PaymentHistory/PaymentHistory";
import Rates from "../pages/Finances/Rates/Rates";
import Referrals from "../pages/Finances/Referrals/Referrals";
import Total from "../pages/Finances/Total/Total";
// AUDIT LOGS END
import Profile from "../pages/Profile/Profile";
// EVENTS END
// AUDIT LOGS START
import ServiceAgreementAll from "../pages/ServiceAgreement/ServiceAgreement.all";
import ServiceAgreementView from "../pages/ServiceAgreement/ServiceAgreement.view";
// USER MANAGEMENT END
// SYSTEM CONFIGURATION START
import ExpenseClaimTypeAll from "../pages/SystemConfiguration/ExpenseClaimType/ExpenseClaimType.all";
import ExpenseClaimTypeEdit from "../pages/SystemConfiguration/ExpenseClaimType/ExpenseClaimType.edit";
import ExpenseClaimTypeNew from "../pages/SystemConfiguration/ExpenseClaimType/ExpenseClaimType.new";
import NotificationManagerAll from "../pages/SystemConfiguration/NotificationManager/NotificationManager.all";
import NotificationManagerEdit from "../pages/SystemConfiguration/NotificationManager/NotificationManager.edit";
import NotificationManagerNew from "../pages/SystemConfiguration/NotificationManager/NotificationManager.new";
import PositionRoleAll from "../pages/SystemConfiguration/PositionRole/PositionRole.all";
import PositionRoleEdit from "../pages/SystemConfiguration/PositionRole/PositionRole.edit";
import PositionRoleNew from "../pages/SystemConfiguration/PositionRole/PositionRole.new";
import SkillAll from "../pages/SystemConfiguration/Skill/Skill.all";
import SkillEdit from "../pages/SystemConfiguration/Skill/Skill.edit";
import SkillNew from "../pages/SystemConfiguration/Skill/Skill.new";
import SportCategoryAll from "../pages/SystemConfiguration/SportCategory/SportCategory.all";
import SportCategoryEdit from "../pages/SystemConfiguration/SportCategory/SportCategory.edit";
import SportCategoryNew from "../pages/SystemConfiguration/SportCategory/SportCategory.new";
import SportTypeAll from "../pages/SystemConfiguration/SportType/SportType.all";
import SportTypeEdit from "../pages/SystemConfiguration/SportType/SportType.edit";
import SportTypeNew from "../pages/SystemConfiguration/SportType/SportType.new";
import UserTypeAll from "../pages/SystemConfiguration/UserTypes/UserType.all";
import UserTypeEdit from "../pages/SystemConfiguration/UserTypes/UserType.edit";
import UserTypeNew from "../pages/SystemConfiguration/UserTypes/UserType.new";
// USER MANAGEMENT START
import AdminUserAll from "../pages/UserManagement/Admin/User.all";
import AdminUserEdit from "../pages/UserManagement/Admin/User.edit";
import AdminUserNew from "../pages/UserManagement/Admin/User.new";
import EmployerUserAll from "../pages/UserManagement/Employer/User.all";
import EmployerUserView from "../pages/UserManagement/Employer/User.view";
import StaffUserAll from "../pages/UserManagement/Staff/User.all";
import StaffUserView from "../pages/UserManagement/Staff/User.view";
import Feedback from "../pages/Feedback/Feedback";
import EmployerChat from "../pages/Chat/EmployerChat";
import Communication from "../pages/Communication/Communication";

const ProTouchStaffRoutes = [
  {
    path: "/app/dashboard",
    icon: FiPieChart,
    title: "Dashboard",
    hasPermissions: false,
    defaultRoute: true,
    active: "dashboard",
    permissions: [],
    component: Dashboard,
    sideNav: true,
  },
  {
    path: "/app/user-management",
    icon: FiUsers,
    title: "User Management",
    hasPermissions: true,
    active: "userManagement",
    permissions: ["admin", "staff", "employer", "userTypes"],
    sideNav: true,
    childRoutes: [
      {
        path: "/app/user-management/company",
        title: "Company Users",
        defaultTab: true,
        tab: true,
        component: EmployerUserAll,
      },
      {
        path: "/app/user-management/company/:id",
        title: "View Company User",
        component: EmployerUserView,
      },
      {
        path: "/app/user-management/staff",
        title: "Staff Users",
        defaultTab: true,
        tab: true,
        component: StaffUserAll,
      },
      {
        path: "/app/user-management/staff/:id",
        title: "View Staff User",
        component: StaffUserView,
      },
      {
        path: "/app/user-management/admin",
        title: "Admin Users",
        tab: true,
        component: AdminUserAll,
      },
      {
        path: "/app/user-management/admin/new",
        title: "Create Admin User",
        component: AdminUserNew,
      },
      {
        path: "/app/user-management/admin/:id",
        title: "Edit Admin User",
        component: AdminUserEdit,
      },
    ],
  },
  {
    path: "/app/system-configuration",
    icon: FiSettings,
    title: "System Configuration",
    hasPermissions: true,
    active: "sysConf",
    permissions: ["sport", "sportType"],
    sideNav: true,
    childRoutes: [
      {
        path: "/app/system-configuration/sport-type",
        title: "Sport Type",
        defaultTab: true,
        tab: true,
        component: SportTypeAll,
      },
      {
        path: "/app/system-configuration/sport-type/new",
        title: "Create Sport Type",
        component: SportTypeNew,
      },
      {
        path: "/app/system-configuration/sport-type/:id",
        title: "Edit Sport Type",
        component: SportTypeEdit,
      },
      {
        path: "/app/system-configuration/sport-category",
        title: "Sport Category",
        tab: true,
        component: SportCategoryAll,
      },
      {
        path: "/app/system-configuration/sport-category/new",
        title: "Create Sport Category",
        component: SportCategoryNew,
      },
      {
        path: "/app/system-configuration/sport-category/:id",
        title: "Edit Sport Category",
        component: SportCategoryEdit,
      },
      {
        path: "/app/system-configuration/skill",
        title: "Skill",
        tab: true,
        component: SkillAll,
      },
      {
        path: "/app/system-configuration/skill/new",
        title: "Create Skill",
        component: SkillNew,
      },
      {
        path: "/app/system-configuration/skill/:id",
        title: "Edit Skill",
        component: SkillEdit,
      },
      {
        path: "/app/system-configuration/user-types",
        title: "User Type",
        tab: true,
        component: UserTypeAll,
      },
      {
        path: "/app/system-configuration/user-types/new",
        component: UserTypeNew,
      },
      {
        path: "/app/system-configuration/user-types/:id",
        component: UserTypeEdit,
      },
      {
        path: "/app/system-configuration/expense-claim-type",
        title: "Expense Claim Type",
        tab: true,
        component: ExpenseClaimTypeAll,
      },
      {
        path: "/app/system-configuration/expense-claim-type/new",
        title: "Create Expense Claim Type",
        component: ExpenseClaimTypeNew,
      },
      {
        path: "/app/system-configuration/expense-claim-type/:id",
        title: "Edit Expense Claim Type",
        component: ExpenseClaimTypeEdit,
      },
      {
        path: "/app/system-configuration/position-role",
        title: "Position Role",
        tab: true,
        component: PositionRoleAll,
      },
      {
        path: "/app/system-configuration/position-role/new",
        title: "Create Position Role",
        component: PositionRoleNew,
      },
      {
        path: "/app/system-configuration/position-role/:id",
        title: "Edit Position Role",
        component: PositionRoleEdit,
      },
      {
        path: "/app/system-configuration/notification-manager",
        title: "Notification Manager",
        tab: true,
        component: NotificationManagerAll,
      },
      {
        path: "/app/system-configuration/notification-manager/new",
        title: "Create Automatic Notification",
        component: NotificationManagerNew,
      },
      {
        path: "/app/system-configuration/notification-manager/:id",
        title: "Edit Automatic Notification",
        component: NotificationManagerEdit,
      },
    ],
  },
  {
    path: "/app/service-agreement",
    icon: FiBriefcase,
    title: "Service Agreements",
    hasPermissions: true,
    active: "serviceAgreement",
    permissions: ["superAdmin"],
    component: ServiceAgreementAll,
    sideNav: true,
  },
  {
    path: "/app/service-agreement/:id",
    title: "Service Agreement",
    hasPermissions: true,
    active: "auditLogs",
    permissions: ["superAdmin"],
    component: ServiceAgreementView,
  },
  {
    path: "/app/cms",
    icon: FiBook,
    title: "Content Management",
    hasPermissions: true,
    active: "cm",
    sideNav: true,
    childRoutes: [
      {
        defaultTab: true,
        tab: true,
        path: "/app/cms/content-management",
        title: "Content Management",
        component: ContentManagementAll,
      },
      {
        path: "/app/cms/content-management/new",
        title: "Content Management New",
        component: ContentManagementNew,
      },
      {
        path: "/app/cms/content-management/:id",
        title: "Content Management Edit",
        component: ContentManagementEdit,
      },
      {
        path: "/app/cms/contact-information",
        title: "Contact Information",
        tab: true,
        component: ContactInformationAll,
      },
      {
        path: "/app/cms/contact-information/new",
        title: "Create Contact Information",
        component: ContactInformationNew,
      },
      {
        path: "/app/cms/contact-information/:id",
        title: "Edit Contact Information",
        component: ContactInformationEdit,
      },
      {
        path: "/app/cms/faq",
        title: "FAQ",
        tab: true,
        component: FAQAll,
      },
      {
        path: "/app/cms/faq/new",
        title: "Create FAQ",
        component: FAQNew,
      },
      {
        path: "/app/cms/faq/:id",
        title: "Edit FAQ",
        component: FAQEdit,
      },
      {
        path: "/app/cms/privacy-policy",
        title: "Privacy Policy",
        tab: true,
        component: PrivacyPolicyPreview,
      },
      {
        path: "/app/cms/privacy-policy/edit",
        title: "Edit Privacy Policy",
        component: PrivacyPolicyEdit,
      },
      {
        path: "/app/cms/terms-and-conditions",
        title: "Terms & Conditions",
        tab: true,
        component: TermsAndConditionsPreview,
      },
      {
        path: "/app/cms/terms-and-conditions/edit",
        title: "Edit Terms & Conditions",
        component: TermsAndConditionsEdit,
      },
    ],
  },
  {
    path: "/app/finances",
    icon: FiCreditCard,
    title: "Accounts & Billing",
    hasPermissions: true,
    active: "billing",
    sideNav: true,
    childRoutes: [
      {
        path: "/app/finances/total",
        title: "Payments Due",
        defaultTab: true,
        tab: true,
        component: Total,
      },
      {
        path: "/app/finances/rates",
        title: "All Rates",
        tab: true,
        component: Rates,
      },
      {
        tab: true,
        path: "/app/finances/expense-claims",
        title: "All Expense Claims",
        component: ExpenseClaims,
      },
      {
        path: "/app/finances/referrals",
        title: "All Referrals",
        tab: true,
        component: Referrals,
      },
      {
        path: "/app/finances/payment-history",
        title: "Payment History",
        tab: true,
        component: PaymentHistory,
      },
    ],
  },
  {
    path: "/app/support",
    icon: FiMessageCircle,
    title: "Support",
    hasPermissions: true,
    active: "support",
    sideNav: true,
    childRoutes: [
      {
        defaultTab: true,
        tab: true,
        path: "/app/support/staff",
        title: "Staff",
        component: StaffChat,
      },
      {
        path: "/app/support/company",
        title: "Companies",
        tab: true,
        component: EmployerChat,
      },
      {
        path: "/app/support/feedback",
        title: "Feedback",
        tab: true,
        component: Feedback,
      },
    ],
  },
  // {
  //   path: "/app/suggestions",
  //   icon: FiPocket,
  //   title: "Manage Suggestions",
  //   hasPermissions: true,
  //   active: "suggestions",
  //   component: SuggestionAll,
  //   sideNav: true,
  // },
    {
    path: "/app/communication",
    icon: FiCast,
    title: "Communication",
    hasPermissions: true,
    active: "communication",
    component: Communication,
    sideNav: true,
  },
  {
    path: "/app/events",
    icon: FiCalendar,
    title: "Manage Events",
    hasPermissions: true,
    active: "events",
    permissions: ["superAdmin"],
    component: Events,
    sideNav: true,
  },
  {
    path: "/app/audit-logs",
    icon: FiActivity,
    title: "Audit Logs",
    hasPermissions: true,
    active: "auditLogs",
    permissions: ["superAdmin"],
    component: AuditLogs,
    sideNav: true,
  },
  {
    path: "/app/audit-logs/:id",
    title: "Audit Logs",
    hasPermissions: true,
    active: "auditLogs",
    permissions: ["superAdmin"],
    component: AuditLog,
  },
  {
    path: "/app/profile",
    title: "Update Profile",
    component: Profile,
  },
];

export default ProTouchStaffRoutes;
