import styled from "styled-components";
import { COLOR_SECONDARY } from "../../common/styles/Colors";

export const PaymentOptionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 18px 17px;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  box-shadow: ${(props) =>
    !props.modal ? "0px 4px 10px -3px #adacb6" : "unset"};
  border: ${(props) => (props.modal ? "2px solid #adacb6" : "unset")};
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: ${(props) => (props.modal ? "7.5px 0" : "0")};
`;

export const CardDetails = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 17px;
  span {
    color: #625f6b;
  }
`;
export const CardSettings = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  svg {
    margin-right: 15px;
  }
`;
export const CardExpiry = styled.div`
  color: #625f6b;
  width: 100%;
  margin-top: 25px;
  font-size: 12px;
`;

export const PaymentOption = styled.div`
  padding: 20px;
  padding-left: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px -6px #adacb6;
  width: 25%;
  flex: 1 1 auto;
  position: relative;
  border: 1px solid ${(props) => (props.active ? COLOR_SECONDARY : "white")};
  transition: all 0.3s ease-in-out;
  @media (max-width: 700px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Radio = styled.div`
  position: absolute;
  left: 10px;
  top: 23px;
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => (props.active ? COLOR_SECONDARY : "#adacb6")};
  padding: 2.5px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  &:after {
    transition: all 0.3s ease-in-out;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    border-radius: 50px;
    transform: translate(-50%, -50%);
    background-color: ${(props) => (props.active ? COLOR_SECONDARY : "white")};
  }
`;

export const Logo = styled.img`
  position: absolute;
  left: 13px;
  top: 0;
  height: 60px;
  padding: 5px;
  margin: 0;
`;
export const LogoHolder = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 60px;
  padding: 5px;
  margin: 0;
  background-color: white;
  z-index: 9990;
`;

export default {
  PaymentOption,
  PaymentOptionWrapper,
  Logo,
};
