import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import ReactStars from "react-rating-stars-component";
import { RiStarHalfLine, RiStarLine } from "react-icons/ri";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import {
  ImageHolder,
  Image,
  CompanyNameProfile,
  CompanyRating,
} from "./Employer.style";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  UPDATE_APP_USER,
  // FORGOT_PASSWORD,
} from "../../../graphql/mutations/appUser.mutation";
import { formatGraphqlError } from "../../../utils/api";
import { COLOR_PRIMARY } from "../../../common/styles/Colors";
import { GET_RATINGS } from "../../../graphql/queries/employer.query";

const EmployerProfile = ({ employerData, refetch }) => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [canEdit, setCanEdit] = useState(false);

  const { data: ratingsData, loading: ratingsLoading } = useQuery(GET_RATINGS, {
    fetchPolicy: "no-cache",
    variables: { where: { ratedEmployer: { id: employerData.id } } },
  });

  const [updateAppUser, { loading }] = useMutation(UPDATE_APP_USER);
  // const [forgotPassword, { loading: forgotPasswordLoading }] = useMutation(
  //   FORGOT_PASSWORD
  // );

  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Companies Profile");
  });

  const { register, handleSubmit, errors, reset } = useForm();

  const resetValues = () => {
    const values = {
      firstName: employerData.appUser.firstName,
      lastName: employerData.appUser.lastName,
      email: employerData.appUser.email,
      employer: {
        update: {
          companyName: employerData.companyName,
          payingAgentName: employerData.payingAgentName,
          address: employerData.address,
          country: employerData.country,
          ceoOrManagerName: employerData.ceoOrManagerName,
          ceoOrManagerEmail: employerData.ceoOrManagerEmail,
          ceoOrManagerContactNumber: employerData.ceoOrManagerContactNumber,
          nameHR: employerData.nameHR,
          emailHR: employerData.emailHR,
          mobileContactPerson: employerData.mobileContactPerson,
          phoneNumberContactPerson: employerData.phoneNumberContactPerson,
        },
      },
    };
    reset(values, {
      errors: true, // errors will not be reset
      dirtyFields: true, // dirtyFields will not be reset
      isDirty: true, // dirty will not be reset
      isSubmitted: false,
      touched: false,
      isValid: false,
      submitCount: false,
    });
  };

  const getRatingAvg = () => {
    if (!ratingsData) {
      return 3;
    }
    if (ratingsData.ratings.length === 0) {
      return 3;
    }
    let ratingsCount = 0;
    ratingsData.ratings.forEach((rating) => {
      ratingsCount += rating.numberRating;
    });

    return ratingsCount / ratingsData.ratings.length;
  };

  const onSubmit = (values) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update this profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update Profile",
    }).then((result) => {
      if (result.value) {
        updateAppUser({
          variables: {
            id: employerData.appUser.id,
            data: values,
          },
        })
          .then(() => {
            Notification.success({
              title: "Profile Updated",
              message: "Updated user profile successfully",
            });
            setCanEdit(false);
            refetch();
          })
          .catch((err) =>
            Notification.error({
              title: "Profile Update Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const toggleSuspendUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${
        employerData.suspended ? "un" : ""
      }suspend this profile?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Suspend Profile",
    }).then((result) => {
      if (result.value) {
        updateAppUser({
          variables: {
            id: employerData.appUser.id,
            data: {
              employer: {
                update: {
                  suspended: !employerData.suspended,
                },
              },
            },
          },
        })
          .then(() => {
            if (employerData.suspended) {
              Notification.success({
                title: "Profile Unsuspended",
                message: "Unsuspended user profile successfully",
              });
            } else {
              Notification.success({
                title: "Profile Suspended",
                message: "Suspended user profile successfully",
              });
            }
            refetch();
          })
          .catch((err) =>
            Notification.error({
              title: "Profile Suspension Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  return (
    <Row style={{ marginTop: "15px", height: "auto" }}>
      <Column width="70%" noPad>
        <Row>
          <Column sub>
            <H2>Personal Details</H2>
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="First Name"
              name="firstName"
              label=""
              type="text"
              value={employerData.appUser.firstName}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a first name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.firstName && <Error message={errors.firstName.message} />
              }
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Last Name"
              name="lastName"
              label=""
              type="text"
              value={employerData.appUser.lastName}
              readOnly={!canEdit}
              register={register}
              required={{
                required: "please enter a last name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.lastName && <Error message={errors.lastName.message} />
              }
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Email Address"
              name="email"
              label=""
              type="email"
              value={employerData.appUser.email}
              register={register}
              readOnly
              required={{
                required: "please enter a valid email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "please enter a valid email",
                },
              }}
              error={errors.email && <Error message={errors.email.message} />}
            />
          </Column>
        </Row>
        <Row>
          <Column sub>
            <H2>Company Details</H2>
          </Column>
        </Row>
        <Row>
          <Column sub>
            <BorderInput
              header="Company Name"
              name="employer.update.companyName"
              label=""
              type="text"
              value={employerData.companyName}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a company name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.companyName && (
                  <Error message={errors.employer.update.companyName.message} />
                )
              }
            />
          </Column>
          <Column sub>
            <BorderInput
              header="Paying Agent Name"
              name="employer.update.payingAgentName"
              label=""
              type="text"
              value={employerData.payingAgentName}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a paying agent name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.payingAgentName && (
                  <Error
                    message={errors.employer.update.payingAgentName.message}
                  />
                )
              }
            />
          </Column>
        </Row>
        <Row>
          <Column sub>
            <BorderInput
              header="Adddress"
              name="employer.update.address"
              label=""
              type="text"
              value={employerData.address}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a address",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.address && (
                  <Error message={errors.employer.update.address.message} />
                )
              }
            />
          </Column>
          <Column sub>
            <BorderInput
              header="Country"
              name="employer.update.country"
              label=""
              type="text"
              value={employerData.country}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a country",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.country && (
                  <Error message={errors.employer.update.country.message} />
                )
              }
            />
          </Column>
        </Row>
        <Row>
          <Column sub>
            <BorderInput
              header="CEO or Manager Name"
              name="employer.update.ceoOrManagerName"
              label=""
              type="text"
              value={employerData.ceoOrManagerName}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a CEO or Manager Name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.ceoOrManagerName && (
                  <Error
                    message={errors.employer.update.ceoOrManagerName.message}
                  />
                )
              }
            />
          </Column>
          <Column sub>
            <BorderInput
              header="CEO OR Manager Email"
              name="employer.update.ceoOrManagerEmail"
              label=""
              type="text"
              value={employerData.ceoOrManagerEmail}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a CEO or Manager Email",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.ceoOrManagerEmail && (
                  <Error
                    message={errors.employer.update.ceoOrManagerEmail.message}
                  />
                )
              }
            />
          </Column>
          <Column sub>
            <BorderInput
              header="CEO OR Manager Number"
              name="employer.update.ceoOrManagerContactNumber"
              label=""
              type="text"
              value={employerData.ceoOrManagerContactNumber}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a CEO or Manager Number",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.ceoOrManagerContactNumber && (
                  <Error
                    message={
                      errors.employer.update.ceoOrManagerContactNumber.message
                    }
                  />
                )
              }
            />
          </Column>
        </Row>
        <Row>
          <Column sub>
            <BorderInput
              header="Contact Person Name"
              name="employer.update.nameHR"
              label=""
              type="text"
              value={employerData.nameHR}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a contact person name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.nameHR && (
                  <Error message={errors.employer.update.nameHR.message} />
                )
              }
            />
          </Column>
          <Column sub>
            <BorderInput
              header="Contact Person Email"
              name="employer.update.emailHR"
              label=""
              type="text"
              value={employerData.emailHR}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a contact person email",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.emailHR && (
                  <Error message={errors.employer.update.emailHR.message} />
                )
              }
            />
          </Column>
        </Row>
        <Row>
          <Column sub>
            <BorderInput
              header="Contact Person Mobile"
              name="employer.update.mobileContactPerson"
              label=""
              type="text"
              value={employerData.mobileContactPerson}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a contact person name",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.mobileContactPerson && (
                  <Error
                    message={errors.employer.update.mobileContactPerson.message}
                  />
                )
              }
            />
          </Column>
          <Column sub>
            <BorderInput
              header="Contact Person Phone Number"
              name="employer.update.phoneNumberContactPerson"
              label=""
              type="text"
              value={employerData.phoneNumberContactPerson}
              register={register}
              readOnly={!canEdit}
              required={{
                required: "please enter a contact person phone number",
                pattern: {
                  value: /[\s\S]*\S[\s\S]/,
                  message: "field cannot be empty",
                },
              }}
              error={
                errors.employer &&
                errors.employer.update &&
                errors.employer.update.phoneNumberContactPerson && (
                  <Error
                    message={
                      errors.employer.update.phoneNumberContactPerson.message
                    }
                  />
                )
              }
            />
          </Column>
        </Row>
      </Column>
      <Column width="30%">
        <ImageHolder>
          <Image src={employerData.companyLogo} />
        </ImageHolder>
        <CompanyNameProfile>
          <H2>{employerData.companyName}</H2>
        </CompanyNameProfile>
        {!ratingsLoading && (
          <CompanyRating>
            <ReactStars
              count={5}
              size={24}
              isHalf
              edit={false}
              value={getRatingAvg()}
              activeColor={COLOR_PRIMARY}
              fullIcon={<RiStarLine />}
              halfIcon={<RiStarHalfLine />}
              emptyIcon={<RiStarLine />}
            />
          </CompanyRating>
        )}

        {employerData.suspended ? (
          <Button
            width="100%"
            type="button"
            value="Unsuspend User"
            onClick={toggleSuspendUser}
            loading={loading}
          />
        ) : (
          <Button
            width="100%"
            type="button"
            value="Suspend User"
            onClick={toggleSuspendUser}
            loading={loading}
          />
        )}
        {/* <Button
          width="100%"
          type="button"
          value="Send Password Reset"
          loading={loading}
        /> */}
        {canEdit ? (
          <>
            <Button
              width="100%"
              onClick={handleSubmit(onSubmit)}
              type="button"
              kind="secondary"
              value="Save & Update"
              loading={loading}
            />
            <Button
              width="100%"
              onClick={() => {
                setCanEdit(false);
                resetValues();
              }}
              type="button"
              value="Cancel Edit"
              loading={loading}
            />
          </>
        ) : (
          <Button
            width="100%"
            onClick={() => setCanEdit(true)}
            type="button"
            kind="secondary"
            value="Edit Profile"
          />
        )}
      </Column>
    </Row>
  );
};

EmployerProfile.propTypes = {
  employerData: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default EmployerProfile;
