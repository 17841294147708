import gql from "graphql-tag";

export const GET_EXPENSE_CLAIMS = gql`
  query getExpenseClaims($where: ExpenseClaimWhereInput) {
    groupedExpenseClaims(where: $where) {
      staff {
        id
        appUser {
          id
          firstName
          lastName
          email
        }
        bankingDetails {
          accountName
          notOwnName
          bankName
          bankAddress
          addressOfAccountHolder
          bankCountry
          IBAN
          BICSWIFT
          accountNumber
          ABACode
          sortCode
          additionalInfo
        }
      }
      expenseClaims {
        id
        amount
        createdAt
        status
        invoice
        position {
          id
          title
        }
        employer {
          companyName
          payingAgentName
          phoneNumberContactPerson
          ceoOrManagerName
          ceoOrManagerEmail
          ceoOrManagerContactNumber
          appUser {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const GET_REFERRALS = gql`
  query getReferrals($where: referralWhereInput) {
    groupedReferrals(where: $where) {
      appUser {
        id
        firstName
        lastName
        email
        staff {
          id
          bankingDetails {
            accountName
            notOwnName
            bankName
            bankAddress
            addressOfAccountHolder
            bankCountry
            IBAN
            BICSWIFT
            accountNumber
            ABACode
            sortCode
            additionalInfo
          }
        }
        employer {
          id
        }
      }
      referrals {
        id
        createdAt
        status
        referee {
          id
          firstName
          lastName
          email
          staff {
            id
          }
          employer {
            id
          }
        }
      }
    }
  }
`;

export const GET_RATES = gql`
  query getRates($where: RatePaymentWhereInput) {
    groupedRates(where: $where) {
      staff {
        id
        appUser {
          firstName
          lastName
        }
      }
      rates {
        id
        amount
        status
        createdAt
        updatedAt
        reason
        serviceAgreement {
          id
          employer {
            companyName
            companyLogo
          }
          position {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_TOTAL_TO_PAY = gql`
  query getTotalToPay($where: TotalToPayWhereInput) {
    groupedTotalToPay(where: $where) {
      staff {
        id
        appUser {
          id
          firstName
          lastName
          email
        }
        bankingDetails {
          id
          accountName
          notOwnName
          addressOfAccountHolder
          accountCurrency
          bankName
          bankAddress
          bankCountry
          IBAN
          BICSWIFT
          accountNumber
          ABACode
          sortCode
          additionalInfo
        }
      }
      expenseClaims {
        id
        amount
        createdAt
        status
        invoice
        position {
          id
          title
        }
        employer {
          companyName
          payingAgentName
          phoneNumberContactPerson
          ceoOrManagerName
          ceoOrManagerEmail
          ceoOrManagerContactNumber
          appUser {
            id
            firstName
            lastName
            email
          }
        }
      }
      rates {
        id
        amount
        status
        createdAt
        updatedAt
        reason
        serviceAgreement {
          id
          employer {
            companyName
            companyLogo
          }
          position {
            id
            title
          }
        }
      }
      referrals {
        id
        createdAt
        status
        referee {
          id
          firstName
          lastName
          email
          staff {
            id
          }
          employer {
            id
          }
        }
      }
    }
  }
`;

export const GET_TOTAL_TO_PAY_EXPORT = gql`
  query getTotalToPay($where: TotalToPayWhereInput) {
    groupedTotalToPay(where: $where) {
      staff {
        id
        appUser {
          id
          firstName
          lastName
          email
        }
        bankingDetails {
          id
          accountName
          notOwnName
          addressOfAccountHolder
          accountCurrency
          bankName
          bankAddress
          bankCountry
          IBAN
          BICSWIFT
          accountNumber
          ABACode
          sortCode
          additionalInfo
        }
      }
      expenseClaims {
        id
        amount
        createdAt
        status
        invoice
        position {
          id
          title
        }
        employer {
          companyName
          payingAgentName
          phoneNumberContactPerson
          ceoOrManagerName
          ceoOrManagerEmail
          ceoOrManagerContactNumber
          appUser {
            id
            firstName
            lastName
            email
          }
        }
      }
      rates {
        id
        amount
        status
        createdAt
        updatedAt
        reason
        serviceAgreement {
          id
          employer {
            companyName
            companyLogo
          }
          position {
            id
            title
          }
        }
      }
      referrals {
        id
        createdAt
        status
        referee {
          id
          firstName
          lastName
          email
          staff {
            id
          }
          employer {
            id
          }
        }
      }
    }
  }
`;

export const GET_PAYMENT_HISTORY = gql`
  query getFaqs(
    $order: PaymentOrderByInput
    $first: Int!
    $skip: Int!
    $where: PaymentWhereInput
  ) {
    payments(orderBy: $order, first: $first, skip: $skip, where: $where) {
      id
      amount
      fileName
      proofOfPayment
      createdAt
      staff {
        id
        appUser {
          firstName
          lastName
        }
      }
      ratePayments {
        id
        amount
        createdAt
        serviceAgreement {
          employer {
            companyName
          }
          position {
            id
            title
          }
        }
      }
      expenseClaims {
        id
        amount
        createdAt
      }
      referrals {
        id
      }
    }
    paymentsCount: payments(where: $where) {
      id
    }
  }
`;
