import styled from "styled-components";

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  background: white;
  border-radius: 3px;
  margin: ${(props) => (props.sub ? "0" : "0 15px")};
  padding: ${(props) => (props.noPad ? "0 0 15px 0" : "18px 17px")};
  box-shadow: ${(props) =>
    props.sub ? "unset" : "0px 2px 2px 0px rgba(7,0,47,0.14)"};
  flex-direction: column;
  transition: width 0.3s ease-in-out;
  height: 100%;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export { Row, Column };
