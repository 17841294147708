import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import Swal from 'sweetalert2';
import { APP_CONTEXT } from "../../utils/context";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../components/Grid/Grid.component";
import BorderInput from "../../components/Form/BorderInput/BorderInput.component";
import { H2 } from "../../components/Typography/Typography.component";
import Button from "../../components/Button/Button.component";
import Error from "../../components/Error/ErrorMessage.component";
import Notification from "../../components/Toast/Toast.component";

// GQL
import { UPDATE_ADMIN_PROFILE } from "../../graphql/mutations/profile.mutation";
import { GET_PROFILE } from "../../graphql/queries/profile.query";
import { formatGraphqlError } from "../../utils/api";

const Profile = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [updateAdminUser, update] = useMutation(UPDATE_ADMIN_PROFILE);
  const { data, loading } = useQuery(GET_PROFILE, { fetchPolicy: "no-cache" });
  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Admin User");
  });

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (values) => {
    /**
     * Update Profile
     * push to list all admin users
     */

    const submitValues = values;
    submitValues.id = data.profile.id;

    updateAdminUser({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({
          title: "Update Profile Succeeded",
          message: "",
        });
      })
      .catch((err) =>
        Notification.error({
          title: "Update Profile Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  if (loading) {
    return "loading...";
  }

  return (
    <>
      <PageHeader title="Manage Profile" back />
      <Row>
        <Column width="70%" noPad>
          <Column sub noPad>
            <Column sub noPad>
              <Row>
                <Column width="100%" sub>
                  <H2>Edit Profile</H2>
                </Column>
              </Row>
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="First Name"
                    name="firstName"
                    label=""
                    type="text"
                    value={data.profile.firstName}
                    register={register}
                    required={{
                      required: "please enter a first name",
                      pattern: {
                        value: /[\s\S]*\S[\s\S]/,
                        message: "field cannot be empty",
                      },
                    }}
                    error={
                      errors.firstName && (
                        <Error message={errors.firstName.message} />
                      )
                    }
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="Last Name"
                    name="lastName"
                    label=""
                    type="text"
                    value={data.profile.lastName}
                    register={register}
                    required={{
                      required: "please enter a last name",
                      pattern: {
                        value: /[\s\S]*\S[\s\S]/,
                        message: "field cannot be empty",
                      },
                    }}
                    error={
                      errors.lastName && (
                        <Error message={errors.lastName.message} />
                      )
                    }
                  />
                </Column>
              </Row>
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="Contact Number"
                    name="cellphone"
                    label=""
                    type="text"
                    value={data.profile.cellphone}
                    register={register}
                    required={{
                      required: "please enter a valid cell phone number",
                      pattern: {
                        value: /[\s\S]*\S[\s\S]/,
                        message: "field cannot be empty",
                      },
                    }}
                    error={
                      errors.cellphone && (
                        <Error message={errors.cellphone.message} />
                      )
                    }
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="Email Address"
                    name="email"
                    label=""
                    type="email"
                    value={data.profile.email}
                    register={register}
                    required={{
                      required: "please enter a valid email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "please enter a valid email",
                      },
                    }}
                    error={
                      errors.email && <Error message={errors.email.message} />
                    }
                  />
                </Column>
              </Row>
            </Column>
          </Column>
        </Column>
        <Column width="30%">
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={update.loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default Profile;
