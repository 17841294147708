export const COLOR_PRIMARY = "#090031";
export const COLOR_SECONDARY = "#FF003A";
export const COLOR_PRIMARY_BUTTON = "#070029";
export const COLOR_PRIMARY_BUTTON_SHADOW = "#ADACB7";
export const COLOR_PRIMARY_INPUT_FONT = "#ADACB7";
export const COLOR_PRIMARY_INPUT_UNDERLINE = "#ADACB7";
export const COLOR_PRIMARY_CHECKBOX = "#07002F";
export const COLOR_PRIMARY_CHECKBOX_HOVER = "#7A73A1";
export const COLOR_GREY2 = "#393B3C";
export const COLOR_GREY3 = "#D8D8D8";
export const COLOR_DISABLED = "#B2B2B2";

export default {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_PRIMARY_BUTTON,
  COLOR_PRIMARY_INPUT_FONT,
  COLOR_PRIMARY_INPUT_UNDERLINE,
  COLOR_PRIMARY_CHECKBOX,
  COLOR_PRIMARY_CHECKBOX_HOVER,
  COLOR_GREY2,
};
