/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect, Fragment } from "react";
import { useDebounce } from "use-debounce";
import moment from "moment";
import { useTable, useSortBy } from "react-table";
import { ClipLoader } from "react-spinners";
import {
  FiChevronDown,
  FiChevronUp,
  FiFileText,
  FiAlertTriangle,
  FiSearch,
  FiDownload,
  FiColumns,
  FiFilter,
} from "react-icons/fi";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-dates";
import MultiSelect from "react-multi-select-component";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Swal from "sweetalert2";
import {
  PaginationLast,
  PaginationFirst,
  PaginationPrevious,
  PaginationNext,
} from "./components/Pagination/PaginationIcons.component";
import { Span, H2 } from "./components/Typography/Typography.component";
import Button from "./components/Button/Button.component";
import CheckBox from "./components/CheckBox/CheckBox.component";
import BorderSelect from "./components/BorderSelect/BorderSelect.component";
import DateTimeRangePicker from "./components/DateTimeRangePicker/DateTimeRangePicker.component";
import LineInput from "./components/LineInput/LineInput.component";
import {
  StyledTable,
  PaginationBtnContainer,
  PaginationContainer,
  PageSelect,
  ColumnHeader,
  RowHeader,
  Row,
  Column,
  TableIcons,
  TableHeader,
  TableTitle,
  TableFilterIcons,
  TableWrapper,
  DropDownHolder,
  DropDown,
  DropDownContent,
  DropDownDismiss,
  TableContainer,
} from "./Table.style";
import { COLOR_GREY2, COLOR_GREY3, COLOR_DISABLED } from "./components/Colors";
import {
  refetchData,
  getPaginationData,
  getApplyFilter,
  getSearchData,
  downloadXLSX,
  getExportArrayUtil,
} from "./utils/tableFunctions";

const Table = ({
  columns,
  data,
  loading,
  error,
  refetch,
  dataCount,
  variables,
  pageSizes,
  onRowClick,
  setVariables,
  tableTitle,
  tableOptions,
  filters,
  downloadData,
  getDownloadData,
  downloadAccessor,
  fileName,
  maxHeight,
  type,
  maxPageCount,
  defaultVariables,
  multiSheet,
  getSheetName,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    state: { sortBy },
  } = useTable({ columns, data, manualSortBy: true }, useSortBy);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [columnOpen, setColumnOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [filterIndex, setFilterIndex] = useState(-1);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [busyDownloading, setBusyDownloading] = useState(false);
  const [search, setSearch] = useState("");
  const [debounceSearch] = useDebounce(search, 800);
  const [searchIndex, setSearchIndex] = useState(-1);
  const toggleVisible = (e, id) => {
    const tempVisibleColumns = visibleColumns.map((col) => ({ ...col }));
    tempVisibleColumns.forEach((col, i) => {
      if (col.id === id) {
        tempVisibleColumns[i].visible = e.target.checked;
      }
    });
    setVisibleColumns(tempVisibleColumns);
  };
  const handleDatesChange = (startDate, endDate, accessor) => {
    const tempFilters = filterValues;

    tempFilters[accessor] = {
      ...tempFilters[accessor],
      startDate: startDate
        ? moment(startDate.utcOffset(0).toISOString()).utcOffset(0)
        : null,
      endDate: endDate
        ? moment(endDate.utcOffset(0).toISOString()).utcOffset(0)
        : null,
    };
    setFilterValues(tempFilters);
  };
  const handleTimeChange = (date, dateType, accessor) => {
    const tempFilters = { ...filterValues };
    let fUpdate = 0;

    if (tempFilters[accessor].fUpdate) {
      fUpdate = tempFilters[accessor].fUpdate;
    }

    if (dateType === "startDate") {
      tempFilters[accessor] = {
        ...tempFilters[accessor],
        startDate: date
          ? moment(date.utcOffset(0).toISOString()).utcOffset(0)
          : null,
        fUpdate: fUpdate + 1,
      };
    } else {
      tempFilters[accessor] = {
        ...tempFilters[accessor],
        endDate: date
          ? moment(date.utcOffset(0).toISOString()).utcOffset(0)
          : null,
        fUpdate: fUpdate + 1,
      };
    }
    setFilterValues(tempFilters);
  };
  const handleFocusChange = (focusedInput, accessor) => {
    const tempFilters = { ...filterValues };

    tempFilters[accessor] = {
      ...tempFilters[accessor],
      focusedInput,
    };

    setFilterValues(tempFilters);
  };
  const handleChangeSelect = (e, accessor) => {
    const tempFilters = { ...filterValues };

    tempFilters[accessor] = {
      value: e.target.value,
    };

    setFilterValues(tempFilters);
  };
  const handleChangeMultiSelect = (val, accessor) => {
    const tempFilters = { ...filterValues };
    tempFilters[accessor] = {
      value: val,
    };

    setFilterValues(tempFilters);
  };
  const createFilters = (reset = false) => {
    const tempFilters = { ...filterValues };
    filters.forEach((filter) => {
      switch (filter.type) {
        case "daterange":
          tempFilters[filter.accessor] = {
            startDate: null,
            endDate: null,
            focusedInput: null,
          };
          break;
        case "datetimerange":
          tempFilters[filter.accessor] = {
            startDate: null,
            endDate: null,
            focusedInput: null,
          };
          break;
        case "select":
          tempFilters[filter.accessor] = {
            value: "",
          };
          break;
        case "multiselect":
          tempFilters[filter.accessor] = {
            value: [],
          };
          break;
        default:
          break;
      }
    });

    if (reset) {
      if (defaultVariables) {
        setVariables(defaultVariables);
      }
    }
    setFilterValues(tempFilters);
  };
  const clearFilters = () => {
    createFilters(true);
    if (filterIndex !== -1) {
      const getQueryVariables = JSON.parse(JSON.stringify(variables));
      getQueryVariables.where.AND.splice(filterIndex, 1);
      setFilterIndex(-1);
      setVariables(getQueryVariables);
    }
  };
  const clearSearch = (hideSearch) => {
    if (type === "custom") {
      const variablesCopy = JSON.parse(JSON.stringify(variables));
      delete variablesCopy.search;
      setVariables(variablesCopy);
    } else if (searchIndex !== -1) {
      const variablesCopy = JSON.parse(JSON.stringify(variables));
      variablesCopy.where.AND.splice(searchIndex, 1);
      setSearchIndex(-1);
      setVariables(variablesCopy);
      if (search !== "") {
        setSearch("");
      }
    } else {
      setSearchOpen(false);
    }
    if (hideSearch) {
      setSearchOpen(false);
    }
  };

  const applyFilters = () => {
    const newFilters = getApplyFilter(
      filterValues,
      filters,
      { ...variables },
      type,
      filterIndex
    );

    if (newFilters) {
      if (newFilters.index) {
        setFilterIndex(newFilters.index);
      }
      setVariables(newFilters.variables);
      setFilterOpen(false);
    } else {
      setFilterOpen(false);
    }
  };
  const renderColumnOption = () => (
    <DropDownHolder key="column">
      <FiColumns
        className={columnOpen ? "active" : ""}
        onClick={() => setColumnOpen(!columnOpen)}
        style={{ fontSize: "20px", cursor: "pointer" }}
      />
      {columnOpen && (
        <DropDownDismiss
          onClick={() => {
            setColumnOpen(false);
          }}
        />
      )}
      <DropDown open={columnOpen}>
        <DropDownContent>
          <div className="title" style={{ textAlign: "center" }}>
            Show Columns
          </div>
          {visibleColumns.map((col, i) => (
            <CheckBox
              key={`checkbox-${i}`}
              type="checkbox"
              width="100%"
              margin="10px 0"
              value={col.visible}
              label={col.title}
              onChange={(e) => {
                toggleVisible(e, col.id);
              }}
            />
          ))}
        </DropDownContent>
      </DropDown>
    </DropDownHolder>
  );
  const renderFilterOption = () => (
    <DropDownHolder key="filter">
      <FiFilter
        className={filterOpen ? "active" : ""}
        onClick={() => setFilterOpen(!filterOpen)}
        style={{ fontSize: "20px", cursor: "pointer" }}
      />
      {filterOpen && (
        <DropDownDismiss
          onClick={() => {
            setFilterOpen(false);
          }}
        />
      )}
      <DropDown open={filterOpen} width="450px">
        <DropDownContent>
          <div
            className="title"
            style={{ textAlign: "left", marginBottom: "15px" }}
          >
            Table Filters:
          </div>
          {filters.map((filter, index) => {
            switch (filter.type) {
              case "daterange":
                if (filterValues[filter.accessor]) {
                  return (
                    <Fragment key={`filter-${tableTitle}-${index}`}>
                      <H2 style={{ marginBottom: "15px" }}>{filter.header}</H2>
                      <DateRangePicker
                        startDate={filterValues[filter.accessor].startDate} // momentPropTypes.momentObj or null,
                        startDateId={`start_date_${filter.accessor}`} // PropTypes.string.isRequired,
                        endDate={filterValues[filter.accessor].endDate} // momentPropTypes.momentObj or null,
                        endDateId={`end_date_${filter.accessor}`} // PropTypes.string.isRequired,
                        onFocusChange={(e) => {
                          handleFocusChange(e, filter.accessor);
                        }}
                        onDatesChange={({ startDate, endDate }) => {
                          handleDatesChange(
                            startDate,
                            endDate,
                            filter.accessor
                          );
                        }}
                        focusedInput={
                          filterValues[filter.accessor].focusedInput
                        }
                        block={false}
                        numberOfMonths={2}
                        displayFormat="YYYY/MM/DD"
                        orientation="horizontal"
                        anchorDirection="left"
                        isOutsideRange={() => false}
                      />
                    </Fragment>
                  );
                }
                return null;
              case "datetimerange":
                if (filterValues[filter.accessor]) {
                  return (
                    <Fragment key={`filter-${tableTitle}-${index}`}>
                      <H2 style={{ marginBottom: "15px" }}>{filter.header}</H2>
                      <DateTimeRangePicker
                        startDate={filterValues[filter.accessor].startDate} // momentPropTypes.momentObj or null,
                        startDateId={`start_date_${filter.accessor}`} // PropTypes.string.isRequired,
                        endDate={filterValues[filter.accessor].endDate} // momentPropTypes.momentObj or null,
                        endDateId={`end_date_${filter.accessor}`} // PropTypes.string.isRequired,
                        handleFocusChange={(e) => {
                          handleFocusChange(e, filter.accessor);
                        }}
                        handleDatesChange={({ startDate, endDate }) => {
                          handleDatesChange(
                            startDate,
                            endDate,
                            filter.accessor
                          );
                        }}
                        focusedInput={
                          filterValues[filter.accessor].focusedInput
                        }
                        block={false}
                        numberOfMonths={2}
                        handleTimeChange={(d, t) => {
                          handleTimeChange(d, t, filter.accessor);
                        }}
                        displayFormat="YYYY/MM/DD"
                        orientation="horizontal"
                        anchorDirection="left"
                        isOutsideRange={() => false}
                      />
                    </Fragment>
                  );
                }
                return null;

              case "select":
                if (filterValues[filter.accessor]) {
                  return (
                    <Fragment key={`filter-${tableTitle}-${index}`}>
                      <H2 style={{ margin: "15px 0" }}>{filter.header}</H2>
                      <BorderSelect
                        header=""
                        name="status"
                        label={filter.placeholder}
                        value={filterValues[filter.accessor].value}
                        onChange={(e) => {
                          handleChangeSelect(e, filter.accessor);
                        }}
                        options={filter.options}
                        stateValue
                      />
                    </Fragment>
                  );
                }
                return null;
              case "multiselect":
                if (filterValues[filter.accessor]) {
                  return (
                    <Fragment key={`filter-${tableTitle}-${index}`}>
                      <H2 style={{ margin: "15px 0" }}>{filter.header}</H2>
                      <MultiSelect
                        options={filter.options}
                        value={filterValues[filter.accessor].value}
                        onChange={(value) =>
                          handleChangeMultiSelect(value, filter.accessor)
                        }
                        labelledBy="Select"
                      />
                    </Fragment>
                  );
                }
                return null;
              default:
                return null;
            }
          })}
          <Button
            onClick={applyFilters}
            kind="secondary"
            value="Filter"
            width="calc(50% - 5px)"
            margin="10px 5px 10px 0px"
          />
          <Button
            onClick={clearFilters}
            value="Clear Filters"
            width="calc(50% - 5px)"
            margin="10px 0 10px 5px"
          />
        </DropDownContent>
      </DropDown>
    </DropDownHolder>
  );
  const download = () => {
    if (type === "custom") {
      const variablesCopy = JSON.parse(JSON.stringify(variables));
      // delete variablesCopy.pagination;
      if (variablesCopy.pagination) {
        variablesCopy.pagination.resultCount = pageSize * maxPageCount;
        variablesCopy.pagination.page = 0;
      } else {
        variablesCopy.pagination = {
          resultCount: pageSize * maxPageCount,
          page: 0,
        };
      }
      getDownloadData({
        variables: variablesCopy,
      });
    } else {
      getDownloadData({
        variables,
      });
    }
    getDownloadData({
      variables,
    });
  };

  const renderOptions = () =>
    tableOptions.map((option) => {
      switch (option) {
        case "search":
          return (
            <FiSearch
              onClick={() => {
                setSearchOpen(!searchOpen);
              }}
              style={{ fontSize: "20px", cursor: "pointer" }}
              key={option}
            />
          );
        case "download":
          return (
            <FiDownload
              onClick={download}
              key={option}
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
          );
        case "columns":
          return renderColumnOption();
        case "filter":
          return renderFilterOption();
        default:
          return null;
      }
    });
  const getExportArray = () => {
    // Converting json into a string array.
    return getExportArrayUtil(downloadAccessor, multiSheet, getSheetName);
  };
  const startDownload = () => {
    const exportArray = getExportArray();
    downloadXLSX(exportArray, fileName, multiSheet, () => {
      setBusyDownloading(false);
    });
  };
  useEffect(() => {
    // COLUMN DATA
    const tempColumns = [];
    columns.forEach((column) => {
      if (column.id) {
        tempColumns.push({
          id: column.id,
          accessor: column.accessor,
          visible: column.visible || true,
          title: column.Header,
        });
      }
    });
    setVisibleColumns(tempColumns);
    let getQueryVariables = JSON.parse(JSON.stringify(variables));
    if (getQueryVariables.where) {
      if (!getQueryVariables.where.AND) {
        getQueryVariables.where = {
          ...getQueryVariables.where,
          AND: [],
        };
        setVariables(getQueryVariables);
      }
    } else {
      getQueryVariables = {
        ...getQueryVariables,
        where: { AND: [] },
      };
      setVariables(getQueryVariables);
    }
    // FILTER DATA
    createFilters();
  }, []);
  useEffect(() => {
    const hideColumns = [];
    visibleColumns.forEach((col) => {
      if (!col.visible) {
        hideColumns.push(col.id);
      }
    });
    setHiddenColumns(hideColumns);
  }, [visibleColumns]);
  useEffect(() => {
    if (refetch) {
      setTimeout(() => {
        refetchData({
          variables,
          refetch,
          pageIndex,
          pageSize,
          sortBy,
          type,
        });
      }, 1);
    }
  }, [variables, refetch, pageIndex, pageSize, sortBy, type]);
  useEffect(() => {
    if (
      !busyDownloading &&
      downloadData.called &&
      !downloadData.loading &&
      downloadData.data
    ) {
      setBusyDownloading(true);
      startDownload();
    }

    if (busyDownloading) {
      Swal.fire(
        "Please wait!",
        "We are busy generating the report.",
        "warning"
      );
    }
  }, [downloadData]);
  useEffect(() => {
    if (debounceSearch) {
      if (debounceSearch === "") {
        clearSearch();
      } else if (type === "custom") {
        setVariables({ ...variables, search });
      } else {
        const variablesCopy = JSON.parse(JSON.stringify(variables));
        if (searchIndex === -1) {
          setSearchIndex(variablesCopy.where.AND.length);
          variablesCopy.where.AND.push({
            OR: getSearchData(columns, search),
          });
        } else {
          variablesCopy.where.AND[searchIndex].OR = getSearchData(
            columns,
            search
          );
        }
        setVariables(variablesCopy);
      }
    } else {
      clearSearch();
    }
  }, [debounceSearch, refetch]);
  const paginationData = getPaginationData({
    pageIndex,
    pageSize,
    maxPageCount,
    type,
    dataCount,
  });
  return (
    <>
      <TableHeader>
        {searchOpen ? (
          <TableTitle>
            <LineInput
              autoFocus
              name="search"
              type="text"
              label="Search..."
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              customStyle={{ margin: "0" }}
              inputStyle={{ padding: "8px" }}
              id="searchBox"
              icon={
                <MdClose
                  onClick={() => clearSearch(true)}
                  style={{ cursor: "pointer", width: "1.2em", height: "1.2em" }}
                />
              }
            />
          </TableTitle>
        ) : (
          <TableTitle>{tableTitle}</TableTitle>
        )}
        <TableFilterIcons>{renderOptions()}</TableFilterIcons>
      </TableHeader>
      <TableContainer style={{ maxHeight }}>
        <StyledTable {...getTableProps()}>
          <thead style={{ borderBottom: `2px solid ${COLOR_GREY3}` }}>
            {headerGroups.map((headerGroup, index) => (
              <RowHeader
                {...headerGroup.getHeaderGroupProps()}
                key={`header-group-${tableTitle}-${index}`}
              >
                {headerGroup.headers.map((column) => (
                  <ColumnHeader
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    width={column.width || `${100 / columns.length}%`}
                  >
                    <span>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted && column.isSortedDesc && (
                        <FiChevronDown />
                      )}
                      {column.isSorted && !column.isSortedDesc && (
                        <FiChevronUp />
                      )}
                    </span>
                  </ColumnHeader>
                ))}
              </RowHeader>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading && (
              <Row>
                <Column
                  colSpan={columns.length}
                  style={{ textAlign: "center", padding: "25px 0" }}
                >
                  <ClipLoader loading size={50} color={COLOR_GREY3} />
                  <br />
                  <span
                    style={{
                      color: COLOR_DISABLED,
                      fontSize: 18,
                      fontWeight: 400,
                    }}
                  >
                    Loading data...
                  </span>
                </Column>
              </Row>
            )}
            {error && (
              <Row>
                <Column
                  colSpan={columns.length}
                  style={{ textAlign: "center", padding: "60px 0" }}
                >
                  <FiAlertTriangle
                    size={50}
                    color={COLOR_GREY3}
                    strokeWidth={1.2}
                  />
                  <br />
                  <span
                    style={{
                      color: COLOR_DISABLED,
                      fontSize: 18,
                      fontWeight: 400,
                    }}
                  >
                    There was an error while loading your data, please try again
                  </span>
                </Column>
              </Row>
            )}
            {!loading && !error && rows.length === 0 && (
              <Row>
                <Column
                  colSpan={columns.length}
                  style={{ textAlign: "center", padding: "60px 0" }}
                >
                  <FiFileText size={50} color={COLOR_GREY3} strokeWidth={1.2} />
                  <br />
                  <span
                    style={{
                      color: COLOR_DISABLED,
                      fontSize: 18,
                      fontWeight: 400,
                    }}
                  >
                    There is currently no data to display
                  </span>
                </Column>
              </Row>
            )}
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Row
                  {...row.getRowProps()}
                  key={`row-${tableTitle}-${index}`}
                  onClick={
                    onRowClick
                      ? () => {
                          onRowClick(row);
                        }
                      : null
                  }
                  className={onRowClick ? "clickable" : ""}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <Column
                      height={50}
                      {...cell.getCellProps()}
                      colSpan={1}
                      key={`row-${tableTitle}-${index}-cell-${tableTitle}-${cellIndex}`}
                    >
                      {cell.render("Cell")}
                    </Column>
                  ))}
                </Row>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>
      <PaginationContainer>
        <Span
          htmlFor="pageSelection"
          style={{ marginRight: "auto", display: "flex", alignItems: "center" }}
        >
          Rows per page
          <PageSelect
            id="pageSelection"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPageIndex(0);
            }}
          >
            {pageSizes.map((pageSize_) => (
              <option
                key={`pageindex-${tableTitle}-${pageSize_}`}
                value={pageSize_}
              >
                {pageSize_}
              </option>
            ))}
          </PageSelect>
        </Span>
        <Span style={{ marginRight: 32 }}>{paginationData.paginationText}</Span>
        <PaginationBtnContainer
          onClick={() => {
            if (pageIndex !== 0) {
              setPageIndex(0);
            }
          }}
          disabled={!paginationData.canPrevious}
        >
          <PaginationFirst
            color={!paginationData.canPrevious ? COLOR_DISABLED : COLOR_GREY2}
          />
        </PaginationBtnContainer>
        <PaginationBtnContainer
          onClick={() => {
            if (pageIndex !== 0) {
              setPageIndex(pageIndex - 1);
            }
          }}
          disabled={pageIndex === 0}
        >
          <PaginationPrevious
            color={!paginationData.canPrevious ? COLOR_DISABLED : COLOR_GREY2}
          />
        </PaginationBtnContainer>
        <PaginationBtnContainer
          onClick={() => {
            if (paginationData.canNext) {
              setPageIndex(pageIndex + 1);
            }
          }}
          disabled={!paginationData.canNext}
        >
          <PaginationNext
            color={!paginationData.canNext ? COLOR_DISABLED : COLOR_GREY2}
          />
        </PaginationBtnContainer>
        <PaginationBtnContainer
          onClick={() => {
            if (paginationData.canNext) {
              setPageIndex(paginationData.lastPage);
            }
          }}
          disabled={!paginationData.canNext}
        >
          <PaginationLast
            color={!paginationData.canNext ? COLOR_DISABLED : COLOR_GREY2}
          />
        </PaginationBtnContainer>
      </PaginationContainer>
    </>
  );
};

Table.propTypes = {
  /**
   * The columns that the table should render. https://react-table.js.org/quickstart#define-columns
   */
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * The data that should be rendered on the table rows. https://react-table.js.org/quickstart#define-row-shape
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  /**
   * Flag if the data is loading, also displays loader on table if true.
   */
  loading: PropTypes.bool,
  /**
   * Flag if there was an error while loading data.
   */
  error: PropTypes.bool,
  /**
   * Query function to refetch the data, incase variables change or pagination changes.
   */
  refetch: PropTypes.func.isRequired,
  /**
   * The total number of data rows that there are.
   */
  dataCount: PropTypes.number,
  /**
   * Variables to pass to the refetch query. These get combined with the
   * pagination variables.
   */
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.any,
  /**
   * Page sizes to display on the table. This is used to chunk the data
   * when quering from backend.
   */
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  /**
   * An on click event that needs to trigger when a user clicks on a row.
   * If this is passed through there will be a hover on the row as well as the cursor will change.
   */
  onRowClick: PropTypes.func,

  setVariables: PropTypes.func.isRequired,

  tableTitle: PropTypes.string.isRequired,
  tableOptions: PropTypes.arrayOf(
    PropTypes.oneOf(["search", "download", "columns", "filter"])
  ),
  filters: PropTypes.arrayOf(PropTypes.any),
  downloadData: PropTypes.any,
  getDownloadData: PropTypes.func,
  downloadAccessor: PropTypes.arrayOf(PropTypes.any),
  fileName: PropTypes.string,
  /**
   * MaxHeight of table, defaults to 400
   */
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Table type. This is used to determine with what kind of API the table is talking to.
   * This influences the way pagination and filters are built.
   * There are two types prisma and custom.
   */
  type: PropTypes.oneOf(["prisma", "custom"]),
  /**
   * Used for pagination in the custom graphql API.
   * The amount of pages that there are with the current pageSize.
   */
  maxPageCount: PropTypes.number,
  defaultVariables: PropTypes.any,
  multiSheet: PropTypes.bool,
  getSheetName: PropTypes.func,
};

Table.defaultProps = {
  variables: {},
  pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 100],
  loading: false,
  error: false,
  onRowClick: undefined,
  tableOptions: [],
  filters: [],
  dataCount: 0,
  maxPageCount: 0,
  downloadData: [],
  getDownloadData: undefined,
  downloadAccessor: [],
  fileName: "",
  maxHeight: "60vh",
  type: "prisma",
  defaultVariables: undefined,
  multiSheet: false,
  getSheetName: null,
};

export { TableIcons, TableHeader, TableTitle, TableFilterIcons, TableWrapper };

export default Table;
