import React from "react";
import PropTypes from "prop-types";
import Profile from "../Profile/Profile.component";
import Burger from "../Burger/Burger.component";
import { TopNavigation, Logo } from "./Header.style";

const logo = require("../../images/icon.svg");

const Header = ({ burgerMenu, burgerMenuToggle }) => {
  return (
    <TopNavigation>
      <Burger open={burgerMenu} setOpen={burgerMenuToggle} />
      <Logo src={logo} />
      <Profile />
    </TopNavigation>
  );
};

Header.propTypes = {
  burgerMenu: PropTypes.bool.isRequired,
  burgerMenuToggle: PropTypes.func.isRequired,
};

export default Header;
