/**
 * This document will handle all the routes.
 * All files in the pages folder can be seen as a route
 */

import Layout from "../common/layout/Layout";
import LoginLoayout from "../common/layout/LoginLayout";
import ManageAccount from "../pages/ManageAccount/ManageAccount";

const ProTouchStaffRoutes = [
  { path: "/app", component: Layout },
  { path: "/youraccount/:token", component: ManageAccount },
  { path: "/", component: LoginLoayout },
];

export default ProTouchStaffRoutes;
