import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { AnimatePresence } from "framer-motion";
import { FiX } from "react-icons/fi";
import {
  ModalOverlay,
  Modal as ModalComponent,
  ModalChildren,
} from "./Modal.style";

const modalRoot = document.getElementById("modal");

const Modal = ({
  children,
  open,
  close,
  height,
  showClose,
  width,
  showOverflow,
  fullScreen,
}) => {
  return ReactDOM.createPortal(
    <AnimatePresence>
      {open && (
        <>
          <ModalOverlay
            onClick={close}
            key="modalOverlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <ModalComponent
            key="modal"
            initial={{ opacity: 0, y: 0, x: "-50%" }}
            animate={{ opacity: 1, y: "-50%", x: "-50%" }}
            exit={{ opacity: 0, y: 0, x: "-50%" }}
            height={height}
            width={width}
            fullScreen={fullScreen}
          >
            {showClose && <FiX className="close" onClick={close} />}
            <ModalChildren showOverflow={showOverflow} fullScreen={fullScreen}>
              {children}
            </ModalChildren>
          </ModalComponent>
        </>
      )}
    </AnimatePresence>,
    modalRoot
  );
};

Modal.defaultProps = {
  height: null,
  width: null,
  showClose: false,
  showOverflow: false,
  fullScreen: false,
};

Modal.propTypes = {
  children: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  showClose: PropTypes.bool,
  showOverflow: PropTypes.bool,
  fullScreen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Modal;
