import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { GoPrimitiveDot } from "react-icons/go";
import { useMutation } from "@apollo/react-hooks";

import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./NotificationManager.form";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import { CREATE_AUTOMATIC_NOTIFICATION } from "../../../graphql/mutations/automaticNotification.mutation";
import { formatGraphqlError } from "../../../utils/api";

const AutomaticNotificationNew = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [automaticNotificationData, setAutomaticNotificationData] = useState({
    id: "",
    name: "",
    description: "",
    message: "",
    status: "ON",
    endDate: null,
    frequency: {
      notificationType: "ONCEOFF",
      repeatEveryOption: "Week",
      repeatEveryNumber: "1",
      repeatCounter: "0",
      dom: "1",
      startDate: moment(),
      dow: {
        SU: true,
        MO: false,
        TU: false,
        WE: false,
        TH: false,
        FR: false,
        SA: false,
      },
    },
  });
  const [createAutomaticNotification, { loading }] = useMutation(
    CREATE_AUTOMATIC_NOTIFICATION
  );

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Create New Automatic Notification");
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
    control,
  } = useForm();

  const changeStatus = (e) => {
    const tempAutomaticNotificationData = { ...automaticNotificationData };
    tempAutomaticNotificationData.status = e.target.value;
    setAutomaticNotificationData(tempAutomaticNotificationData);
  };

  const getColor = () => {
    switch (automaticNotificationData.status) {
      case "ON":
        return "#39C568";
      case "OFF":
        return "#FF003A";
      case "ARCHIVE":
        return "#FF003A";
      default:
        return "#FF003A";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new AutomaticNotification
     * push to list all automaticNotifications
     */

    createAutomaticNotification({
      variables: {
        data: values,
      },
    })
      .then(() => {
        Notification.success({
          title: "Create Automatic Notification Succeeded",
          message: "",
        });
        history.push("/app/system-configuration/notification-manager");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Automatic Notification Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <PageHeader title="Create New Automatic Notification" back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            automaticNotificationData={automaticNotificationData}
            setAutomaticNotificationData={setAutomaticNotificationData}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            control={control}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={automaticNotificationData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ON", text: "On" },
              { value: "OFF", text: "Off" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a AutomaticNotification ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Create Automatic Notification"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => history.goBack()}
            type="button"
            value="Cancel"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default AutomaticNotificationNew;
