import gql from "graphql-tag";

export const GET_EMPLOYERS = gql`
  query getEmployerProfiles(
    $order: EmployerProfileOrderByInput
    $first: Int!
    $skip: Int!
    $where: EmployerProfileWhereInput
  ) {
    employerProfiles(
      orderBy: $order
      first: $first
      skip: $skip
      where: $where
    ) {
      id
      companyName
      companyLogo
      appUser {
        firstName
        lastName
        email
      }
      ceoOrManagerName
      ceoOrManagerEmail
      ceoOrManagerContactNumber
      nameHR
      emailHR
      roleHR
      phoneNumberContactPerson
      mobileContactPerson
      subscriptionId
      suspended
    }
    employerProfilesCount: employerProfiles(where: $where) {
      id
    }
  }
`;

export const GET_EMPLOYERS_EXPORT = gql`
  query getEmployerProfiles(
    $order: EmployerProfileOrderByInput
    $where: EmployerProfileWhereInput
  ) {
    employerProfiles(orderBy: $order, where: $where) {
      id
      companyName
      companyLogo
      appUser {
        id
        firstName
        lastName
        email
      }
      ceoOrManagerName
      ceoOrManagerEmail
      ceoOrManagerContactNumber
      nameHR
      emailHR
      subscriptionId
      suspended
      payingAgentName
      address
      country
      mobileContactPerson
      phoneNumberContactPerson
      sportCategories {
        id
        title
      }
    }
  }
`;

export const GET_EMPLOYER = gql`
  query getEmployerProfile($id: ID) {
    employerProfile(where: { id: $id }) {
      id
      companyName
      companyLogo
      appUser {
        id
        firstName
        lastName
        email
      }
      ceoOrManagerName
      ceoOrManagerEmail
      ceoOrManagerContactNumber
      nameHR
      emailHR
      subscriptionId
      suspended
      payingAgentName
      address
      country
      mobileContactPerson
      phoneNumberContactPerson
      sportCategories {
        id
        title
      }
    }
  }
`;

export const GET_POSITIONS = gql`
  query getPostitions($where: PositionWhereInput) {
    positions(where: $where) {
      positionStatus
      id
    }
  }
`;

export const GET_SERVICE_AGREEMENTS = gql`
  query getServiceAgreements($where: ServiceAgreementWhereInput) {
    serviceAgreements(where: $where) {
      status
      id
    }
  }
`;

export const GET_EXPENSE_CLAIMS = gql`
  query getExpenseClaims($where: ExpenseClaimWhereInput) {
    expenseClaims(where: $where) {
      status
      id
    }
  }
`;

export const GET_RATINGS = gql`
  query getRatings($where: RatingWhereInput) {
    ratings(where: $where) {
      id
      numberRating
      ratingText
      author {
        id
        firstName
        lastName
      }
    }
  }
`;

export default {
  GET_EMPLOYERS,
  GET_EMPLOYERS_EXPORT,
  GET_EMPLOYER,
  GET_POSITIONS,
  GET_SERVICE_AGREEMENTS,
  GET_EXPENSE_CLAIMS,
  GET_RATINGS,
};
