import gql from "graphql-tag";

export const GET_PARTIAL_PROFILE = gql`
  query {
    profile: adminProfile {
      firstName
      lastName
      id
    }
  }
`;

export const GET_PROFILE = gql`
  query {
    profile: adminProfile {
      firstName
      lastName
      id
      email
      cellphone
    }
  }
`;

export default { GET_PARTIAL_PROFILE, GET_PROFILE };
