import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EditorState } from "draft-js";
import { useHistory } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { useMutation } from "@apollo/react-hooks";
import { stateToHTML } from "draft-js-export-html";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./FAQ.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import { CREATE_FAQ } from "../../../graphql/mutations/faq.mutation";
import { formatGraphqlError } from "../../../utils/api";

const FAQNew = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [faqData, setFaqData] = useState({
    id: "",
    question: "",
    answer: "",
    status: "ACTIVE",
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [createFaq, { loading }] = useMutation(CREATE_FAQ);

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("cm");
    setPageTitle("Create New Faq");
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const changeStatus = (e) => {
    const tempFaqData = { ...faqData };
    tempFaqData.status = e.target.value;
    setFaqData(tempFaqData);
  };

  const getColor = () => {
    switch (faqData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Faq
     * push to list all content
     */

    createFaq({
      variables: {
        ...values,
        answer: stateToHTML(editorState.getCurrentContent()),
      },
    })
      .then(() => {
        Notification.success({
          title: "Create Faq Succeeded",
          message: "",
        });
        history.push("/app/cms/faq");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Faq Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <PageHeader title="Create New FAQ" back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            faqData={faqData}
            setFaqData={setFaqData}
            setValue={setValue}
            getValues={getValues}
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={faqData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a Faq ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Create FAQ"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => history.goBack()}
            type="button"
            value="Cancel"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default FAQNew;
