import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import { GoPrimitiveDot } from "react-icons/go";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Swal from "sweetalert2";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Button from "../../../components/Button/Button.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
import { DELETE_CONTACT_INFORMATION } from "../../../graphql/mutations/contactInformation.mutation";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  GET_CONTACT_INFORMATIONS,
  GET_CONTACT_INFORMATIONS_EXPORT,
} from "../../../graphql/queries/contactInformation.query";
import { formatGraphqlError } from "../../../utils/api";

const ContactInformationAll = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(
    GET_CONTACT_INFORMATIONS,
    {
      variables: { order: "createdAt_DESC" },
      fetchPolicy: "no-cache",
    }
  );
  const [variables, setVariables] = useState({
    where: { AND: [{ NOT: { status: "ARCHIVED" } }] },
  });
  const [deleteContactInformation] = useMutation(DELETE_CONTACT_INFORMATION);
  const [
    getContactInformationsExport,
    contactInformationsExport,
  ] = useLazyQuery(GET_CONTACT_INFORMATIONS_EXPORT);

  useEffect(() => {
    setActiveSideNav("cm");
    setPageTitle("Manage Contact Informations");
  });

  const handleDelete = (e, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteContactInformation({
          variables: {
            id: row.original.id,
          },
        })
          .then(() => {
            Swal.fire(
              "Deleted!",
              "Contact Information has been deleted",
              "success"
            );
            refetch({
              variables: {
                ...variables,
                first: 50,
                skip: 0,
              },
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Contact Information Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const HeaderInfo = [
    {
      Header: "Name",
      accessor: "name",
      id: "name",
      search: true,
    },
    {
      Header: "Description",
      accessor: "description",
      id: "description",
      search: true,
    },
    {
      Header: "Status",
      id: "status",
      Cell: ({ row }) => (
        <TableIcons>
          <GoPrimitiveDot
            style={{ fontSize: "25px", color: getColor(row.original.status) }}
          />
          <span>{row.original.status.toLowerCase()}</span>
        </TableIcons>
      ),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <Link to={`/app/cms/contact-information/${row.original.id}`}>
            <FiEdit
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          </Link>
          <FiTrash
            onClick={(e) => handleDelete(e, row)}
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
    {
      header: "Status:",
      accessor: "status",
      placeholder: "Please select a Status",
      type: "select",
      options: [
        { value: "ACTIVE", text: "Active" },
        { value: "ARCHIVE", text: "Archive" },
        { value: "DRAFT", text: "Draft" },
      ],
    },
  ];

  return (
    <>
      <PageHeader title="Manage Contact Information">
        <Link to="/app/cms/contact-information/new">
          <Button
            onClick={() => {}}
            kind="secondary"
            value="New Contact Information"
            width="auto"
            margin="10px 0 10px 10px"
          />
        </Link>
      </PageHeader>
      <Table
        columns={HeaderInfo}
        data={(data && data.contactInformations) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.contactInformationsCount.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Contact Informations"
        tableOptions={["search", "download", "columns", "filter"]}
        filters={filters}
        downloadData={contactInformationsExport}
        getDownloadData={getContactInformationsExport}
        downloadAccessor={
          contactInformationsExport.data
            ? contactInformationsExport.data.contactInformations
            : []
        }
        fileName="ContactInformations"
      />
    </>
  );
};

export default ContactInformationAll;
