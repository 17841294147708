/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import Error from "../../../components/Error/ErrorMessage.component";

const ExpenseClaimTypeForm = (props) => {
  const {
    register,
    errors,
    expenseClaimTypeEditData,
    expenseClaimTypeData,
    setExpenseClaimTypeData,
  } = props;

  useEffect(() => {
    if (expenseClaimTypeEditData && expenseClaimTypeData.title === "") {
      setExpenseClaimTypeData(expenseClaimTypeEditData);
    }
  });

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>Expense Claim Type Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="title"
            label=""
            type="text"
            value={expenseClaimTypeData.title}
            register={register}
            required={{
              required: "please enter a ExpenseClaimType Name",
            }}
            error={errors.title && <Error message={errors.title.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderInput
            header="Description"
            name="description"
            label=""
            type="text"
            value={expenseClaimTypeData.description}
            register={register}
            error={
              errors.description && (
                <Error message={errors.description.message} />
              )
            }
          />
        </Column>
      </Row>
    </Column>
  );
};

ExpenseClaimTypeForm.defaultProps = {
  errors: {},
  expenseClaimTypeEditData: undefined,
};

ExpenseClaimTypeForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  expenseClaimTypeEditData: PropTypes.any,
  expenseClaimTypeData: PropTypes.any.isRequired,
  setExpenseClaimTypeData: PropTypes.func.isRequired,
};

export default ExpenseClaimTypeForm;
