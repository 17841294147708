import styled from "styled-components";

export const CompanyHolder = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const CompanyName = styled.div`
  width: 100%;
  text-align: center;
`;

export const CompanyRating = styled.div`
  width: 100%;
  text-align: center;
  margin: 7.5px 0;
  justify-content: center;
  display: flex;
`;

export const CompanyNameProfile = styled.div`
  width: 100%;
  margin-bottom: 34px;
  text-align: center;
  margin-top: 7px;
`;

export const ImageHolder = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
  align-self: center;
`;
export const SmallImageHolder = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Image = styled.img`
  width: auto;
  height: 100%;
`;

export const SmallImage = styled.img`
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  padding: 18px;
`;

export const Holder = styled.div`
  position: relative;
  width: 100%;
`;

export const SimpleTable = styled.table`
  min-width: 100%;
  background-color: white;
  border-collapse: separate;
  border-spacing: 0;
  thead {
    border-bottom: 2px solid rgb(216, 216, 216);
    tr {
      min-width: 100%;
      th {
        line-height: 24px;
        font-size: 14px;
        font-weight: 600;
        color: #393b3c;
        padding: 0 15px;
        text-align: left;
        height: 50px;
        cursor: pointer;
        top: 0;
        background: white;
        border-bottom: 2px solid #d8d8d8;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 2px solid #e3e3e3;
      min-width: 100%;
      transition: background-color 0.3s ease-in-out;
      td {
        margin: 0;
        font-size: 14px;
        color: #707172;
        line-height: 16px;
        height: 64px;
        border-bottom: 2px solid #e3e3e3;
        word-wrap: break-word;
        white-space: normal;
      }
    }
  }
`;
