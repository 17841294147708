import gql from "graphql-tag";

export const GET_FEEDBACK = gql`
  query feedbacks(
    $where: FeedbackWhereInput
    $orderBy: FeedbackOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    feedbacks(
      orderBy: $orderBy
      first: $first
      after: $after
      before: $before
      skip: $skip
      where: $where
      last: $last
    ) {
      id
      createdAt
      appUser {
        id
        email
      }
      body
      type
      read
    }
  }
`;

export default {
  GET_FEEDBACK,
};
