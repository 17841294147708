import React from "react";
import { AnimatePresence } from "framer-motion";
import { Route, Switch, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { MountTransition } from "./MountTransition.component";

export const RouteTransition = ({
  children,
  exact,
  path,
  slide,
  slideUp,
  ...rest
}) => (
  <Route exact={exact} path={path} {...rest}>
    <MountTransition slide={slide} slideUp={slideUp}>
      {children}
    </MountTransition>
  </Route>
);

RouteTransition.defaultProps = {
  exact: false,
  slide: 0,
  slideUp: 0,
};

RouteTransition.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  slide: PropTypes.number,
  slideUp: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const AnimatedRoutes = ({ exitBeforeEnter, initial, children }) => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
      <Switch location={location}>{children}</Switch>
    </AnimatePresence>
  );
};

AnimatedRoutes.propTypes = {
  exitBeforeEnter: PropTypes.bool.isRequired,
  initial: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default {
  AnimatedRoutes,
  RouteTransition,
};
