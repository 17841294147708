import gql from "graphql-tag";

export const SUBSCRIBE_TO_NOTIFICATIONS = gql`
  subscription subNotifications($id: ID) {
    notification(
      where: {
        node: { userType: ADMIN, adminUser: { id: $id } }
        mutation_in: [CREATED, UPDATED]
      }
    ) {
      node {
        id
        message
        notificationType
        createdAt
        read
      }
      mutation
    }
  }
`;

export default {
  SUBSCRIBE_TO_NOTIFICATIONS,
};
