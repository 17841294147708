import React, { useContext, useEffect, useState } from "react";
// import Swal from 'sweetalert2';
import styled from "styled-components";
import moment from "moment";
import { useQuery } from "react-apollo";

// Custom Components
import { APP_CONTEXT } from "../../utils/context";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../components/Grid/Grid.component";
import Calendar from "../../components/Calendar/Calendar";
import Button from "../../components/Button/Button.component";
import EventForm from "./Event.Form";
import { GET_EVENTS } from "../../graphql/queries/events.query";

const EventsWrapper = styled.div`
  width: 90%;
  justify-self: center;
  margin: 0 auto;
`;

const Events = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [data, setData] = useState({
    id: "",
    title: "",
    description: "",
    location: "",
    startDate: null,
    endDate: null,
    open: false,
    status: "DRAFT",
    sportCategory: {
      connect: {
        id: "",
      },
    },
    sportType: {
      connect: {
        id: "",
      },
    },
  });

  const { data: events, loading: eventsLoading } = useQuery(GET_EVENTS, {
    fetchPolicy: "no-cache",
    pollInterval: 500,
    variables: {
      where: {
        eventType: "PUBLIC",
        NOT: {
          status: "ARCHIVED",
        },
      },
    },
  });

  useEffect(() => {
    setActiveSideNav("events");
    setPageTitle("Manage Events");
  });

  const setShowModal = (val) => {
    setData({
      ...data,
      open: val,
    });
  };

  const handleDateClick = (args) => {
    setData({
      id: args.event.id,
      title: args.event.title,
      description: args.event.extendedProps.description,
      location: args.event.extendedProps.location
        ? args.event.extendedProps.location
        : "",
      startDate: moment(args.event.extendedProps.startDate),
      endDate: args.event.extendedProps.endDate
        ? moment(args.event.extendedProps.endDate)
        : moment(args.event.extendedProps.startDate),
      status: args.event.extendedProps.status,
      sportCategory: {
        connect: {
          id: args.event.extendedProps.sportCategory.id,
        },
      },
      sportType: {
        connect: {
          id: args.event.extendedProps.sportType.id,
        },
      },
      open: true,
    });
  };

  const getColor = (status, endDate) => {
    const end = new Date(endDate).toJSON().slice(0, 10);
    const today = new Date().toJSON().slice(0, 10);
    if (end < today) {
      return "#cfcfcf";
    }
    switch (status) {
      case "ACTIVE":
        return "#39C568";
      case "PENDING":
        return "#1a0388";
      case "ARCHIVED":
        return "#FF003A";
      default:
        return "#707172";
    }
  };

  const addNewEvent = () => {
    setData({
      id: "",
      title: "",
      description: "",
      location: "",
      startDate: null,
      endDate: null,
      open: true,
    });
  };

  return (
    <EventsWrapper>
      <PageHeader title="Manage Events" back>
        <Button
          onClick={addNewEvent}
          kind="secondary"
          value="New Event"
          width="177px"
        />
      </PageHeader>
      <Row>
        <Column>
          <Calendar
            handleDateClick={handleDateClick}
            events={
              !eventsLoading
                ? events.events.map((event) => {
                    const start = new Date(event.startDate);
                    const end = new Date(event.endDate);
                    end.setDate(end.getDate() + 1);
                    return {
                      id: event.id,
                      title: event.title,
                      description: event.description,
                      location: event.location,
                      start,
                      end,
                      startDate: event.startDate,
                      endDate: event.endDate,
                      sportCategory: event.sportCategory,
                      sportType: event.sportType,
                      status: event.status,
                      allDay: true,
                      backgroundColor: getColor(event.status, event.endDate),
                      borderColor: getColor(event.status, event.endDate),
                    };
                  })
                : []
            }
          />
        </Column>
      </Row>
      {data.open && (
        <EventForm
          setShowModal={setShowModal}
          showModal={data.open}
          data={data}
        />
      )}
    </EventsWrapper>
  );
};

export default Events;
