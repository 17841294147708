import gql from "graphql-tag";

export const ACCESS_ACCOUNT = gql`
  query AccessAccount($id: ID!) {
    accessAccount(data: { AuthToken: $id }) {
      jwt
      user {
        id
        lastName
        firstName
        email
        staff {
          id
          stripeId
          subscriptionId
          paymentMethodId
        }
        employer {
          id
          stripeId
          subscriptionId
          paymentMethodId
        }
      }
      products
      subscription
      userGroup
    }
  }
`;
export default {
  ACCESS_ACCOUNT,
};
