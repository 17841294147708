import React, { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { AiOutlineEye } from "react-icons/ai";
import styled from "styled-components";

import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
// GQL
import { GET_RATES } from "../../../graphql/queries/finances.query";
import Modal from "../../../components/Modal/Modal.component";
import {
  H1,
  H2,
  Span,
} from "../../../components/Typography/Typography.component";
import { Row, Col } from "../../../components/Grid/Grid.new.component";
import { sumObjectArray } from "../../../utils";

const Card = styled.div`
  border: 1px solid #393b3c;
  border-radius: 4px;
  padding: 15px;
`;

const Rates = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_RATES, {
    variables: { order: "title_ASC" },
    fetchPolicy: "no-cache",
  });
  const [variables, setVariables] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [activeRate, setActiveRate] = useState(false);

  useEffect(() => {
    setActiveSideNav("billing");
    setPageTitle("View Rates");
  });

  const filterData = (rates, status) => {
    return sumObjectArray(
      rates.filter((claim) => claim.status === status),
      "amount"
    );
  };

  const HeaderInfo = [
    {
      Header: "App User",
      accessor: (row) =>
        `${row.staff.appUser.firstName} ${row.staff.appUser.lastName}`,
      id: "appUser",
      search: true,
    },
    {
      Header: "Total Pending Rates",
      id: "totalPendingRates",
      accessor: (row) => filterData(row.rates, "PENDING"),
    },
    {
      Header: "Total Paid Rates",
      id: "totalPaidRates",
      accessor: (row) => filterData(row.rates, "PAID"),
    },
    {
      Header: "Total Discputed Rates",
      id: "totalDisputedRates",
      accessor: (row) => filterData(row.rates, "DISPUTED"),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <AiOutlineEye
            style={{ fontSize: "20px", color: "#07002F", cursor: "DECLINED" }}
            onClick={() => {
              setShowModal(true);
              setActiveRate(row.original);
            }}
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
  ];

  return (
    <>
      <PageHeader title="View Rates" />
      <Table
        columns={HeaderInfo}
        data={(data && data.groupedRates) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.groupedRates.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Rate Payments"
        tableOptions={["columns", "filter"]}
        filters={filters}
        fileName="Rates"
      />
      <Modal
        open={showModal}
        showClose
        close={() => {
          setShowModal(false);
        }}
      >
        <Row style={{ marginBottom: "0" }}>
          <Col sm={12} md={12} lg={12} sub>
            <H1>Rates Overview</H1>
          </Col>
        </Row>
        {activeRate && (
          <>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={6} md={3} lg={3} sub>
                <H2 style={{ marginBottom: "7.5px" }}>App User</H2>
              </Col>
              <Col sm={6} md={3} lg={3} sub>
                <Span>
                  {`${activeRate.staff.appUser.firstName} ${activeRate.staff.appUser.lastName}`}
                </Span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={12} md={12} lg={12} sub>
                <H2 style={{ marginBottom: "7.5px" }}>All Rates</H2>
              </Col>
              {activeRate.rates.map((rate) => (
                <Col sm={6} md={6} lg={6} sub key={rate.id} padding="8px 17px">
                  <Card>
                    <Row style={{ marginBottom: "0" }}>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Company:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {rate.serviceAgreement.employer.companyName}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Position:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {rate.serviceAgreement.position.title}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Status:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {rate.status}
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        Amount:
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        sub
                        padding="0"
                        style={{ marginBottom: "5px" }}
                      >
                        {rate.amount}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default Rates;
