import gql from "graphql-tag";

export const GET_ADMIN_USERS = gql`
  query getAdminUsers(
    $order: AdminUserOrderByInput
    $first: Int!
    $skip: Int!
    $where: AdminUserWhereInput!
  ) {
    adminUsers(orderBy: $order, first: $first, skip: $skip, where: $where) {
      id
      firstName
      lastName
      roles {
        title
        id
      }
      email
      cellphone
      superAdmin
      status
    }
    adminUsersCount: adminUsers(where: $where) {
      id
    }
  }
`;

export const GET_ADMIN_USERS_EXPORT = gql`
  query getAdminUsers(
    $order: AdminUserOrderByInput
    $where: AdminUserWhereInput!
  ) {
    adminUsers(orderBy: $order, where: $where) {
      id
      firstName
      lastName
      roles {
        title
        id
      }
      email
      cellphone
      superAdmin
      status
    }
    adminUsersCount: adminUsers(where: $where) {
      id
    }
  }
`;

export const GET_ADMIN_USER = gql`
  query getAdminUser($id: ID) {
    adminUser(where: { id: $id }) {
      id
      firstName
      lastName
      roles {
        title
        id
      }
      email
      cellphone
      superAdmin
      status
    }
  }
`;

export default {
  GET_ADMIN_USERS,
  GET_ADMIN_USER,
  GET_ADMIN_USERS_EXPORT,
};
