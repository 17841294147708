import gql from "graphql-tag";

export const CREATE_EXPENSE_CLAIM_TYPE = gql`
  mutation createExpenseClaimType(
    $title: String!
    $description: String!
    $status: GeneralStatus!
  ) {
    createExpenseClaimType(
      data: { title: $title, description: $description, status: $status }
    ) {
      id
    }
  }
`;

export const UPDATE_EXPENSE_CLAIM_TYPE = gql`
  mutation updateExpenseClaimType(
    $title: String!
    $description: String!
    $status: GeneralStatus!
    $id: ID!
  ) {
    updateExpenseClaimType(
      data: { title: $title, description: $description, status: $status }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_EXPENSE_CLAIM_TYPE = gql`
  mutation deleteExpenseClaimType($id: ID!) {
    deleteExpenseClaimType(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_EXPENSE_CLAIM_TYPE,
  UPDATE_EXPENSE_CLAIM_TYPE,
  DELETE_EXPENSE_CLAIM_TYPE,
};
