import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: ${(props) => (props.sub ? 'flex-start' : 'center')};
  flex-direction: ${(props) => (props.sub ? 'column' : 'row')};
  background: transparent;
  width: 100%;

  span {
    margin-left: 20px;
    font-size: 12px;
    font-weight: 600;
  }

  p {
    margin-left: 20px;
    color: #fff;
    font-size: 12px;
  }
`;