import gql from "graphql-tag";

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription(
    $subscriptionId: String!
    $userGroup: UserGroup!
    $profileId: ID!
  ) {
    cancelSubscription(
      data: {
        subscriptionId: $subscriptionId
        userGroup: $userGroup
        profileId: $profileId
      }
    )
  }
`;

export default {
  CANCEL_SUBSCRIPTION,
};
