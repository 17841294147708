import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { useMutation } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./PositionRole.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import { CREATE_POSITION_ROLE } from "../../../graphql/mutations/positionRole.mutation";
import { formatGraphqlError } from "../../../utils/api";

const PositionRoleNew = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const urlParams = new URLSearchParams(window.location.search);
  const [positionRoleData, setPositionRoleData] = useState({
    id: "",
    title: urlParams.has("title") ? urlParams.get("title") : "",
    description: urlParams.has("description")
      ? urlParams.get("description")
      : "",
    sportCategories: [],
    sportTypes: [],
    status: "ACTIVE",
    baseSkills: [],
  });
  const [createPositionRole, { loading }] = useMutation(CREATE_POSITION_ROLE);

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Create New Position Role");
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
  } = useForm();

  const changeStatus = (e) => {
    const tempPositionRoleData = { ...positionRoleData };
    tempPositionRoleData.status = e.target.value;
    setPositionRoleData(tempPositionRoleData);
  };

  const setSkills = (skills) => {
    const tempPositionRoleData = { ...positionRoleData };
    tempPositionRoleData.baseSkills = skills;
    setPositionRoleData(tempPositionRoleData);
  };

  const getColor = () => {
    switch (positionRoleData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Position Role
     * push to list all positionRoles
     */
    console.log(values);
    const submitValues = values;

    if (
      !submitValues.sportCategories ||
      submitValues.sportCategories.connect.length === 0
    ) {
      Notification.error({
        title: "At least one sport category needs to be selected",
        message: "",
      });
      return;
    }
    if (
      !submitValues.sportTypes ||
      submitValues.sportTypes.connect.length === 0
    ) {
      Notification.error({
        title: "At least one sport type needs to be selected",
        message: "",
      });
      return;
    }

    const sportCategoriesConnectIds = submitValues.sportCategories.connect.map(
      (cat) => {
        return {
          id: cat.value,
        };
      }
    );
    submitValues.sportCategories = {
      connect: sportCategoriesConnectIds,
    };

    const sportTypesConnectIds = submitValues.sportTypes.connect.map((cat) => {
      return {
        id: cat.value,
      };
    });
    submitValues.sportTypes = {
      connect: sportTypesConnectIds,
    };

    createPositionRole({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({
          title: "Create Position Role Succeeded",
          message: "",
        });
        history.push("/app/system-configuration/position-role");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Position Role Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <PageHeader title="Create New Position Role" back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            positionRoleData={positionRoleData}
            setPositionRoleData={setPositionRoleData}
            setValue={setValue}
            getValues={getValues}
            setSkills={setSkills}
            control={control}
            watch={watch}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={positionRoleData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a Position Role ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Create Position Role"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => history.goBack()}
            type="button"
            value="Cancel"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default PositionRoleNew;
