import React, { useContext, useEffect, useState } from "react";
// import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import styled from "styled-components";
import { useQuery } from "react-apollo";
import { Redirect, useHistory } from "react-router-dom";
// import NavLinks from '../../common/routes';
import Header from "../../components/Header/Header.component";
import SideNavigation from "../../components/SideNavigation/SideNavigation.component";
import IS_LOGGED_IN from "../../graphql/queries/isLoggedIn.query";
import { APP_CONTEXT } from "../../utils/context";
import {
  AnimatedRoutes,
  RouteTransition,
} from "../../components/Animation/AnimatedRoutes/AnimatedRoutes.component";
import AppRoutes from "../../routes/App";
import Tabs from "../../components/Tabs/Tabs.component";
import LoadingFullScreen from "../../components/Animation/Loading/LoadingFullScreen.component";
import { GlobalStyles } from "../styles/Global";

const Main = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
`;

const Nav = styled.div`
  width: 100%;
  top: 0;
`;

const Body = styled.div`
  display: flex;
  margin: 0;
  min-height: calc(100vh - 60px);
  align-items: flex-start;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  align-content: stretch;
  padding: 2%;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
  height: calc(100vh - 60px);
  position: relative;

  @media (max-width: 1200px) {
    width: 100vw;
    border: 2px solid red;
  }

  transition: 0.1s ease-in-out;
  border-radius: 8px;
`;
const DivNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  align-content: stretch;
  width: auto;
  min-width: ${({ open }) =>
    open ? "calc(100vw - 250px)" : "calc(100vw - 60px)"};
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: hidden;
  @media (max-width: 1200px) {
    width: 100vw;
    border: 2px solid red;
  }

  transition: 0.1s ease-in-out;
  border-radius: 8px;
`;

const renderRoute = (route, index, pos) => {
  if (pos) {
    return (
      <RouteTransition
        key={`${route.path}_childroute_${index}`}
        path={route.path}
        exact
        slide={25}
      >
        <Div style={pos ? { height: "calc(100vh - 100px)" } : {}}>
          <route.component />
        </Div>
      </RouteTransition>
    );
  }
  return (
    <RouteTransition
      key={`${route.path}_childroute_${index}`}
      path={route.path}
      exact
      slideUp={25}
    >
      <Div style={pos ? { height: "calc(100vh - 100px)" } : {}}>
        <route.component />
      </Div>
    </RouteTransition>
  );
};

const createAppRoutes = () => {
  return AppRoutes.map((route, index) => {
    if (route.childRoutes) {
      const tabChildren = route.childRoutes.filter(
        (childRoute) => childRoute.tab
      );
      const defaultTabChild =
        tabChildren.find((childRoute) => childRoute.defaultTab) ||
        tabChildren[0];
      const childRoutes = route.childRoutes.filter(
        (childRoute) => !childRoute.tab
      );
      return (
        <RouteTransition
          path={route.path}
          key={`${route.path}_route_${index + 1}`}
          slideUp={25}
        >
          <AnimatedRoutes exitBeforeEnter initial={false}>
            {childRoutes.map(renderRoute)}
            {tabChildren.length > 0 && (
              <RouteTransition path={route.path} slideUp={25}>
                <Tabs tabs={tabChildren} />
                <AnimatedRoutes exitBeforeEnter initial={false}>
                  {tabChildren.map((r, i) => {
                    return r.tab
                      ? renderRoute(r, i, "left")
                      : renderRoute(r, i);
                  })}
                  <RouteTransition path={route.path} slide={25}>
                    <Redirect to={defaultTabChild.path} />
                  </RouteTransition>
                </AnimatedRoutes>
              </RouteTransition>
            )}
          </AnimatedRoutes>
        </RouteTransition>
      );
    }
    return renderRoute(route, index);
  });
};

const Layout = () => {
  const [open, Toggle] = useState(false);
  const history = useHistory();
  const { data, loading } = useQuery(IS_LOGGED_IN);
  const {
    activeSideNav,
    setSuperAdmin,
    setUserId,
    setPermissions,
  } = useContext(APP_CONTEXT);

  useEffect(() => {
    const superAdmin = localStorage.getItem("superAdmin");
    const userId = localStorage.getItem("userId");
    const permissions = localStorage.getItem("permissions");
    if (superAdmin === "true") {
      setSuperAdmin(true);
    }
    setUserId(userId);
    setPermissions(permissions);
  });
  if (!loading && data) {
    if (!data.isLoggedIn) {
      history.push("/login");
    }

    const sideNavRoutes = AppRoutes.filter((route) => route.sideNav);

    return (
      <Main>
        <GlobalStyles />
        <Nav>
          <Header burgerMenu={open} burgerMenuToggle={Toggle} />
        </Nav>
        <Body>
          <SideNavigation
            open={open}
            setOpen={Toggle}
            navlinks={sideNavRoutes}
            activeSideNav={activeSideNav}
          />
          <DivNoPadding open={open}>
            {window.innerWidth < 768 ? (
              <Div>
                <p>Cannot display on this device screen width</p>
              </Div>
            ) : (
              <AnimatedRoutes exitBeforeEnter initial={false}>
                {createAppRoutes()}
                <RouteTransition path="*" key={404} slideUp={25}>
                  <Redirect to="/login" />
                </RouteTransition>
              </AnimatedRoutes>
            )}
          </DivNoPadding>
        </Body>
      </Main>
    );
  }
  return <LoadingFullScreen />;
};

export default Layout;
