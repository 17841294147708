import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";
import { COLOR_GREY, COLOR_PRIMARY } from "../../../common/styles/Colors";
import Button from "../../../components/Button/Button.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Redactor from "../../../components/Form/Redactor/Redactor.component";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Notification from "../../../components/Toast/Toast.component";
import PRIVACY_POLICY from "../../../graphql/queries/privacyPolicy.query";
import UPDATE_PRIVACY_POLICY from "../../../graphql/mutations/privacyPolicy.mutation";
import { formatGraphqlError } from "../../../utils/api";

const DataContainer = styled.div`
  height: 61.5vh;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${COLOR_GREY};
    border-radius: 10px;
    height: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLOR_PRIMARY};
    border-radius: 10px;
    height: 30px;
  }
`;

const PrivacyPolicyPreview = () => {
  const { data: privacyPolicy, loading } = useQuery(PRIVACY_POLICY, {
    fetchPolicy: "no-cache",
  });
  const [managePrivacyPolicy, { loading: saving }] = useMutation(
    UPDATE_PRIVACY_POLICY
  );
  const [updatedEditorState, setUpdatedEditorState] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const history = useHistory();

  const update = () => {
    managePrivacyPolicy({
      variables: {
        body: stateToHTML(editorState.getCurrentContent()),
      },
    })
      .then(() => {
        history.push("/app/cms/privacy-policy/");
        return Notification.success({
          message: `Privacy policy saved and updated`,
        });
      })
      .catch((err) =>
        Notification.error({
          title: "Privacy policy update failed",
          message: `${formatGraphqlError(err)}`,
        })
      );
  };

  if (loading) {
    return "Loading...";
  }

  if (privacyPolicy && privacyPolicy.privacyPolicy && !updatedEditorState) {
    setUpdatedEditorState(true);
    setEditorState(
      EditorState.createWithContent(stateFromHTML(privacyPolicy.privacyPolicy))
    );
  }

  return (
    <>
      <PageHeader title="Update Privacy Policy" back />
      <Row>
        <Column>
          <Row>
            <Column sub>
              <DataContainer>
                <Redactor
                  onChange={setEditorState}
                  value={editorState}
                  fullScreen
                />
              </DataContainer>
            </Column>
          </Row>
          <Row>
            <Column sub style={{ alignItems: "flex-start" }}>
              <Button
                type="submit"
                onClick={() => history.goBack()}
                width="177px"
                value="Cancel"
                loading={saving}
              />
            </Column>
            <Column sub style={{ alignItems: "flex-end" }}>
              <Button
                type="submit"
                onClick={update}
                width="177px"
                value="Update"
                loading={saving}
                kind="secondary"
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </>
  );
};

export default PrivacyPolicyPreview;
