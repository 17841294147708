import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";
import { COLOR_GREY, COLOR_PRIMARY } from "../../../common/styles/Colors";
import Button from "../../../components/Button/Button.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Redactor from "../../../components/Form/Redactor/Redactor.component";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Notification from "../../../components/Toast/Toast.component";
import TERMS_AND_CONDITIONS from "../../../graphql/queries/termsAndConditions.query";
import UPDATE_TERMS_AND_CONDITIONS from "../../../graphql/mutations/termsAndConditions.mutation";
import { formatGraphqlError } from "../../../utils/api";

const DataContainer = styled.div`
  height: 61.5vh;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${COLOR_GREY};
    border-radius: 10px;
    height: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLOR_PRIMARY};
    border-radius: 10px;
    height: 30px;
  }
`;

const TermsAndConditionsPreview = () => {
  const { data: termsAndConditions, loading } = useQuery(TERMS_AND_CONDITIONS, {
    fetchPolicy: "no-cache",
  });
  const [manageTermsAndConditions, { loading: saving }] = useMutation(
    UPDATE_TERMS_AND_CONDITIONS
  );
  const [updatedEditorState, setUpdatedEditorState] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const history = useHistory();

  const update = () => {
    manageTermsAndConditions({
      variables: {
        body: stateToHTML(editorState.getCurrentContent()),
      },
    })
      .then(() => {
        history.push("/app/cms/terms-and-conditions/");
        return Notification.success({
          message: `Terms & Conditions saved and updated`,
        });
      })
      .catch((err) =>
        Notification.error({
          title: "Terms & Conditions update failed",
          message: `${formatGraphqlError(err)}`,
        })
      );
  };

  if (loading) {
    return "Loading...";
  }

  if (
    termsAndConditions &&
    termsAndConditions.termsAndConditions &&
    !updatedEditorState
  ) {
    setUpdatedEditorState(true);
    setEditorState(
      EditorState.createWithContent(
        stateFromHTML(termsAndConditions.termsAndConditions)
      )
    );
  }

  return (
    <>
      <PageHeader title="Update Terms & Conditions" back />
      <Row>
        <Column>
          <Row>
            <Column sub>
              <DataContainer>
                <Redactor
                  onChange={setEditorState}
                  value={editorState}
                  fullScreen
                />
              </DataContainer>
            </Column>
          </Row>
          <Row>
            <Column sub style={{ alignItems: "flex-start" }}>
              <Button
                type="submit"
                onClick={() => history.goBack()}
                width="177px"
                value="Cancel"
                loading={saving}
              />
            </Column>
            <Column sub style={{ alignItems: "flex-end" }}>
              <Button
                type="submit"
                onClick={update}
                width="177px"
                value="Update"
                loading={saving}
                kind="secondary"
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </>
  );
};

export default TermsAndConditionsPreview;
