import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import ReactStars from "react-rating-stars-component";
import { RiStarHalfLine, RiStarLine } from "react-icons/ri";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Button from "../../../components/Button/Button.component";
import {
  ImageHolder,
  Image,
  CompanyNameProfile,
  CompanyRating,
} from "./Staff.style";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import { UPDATE_APP_USER } from "../../../graphql/mutations/appUser.mutation";
import { formatGraphqlError } from "../../../utils/api";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import { COLOR_PRIMARY } from "../../../common/styles/Colors";
import { GET_RATINGS } from "../../../graphql/queries/staff.query";
import Accordion from "../../../components/Accordion/Accordion.component";
import { GET_ALL_ACTIVE_SPORT_CATEGORIES } from "../../../graphql/queries/sportCategory.query";
import { GET_ALL_ACTIVE_SKILLS } from "../../../graphql/queries/skill.query";
import AutoCompleteChips from "../../../components/Form/AutoCompleteChips/AutoCompleteChips.component";
import { GET_POSITION_ROLES } from "../../../graphql/queries/positionRole.query";

const StaffProfile = ({ staffData, refetch }) => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [canEdit, setCanEdit] = useState(false);
  const { data: ratingsData, loading: ratingsLoading } = useQuery(GET_RATINGS, {
    fetchPolicy: "no-cache",
    variables: { where: { ratedStaff: { id: staffData.staff.id } } },
  });

  const [updateAppUser, { loading }] = useMutation(UPDATE_APP_USER);
  const { data: sportCategories, loading: sportCategoriesLoading } = useQuery(
    GET_ALL_ACTIVE_SPORT_CATEGORIES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: skills, loading: skillsLoading } = useQuery(
    GET_ALL_ACTIVE_SKILLS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { data: roles, loading: rolesLoading } = useQuery(GET_POSITION_ROLES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Staff Profile");
  });

  const { register, handleSubmit, reset } = useForm();

  const resetValues = () => {
    const values = {
      firstName: staffData.firstName,
      lastName: staffData.lastName,
      email: staffData.email,
      staff: {
        update: {
          usedName: staffData.staff.usedName,
          descriptionBio: staffData.staff.descriptionBio,
          fullAddress: staffData.staff.fullAddress,
          country: staffData.staff.country,
          ownCar: staffData.staff.ownCar,
          nationality: staffData.staff.nationality,
          countryResidence: staffData.staff.countryResidence,
          countryCitizenship: staffData.staff.countryCitizenship,
          civilStatus: staffData.staff.civilStatus,
          religion: staffData.staff.religion,
        },
      },
    };
    reset(values, {
      errors: true, // errors will not be reset
      dirtyFields: true, // dirtyFields will not be reset
      isDirty: true, // dirty will not be reset
      isSubmitted: false,
      touched: false,
      isValid: false,
      submitCount: false,
    });
  };

  const getRatingAvg = () => {
    if (!ratingsData) {
      return 3;
    }
    if (ratingsData.ratings.length === 0) {
      return 3;
    }
    let ratingsCount = 0;
    ratingsData.ratings.forEach((rating) => {
      ratingsCount += rating.numberRating;
    });

    return ratingsCount / ratingsData.ratings.length;
  };

  const onSubmit = (values) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update this profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update Profile",
    }).then((result) => {
      if (result.value) {
        const submitValues = values;
        submitValues.staff.update.ownCar =
          submitValues.staff.update.ownCar !== "false";
        delete submitValues.remove;

        updateAppUser({
          variables: {
            id: staffData.id,
            data: submitValues,
          },
        })
          .then(() => {
            Notification.success({
              title: "Profile Updated",
              message: "Updated user profile successfully",
            });
            setCanEdit(false);
            refetch();
          })
          .catch((err) =>
            Notification.error({
              title: "Profile Update Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const toggleSuspendUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${
        staffData.suspended ? "un" : ""
      }suspend this profile?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Suspend Profile",
    }).then((result) => {
      if (result.value) {
        updateAppUser({
          variables: {
            id: staffData.id,
            data: {
              staff: {
                update: {
                  suspended: !staffData.staff.suspended,
                },
              },
            },
          },
        })
          .then(() => {
            if (staffData.suspended) {
              Notification.success({
                title: "Profile Unsuspended",
                message: "Unsuspended user profile successfully",
              });
            } else {
              Notification.success({
                title: "Profile Suspended",
                message: "Suspended user profile successfully",
              });
            }
            refetch();
          })
          .catch((err) =>
            Notification.error({
              title: "Profile Suspension Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };
  const setMulti = (multi) => {
    console.log(multi);
  };
  console.log("***", staffData.staff);

  return (
    <Row style={{ marginTop: "15px", height: "auto" }}>
      <Column width="70%" noPad>
        <Row>
          <Column sub>
            <H2>Personal Details</H2>
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="First Name"
              name="firstName"
              label=""
              type="text"
              value={staffData.firstName}
              register={register}
              readOnly={!canEdit}
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Last Name"
              name="lastName"
              label=""
              type="text"
              value={staffData.lastName}
              readOnly={!canEdit}
              register={register}
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Email Address"
              name="email"
              label=""
              type="email"
              value={staffData.email}
              register={register}
              readOnly
            />
          </Column>
        </Row>
        <Row>
          <Column sub>
            <H2>Profile Details</H2>
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="Used Name"
              name="staff.update.usedName"
              label=""
              type="text"
              value={staffData.staff.usedName}
              readOnly={!canEdit}
              register={register}
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Bio"
              name="staff.update.descriptionBio"
              label=""
              type="text"
              value={staffData.staff.descriptionBio}
              register={register}
              readOnly={!canEdit}
              multiLine
            />
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="Address"
              name="staff.update.fullAddress"
              label=""
              type="text"
              value={staffData.staff.fullAddress}
              readOnly
              register={register}
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Country"
              label=""
              type="text"
              value={staffData.staff.country}
              readOnly
            />
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="Looking for"
              label="Looking For"
              name="remove.positionTypeLookingFor"
              type="text"
              value={
                staffData.staff.positionTypeLookingFor
                  ? staffData.staff.positionTypeLookingFor.title
                  : ""
              }
              readOnly
              register={register}
            />
          </Column>
          <Column width="50%" sub>
            <BorderSelect
              header="Own Car"
              name="staff.update.ownCar"
              label="Own Car"
              value={staffData.staff.ownCar ? staffData.staff.ownCar : "false"}
              register={register}
              readOnly={!canEdit}
              options={[
                { value: "true", text: "Yes" },
                { value: "false", text: "No" },
              ]}
            />
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="Nationality"
              name="staff.update.nationality"
              label=""
              type="text"
              value={staffData.staff.nationality}
              readOnly={!canEdit}
              register={register}
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Country of Residence"
              name="staff.update.countryResidence"
              label=""
              type="text"
              value={staffData.staff.countryResidence}
              readOnly={!canEdit}
              register={register}
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Country of Citizenship"
              name="staff.update.countryCitizenship"
              label=""
              type="text"
              value={staffData.staff.countryCitizenship}
              register={register}
              readOnly={!canEdit}
            />
          </Column>
        </Row>
        <Row>
          {" "}
          <Column width="50%" sub>
            <AutoCompleteChips
              disabled={!canEdit}
              header="Sports"
              name="staff.update.sportCategories.connect"
              label="Search sport"
              accessorTitle="title"
              accessorValue="id"
              selected={
                staffData.staff.sportCategories
                  ? staffData.staff.sportCategories
                  : []
              }
              setSelected={setMulti}
              register={register}
              suggestions={
                sportCategoriesLoading
                  ? ["Loading please wait"]
                  : sportCategories.sportCategories
              }
              required={{
                required: "please select at least one sport",
              }}
            />
          </Column>
          <Column width="50%" sub>
            <AutoCompleteChips
              disabled={!canEdit}
              header="Role looking for"
              name="staff.update.positionTypeLookingFor.connect"
              label="Search roles"
              accessorTitle="title"
              accessorValue="id"
              selected={
                staffData.staff.positionTypeLookingFor
                  ? staffData.staff.positionTypeLookingFor
                  : []
              }
              setSelected={setMulti}
              register={register}
              suggestions={
                rolesLoading ? ["Loading please wait"] : roles.positionRoles
              }
              required={{
                required: "please select at least one role",
              }}
              // error={
              //   errors.baseSkills && (
              //     <Error message={errors.baseSkills.create.message} />
              //   )
              // }
            />
          </Column>
          <Column width="50%" sub>
            <AutoCompleteChips
              disabled={!canEdit}
              header="Skills"
              name="staff.update.skills.connect"
              label="Search skills"
              accessorTitle="title"
              accessorValue="id"
              selected={staffData.staff.skills ? staffData.staff.skills : []}
              setSelected={setMulti}
              register={register}
              suggestions={
                skillsLoading ? ["Loading please wait"] : skills.skills
              }
              required={{
                required: "please select at least one skill",
              }}
              // error={
              //   errors.baseSkills && (
              //     <Error message={errors.baseSkills.create.message} />
              //   )
              // }
            />
          </Column>
        </Row>
        <Row>
          <Column width="50%" sub>
            <BorderInput
              header="Civil Status"
              name="staff.update.civilStatus"
              label=""
              type="text"
              value={staffData.staff.civilStatus}
              readOnly={!canEdit}
              register={register}
            />
          </Column>
          <Column width="50%" sub>
            <BorderInput
              header="Religion"
              name="staff.update.religion"
              label=""
              type="text"
              value={staffData.staff.religion}
              register={register}
              readOnly={!canEdit}
            />
          </Column>
        </Row>
        <Row>
          <Column sub>
            <Accordion title="Banking Details:">
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="Account Name"
                    label="Account Name"
                    name="remove.bankingDetails.accountName"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.accountName
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="Not Own Name"
                    label="Not Own Name"
                    name="remove.bankingDetails.notOwnName"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.notOwnName
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="Address Of Account Holder"
                    label="Address Of Account Holder"
                    name="remove.bankingDetails.addressOfAccountHolder"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.addressOfAccountHolder
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
              </Row>
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="Account Currency"
                    label="Account Currency"
                    name="remove.bankingDetails.accountCurrency"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.accountCurrency
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="Bank Name"
                    label="Bank Name"
                    name="remove.bankingDetails.bankName"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.bankName
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="Bank Address"
                    label="Bank Address"
                    name="remove.bankingDetails.bankAddress"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.bankAddress
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
              </Row>
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="Bank Country"
                    label="Bank Country"
                    name="remove.bankingDetails.bankCountry"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.bankCountry
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="IBAN"
                    label="IBAN"
                    name="remove.bankingDetails.IBAN"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.IBAN
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="BICSWIFT"
                    label="BICSWIFT"
                    name="remove.bankingDetails.BICSWIFT"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.BICSWIFT
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
              </Row>
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="Account Number"
                    label="Account Number"
                    name="remove.bankingDetails.accountNumber"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.accountNumber
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="ABACode"
                    label="ABACode"
                    name="remove.bankingDetails.ABACode"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.ABACode
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub>
                  <BorderInput
                    header="sortCode"
                    label="sortCode"
                    name="remove.bankingDetails.sortCode"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.sortCode
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
              </Row>
              <Row>
                <Column width="50%" sub>
                  <BorderInput
                    header="Additional Info"
                    label="Additional Info"
                    name="remove.bankingDetails.additionalInfo"
                    type="text"
                    value={
                      staffData.staff.bankingDetails
                        ? staffData.staff.bankingDetails.additionalInfo
                        : ""
                    }
                    readOnly
                    register={register}
                  />
                </Column>
                <Column width="50%" sub />
                <Column width="50%" sub />
              </Row>
            </Accordion>
          </Column>
        </Row>
      </Column>
      <Column width="30%">
        <ImageHolder>
          <Image src={staffData.staff.profileImageUrl} />
        </ImageHolder>
        <CompanyNameProfile>
          <H2>{`${staffData.firstName} ${staffData.lastName}`}</H2>
        </CompanyNameProfile>

        {!ratingsLoading && (
          <CompanyRating>
            <ReactStars
              count={5}
              size={24}
              isHalf
              edit={false}
              value={getRatingAvg()}
              activeColor={COLOR_PRIMARY}
              fullIcon={<RiStarLine />}
              halfIcon={<RiStarHalfLine />}
              emptyIcon={<RiStarLine />}
            />
          </CompanyRating>
        )}

        {staffData.staff.suspended ? (
          <Button
            width="100%"
            type="button"
            value="Unsuspend User"
            onClick={toggleSuspendUser}
            loading={loading}
          />
        ) : (
          <Button
            width="100%"
            type="button"
            value="Suspend User"
            onClick={toggleSuspendUser}
            loading={loading}
          />
        )}
        {/* <Button
          width="100%"
          type="button"
          value="Send Password Reset"
          loading={loading}
        /> */}
        {canEdit ? (
          <>
            <Button
              width="100%"
              onClick={handleSubmit(onSubmit)}
              type="button"
              kind="secondary"
              value="Save & Update"
              loading={loading}
            />
            <Button
              width="100%"
              onClick={() => {
                setCanEdit(false);
                resetValues();
              }}
              type="button"
              value="Cancel Edit"
              loading={loading}
            />
          </>
        ) : (
          <Button
            width="100%"
            onClick={() => setCanEdit(true)}
            type="button"
            kind="secondary"
            value="Edit Profile"
          />
        )}
      </Column>
    </Row>
  );
};

StaffProfile.propTypes = {
  staffData: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default StaffProfile;
