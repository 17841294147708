import styled from "styled-components";
import { COLOR_PRIMARY_BUTTON } from "../../common/styles/Colors";

export const StyledButton = styled.button`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.height ? props.height : "40px")};
  width: ${(props) => (props.width ? props.width : "50%")};
  border-radius: 5px;
  padding: 15px 20px;
  margin: ${(props) => (props.margin ? props.margin : "10px 0")};
  cursor: pointer;
  color: ${(props) => (props.secondary ? "white" : COLOR_PRIMARY_BUTTON)};
  background: ${(props) =>
    props.color
      ? props.color
      : props.secondary
      ? COLOR_PRIMARY_BUTTON
      : "white"};
  border: ${(props) =>
    props.secondary ? "none" : `1px solid ${COLOR_PRIMARY_BUTTON}`};
  font-size: 16px;

  :disabled {
    background: grey;
  }
`;

export const Icon = styled.i`
  margin-right: 10px;
  font-size: 25px;
  width: 25px;
  height: 25px;
`;
