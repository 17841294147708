import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import { GoPrimitiveDot } from "react-icons/go";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Swal from "sweetalert2";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Button from "../../../components/Button/Button.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
import { DELETE_USER_TYPE } from "../../../graphql/mutations/userType.mutation";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  GET_USER_ROLES,
  GET_USER_ROLES_EXPORT,
} from "../../../graphql/queries/userType.query";
import { formatGraphqlError } from "../../../utils/api";

const UserTypeAll = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_USER_ROLES, {
    variables: { order: "title_ASC" },
    fetchPolicy: "no-cache",
  });
  const [variables, setVariables] = useState({
    where: { AND: [{ NOT: { status: "ARCHIVED" } }] },
  });
  const [deleteUserType] = useMutation(DELETE_USER_TYPE);
  const [getUserRolesExport, userRolesExport] = useLazyQuery(
    GET_USER_ROLES_EXPORT
  );

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Manage User Types");
  });

  const handleDelete = (e, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteUserType({
          variables: {
            id: row.original.id,
          },
        })
          .then(() => {
            Swal.fire("Deleted!", "User type has been deleted", "success");
            refetch({
              variables: {
                ...variables,
                first: 50,
                skip: 0,
              },
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete User Type Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const HeaderInfo = [
    {
      Header: "Name",
      accessor: "title",
      id: "title",
      search: true,
    },
    {
      Header: "Description",
      accessor: "description",
      id: "description",
      search: true,
    },
    {
      Header: "Status",
      id: "status",
      Cell: ({ row }) => (
        <TableIcons>
          <GoPrimitiveDot
            style={{ fontSize: "25px", color: getColor(row.original.status) }}
          />
          <span>{row.original.status.toLowerCase()}</span>
        </TableIcons>
      ),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <Link to={`/app/system-configuration/user-types/${row.original.id}`}>
            <FiEdit
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          </Link>
          <FiTrash
            onClick={(e) => handleDelete(e, row)}
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
    {
      header: "Status:",
      accessor: "status",
      placeholder: "Please select a Status",
      type: "select",
      options: [
        { value: "ACTIVE", text: "Active" },
        { value: "ARCHIVE", text: "Archive" },
        { value: "DRAFT", text: "Draft" },
      ],
    },
  ];

  return (
    <>
      <PageHeader title="Manage User Types">
        <Link to="/app/system-configuration/user-types/new">
          <Button
            onClick={() => {}}
            kind="secondary"
            value="New User Type"
            width="177px"
            margin="10px 0 10px 10px"
          />
        </Link>
      </PageHeader>
      <Table
        columns={HeaderInfo}
        data={(data && data.userRoles) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.userRolesCount.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="User Types"
        tableOptions={["search", "download", "columns", "filter"]}
        filters={filters}
        downloadData={userRolesExport}
        getDownloadData={getUserRolesExport}
        downloadAccessor={
          userRolesExport.data ? userRolesExport.data.userRoles : []
        }
        fileName="UserRoles"
      />
    </>
  );
};

export default UserTypeAll;
