import gql from "graphql-tag";

export const CREATE_PAYMENT = gql`
  mutation createPayment($data: PaymentCreateInput!) {
    createPayment(data: $data) {
      id
    }
  }
`;

export default {
  CREATE_PAYMENT,
};
