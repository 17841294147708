import React from "react";
import { LoadingHolder, SVGHolder, Logo } from "./Loading.style";

const logo = require("../../../images/pt-logo.svg");

const LoadingFullScreen = () => {
  return (
    <LoadingHolder>
      <SVGHolder>
        <div className="svgContainer">
          <Logo src={logo} />
        </div>
      </SVGHolder>
    </LoadingHolder>
  );
};

export default LoadingFullScreen;
