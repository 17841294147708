import gql from "graphql-tag";

export const GET_SPORT_TYPES = gql`
  query getSportTypes(
    $order: SportTypeOrderByInput
    $first: Int!
    $skip: Int!
    $where: SportTypeWhereInput
  ) {
    sportTypes(orderBy: $order, first: $first, skip: $skip, where: $where) {
      title
      description
      status
      id
      levels
      sportCategory {
        id
        title
      }
    }
    sportTypesCount: sportTypes(where: $where) {
      id
    }
  }
`;

export const GET_SPORT_TYPES_EXPORT = gql`
  query getSportTypes(
    $order: SportTypeOrderByInput
    $where: SportTypeWhereInput
  ) {
    sportTypes(orderBy: $order, where: $where) {
      title
      description
      status
      id
      levels
      sportCategory {
        id
        title
      }
    }
  }
`;

export const GET_SPORT_TYPE = gql`
  query getSportType($id: ID) {
    sportType(where: { id: $id }) {
      title
      description
      status
      id
      levels
      sportCategory {
        id
        title
      }
    }
  }
`;

export const GET_ALL_ACTIVE_SPORT_TYPES = gql`
  query {
    sportTypes(where: { status: ACTIVE }, orderBy: title_ASC) {
      title
      id
      levels
      sportCategory {
        id
      }
    }
  }
`;

export default {
  GET_SPORT_TYPES,
  GET_SPORT_TYPES_EXPORT,
  GET_SPORT_TYPE,
  GET_ALL_ACTIVE_SPORT_TYPES,
};
