import React from "react";
import PropTypes from "prop-types";
import {
  CardWrapper,
  CardHeader,
  CardBody,
  Title,
} from "./DashboardCard.style";
/**
 * DashboardCard component for generic use in project.
 */

const DashboardCard = ({ title, children }) => {
  return (
    <CardWrapper>
      <CardHeader>
        <Title>{title}</Title>
        {/* <Actions></Actions> */}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </CardWrapper>
  );
};

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DashboardCard.defaultProps = {};

export default DashboardCard;
