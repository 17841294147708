import gql from "graphql-tag";

export const GET_SKILLS = gql`
  query getSkills(
    $order: SkillOrderByInput
    $first: Int!
    $skip: Int!
    $where: SkillWhereInput!
  ) {
    skills(orderBy: $order, first: $first, skip: $skip, where: $where) {
      title
      description
      status
      id
    }
    skillsCount: skills(where: $where) {
      id
    }
  }
`;

export const GET_SKILLS_EXPORT = gql`
  query getSkills($order: SkillOrderByInput, $where: SkillWhereInput!) {
    skills(orderBy: $order, where: $where) {
      title
      description
      status
      id
    }
  }
`;

export const GET_SKILL = gql`
  query getSkill($id: ID) {
    skill(where: { id: $id }) {
      title
      description
      status
      id
    }
  }
`;

export const GET_ALL_ACTIVE_SKILLS = gql`
  query {
    skills(where: { status: ACTIVE }) {
      title
      id
    }
  }
`;

export default {
  GET_SKILLS,
  GET_SKILLS_EXPORT,
  GET_SKILL,
  GET_ALL_ACTIVE_SKILLS,
};
