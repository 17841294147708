import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

import { APP_CONTEXT } from "../../utils/context";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import Table, { TableIcons } from "../../components/Table/Table.component";

// GQL
import {
  GET_SERVICE_AGREEMENTS,
  GET_SERVICE_AGREEMENTS_EXPORT,
} from "../../graphql/queries/serviceAgreement.query";
import {
  CompanyHolder,
  CompanyName,
  SmallImage,
  SmallImageHolder,
} from "../UserManagement/Staff/Staff.style";

const ServiceAgreement = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(
    GET_SERVICE_AGREEMENTS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [variables, setVariables] = useState({
    where: { AND: [] },
  });
  const [getServiceAgreementsExport, serviceAgreementsExport] = useLazyQuery(
    GET_SERVICE_AGREEMENTS_EXPORT
  );

  useEffect(() => {
    setActiveSideNav("serviceAgreement");
    setPageTitle("Manage Service Agreements");
  });

  const HeaderInfo = [
    {
      Header: "Staff",
      accessor: "staff",
      id: "staff",
      Cell: ({ row }) => {
        return (
          <CompanyHolder>
            <SmallImageHolder>
              <SmallImage src={row.original.staff.profileImageUrl} />
            </SmallImageHolder>
            <CompanyName>{`${row.original.staff.appUser.firstName} ${row.original.staff.appUser.lastName}`}</CompanyName>
          </CompanyHolder>
        );
      },
    },
    {
      Header: "Company",
      accessor: "employer",
      id: "employer",
      Cell: ({ row }) => {
        return (
          <CompanyHolder>
            <SmallImageHolder>
              <SmallImage src={row.original.employer.companyLogo} />
            </SmallImageHolder>
            <CompanyName>{row.original.employer.companyName}</CompanyName>
          </CompanyHolder>
        );
      },
    },
    {
      Header: "Postition",
      accessor: (row) => row.position.title,
      id: "position",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      id: "createdAt",
      search: true,
      Cell: ({ row }) =>
        moment(row.original.createdAt).format("YYYY/MM/DD | HH:mm"),
    },
    {
      Header: "Updated At",
      accessor: "updatedAt",
      id: "updatedAt",
      search: true,
      Cell: ({ row }) =>
        moment(row.original.updatedAt).format("YYYY/MM/DD | HH:mm"),
    },
    {
      Header: "Status",
      id: "status",
      Cell: ({ row }) => (
        <TableIcons>
          <span>{row.original.status.toLowerCase().replace(/_/g, " ")}</span>
        </TableIcons>
      ),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <Link to={`/app/service-agreement/${row.original.id}`}>
            <AiOutlineEye
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          </Link>
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
  ];

  return (
    <>
      <PageHeader title="Manage Service Agreements" />
      <Table
        columns={HeaderInfo}
        data={(data && data.serviceAgreements) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.serviceAgreementsCount.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Service Agreements"
        tableOptions={["search", "download", "columns", "filter"]}
        filters={filters}
        downloadData={serviceAgreementsExport}
        getDownloadData={getServiceAgreementsExport}
        downloadAccessor={
          serviceAgreementsExport.data
            ? serviceAgreementsExport.data.serviceAgreements
            : []
        }
        fileName="ServiceAgreements"
      />
    </>
  );
};

export default ServiceAgreement;
