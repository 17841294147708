import styled from "styled-components";
import { motion } from "framer-motion";
import { COLOR_PRIMARY } from "../../common/styles/Colors";

const getWidth = (props) => {
  if (props.fullScreen) {
    return "100%";
  }
  return props.width ? props.width : "50%";
};

const getHeight = (props) => {
  if (props.fullScreen) {
    return "100%";
  }
  return props.height ? props.height : "auto";
};

const ModalOverlay = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #393b3c82;
  z-index: 9999;
`;
const Modal = styled(motion.div)`
  position: fixed;
  width: ${(props) => getWidth(props)};
  height: ${(props) => getHeight(props)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  padding: ${(props) => (props.fullScreen ? "0px" : "25px")};
  z-index: 9999;
  .close {
    position: absolute;
    top: 5px;
    right: ${(props) => (props.fullScreen ? "10px" : "5px")};
    font-size: 24px;
    color: ${COLOR_PRIMARY};
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    z-index: 999;
    &:hover {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 1200px) {
    width: ${(props) => (props.fullScreen ? "100%" : "95%")};
    height: ${(props) => (props.fullScreen ? "100%" : "95%")};
  }
`;

const ModalChildren = styled.div`
  width: 100%;
  max-height: ${(props) => (props.fullScreen ? "100vh" : "75vh")};
  overflow-y: ${(props) => (props.showOverflow ? "visible" : "auto")};
  @media (max-width: 1200px) {
    max-height: 100%;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;

export { ModalOverlay, Modal, ModalChildren };
