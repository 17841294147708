/* eslint-disable no-nested-ternary */
import styled from "styled-components";

const Footer = styled.div`
  width: ${(props) =>
    props.sub
      ? props.width
        ? props.width
        : "50%"
      : props.width
      ? props.width
      : "100%"};
  padding: ${(props) => (props.sub ? "20px 0 0 0" : "10px 0 20px 0")};
  display: flex;
  flex-direction: ${(props) => (props.sub ? "row" : "column")};
  align-items: center;
  justify-content: ${(props) => (props.sub ? "flex-end" : "center")};
  font-size: 100%;

  @media (max-width: 1110px) {
    width: ${(props) => (props.sub ? "58%" : "100%")};
  }

  @media (max-width: 900px) {
    width: ${(props) => (props.sub ? "88%" : "100%")};
  }
`;

export default Footer;
