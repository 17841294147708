import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo";
import { Container } from "./Employer.style";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { GET_EVENTS } from "../../../graphql/queries/events.query";
import Calendar from "../../../components/Calendar/Calendar";
import { Modal } from "../../../components/Modal/Modal.style";
import { H2, H3 } from "../../../components/Typography/Typography.component";

const EmployerCalendar = ({ employerData }) => {
  const [data, setData] = useState({
    id: "",
    title: "",
    description: "",
    location: "",
    startDate: null,
    endDate: null,
    open: false,
    status: "DRAFT",
    sportCategory: "",
    sportType: "",
    position: [],
    employer: "",
    eventType: "",
  });
  const { data: events, loading: eventsLoading } = useQuery(GET_EVENTS, {
    fetchPolicy: "no-cache",
    variables: {
      where: {
        createdByEmployer: {
          id: employerData.id,
        },
      },
    },
  });
  const handleDateClick = (args) => {
    setData({
      id: args.event.id,
      title: args.event.title,
      description: args.event.extendedProps.description,
      location: args.event.extendedProps.location
        ? args.event.extendedProps.location
        : "",
      startDate: moment(args.event.extendedProps.startDate),
      endDate: args.event.extendedProps.endDate
        ? moment(args.event.extendedProps.endDate)
        : moment(args.event.extendedProps.startDate),
      status: args.event.extendedProps.status,
      sportCategory: args.event.extendedProps.sportCategory,
      sportType: args.event.extendedProps.sportType,
      position: args.event.extendedProps.position,
      createdBy: args.event.extendedProps.createdBy,
      eventType: args.event.extendedProps.eventType,
      open: true,
    });
  };

  const setShowModal = (val) => {
    setData({
      ...data,
      open: val,
    });
  };

  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "#39C568";
      case "PENDING":
        return "#1a0388";
      case "ARCHIVED":
        return "#FF003A";
      default:
        return "#707172";
    }
  };

  return (
    <>
      <Column style={{ marginTop: "15px", height: "auto" }}>
        <Container>
          <Row>
            <Column sub>
              <Calendar
                handleDateClick={handleDateClick}
                events={
                  !eventsLoading
                    ? events.events.map((event) => {
                        const start = new Date(event.startDate);
                        const end = new Date(event.endDate);
                        end.setDate(end.getDate() + 1);
                        return {
                          id: event.id,
                          title: event.title,
                          description: event.description,
                          location: event.location,
                          start,
                          end,
                          startDate: event.startDate,
                          endDate: event.endDate,
                          sportCategory: event.sportCategory,
                          sportType: event.sportType,
                          status: event.status,
                          allDay: true,
                          backgroundColor: getColor(event.status),
                          borderColor: getColor(event.status),
                        };
                      })
                    : []
                }
              />
            </Column>
          </Row>
        </Container>
      </Column>
      {data.open && (
        <Modal
          open={data.open}
          showClose
          close={() => {
            setShowModal(false);
          }}
          width="50%"
        >
          <H2>Event Info</H2>
          <Row>
            <Column>
              <H3>Title</H3>
              {data.title}
            </Column>
            <Column>
              <H3>Description</H3>
              {data.description}
            </Column>
          </Row>
          <Row>
            <Column>
              <H3>Location</H3>
              {data.location}
            </Column>
            <Column>
              <H3>Status</H3>
              {data.status}
            </Column>
          </Row>
          <Row>
            <Column>
              <H3>Start Date</H3>
              {data.startDate}
            </Column>
            <Column>
              <H3>End Date</H3>
              {data.endDate}
            </Column>
          </Row>
          <Row>
            <Column>
              <H3>Sport Category</H3>
              {data.sportCategory}
            </Column>
            <Column>
              <H3>Sport Type</H3>
              {data.sportType}
            </Column>
          </Row>
          <Row>
            <Column>
              <H3>Positions</H3>
              {data.position.map((pos, index) => {
                return `${pos.title}${
                  index !== data.position.length - 1 && " |"
                }`;
              })}
            </Column>
            <Column>
              <H3>Created By:</H3>
              {`${data.createdBy.firstName} ${data.createdBy.lastName}`}
            </Column>
          </Row>
          <Row>
            <Column>
              <H3>Status:</H3>
              {data.status}
            </Column>
            <Column>
              <H3>Type:</H3>
              {data.eventType}
            </Column>
          </Row>
        </Modal>
      )}
    </>
  );
};

EmployerCalendar.propTypes = {
  employerData: PropTypes.object.isRequired,
};

export default EmployerCalendar;
