import styled from "styled-components";
import { COLOR_PRIMARY_HEADER, COLOR_GREY } from "../../common/styles/Colors";

export const TopNavigation = styled.nav`
  min-width: 100vw;
  height: 60px;
  background-color: ${COLOR_PRIMARY_HEADER};
  display: flex;
  justify-content: ${(props) => (props.title ? "center" : "flex-end")};
  box-shadow: 1px 0 5px -2px ${COLOR_GREY};
  position: relative;
  z-index: 12;
`;

export const Logo = styled.img`
  position: absolute;
  left: 3.5%;
  height: 60px;
  padding: 16px 5px;
  margin: 0;
`;
