import moment from "moment";
import { v4 as uuidv4 } from "uuid";

/* eslint-disable no-plusplus */
export const generatePassword = () => {
  return uuidv4();
};

export const prettyDate = (date) => {
  return moment(date).utcOffset(0).format("YYYY/MM/DD HH:mm:ss");
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export const sumObjectArray = (arr, accessor) => {
  return arr.reduce((total, obj) => {
    if (typeof total === "object") {
      return Number(total[accessor]) + Number(obj[accessor]);
    }
    return Number(total) + Number(obj[accessor]);
  }, 0);
};

export default {
  generatePassword,
  prettyDate,
  fileToBase64,
  sumObjectArray,
};
