import gql from "graphql-tag";

const UPDATE_TERMS_AND_CONDITIONS = gql`
  mutation manageTermsAndConditions($body: String!) {
    manageTermsAndConditions(data: { body: $body }) {
      id
      body
      updatedAt
    }
  }
`;

export default UPDATE_TERMS_AND_CONDITIONS;
