/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  InputHeader,
  StyledInput,
  Icon,
  Input,
  Validation,
} from "./BorderInput.style";

const BorderInput = ({
  header,
  name,
  label,
  register,
  required,
  type,
  error,
  value,
  icon,
  onChange,
  validationLabel,
  readOnly,
  multiLine,
  injectProps,
  hidden,
}) => {
  return (
    <Container hidden={hidden}>
      <InputHeader>{header}</InputHeader>
      <Input>
        <StyledInput
          name={name}
          label={label}
          placeholder={label}
          ref={register && register(required)}
          type={type}
          defaultValue={value}
          onChange={onChange}
          readOnly={readOnly}
          multiLine={multiLine}
          {...injectProps}
        />
        {icon && <Icon>{icon}</Icon>}
      </Input>
      {!error && validationLabel && <Validation>*{validationLabel}</Validation>}
      <div>{error}</div>
    </Container>
  );
};

BorderInput.defaultProps = {
  header: "",
  label: PropTypes.string,
  required: {},
  type: "text",
  error: undefined,
  value: "",
  icon: undefined,
  onChange: undefined,
  validationLabel: "",
  readOnly: false,
  multiLine: false,
  hidden: false,
  injectProps: {},
};

BorderInput.propTypes = {
  // input field header
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // react-hook-form validation object
  register: PropTypes.func.isRequired,
  required: PropTypes.object,
  type: PropTypes.string,
  // error object
  error: PropTypes.object,
  injectProps: PropTypes.object,
  value: PropTypes.string,
  icon: PropTypes.element,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  multiLine: PropTypes.bool,
  hidden: PropTypes.bool,
  // specify type of input expected below input field
  validationLabel: PropTypes.string,
};

export default BorderInput;
