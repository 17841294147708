import React from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
// import { useQuery } from '@apollo/react-hooks';
import { TabsHolder, Tab as TabStyle, Back } from "./Tabs.style";

const Tab = ({ tab }) => {
  const match = useRouteMatch(tab.path);
  return (
    <TabStyle className={match ? "active" : ""}>
      <Link to={tab.path}>{tab.title}</Link>
    </TabStyle>
  );
};

Tab.propTypes = {
  tab: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};

const TabComponent = ({ tab, activeComponent, onClick }) => {
  return (
    <TabStyle
      className={activeComponent === tab.id ? "active" : ""}
      onClick={onClick}
    >
      <span>{tab.title}</span>
    </TabStyle>
  );
};

TabComponent.propTypes = {
  tab: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  activeComponent: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Tabs = ({
  tabs,
  componentTabs,
  activeComponent,
  setActiveComponent,
  back,
}) => {
  const history = useHistory();
  if (componentTabs.length > 0) {
    return (
      <TabsHolder absolute={componentTabs.length > 0}>
        {back && (
          <Back onClick={history.goBack}>
            <FiArrowLeft
              style={{
                marginRight: "12px",
                fontSize: "20px",
              }}
            />
            Go Back
          </Back>
        )}
        {componentTabs.map((tab) => (
          <TabComponent
            activeComponent={activeComponent}
            tab={tab}
            key={`tab-${tab.id}`}
            onClick={() => {
              setActiveComponent(tab.id);
            }}
          />
        ))}
      </TabsHolder>
    );
  }

  if (tabs.length === 0) {
    return null;
  }

  return (
    <TabsHolder>
      {tabs.map((tab) => (
        <Tab tab={tab} key={`tab-${tab.path}`} />
      ))}
    </TabsHolder>
  );
};

Tabs.defaultProps = {
  tabs: [],
  componentTabs: [],
  activeComponent: "",
  setActiveComponent: undefined,
  back: false,
};
Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  componentTabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  activeComponent: PropTypes.string,
  back: PropTypes.bool,
  setActiveComponent: PropTypes.func,
};

export default Tabs;
