import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { COLOR_PRIMARY, COLOR_SECONDARY } from "../../../common/styles/Colors";

export const NotificationSection = styled.div`
  display: inline-block;
  position: relative;
  svg {
    position: relative;
    z-index: 5;
  }
`;

export const ViewAllNotifications = styled(motion.div)`
  color: ${COLOR_SECONDARY};
  display: block;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  overflow: hidden;
  span {
    padding-bottom: 10px;
    padding: 27px;
    display: inline-block;
  }
`;

export const NotificationDropDownHolder = styled(motion.div)`
  position: absolute;
  width: 420px;
  max-height: 65vh;
  overflow: ${(props) => (props.viewAllNotifications ? "auto" : "hidden")};
  top: 150%;
  left: -170px;
  box-shadow: 0px 2px 10px -1px #8386a3;
`;
export const NotificationDropDown = styled.div`
  padding: 17px;
  background-color: white;
`;

export const None = styled.div`
  text-align: center;
`;

export const Message = styled.div`
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 8px;
  position: relative;
`;
export const Date = styled.div`
  font-size: 12px;
  line-height: 16px;
  position: relative;
`;
export const Icon = styled.div`
  display: inline-block;
  position: relative;
`;
export const NewNotification = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  top: -2.5px;
  right: -2.5px;
  background-color: ${COLOR_SECONDARY};
  border-radius: 50px;
`;

export const Dissmiss = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Notification = styled(Link)`
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  color: #635f6c;
  font-weight: 400;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: padding 0.2s ease-in-out, color 0.5s ease-in-out;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    padding-left: 10px;
    color: white;
    .unread {
      background-color: ${COLOR_PRIMARY};
      width: 500px;
      height: 500px;
      top: 50%;
      right: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  }
`;

export const Dot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  top: 5px;
  right: 5px;
  background-color: ${(props) => (props.read ? "white" : COLOR_SECONDARY)};
  border-radius: 250px;
  transition: all 0.5s ease-in-out;
`;
