import gql from "graphql-tag";

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription(
    $stripeId: String!
    $paymentMethodId: String!
    $priceId: String!
  ) {
    createSubscription(
      data: {
        stripeId: $stripeId
        paymentMethodId: $paymentMethodId
        priceId: $priceId
      }
    )
  }
`;

export default {
  CREATE_SUBSCRIPTION,
};
