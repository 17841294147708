import gql from "graphql-tag";

export const GET_CHAT_GROUPS = gql`
  query getChatGroups($userGroup: UserGroup!) {
    chatGroups(where: { type: SUPPORT, userGroup: $userGroup }) {
      id
      createdAt
      createdBy {
        firstName
        lastName
      }
      type
      status
      messages(orderBy: createdAt_DESC, first: 1) {
        id
        adminUser {
          id
          firstName
          lastName
        }
        appUser {
          id
          firstName
          lastName
        }
        createdAt
        message
      }
    }
  }
`;
export default {
  GET_CHAT_GROUPS,
};
