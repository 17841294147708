import gql from "graphql-tag";

export const GET_SERVICE_AGREEMENTS = gql`
  query getServiceAgreements(
    $order: ServiceAgreementOrderByInput
    $first: Int!
    $skip: Int!
    $where: ServiceAgreementWhereInput
  ) {
    serviceAgreements(
      orderBy: $order
      first: $first
      skip: $skip
      where: $where
    ) {
      id
      status
      createdAt
      updatedAt
      currentVersion
      descriptionOfService
      startDate
      endDate
      employer {
        id
        companyName
        companyLogo
      }
      staff {
        id
        profileImageUrl
        appUser {
          id
          firstName
          lastName
        }
      }
      chatGroup {
        id
        messages {
          id
          message
        }
      }
      position {
        id
        title
      }
    }
    serviceAgreementsCount: serviceAgreements(where: $where) {
      id
    }
  }
`;

export const GET_SERVICE_AGREEMENTS_EXPORT = gql`
  query getServiceAgreements(
    $order: ServiceAgreementOrderByInput
    $where: ServiceAgreementWhereInput!
  ) {
    serviceAgreements(orderBy: $order, where: $where) {
      id
      status
      versions
      rateMetric
      paymentMetric
      createdAt
      updatedAt
      currentVersion
      descriptionOfService
      startDate
      endDate
      serviceAgreementDuration
      rate
      additionalTerms
      travelDayRate
      expensePolicy
      clothing
      footwear
      phone
      phoneAllowance
      perDiem
      additionalCommentsEmployer
      additionalCommentsStaff
      other
      companySignature
      staffSignature
      employer {
        id
        companyName
        companyLogo
      }
      staff {
        id
        profileImageUrl
        appUser {
          id
          firstName
          lastName
        }
      }
      chatGroup {
        id
        messages {
          id
          message
        }
      }
      position {
        id
        title
      }
    }
  }
`;

export const GET_SERVICE_AGREEMENT = gql`
  query getServiceAgreement($id: ID) {
    serviceAgreement(where: { id: $id }) {
      id
      status
      versions
      rateMetric
      paymentMetric
      createdAt
      updatedAt
      currentVersion
      descriptionOfService
      startDate
      endDate
      serviceAgreementDuration
      rate
      additionalTerms
      travelDayRate
      expensePolicy
      clothing
      footwear
      phone
      phoneAllowance
      perDiem
      additionalCommentsEmployer
      additionalCommentsStaff
      other
      signatureEmployer
      signatureDateEmployer
      signatureStaff
      signatureDateStaff
      employer {
        id
        companyName
        companyLogo
      }
      staff {
        id
        profileImageUrl
        appUser {
          id
          firstName
          lastName
        }
      }
      chatGroup {
        id
        messages {
          id
          message
          appUser {
            id
            firstName
            lastName
          }
        }
      }
      position {
        id
        title
      }
    }
  }
`;

export default {
  GET_SERVICE_AGREEMENTS,
  GET_SERVICE_AGREEMENTS_EXPORT,
  GET_SERVICE_AGREEMENT,
};
