/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./CardSectionStyles.css";
import {
  COLOR_PRIMARY,
  COLOR_GREY2,
  COLOR_SECONDARY,
} from "../../common/styles/Colors";
import { H2 } from "../Typography/Typography.component";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: COLOR_PRIMARY,
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: COLOR_GREY2,
      },
    },
    invalid: {
      color: COLOR_SECONDARY,
      iconColor: COLOR_SECONDARY,
    },
  },
};

function CardSection() {
  return (
    <>
      <H2 style={{ margin: "15px 0" }}>Card details</H2>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </>
  );
}

export default CardSection;
