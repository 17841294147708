import gql from "graphql-tag";

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($paymentMethodId: String!) {
    deletePaymentMethod(data: { paymentMethodId: $paymentMethodId })
  }
`;

export default {
  DELETE_PAYMENT_METHOD,
};
