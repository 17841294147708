import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Row as RowGrid, Col as ColGrid } from "react-grid-system";

export const Row = styled(RowGrid)`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
`;

export const ColContent = styled.div`
  background: ${(props) => (props.sub ? "transparent" : "white")};
  border-radius: 3px;
  padding: ${(props) => (props.padding ? props.padding : "18px 17px")};
  box-shadow: ${(props) =>
    props.sub ? "unset" : "0px 2px 2px 0px rgba(7,0,47,0.14)"};
  transition: width 0.3s ease-in-out;
`;

export const Col = (props) => {
  const { children } = props;
  return (
    <ColGrid {...props}>
      <ColContent {...props}>{children}</ColContent>
    </ColGrid>
  );
};

Col.defaultProps = {
  sub: false,
};
Col.propTypes = {
  sub: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default { Row, Col };
