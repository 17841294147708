import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import Notifications from "./Notifications/Notifications.component";
import {
  ProfileSection,
  ProfilePhoto,
  Span,
  Initials,
  Icons,
} from "./Profile.style";
// import PP from '../../images/face-placeholder.gif';
import { GET_PARTIAL_PROFILE } from "../../graphql/queries/profile.query";

const Profile = () => {
  const { data, loading } = useQuery(GET_PARTIAL_PROFILE, {
    fetchPolicy: "network-only",
  });
  return (
    <ProfileSection>
      {loading && <span>Loading ...</span>}
      {!loading && data && (
        <>
          <Icons>
            <Notifications userId={data.profile.id} />
          </Icons>
          <Link to="/app/profile">
            <ProfilePhoto>
              <Initials>{`${data.profile.firstName[0]}${data.profile.lastName[0]}`}</Initials>
            </ProfilePhoto>
          </Link>
          <Link to="/app/profile">
            <Span>{`${data.profile.firstName} ${data.profile.lastName}`}</Span>
          </Link>
        </>
      )}
    </ProfileSection>
  );
};

export default Profile;
