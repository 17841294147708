import styled from "styled-components";
import { COLOR_PRIMARY } from "../../../common/styles/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: inline;
  width: 50%;
  margin: 10px 20px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};

  h3 {
    color: #393b3c;
    font-size: 18px;
  }
`;
export const Icon = styled.i`
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: ${COLOR_PRIMARY};
`;
