import gql from "graphql-tag";

export const CREATE_CONTACT_INFORMATION = gql`
  mutation createContactInformation(
    $name: String!
    $description: String!
    $options: Json!
    $status: GeneralStatus!
  ) {
    createContactInformation(
      data: {
        name: $name
        options: $options
        description: $description
        status: $status
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CONTACT_INFORMATION = gql`
  mutation updateUserType(
    $name: String!
    $description: String!
    $options: Json!
    $status: GeneralStatus!
    $id: ID!
  ) {
    updateContactInformation(
      data: {
        name: $name
        options: $options
        description: $description
        status: $status
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_CONTACT_INFORMATION = gql`
  mutation deleteContactInformation($id: ID!) {
    deleteContactInformation(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_CONTACT_INFORMATION,
  UPDATE_CONTACT_INFORMATION,
  DELETE_CONTACT_INFORMATION,
};
