import React from "react";
import PropTypes from "prop-types";
import { Container, CheckBox, CheckMark } from "./CheckBox.style";

const Checkbox = ({ width, margin, label, value, name, onChange }) => {
  return (
    <Container width={width} margin={margin}>
      {label}
      <CheckBox
        type="checkbox"
        defaultChecked={value}
        onChange={onChange}
        name={name}
      />
      <CheckMark className="checkMark" />
    </Container>
  );
};

Checkbox.defaultProps = {
  width: "",
  name: "",
  margin: "",
  value: undefined,
  onChange: undefined,
};

Checkbox.propTypes = {
  width: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  name: PropTypes.string,
};

export default Checkbox;
