import gql from "graphql-tag";

export const GET_POSITION_ROLES = gql`
  query getPositionRoles(
    $order: PositionRoleOrderByInput
    $first: Int
    $skip: Int
    $where: PositionRoleWhereInput
  ) {
    positionRoles(orderBy: $order, first: $first, skip: $skip, where: $where) {
      title
      description
      status
      id
      sportTypes {
        id
        title
      }
      sportCategories {
        id
        title
      }
      baseSkills {
        id
        title
      }
    }
    positionRolesCount: positionRoles(where: $where) {
      id
    }
  }
`;

export const GET_POSITION_ROLES_EXPORT = gql`
  query getPositionRoles(
    $order: PositionRoleOrderByInput
    $where: PositionRoleWhereInput
  ) {
    positionRoles(orderBy: $order, where: $where) {
      title
      description
      status
      id
      sportTypes {
        id
        title
      }
      sportCategories {
        id
        title
      }
      baseSkills {
        id
        title
      }
    }
  }
`;

export const GET_POSITION_ROLE = gql`
  query getPositionRole($id: ID) {
    positionRole(where: { id: $id }) {
      title
      description
      status
      id
      sportTypes {
        id
        title
      }
      sportCategories {
        id
        title
      }
      baseSkills {
        id
        title
      }
    }
  }
`;

export default {
  GET_POSITION_ROLES,
  GET_POSITION_ROLES_EXPORT,
  GET_POSITION_ROLE,
};
