import React, { useState, useContext } from "react";
// import Swal from 'sweetalert2';
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { SingleDatePicker } from "react-dates";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-apollo";
import { GoPrimitiveDot } from "react-icons/go";

// Custom Components
import Button from "../../components/Button/Button.component";
import Modal from "../../components/Modal/Modal.component";
import BorderInput from "../../components/Form/BorderInput/BorderInput.component";
import Error from "../../components/Error/ErrorMessage.component";
import BorderTextArea from "../../components/Form/BorderTextArea/BorderTextArea.component";
import { H2 } from "../../components/Typography/Typography.component";
import { APP_CONTEXT } from "../../utils/context";
import {
  CREATE_EVENT,
  UPDATE_EVENT,
} from "../../graphql/mutations/event.mutation";
import { GET_ALL_ACTIVE_SPORT_CATEGORIES } from "../../graphql/queries/sportCategory.query";
import { GET_ALL_ACTIVE_SPORT_TYPES } from "../../graphql/queries/sportType.query";
import { formatGraphqlError } from "../../utils/api";
import Notification from "../../components/Toast/Toast.component";
import BorderSelect from "../../components/Form/BorderSelect/BorderSelect.component";
import GooglePlaces from "../../components/Form/GooglePlaces/GooglePlaces.component";

const DatesWrapper = styled.div`
  margin-top: 10px;
`;

const EventForm = ({ showModal, setShowModal, data }) => {
  const { userId } = useContext(APP_CONTEXT);
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: data,
  });
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [endDateFocus, setEndDateFocus] = useState(false);

  const { data: sportCategories, loading: sportCategoriesLoading } = useQuery(
    GET_ALL_ACTIVE_SPORT_CATEGORIES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: sportTypes, loading: sportTypesLoading } = useQuery(
    GET_ALL_ACTIVE_SPORT_TYPES,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [updateEvent, { loading: updateLoading }] = useMutation(UPDATE_EVENT);
  const [createEvent, { loading: createLoading }] = useMutation(CREATE_EVENT);

  const submit = (values) => {
    if (data.id !== "") {
      updateEvent({
        variables: {
          where: { id: data.id },
          data: values,
        },
      })
        .then(() => {
          Notification.success({
            title: "Event successfully updated",
          });
          setShowModal(false);
        })
        .catch((err) =>
          Notification.error({
            title: "Update Event Failed",
            message: formatGraphqlError(err),
          })
        );
    } else {
      createEvent({
        variables: {
          data: {
            ...values,
            eventType: "PUBLIC",
            createdByAdmin: {
              connect: {
                id: userId,
              },
            },
          },
        },
      })
        .then(() => {
          Notification.success({
            title: "Event successfully created",
          });
          setShowModal(false);
        })
        .catch((err) =>
          Notification.error({
            title: "Create Event Failed",
            message: formatGraphqlError(err),
          })
        );
    }
  };
  const status = watch("status");

  const getColor = () => {
    switch (status) {
      case "ACTIVE":
        return "#39C568";
      case "PENDING":
        return "#1a0388";
      case "ARCHIVED":
        return "#FF003A";
      default:
        return "#707172";
    }
  };

  const sportCategory = watch("sportCategory.connect.id");

  return (
    <Modal
      open={showModal}
      showClose
      close={() => {
        setShowModal(false);
      }}
      width="25%"
      showOverflow={startDateFocus === true || endDateFocus === true}
    >
      <H2>Add New Event</H2>
      <BorderInput
        name="title"
        type="text"
        label="Event Name"
        register={register}
        customStyle={{ width: "95%", alignItems: "center" }}
        required={{
          required: "please enter a event name",
        }}
        error={errors.title && <Error>{`*${errors.title.message}`}</Error>}
      />
      <BorderTextArea
        name="description"
        type="text"
        label="Event Description"
        register={register}
        customStyle={{ width: "95%", alignItems: "center" }}
        required={{
          required: "please enter a event name",
        }}
        error={
          errors.description && (
            <Error>{`*${errors.description.message}`}</Error>
          )
        }
      />
      <GooglePlaces control={control} name="location" />
      <DatesWrapper>
        <Controller
          control={control}
          name="startDate"
          render={({ onChange, value }) => (
            <SingleDatePicker
              block
              numberOfMonths={1}
              placeholder="Start Date"
              focused={startDateFocus}
              onFocusChange={({ focused }) => setStartDateFocus(focused)}
              date={value}
              onDateChange={(date) => onChange(date)}
            />
          )}
        />
      </DatesWrapper>
      <DatesWrapper>
        <Controller
          control={control}
          name="endDate"
          render={({ onChange, value }) => (
            <SingleDatePicker
              block
              numberOfMonths={1}
              placeholder="End Date"
              focused={endDateFocus}
              onFocusChange={({ focused }) => setEndDateFocus(focused)}
              date={value}
              onDateChange={(date) => onChange(date)}
            />
          )}
        />
      </DatesWrapper>
      {!sportCategoriesLoading && (
        <BorderSelect
          name="sportCategory.connect.id"
          label="Please select a sport category"
          register={register}
          options={sportCategories.sportCategories.map((cat) => {
            return {
              value: cat.id,
              text: cat.title,
            };
          })}
          required={{
            required: "please select a sport category",
          }}
          error={
            errors.sportCategory && (
              <Error message={errors.sportCategory.connect.id.message} />
            )
          }
        />
      )}
      {sportCategory && !sportTypesLoading && (
        <BorderSelect
          name="sportType.connect.id"
          label="Please select a sport type"
          register={register}
          options={sportTypes.sportTypes
            .filter((type) => sportCategory === type.sportCategory.id)
            .map((type) => {
              return {
                value: type.id,
                text: type.title,
              };
            })}
          required={{
            required: "please select a Sport Type",
          }}
          error={
            errors.sportType && (
              <Error message={errors.sportType.connect.id.message} />
            )
          }
        />
      )}
      <BorderSelect
        name="status"
        label="Please select a status"
        register={register}
        options={[
          { value: "DRAFT", text: "Draft" },
          { value: "ACTIVE", text: "Active" },
          { value: "ARCHIVED", text: "Archive" },
          { value: "PENDING", text: "Pending" },
        ]}
        icon={
          <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
        }
        error={errors.id && <Error message={errors.id.message} />}
      />
      <Button
        onClick={handleSubmit(submit)}
        kind="secondary"
        value={data.id !== "" ? "Update Event" : "Create New Event"}
        width="100%"
        margin="20px 0"
        loading={updateLoading || createLoading}
      />
    </Modal>
  );
};

EventForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default EventForm;
