import { useQuery } from "@apollo/react-hooks";
import parse from "html-react-parser";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { COLOR_GREY, COLOR_PRIMARY } from "../../../common/styles/Colors";
import Button from "../../../components/Button/Button.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import TERMS_AND_CONDITIONS from "../../../graphql/queries/termsAndConditions.query";

const DataContainer = styled.div`
  height: 64.5vh;
  overflow: auto;
  padding: 2%;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${COLOR_GREY};
    border-radius: 10px;
    height: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLOR_PRIMARY};
    border-radius: 10px;
    height: 30px;
  }
`;

const TermsAndConditionsPreview = () => {
  const { data, loading } = useQuery(TERMS_AND_CONDITIONS, {
    fetchPolicy: "no-cache",
  });
  const history = useHistory();

  return (
    <>
      <Row>
        <Column sub>
          <DataContainer>
            {data && parse(data.termsAndConditions)}
          </DataContainer>
        </Column>
      </Row>
      <Row>
        <Column sub style={{ alignItems: "flex-end" }}>
          <Button
            type="submit"
            onClick={() =>
              history.push(
                "/app/cms/terms-and-conditions/edit"
              )
            }
            width="177px"
            value="Edit"
            loading={loading}
            kind="secondary"
          />
        </Column>
      </Row>
    </>
  );
};

export default TermsAndConditionsPreview;
