import gql from "graphql-tag";

export const GET_CONTENT_MANAGEMENT_ALL = gql`
  query getCmsEntries(
    $order: CmsEntryOrderByInput
    $first: Int!
    $skip: Int!
    $where: CmsEntryWhereInput
  ) {
    cmsEntries(orderBy: $order, first: $first, skip: $skip, where: $where) {
      title
      body
      status
      id
      type
      publishAt
    }
    cmsEntryCount: cmsEntries(where: $where) {
      id
    }
  }
`;

export const GET_CONTENT_MANAGEMENT_ALL_EXPORT = gql`
  query getCmsEntries(
    $order: CmsEntryOrderByInput
    $where: CmsEntryWhereInput
  ) {
    cmsEntries(orderBy: $order, first: $first, skip: $skip, where: $where) {
      title
      body
      status
      id
      type
      publishAt
    }
  }
`;

export const GET_CONTENT_MANAGEMENT = gql`
  query getCmsEntry($id: ID) {
    cmsEntry(where: { id: $id }) {
      title
      body
      status
      id
      type
      publishAt
      featuredImage
    }
  }
`;

export default {
  GET_CONTENT_MANAGEMENT_ALL,
  GET_CONTENT_MANAGEMENT_ALL_EXPORT,
  GET_CONTENT_MANAGEMENT,
};
