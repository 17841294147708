import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_GREY,
} from "../../common/styles/Colors";

export const TabsHolder = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  height: 40px;
  justify-content: center;
  box-shadow: 1px 0 5px -2px ${COLOR_GREY};
  position: ${(props) => (props.absolute ? "absolute" : "relative")};
  left: ${(props) => (props.absolute ? "0" : "unset")};
  top: ${(props) => (props.absolute ? "0" : "unset")};
`;

export const Back = styled.div`
  display: flex;
  align-self: center;
  position: absolute;
  left: 15px;
  cursor: pointer;
`;

export const Tab = styled.div`
  display: flex;
  width: fit-content;
  border-right: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.2s ease-in-out;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid ${COLOR_SECONDARY};
  }
  &:hover {
    border-bottom: 2px solid ${COLOR_PRIMARY};
  }

  a,
  span {
    padding: 0 18px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
`;
