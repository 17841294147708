/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import { FiX } from "react-icons/fi";
import { useFieldArray } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";

const ContactInformationForm = (props) => {
  const {
    register,
    errors,
    contactInformationEditData,
    contactInformationData,
    setContactInformationData,
    getValues,
    control,
  } = props;

  useEffect(() => {
    if (contactInformationEditData && contactInformationData.name === "") {
      setContactInformationData(contactInformationEditData);
    }
  });

  const { remove } = useFieldArray({
    control,
    name: "options",
  });

  const newContactDetails = () => {
    const contactDetails = {
      value: "",
      type: "",
      uid: uuidv4(),
    };

    const { options } = contactInformationData;
    options.push(contactDetails);

    setContactInformationData({
      ...contactInformationData,
      options,
    });
  };

  const removeContactType = (i) => {
    // set value of the selection
    const { options: oldOptions } = { ...contactInformationData };
    const options = [];

    oldOptions.forEach((o, k) => {
      if (i !== k) {
        options.push({
          value: getValues(`options[${k}].value`),
          type: getValues(`options[${k}].type`),
          uid: o.uid,
        });
      }
    });

    setContactInformationData({
      ...contactInformationData,
      options,
    });

    remove(i);
  };

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>Contact Information Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="name"
            label=""
            type="text"
            value={contactInformationData.name}
            register={register}
            required={{
              required: "please enter a ContactInformation Name",
            }}
            error={errors.name && <Error message={errors.name.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderInput
            header="Description"
            name="description"
            label=""
            type="text"
            value={contactInformationData.description}
            register={register}
            required={{
              required: "please enter a description",
              pattern: {
                value: /[\s\S]*\S[\s\S]/,
                message: "field cannot be empty",
              },
            }}
            error={
              errors.description && (
                <Error message={errors.description.message} />
              )
            }
          />
        </Column>
      </Row>
      <Row>
        <Column sub width="100%">
          <Button
            width="177px"
            onClick={newContactDetails}
            type="button"
            kind="secondary"
            value="Add Contact Type"
          />
        </Column>
      </Row>
      <AnimatePresence>
        {contactInformationData.options.map((cI, i) => {
          const key = `option-${cI.uid ? cI.uid : cI.value.replace(/ /g, "-")}`;
          return (
            <motion.div
              initial={{ opacity: 0, y: -25 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -25 }}
              transition={{ duration: 0.5 }}
              key={key}
            >
              <Row>
                <Column sub width="50%">
                  <BorderInput
                    header="Value"
                    name={`options[${i}].value`}
                    label=""
                    type="text"
                    value={cI.value}
                    register={register}
                    required={{
                      required: "please enter a value",
                    }}
                    error={
                      errors.options &&
                      errors.options.length > i &&
                      errors.options[i] &&
                      errors.options[i].value && (
                        <Error message={errors.options[i].value.message} />
                      )
                    }
                  />
                </Column>
                <Column sub width="50%">
                  <BorderSelect
                    header="Type"
                    name={`options[${i}].type`}
                    label="Please select a type"
                    value={cI.type}
                    register={register}
                    options={[
                      { value: "EMAIL", text: "Email" },
                      { value: "FAX", text: "Fax" },
                      { value: "TELEPHONE", text: "Telephone" },
                      { value: "CELLPHONE", text: "Cellphone" },
                      { value: "LINK", text: "Link" },
                    ]}
                    required={{
                      required: "please select a contact type",
                      pattern: {
                        value: /^[a-zA-Z]+$/,
                        message: "field cannot be empty",
                      },
                    }}
                    error={
                      errors.options &&
                      errors.options.length > i &&
                      errors.options[i] &&
                      errors.options[i].type && (
                        <Error message={errors.options[i].type.message} />
                      )
                    }
                  />
                </Column>
                <Column sub width="100px">
                  <FiX
                    onClick={() => removeContactType(i)}
                    style={{
                      margin: "26px 0 0 0",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  />
                </Column>
              </Row>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Column>
  );
};

ContactInformationForm.defaultProps = {
  errors: {},
  contactInformationEditData: undefined,
};

ContactInformationForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  contactInformationEditData: PropTypes.any,
  contactInformationData: PropTypes.any.isRequired,
  setContactInformationData: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
};

export default ContactInformationForm;
