import styled from "styled-components";

export const CardWrapper = styled.div``;
export const CardHeader = styled.div`
  padding: 18px 17px;
  border-bottom: 2px solid rgb(7 0 47 / 0.2);
`;
export const CardBody = styled.div`
  padding: 0 17px;
  max-height: 415px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;
export const Title = styled.div`
  font-size: 17px;
  color: #635f6c;
  font-weight: 600;
`;
export const Actions = styled.div``;

export default {
  CardWrapper,
  CardHeader,
  CardBody,
  Title,
  Actions,
};
