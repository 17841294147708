import gql from "graphql-tag";

export const GET_NOTIFICATIONS = gql`
  query getNotifications($id: ID, $first: Int!, $skip: Int!) {
    notifications(
      orderBy: createdAt_DESC
      first: $first
      skip: $skip
      where: { userType: ADMIN, adminUser: { id: $id } }
    ) {
      id
      message
      notificationType
      createdAt
      read
    }
    notificationCount: notifications(
      where: { userType: ADMIN, adminUser: { id: $id } }
    ) {
      id
    }
  }
`;

export default { GET_NOTIFICATIONS };
