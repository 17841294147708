import gql from "graphql-tag";

export const CONNECT_SUBSCRIPTION = gql`
  mutation connectSubscription(
    $subscriptionId: String!
    $paymentMethodId: String!
    $userGroup: UserGroup!
    $profileId: ID!
  ) {
    connectSubscription(
      data: {
        subscriptionId: $subscriptionId
        paymentMethodId: $paymentMethodId
        userGroup: $userGroup
        profileId: $profileId
      }
    )
  }
`;

export default {
  CONNECT_SUBSCRIPTION,
};
