import gql from "graphql-tag";

export const CREATE_SPORT_TYPE = gql`
  mutation createSportType(
    $title: String!
    $description: String!
    $status: GeneralStatus!
    $sportCategory: SportCategoryCreateOneInput!
    $levels: SportTypeCreatelevelsInput
  ) {
    createSportType(
      data: {
        title: $title
        description: $description
        status: $status
        sportCategory: $sportCategory
        levels: $levels
      }
    ) {
      id
    }
  }
`;

export const UPDATE_SPORT_TYPE = gql`
  mutation updateUserType(
    $title: String!
    $description: String!
    $status: GeneralStatus!
    $sportCategory: SportCategoryUpdateOneInput!
    $levels: SportTypeUpdatelevelsInput
    $id: ID!
  ) {
    updateSportType(
      data: {
        title: $title
        description: $description
        status: $status
        sportCategory: $sportCategory
        levels: $levels
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_SPORT_TYPE = gql`
  mutation deleteSportType($id: ID!) {
    deleteSportType(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_SPORT_TYPE,
  UPDATE_SPORT_TYPE,
  DELETE_SPORT_TYPE,
};
