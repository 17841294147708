export const PermissionsList = {
  admin: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  staff: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  employer: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  userRoles: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  sport: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  sportCategory: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  skill: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  autoMaticNotification: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  content: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  events: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  expenseClaimType: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
  positionRole: {
    all: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
};

export const PermissionGroups = [
  {
    group: "admin",
    title: "Admin Users",
  },
  {
    group: "staff",
    title: "Staff Users",
  },
  {
    group: "employer",
    title: "Employer Users",
  },
  {
    group: "userRoles",
    title: "User Type",
  },
  {
    group: "sport",
    title: "Sport",
  },
  {
    group: "sportCategory",
    title: "Sport Category",
  },
  {
    group: "skill",
    title: "Skill",
  },
  {
    group: "autoMaticNotification",
    title: "Manage Notifications",
  },
  {
    group: "content",
    title: "Manage CMS",
  },
  {
    group: "events",
    title: "Manage Events",
  },
  {
    group: "expenseClaimType",
    title: "Manage Expense Claim Types",
  },
  {
    group: "positionRole",
    title: "Manage Position Roles",
  },
];

export default {
  PermissionGroups,
  PermissionsList,
};
