/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import Error from "../../../components/Error/ErrorMessage.component";

const SkillForm = (props) => {
  const { register, errors, skillEditData, skillData, setSkillData } = props;

  useEffect(() => {
    if (skillEditData && skillData.title === "") {
      setSkillData(skillEditData);
    }
  });

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>Skill Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="title"
            label=""
            type="text"
            value={skillData.title}
            register={register}
            required={{
              required: "please enter a Skill Name",
            }}
            error={errors.title && <Error message={errors.title.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderInput
            header="Description"
            name="description"
            label=""
            type="text"
            value={skillData.description}
            register={register}
            error={
              errors.description && (
                <Error message={errors.description.message} />
              )
            }
          />
        </Column>
      </Row>
    </Column>
  );
};

SkillForm.defaultProps = {
  errors: {},
  skillEditData: undefined,
};

SkillForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  skillEditData: PropTypes.any,
  skillData: PropTypes.any.isRequired,
  setSkillData: PropTypes.func.isRequired,
};

export default SkillForm;
