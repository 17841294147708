import styled from "styled-components";
import { COLOR_GREY2, COLOR_SECONDARY } from "./components/Colors";

export const TableHeader = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  padding: 16px 24px 18px 24px;
  background-color: white;
  border-bottom: 2px solid #e3e3e3;
  border-radius: 3px 3px 0 0;
  align-items: center;
  box-sizing: border-box;
`;

export const PageSelect = styled.select`
  border: 0;
  background: white;
  margin-left: 15px;
  color: ${COLOR_GREY2};
  padding: 5px;
  font-size: 16px;
  option {
    color: ${COLOR_GREY2};
    font-size: 16px;
  }
  outline: none;
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const PaginationBtnContainer = styled.span`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TableTitle = styled.div`
  display: flex;
  width: 50%;
  font-size: 17px;
  color: #635f6c;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const TableFilterIcons = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  > svg,
  span > svg {
    margin: 10px;
    color: #888888;
    transition: color 0.1s ease-in-out;
    &:hover,
    &.active {
      color: ${COLOR_SECONDARY};
    }
  }
`;

export const StyledTable = styled.table`
  min-width: 100%;
  background-color: white;
  border-collapse: separate;
  border-spacing: 0;
`;

export const TableIcons = styled.div`
  color: #adacb6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin: 10px;
    margin-left: 0;
    cursor: black pointer;
  }
  span {
    text-transform: capitalize;
  }
`;

export const RowHeader = styled.tr`
  min-width: 100%;
`;

export const ColumnHeader = styled.th`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #393b3c;
  text-align: left;
  height: 50px;
  span {
    display: flex;
    align-items: center;
  }
  position: sticky;
  cursor: pointer;
  top: 0;
  background: white;
  border-bottom: 2px solid #d8d8d8;
  &:nth-child(1) {
    padding: 0 25px;
  }
`;

export const Row = styled.tr`
  border-bottom: 2px solid #e3e3e3;
  min-width: 100%;
  transition: background-color 0.3s ease-in-out;
  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Column = styled.td`
  margin: 0;
  font-size: 14px;
  color: #707172;
  line-height: 16px;
  height: 64px;
  border-bottom: 2px solid #e3e3e3;
  &:nth-child(1) {
    padding: 0 25px;
  }
  word-wrap: break-word;
  white-space: normal;
`;

export const DropDownHolder = styled.span`
  position: relative;
  margin: 0;
`;
export const DropDown = styled.div`
  position: absolute;
  width: ${(props) => (props.width ? props.width : "300px")};
  top: 100%;
  right: 0;
  background-color: white;
  margin: 0;
  opacity: ${(props) => (props.open ? "1" : "0")};
  display: ${(props) => (props.open ? "block" : "none")};
  height: auto;
  overflow: ${(props) => (props.open ? "visible" : "hidden")};
  transition: opacity 0.15s ease-in-out;
  z-index: 10;
`;

export const DropDownDismiss = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

export const DropDownContent = styled.div`
  height: 100%;
  border-radius: 4px;
  padding: 15px;
  border: 2px solid #e3e3e3;
`;

export const TableContainer = styled.div`
  overflow: auto;
  position: relative;
  width: 100%;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: white;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  scrollbar-face-color: rgba(0, 0, 0, 0.3); /* Firefox 63 compatibility */
  scrollbar-track-color: rgba(0, 0, 0, 0.2); /* Firefox 63 compatibility */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
`;
