/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import SingleDateTimePicker from "../../../components/Form/SingleDateTimePicker/SingleDateTimePicker.component";
import Redactor from "../../../components/Form/Redactor/Redactor.component";
import Error from "../../../components/Error/ErrorMessage.component";

const CmsEntryForm = (props) => {
  const {
    register,
    errors,
    cmsEntryEditData,
    cmsEntryData,
    setCmsEntryData,
    editorState,
    setEditorState,
    setValue,
  } = props;

  const onExpireChange = (date) => {
    setCmsEntryData({
      ...cmsEntryData,
      expiresAt: date,
    });
  };

  const onPublishChange = (date) => {
    setCmsEntryData({
      ...cmsEntryData,
      publishAt: date,
    });
  };

  useEffect(() => {
    if (cmsEntryEditData && cmsEntryData.title === "") {
      if (cmsEntryEditData.expiresAt) {
        cmsEntryEditData.expiresAt = moment(cmsEntryEditData.expiresAt);
      }
      if (cmsEntryEditData.publishAt) {
        cmsEntryEditData.publishAt = moment(cmsEntryEditData.publishAt);
      }
      if (cmsEntryEditData.type) {
        setValue("type", cmsEntryEditData.type);
      }
      setCmsEntryData(cmsEntryEditData);
    }
  });

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>Content</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Name"
            name="title"
            label=""
            type="text"
            value={cmsEntryData.title}
            register={register}
            required={{
              required: "please enter a CmsEntry Name",
            }}
            error={errors.title && <Error message={errors.title.message} />}
          />
        </Column>
        <Column width="50%" sub>
          <BorderSelect
            header="Content Type"
            name="type"
            label="Please select a content type"
            value={cmsEntryData.type}
            register={register}
            options={[
              { value: "NEWS", text: "News" },
              { value: "ARTICLE", text: "Article" },
            ]}
            required={{
              required: "please select a content type",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={
              errors.contentType && (
                <Error message={errors.contentType.message} />
              )
            }
          />
        </Column>
      </Row>
      <Row>
        <Column sub width="50%">
          <SingleDateTimePicker
            header="Publish at"
            name="publishAt"
            date={cmsEntryData.publishAt}
            onDateChange={onPublishChange}
          />
        </Column>
        <Column sub width="50%">
          <SingleDateTimePicker
            header="Expire at"
            name="expireAt"
            date={cmsEntryData.expiresAt}
            onDateChange={onExpireChange}
          />
        </Column>
      </Row>
      <Row>
        <Column sub width="100%">
          <Redactor
            onChange={setEditorState}
            value={editorState}
            header="Description"
          />
        </Column>
      </Row>
    </Column>
  );
};

CmsEntryForm.defaultProps = {
  errors: {},
  cmsEntryEditData: undefined,
};

CmsEntryForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  cmsEntryEditData: PropTypes.any,
  cmsEntryData: PropTypes.any.isRequired,
  setCmsEntryData: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setEditorState: PropTypes.func.isRequired,
};

export default CmsEntryForm;
