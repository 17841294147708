import React, { useState, useEffect } from "react";
import moment from "moment";
import { useQuery, useMutation, useLazyQuery } from "react-apollo";
import Swal from "sweetalert2";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { RiMastercardLine, RiVisaLine } from "react-icons/ri";
import { Row, Column } from "../../components/Grid/Grid.component";
import CheckoutForm from "../../components/Stripe/CheckoutForm.component";
// import UpdateSubscription from "../../components/Stripe/UpdateSubscription.component";
import LoadingFullScreen from "../../components/Animation/Loading/LoadingFullScreen.component";
import { YourAccount } from "../../common/styles/YourAccount";
import {
  Logo,
  LogoHolder,
  Card,
  CardDetails,
  CardSettings,
  CardExpiry,
} from "../../components/Stripe/ManageAccount.style";

import { ACCESS_ACCOUNT } from "../../graphql/queries/accessAccount.query";
// import { formatGraphqlError } from "../../utils/api";
import Notification from "../../components/Toast/Toast.component";
import { H1, H2, H3 } from "../../components/Typography/Typography.component";
import Button from "../../components/Button/Button.component";
import { CANCEL_SUBSCRIPTION } from "../../graphql/mutations/stripe/cancelSubscription.mutation";
import { DELETE_PAYMENT_METHOD } from "../../graphql/mutations/stripe/deletePaymentMethod.mutation";
import { UPDATE_DEFAULT_PAYMENT_METHOD } from "../../graphql/mutations/stripe/updateDefaultPaymentMethod.mutation";
import { PAYMENT_METHODS } from "../../graphql/queries/paymentMethods.query";
import Modal from "../../components/Modal/Modal.component";
import AddNewCard from "../../components/Stripe/AddNewCard";

const logo = require("../../images/icon.svg");

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Profile = () => {
  const { token } = useParams();
  const [subscribed, setSubscribed] = useState();
  const [addNewCard, setAddNewCard] = useState(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState();
  const [showAddSubscription, setShowAddSubscription] = useState();
  // const [showSubscriptions, setShowSubscriptions] = useState(false);
  const { data, loading, error } = useQuery(ACCESS_ACCOUNT, {
    fetchPolicy: "no-cache",
    pollInterval: 15000,
    variables: {
      id: token,
    },
  });
  const [getPaymentMethods, paymentMethods] = useLazyQuery(PAYMENT_METHODS, {
    fetchPolicy: "no-cache",
  });
  const [cancelSubscription, cancelSubscriptionRes] = useMutation(
    CANCEL_SUBSCRIPTION
  );
  const [updateDefaultPaymentMethod] = useMutation(
    UPDATE_DEFAULT_PAYMENT_METHOD
  );

  const [deletePaymentMethod] = useMutation(DELETE_PAYMENT_METHOD);

  const deletePaymentMethodHandler = (paymentMethodId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this payment method.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete It!",
    }).then((result) => {
      if (result.value) {
        deletePaymentMethod({
          variables: {
            paymentMethodId,
          },
        })
          .then(() => {
            if (data.accessAccount.userGroup === "EMPLOYER") {
              getPaymentMethods({
                variables: {
                  stripeId: data.accessAccount.user.employer.stripeId,
                },
              });
            }

            if (data.accessAccount.userGroup === "STAFF") {
              getPaymentMethods({
                variables: {
                  stripeId: data.accessAccount.user.staff.stripeId,
                },
              });
            }
            Notification.success({
              title: "Card Deleted",
              message: "",
            });
          })
          .catch(() => {
            Notification.error({
              title: "Card Deletion Failed",
              message: "",
            });
          });
      }
    });
  };

  const updateDefaultPaymentMethodHandler = (paymentMethodId) => {
    Swal.fire({
      title: "Are you sure",
      text: "You want to make this card you default payment method?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let variables;
        if (data.accessAccount.userGroup === "EMPLOYER") {
          variables = {
            paymentMethodId,
            stripeId: data.accessAccount.user.employer.stripeId,
          };
        } else {
          variables = {
            paymentMethodId,
            stripeId: data.accessAccount.user.staff.stripeId,
          };
        }

        updateDefaultPaymentMethod({
          variables,
        })
          .then(() => {
            if (data.accessAccount.userGroup === "EMPLOYER") {
              getPaymentMethods({
                variables: {
                  stripeId: data.accessAccount.user.employer.stripeId,
                },
              });
            }

            if (data.accessAccount.userGroup === "STAFF") {
              getPaymentMethods({
                variables: {
                  stripeId: data.accessAccount.user.staff.stripeId,
                },
              });
            }
            Notification.success({
              title: "Default Payment Method Updated",
              message: "",
            });
          })
          .catch(() => {
            Notification.error({
              title: "Update Default Payment Method Failed",
              message: "",
            });
          });
      }
    });
  };

  const cancelSubscriptionHandler = () => {
    let variables;

    Swal.fire({
      title: "Are you sure?",
      text: "You can always resubscribe at a later stage.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel Subscription!",
    }).then((result) => {
      if (result.value) {
        if (data.accessAccount.userGroup === "EMPLOYER") {
          variables = {
            subscriptionId: subscribed.id,
            profileId: data.accessAccount.user.employer.id,
            userGroup: data.accessAccount.userGroup,
          };
        } else {
          variables = {
            subscriptionId: subscribed.id,
            profileId: data.accessAccount.user.staff.id,
            userGroup: data.accessAccount.userGroup,
          };
        }

        cancelSubscription({
          variables,
        })
          .then(() => {
            setSubscribed(null);
            Notification.success({
              title: "Subscribtion Canceled",
              message: "",
            });
          })
          .catch(() => {
            Notification.error({
              title: "Cancel Subscribtion Failed",
              message: "",
            });
          });
      }
    });
  };

  useEffect(() => {
    if (data) {
      if (data.accessAccount.userGroup === "EMPLOYER") {
        if (data.accessAccount.user.employer) {
          if (data.accessAccount.user.employer.subscriptionId) {
            setSubscribed(data.accessAccount.subscription);
          }
          getPaymentMethods({
            variables: {
              stripeId: data.accessAccount.user.employer.stripeId,
            },
          });
        }
      }

      if (data.accessAccount.userGroup === "STAFF") {
        if (data.accessAccount.user.staff) {
          if (data.accessAccount.user.staff.subscriptionId) {
            setSubscribed(data.accessAccount.subscription);
          }

          getPaymentMethods({
            variables: {
              stripeId: data.accessAccount.user.staff.stripeId,
            },
          });
        }
      }
    }
  }, [data, getPaymentMethods]);

  if (!data && loading) {
    return <LoadingFullScreen />;
  }

  if (error) {
    return (
      <>
        <YourAccount />
        <Row style={{ height: "100vh", overflow: "auto" }}>
          <Column>
            <Row>
              <Column sub>
                <LogoHolder>
                  <Logo src={logo} />
                </LogoHolder>
              </Column>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              Please navigate to the app and click on billing
            </Row>
          </Column>
        </Row>
      </>
    );
  }

  return (
    <>
      <YourAccount />
      <Row style={{ height: "100vh", overflow: "auto" }}>
        <Column>
          <Row>
            <Column sub>
              <LogoHolder>
                <Logo src={logo} />
              </LogoHolder>
            </Column>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Column sub noPad>
              <H1>Personal Info:</H1>
            </Column>
            <Column sub noPad style={{ textAlign: "right" }}>
              <H2>
                {data.accessAccount.userGroup === "STAFF"
                  ? "Staff Profile"
                  : "Employer Profile"}
              </H2>
            </Column>
          </Row>
          <Row>
            <Column sub noPad>
              <H3>
                {"Name: "}
                {`${data.accessAccount.user.firstName} ${data.accessAccount.user.lastName}`}
              </H3>
            </Column>
          </Row>
          <Row>
            <Column sub noPad>
              <H3>
                {"Email: "}
                {data.accessAccount.user.email}
              </H3>
            </Column>
          </Row>
          <Row>
            <Column sub width="50%" noPad>
              <H1>Subscription:</H1>
            </Column>
          </Row>
          {subscribed ? (
            <>
              <Row>
                <Column
                  sub
                  width="50%"
                  noPad
                  style={{ textTransform: "capitalize" }}
                >
                  <H3>
                    {"Status: "}
                    {subscribed.status}
                  </H3>
                </Column>
              </Row>
              <Row>
                <Column
                  sub
                  width="50%"
                  noPad
                  style={{ textTransform: "capitalize" }}
                >
                  <H3>
                    {"Plan: "}
                    {subscribed.plan.nickname}
                  </H3>
                </Column>
              </Row>
              <Row>
                <Column sub width="50%" noPad>
                  <H3>
                    {"Renews On: "}
                    {moment(1000 * subscribed.current_period_end).format(
                      "MM/YYYY"
                    )}
                  </H3>
                </Column>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Column sub noPad>
                  <H2 style={{ marginBottom: "15px" }}>
                    No subscriptions active yet
                  </H2>
                </Column>
              </Row>
              <Row>
                <Column sub style={{ textAlign: "center" }}>
                  <Button
                    width="100%"
                    onClick={() => setShowAddSubscription(true)}
                    type="button"
                    value="Add Subscribtion"
                  />
                </Column>
              </Row>
            </>
          )}
          <Row>
            <Column sub noPad>
              <H1 style={{ marginBottom: "15px" }}>Manage Payment Methods:</H1>
            </Column>
          </Row>
          {paymentMethods.data &&
            paymentMethods.data.paymentMethods.list.data
              .filter(
                (paymentMethod) =>
                  paymentMethods.data.paymentMethods.defaultPaymentMethod ===
                  paymentMethod.id
              )
              .map((paymentMethod) => {
                return (
                  <Row>
                    <Card>
                      <CardDetails>
                        {paymentMethod.card.brand === "visa" ? (
                          <RiVisaLine
                            style={{ fontSize: "30px", marginRight: "15px" }}
                          />
                        ) : (
                          <RiMastercardLine style={{ fontSize: "30px" }} />
                        )}
                        <span>
                          {`•••• •••• •••• ${paymentMethod.card.last4}`}
                        </span>
                      </CardDetails>
                      <CardSettings>
                        <AiFillStar
                          style={{ fontSize: "25px", color: "#F53544" }}
                        />
                      </CardSettings>
                      <CardExpiry>
                        {`Expiry ${
                          paymentMethod.card.exp_month < 10
                            ? `0${paymentMethod.card.exp_month}`
                            : paymentMethod.card.exp_month
                        }/${paymentMethod.card.exp_year.toString().slice(-2)}`}
                      </CardExpiry>
                    </Card>
                  </Row>
                );
              })}
          {paymentMethods.data && (
            <Row>
              <Column sub style={{ textAlign: "center" }}>
                <Button
                  width="100%"
                  onClick={() => setShowPaymentMethods(true)}
                  type="button"
                  value="Manage Payment Methods"
                />
              </Column>
            </Row>
          )}

          <Modal
            open={showPaymentMethods}
            showClose
            close={() => {
              setShowPaymentMethods(false);
            }}
          >
            {!addNewCard ? (
              <>
                <H2 style={{ marginBottom: "15px" }}>Manage Payment Methods</H2>
                {paymentMethods.data &&
                  paymentMethods.data.paymentMethods.list.data.map(
                    (paymentMethod) => {
                      const {
                        defaultPaymentMethod,
                      } = paymentMethods.data.paymentMethods;
                      return (
                        <Row>
                          <Card modal>
                            <CardDetails>
                              {paymentMethod.card.brand === "visa" ? (
                                <RiVisaLine
                                  style={{
                                    fontSize: "30px",
                                    marginRight: "15px",
                                  }}
                                />
                              ) : (
                                <RiMastercardLine
                                  style={{ fontSize: "30px" }}
                                />
                              )}
                              <span>
                                {`•••• •••• •••• ${paymentMethod.card.last4}`}
                              </span>
                            </CardDetails>
                            <CardSettings>
                              {defaultPaymentMethod === paymentMethod.id ? (
                                <AiFillStar
                                  style={{
                                    fontSize: "25px",
                                    color: "#F53544",
                                  }}
                                />
                              ) : (
                                <AiOutlineStar
                                  style={{
                                    fontSize: "25px",
                                  }}
                                  onClick={() =>
                                    updateDefaultPaymentMethodHandler(
                                      paymentMethod.id
                                    )
                                  }
                                />
                              )}
                              {paymentMethods.data.paymentMethods
                                .defaultPaymentMethod !== paymentMethod.id && (
                                <FiTrash
                                  style={{ fontSize: "25px" }}
                                  onClick={() =>
                                    deletePaymentMethodHandler(paymentMethod.id)
                                  }
                                />
                              )}
                            </CardSettings>
                            <CardExpiry>
                              {`Expiry ${
                                paymentMethod.card.exp_month < 10
                                  ? `0${paymentMethod.card.exp_month}`
                                  : paymentMethod.card.exp_month
                              }/${paymentMethod.card.exp_year
                                .toString()
                                .slice(-2)}`}
                            </CardExpiry>
                          </Card>
                        </Row>
                      );
                    }
                  )}
                <Row>
                  <Column sub width="100%" noPad>
                    <Button
                      width="100%"
                      onClick={() => setAddNewCard(true)}
                      type="button"
                      value="Add New Card"
                      loading={loading}
                    />
                    <Button
                      width="100%"
                      onClick={() => setShowPaymentMethods(false)}
                      type="button"
                      value="Close"
                      loading={loading}
                    />
                  </Column>
                </Row>
              </>
            ) : (
              <>
                <H2 style={{ marginBottom: "15px" }}>Add new Card</H2>
                <Elements stripe={stripePromise}>
                  <AddNewCard
                    stripeId={
                      data.accessAccount.userGroup === "EMPLOYER"
                        ? data.accessAccount.user.employer.stripeId
                        : data.accessAccount.user.staff.stripeId
                    }
                    getPaymentMethods={getPaymentMethods}
                    stripePromise={stripePromise}
                    setAddNewCard={setAddNewCard}
                  />
                </Elements>
              </>
            )}
          </Modal>

          {!subscribed && (
            <Modal
              open={showAddSubscription}
              showClose
              close={() => {
                setShowAddSubscription(false);
              }}
              fullScreen
            >
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  data={data}
                  setSubscribed={setSubscribed}
                  paymentMethods={paymentMethods}
                />
              </Elements>
            </Modal>
          )}
          {subscribed && (
            <Row style={{ marginTop: "25px" }}>
              <Column sub noPad>
                <Button
                  width="100%"
                  type="submit"
                  kind="secondary"
                  value="Cancel Subscription"
                  loading={cancelSubscriptionRes.loading}
                  onClick={cancelSubscriptionHandler}
                />
              </Column>
            </Row>
          )}
          {/* 
              ** MANAGE SUBSCRIPTION DISABLED FOR NOW. NO API INTEGRATION **
            <Row style={{ marginTop: "25px" }}>
              <Column sub noPad>
                <Button
                  width="100%"
                  type="submit"
                  value="Manage Subscriptions"
                  onClick={() => setShowSubscriptions(true)}
                />
                <Button
                  width="100%"
                  type="submit"
                  kind="secondary"
                  value="Cancel Subscription"
                  loading={cancelSubscriptionRes.loading}
                  onClick={cancelSubscriptionHandler}
                />
                <Modal
                  open={showSubscriptions}
                  showClose
                  close={() => {
                    setShowSubscriptions(false);
                  }}
                >
                  <H2 style={{ marginBottom: "15px" }}>Manage Subscriptions</H2>
                  <Elements stripe={stripePromise}>
                    <UpdateSubscription
                      data={data}
                      setSubscribed={setSubscribed}
                      paymentMethods={paymentMethods}
                      noPad
                      hideNewCard
                    />
                  </Elements>
                  <Row>
                    <Column sub width="100%" noPad>
                      <Button
                        width="100%"
                        onClick={() => setShowSubscriptions(false)}
                        type="button"
                        value="Cancel"
                        loading={loading}
                      />
                    </Column>
                  </Row>
                </Modal>
              </Column>
            </Row> */}
        </Column>
      </Row>
    </>
  );
};

export default Profile;
