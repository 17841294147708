import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import { GoPrimitiveDot } from "react-icons/go";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Swal from "sweetalert2";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Button from "../../../components/Button/Button.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
import { DELETE_AUTOMATIC_NOTIFICATION } from "../../../graphql/mutations/automaticNotification.mutation";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  GET_AUTOMATIC_NOTIFICATIONS,
  GET_AUTOMATIC_NOTIFICATIONS_EXPORT,
} from "../../../graphql/queries/automaticNotification.query";
import { formatGraphqlError } from "../../../utils/api";

const AutomaticNotificationAll = () => {
  const { setActiveSideNav, setPageTitle, userId } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(
    GET_AUTOMATIC_NOTIFICATIONS,
    {
      variables: { order: "name_ASC" },
      fetchPolicy: "no-cache",
    }
  );
  const [variables, setVariables] = useState({
    where: {
      AND: [{ NOT: { status: "ARCHIVED" } }, { createdBy: { id: userId } }],
    },
  });
  const [deleteAutomaticNotification] = useMutation(
    DELETE_AUTOMATIC_NOTIFICATION
  );
  const [
    getAutomaticNotificationsExport,
    automaticNotificationsExport,
  ] = useLazyQuery(GET_AUTOMATIC_NOTIFICATIONS_EXPORT);

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Manage Automatic Notifications");
  });

  const handleDelete = (e, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteAutomaticNotification({
          variables: {
            id: row.original.id,
          },
        })
          .then(() => {
            Swal.fire(
              "Deleted!",
              "Automatic Notification has been deleted",
              "success"
            );
            refetch({
              variables: {
                ...variables,
                first: 50,
                skip: 0,
              },
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Automatic Notification Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const getColor = (status) => {
    switch (status) {
      case "ON":
        return "#39C568";
      case "OFF":
        return "#FF003A";
      case "ARCHIVE":
        return "#FF003A";
      default:
        return "#FF003A";
    }
  };

  const HeaderInfo = [
    {
      Header: "Name",
      accessor: "name",
      id: "name",
      search: true,
    },
    {
      Header: "Description",
      accessor: "description",
      id: "description",
      search: true,
    },
    {
      Header: "Message",
      accessor: "message",
      id: "message",
      search: true,
    },
    {
      Header: "Status",
      id: "status",
      Cell: ({ row }) => (
        <TableIcons>
          <GoPrimitiveDot
            style={{ fontSize: "25px", color: getColor(row.original.status) }}
          />
          <span>{row.original.status.toLowerCase()}</span>
        </TableIcons>
      ),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <Link
            to={`/app/system-configuration/notification-manager/${row.original.id}`}
          >
            <FiEdit
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          </Link>
          <FiTrash
            onClick={(e) => handleDelete(e, row)}
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
    {
      header: "Status:",
      accessor: "status",
      placeholder: "Please select a Status",
      type: "select",
      options: [
        { value: "ACTIVE", text: "Active" },
        { value: "ARCHIVE", text: "Archive" },
        { value: "DRAFT", text: "Draft" },
      ],
    },
  ];

  return (
    <>
      <PageHeader title="Manage Automatic Notifications">
        <Link to="/app/system-configuration/notification-manager/new">
          <Button
            onClick={() => {}}
            kind="secondary"
            value="New Automatic Notification"
            width="auto"
            margin="10px 0 10px 10px"
          />
        </Link>
      </PageHeader>
      <Table
        columns={HeaderInfo}
        data={(data && data.automaticNotifications) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={
          (data &&
            data.automaticNotificationsCount &&
            data.automaticNotificationsCount.length) ||
          0
        }
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Automatic Notifications"
        tableOptions={["search", "download", "columns", "filter"]}
        filters={filters}
        downloadData={automaticNotificationsExport}
        getDownloadData={getAutomaticNotificationsExport}
        downloadAccessor={
          automaticNotificationsExport.data
            ? automaticNotificationsExport.data.automaticNotifications
            : []
        }
        fileName="AutomaticNotifications"
      />
    </>
  );
};

export default AutomaticNotificationAll;
