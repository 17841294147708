import styled from "styled-components";

export const InputHeader = styled.div`
  margin-bottom: 10px;
  color: #63636d;
  font-size: 14px;
  font-weight: 400;
`;

export const Container = styled.div`
  margin: 0;
`;

export const Validation = styled.div`
  width: 100%;
  color: grey;
  font-size: 12px;
  line-height: auto;
  margin: 0;
  padding-left: 5px;
  margin-bottom: 1%;
  overflow: visible;
  white-space: wrap;
`;

export const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  padding: 10px;
  border: 1px solid #bdc2ca;
  border-radius: 4px;
  color: #63636d;
  font-size: 16px;
`;

export const PlacesWrapper = styled.div`
  position: relative;
  .autocomplete-dropdown-container {
    position: absolute;
    top: 105%;
    width: 100%;
    background-color: white;
    z-index: 99;
  }
`;
