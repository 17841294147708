import gql from "graphql-tag";

export const CREATE_SKILL = gql`
  mutation createSkill(
    $title: String!
    $description: String!
    $status: GeneralStatus!
  ) {
    createSkill(
      data: { title: $title, description: $description, status: $status }
    ) {
      id
    }
  }
`;

export const UPDATE_SKILL = gql`
  mutation updateUserType(
    $title: String!
    $description: String!
    $status: GeneralStatus!
    $id: ID!
  ) {
    updateSkill(
      data: { title: $title, description: $description, status: $status }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation deleteSkill($id: ID!) {
    deleteSkill(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_SKILL,
  UPDATE_SKILL,
  DELETE_SKILL,
};
