import styled from "styled-components";
import { COLOR_PRIMARY } from "../Colors";

export const H1 = styled.h1`
  font-size: 28px;
  color: ${COLOR_PRIMARY};
  font-weight: 400;
  margin: 0;
`;

export const H2 = styled.h2`
  font-size: 20px;
  color: ${COLOR_PRIMARY};
  font-weight: 400;
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: 16px;
  color: ${COLOR_PRIMARY};
  font-weight: 400;
  margin: 0;
`;

export const H4 = styled.h4`
  font-size: 14px;
  color: ${COLOR_PRIMARY};
  font-weight: 400;
  margin: 0;
`;

export const P = styled.p``;
export const Span = styled.span``;

export const XMP = styled.pre`
  font-family: lato;
  width: 100%;
  overflow-x: auto;
  padding: 20px;
  background-color: ${COLOR_PRIMARY};
  border-radius: 4px;
  color: white;
`;
