/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AnimatePresence } from "framer-motion";
import { FiX } from "react-icons/fi";
import { useFieldArray } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  InputHeader,
  StyledInput,
  Input,
  Validation,
  ChipsHolder,
  Chip,
  ChipInput,
  Hint,
} from "./InputChips.style";

const InputChips = ({
  header,
  name,
  label,
  error,
  validationLabel,
  register,
  defaultValue,
  required,
  control,
}) => {
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState([]);

  const { remove } = useFieldArray({
    control,
    name,
  });

  const isInArr = (val) => {
    const found = selected.filter((option) => option.value === val);
    if (found.length > 0) {
      return true;
    }
    return false;
  };

  const removeItem = (item) => {
    const items = Array.from(selected);
    const index = items.indexOf(item);
    if (items.length === 1) {
      setSelected([]);
    } else if (index > -1) {
      items.splice(index, 1);
      setSelected(items);
      remove(index);
    }
  };

  const onKeyPressEvent = (event) => {
    if (event.keyCode === 13) {
      if (isInArr(value)) {
        setValue("");
        return;
      }
      const newSelected = Array.from(selected);
      newSelected.push({
        value,
        key: uuidv4(),
      });
      setSelected(newSelected);
      setValue("");
    }
  };

  useEffect(() => {
    if (defaultValue.length > 0) {
      setSelected(
        defaultValue.map((item) => ({
          value: item,
          key: uuidv4(),
        }))
      );
    }
  }, [defaultValue]);

  return (
    <Container>
      <InputHeader>{header}</InputHeader>
      <Input>
        <StyledInput
          label={label}
          placeholder={label}
          onChange={(e) => setValue(e.currentTarget.value)}
          value={value}
          onKeyDown={onKeyPressEvent}
        />
      </Input>
      <ChipsHolder>
        <AnimatePresence>
          {selected.map((item, i) => {
            return (
              <Chip
                key={item.key}
                onClick={() => removeItem(item)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {item.value}
                <ChipInput
                  name={`${name}[${i}]`}
                  defaultValue={item.value}
                  ref={register(required)}
                  readOnly
                />
                <FiX />
              </Chip>
            );
          })}
        </AnimatePresence>
        {selected.length === 0 && <Hint>* press enter to add item</Hint>}
      </ChipsHolder>
      {!error && validationLabel && <Validation>*{validationLabel}</Validation>}
      <div>{error}</div>
    </Container>
  );
};

InputChips.defaultProps = {
  header: "",
  label: PropTypes.string,
  error: undefined,
  required: undefined,
  validationLabel: "",
  defaultValue: [],
};

InputChips.propTypes = {
  // input field header
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.object,
  label: PropTypes.string,
  defaultValue: PropTypes.array,
  // react-hook-form validation object
  // error object
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  // specify type of input expected below input field
  validationLabel: PropTypes.string,
};

export default InputChips;
