import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useSubscription } from "react-apollo";
import { APP_CONTEXT } from "../../utils/context";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../components/Grid/Grid.component";
import Search from "../../components/Search/Search.component";
import Chat from "../../components/Chat/Chat.component";
import Contacts from "../../components/Contacts/Contacts.component";

const ChatHolder = ({
  activeSideNav,
  pageTitle,
  GET_CHAT_GROUPS,
  SUBSCRIBE_TO_CHAT_MESSAGES,
  variables,
}) => {
  const getChatGroups = useQuery(GET_CHAT_GROUPS, {
    fetchPolicy: "no-cache",
    variables,
  });
  const { data: newMessage } = useSubscription(SUBSCRIBE_TO_CHAT_MESSAGES, {
    fetchPolicy: "no-cache",
    shouldResubscribe: true,
  });
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [search, setSearch] = useState("");
  const [activeChat, setActiveChat] = useState(null);
  const [groupChats, setGroupChats] = useState([]);
  const [newMessageFromSocket, setNewMessageFromSocket] = useState(null);
  useEffect(() => {
    setActiveSideNav(activeSideNav);
    setPageTitle(pageTitle);
  });

  if (
    groupChats.length === 0 &&
    !getChatGroups.loading &&
    getChatGroups.data &&
    getChatGroups.data.chatGroups.length > 0
  ) {
    setGroupChats(getChatGroups.data.chatGroups);
  }

  useEffect(() => {
    if (groupChats.length > 0 && !activeChat) {
      const filteredMessages = groupChats.filter((c) => c.messages.length > 0);
      setActiveChat(filteredMessages[0]);
    }
  }, [groupChats, activeChat]);

  useEffect(() => {
    if (newMessage) {
      const addMessage = (message) => {
        if (message && message.chat && message.chat.node) {
          const { node } = { ...message.chat };
          setNewMessageFromSocket(node);
          setGroupChats((prevState) => {
            const { chatGroup } = { ...node };
            let tempChatGroups = Array.from(prevState);
            const exists = tempChatGroups.filter((c) => c.id === chatGroup.id);
            if (exists.length > 0) {
              tempChatGroups = tempChatGroups.map((cG) => {
                const tempCG = { ...cG };
                if (cG.id === chatGroup.id) {
                  tempCG.messages = [node];
                }
                return tempCG;
              });
            } else {
              chatGroup.messages = [node];
              tempChatGroups.push(chatGroup);
            }
            return tempChatGroups;
          });
        }
      };
      addMessage(newMessage);
    }
  }, [newMessage]);

  return (
    <>
      <PageHeader title={pageTitle} />
      <Row style={{ height: "75vh" }}>
        <Column width="25%" style={{ margin: 0 }}>
          <Search
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Contacts
            contacts={groupChats}
            setActiveChat={setActiveChat}
            search={search}
          />
        </Column>
        <Column
          width="75%"
          sub
          style={{ margin: 0, background: "none", padding: 0 }}
        >
          <Chat
            activeChat={activeChat}
            newMessageFromSocket={newMessageFromSocket}
            setNewMessageFromSocket={setNewMessageFromSocket}
          />
        </Column>
      </Row>
    </>
  );
};

ChatHolder.propTypes = {
  GET_CHAT_GROUPS: PropTypes.string.isRequired,
  SUBSCRIBE_TO_CHAT_MESSAGES: PropTypes.string.isRequired,
  activeSideNav: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  variables: PropTypes.object.isRequired,
};

export default ChatHolder;
