/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Column } from "../../../components/Grid/Grid.component";
import { H2 } from "../../../components/Typography/Typography.component";
import BorderInput from "../../../components/Form/BorderInput/BorderInput.component";
import Redactor from "../../../components/Form/Redactor/Redactor.component";
import Error from "../../../components/Error/ErrorMessage.component";

const FaqForm = (props) => {
  const {
    register,
    errors,
    faqEditData,
    faqData,
    setFaqData,
    editorState,
    setEditorState,
  } = props;

  useEffect(() => {
    if (faqEditData && faqData.question === "") {
      setFaqData(faqEditData);
    }
  });

  return (
    <Column sub noPad>
      <Row>
        <Column width="100%" sub>
          <H2>FAQ Details</H2>
        </Column>
      </Row>
      <Row>
        <Column width="50%" sub>
          <BorderInput
            header="Question"
            name="question"
            label=""
            type="text"
            value={faqData.question}
            register={register}
            required={{
              required: "please fill in a question",
            }}
            error={
              errors.question && <Error message={errors.question.message} />
            }
          />
        </Column>
      </Row>
      <Row>
        <Column sub width="100%">
          <Redactor
            onChange={setEditorState}
            value={editorState}
            header="Description"
          />
        </Column>
      </Row>
    </Column>
  );
};

FaqForm.defaultProps = {
  errors: {},
  faqEditData: undefined,
};

FaqForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  faqEditData: PropTypes.any,
  faqData: PropTypes.any.isRequired,
  setFaqData: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
};

export default FaqForm;
