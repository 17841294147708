import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { EditorState } from "draft-js";
import { useHistory, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Swal from "sweetalert2";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./CM.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";
import UploadFeaturedImage from "../../../components/UploadFeaturedImage/UploadFeaturedImage.component";

// GQL
import {
  UPDATE_CMS_ENTRY,
  DELETE_CMS_ENTRY,
} from "../../../graphql/mutations/cmsEntry.mutation";
import { GET_CONTENT_MANAGEMENT } from "../../../graphql/queries/contentManagement.query";
import { formatGraphqlError } from "../../../utils/api";

const CMSEntryEdit = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const { id } = useParams();
  const [cmsEntryData, setCmsEntryData] = useState({
    id: "",
    title: "",
    body: "",
    featuredImage: "",
    expiresAt: null,
    publishAt: moment(),
    status: "ACTIVE",
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [updatedEditorState, setUpdatedEditorState] = useState(false);

  const [updateCmsEntry, { loading }] = useMutation(UPDATE_CMS_ENTRY);
  const [deleteCmsEntry] = useMutation(DELETE_CMS_ENTRY);
  const getEntryData = useQuery(GET_CONTENT_MANAGEMENT, {
    fetchPolicy: "no-cache",
    variables: { id },
  });

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("cm");
    setPageTitle("Create New Content");
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const changeStatus = (e) => {
    const tempCmsEntryData = { ...cmsEntryData };
    tempCmsEntryData.status = e.target.value;
    setCmsEntryData(tempCmsEntryData);
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteCmsEntry({
          variables: {
            id: cmsEntryData.id,
          },
        })
          .then(() => {
            Swal.fire("Deleted!", "Content has been deleted.", "success").then(
              () => {
                history.push("/app/cms/content-management");
              }
            );
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Content Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const setImage = (featuredImage) => {
    setCmsEntryData({
      ...cmsEntryData,
      featuredImage,
    });
  };

  const getColor = () => {
    switch (cmsEntryData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Content
     * push to list all content
     */

    updateCmsEntry({
      variables: {
        ...values,
        featuredImage: cmsEntryData.featuredImage,
        id: cmsEntryData.id,
        publishAt: cmsEntryData.publishAt,
        expiresAt: cmsEntryData.expiresAt,
        body: stateToHTML(editorState.getCurrentContent()),
      },
    })
      .then(() => {
        Notification.success({
          title: "Update Content Succeeded",
          message: "",
        });
        history.push("/app/cms/content-management");
      })
      .catch((err) =>
        Notification.error({
          title: "Update Content Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  if (getEntryData.loading) {
    return "Loading...";
  }

  if (getEntryData.data && getEntryData.data.cmsEntry && !updatedEditorState) {
    setUpdatedEditorState(true);
    setEditorState(
      EditorState.createWithContent(
        stateFromHTML(getEntryData.data.cmsEntry.body)
      )
    );
  }

  return (
    <>
      <PageHeader title="Update Content" back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            cmsEntryData={cmsEntryData}
            setCmsEntryData={setCmsEntryData}
            setValue={setValue}
            getValues={getValues}
            editorState={editorState}
            setEditorState={setEditorState}
            cmsEntryEditData={getEntryData.data && getEntryData.data.cmsEntry}
          />
        </Column>
        <Column width="30%">
          <UploadFeaturedImage
            header="Featured Image"
            image={cmsEntryData.featuredImage}
            setImage={setImage}
          />
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={cmsEntryData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="0px 0px 120px 0px"
            required={{
              required: "please enter a Content ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => handleDelete()}
            type="button"
            value="Archive"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => history.goBack()}
            type="button"
            value="Cancel"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default CMSEntryEdit;
