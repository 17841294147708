export const COLOR_PRIMARY = "#090031";
export const COLOR_SECONDARY = "#FF003A";
export const COLOR_TERTIARY = "#FFF";
export const COLOR_PRIMARY_BUTTON = "#070029";
export const COLOR_PRIMARY_BUTTON_SHADOW = "#ADACB7";
export const COLOR_SECONDARY_BUTTON = "#FF003A";
export const COLOR_SECONDARY_BUTTON_SHADOW = "#ADACB7";
export const COLOR_PRIMARY_FONT = "#090031";
export const COLOR_SECONDARY_FONT = "#63636C";
export const COLOR_PRIMARY_INPUT_FONT = "#ADACB7";
export const COLOR_PRIMARY_INPUT_UNDERLINE = "#ADACB7";
export const COLOR_PRIMARY_CHECKBOX = "#07002F";
export const COLOR_PRIMARY_CHECKBOX_HOVER = "#7A73A1";
export const COLOR_PRIMARY_HEADER = "#FFF";
export const COLOR_GREY = "#707172";
export const COLOR_GREY2 = "#393B3C";
export const COLOR_GREY3 = "#D8D8D8";
export const COLOR_DISABLED = "#B2B2B2";

export default {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TERTIARY,
  COLOR_PRIMARY_BUTTON,
  COLOR_PRIMARY_BUTTON_SHADOW,
  COLOR_SECONDARY_BUTTON,
  COLOR_SECONDARY_BUTTON_SHADOW,
  COLOR_PRIMARY_FONT,
  COLOR_SECONDARY_FONT,
  COLOR_PRIMARY_INPUT_FONT,
  COLOR_PRIMARY_INPUT_UNDERLINE,
  COLOR_PRIMARY_CHECKBOX,
  COLOR_PRIMARY_CHECKBOX_HOVER,
  COLOR_PRIMARY_HEADER,
  COLOR_GREY,
  COLOR_GREY2,
  COLOR_GREY3,
  COLOR_DISABLED,
};
