import styled from "styled-components";
import { COLOR_PRIMARY } from "../../common/styles/Colors";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;
`;
export const Title = styled.div`
  display: flex;
  width: 50%;
  color: ${COLOR_PRIMARY};
  align-items: center;
`;
export const Children = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
`;
