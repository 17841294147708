import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { FiMail, FiRefreshCcw } from "react-icons/fi";
import LineInput from "../../components/Form/LineInput/LineInput.component";
import Button from "../../components/Button/Button.component";
import Footer from "../../components/Footer/Footer.component";
import Error from "../../components/Error/ErrorMessage.component";
import FormHeader from "../../components/Form/FormHeader/FormHeader.component";
import Notification from "../../components/Toast/Toast.component";

import { FORGOT_PASSWORD } from "../../graphql/mutations/adminUser.mutation";
import { formatGraphqlError } from "../../utils/api";

const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [requestPasswordReset, { loading }] = useMutation(FORGOT_PASSWORD);

  const onSubmit = (values) => {
    requestPasswordReset({
      variables: {
        email: values.email,
      },
    })
      .then((res) => {
        Notification.success({
          title: "Email successfully sent",
          message: `Instructions has been sent to: ${values.email}`,
        });
        if (res.data.requestAdminPasswordReset.data) {
          history.push(
            `/reset-password/${res.data.requestAdminPasswordReset.data}`
          );
        }
      })
      .catch((err) =>
        Notification.error({
          title: "Password reset Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <FormHeader
        title="Reset password"
        icon={<FiRefreshCcw />}
        customStyle={{ justifyContent: "center" }}
      />
      <LineInput
        name="email"
        type="email"
        label="Enter Email Address"
        register={register}
        icon={<FiMail style={{ width: "1.2em", height: "1.2em" }} />}
        customStyle={{ width: "95%", alignItems: "center" }}
        required={{
          required: "you must specify an email",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "wrong email format",
          },
        }}
        error={errors.email && <Error>{`*${errors.email.message}`}</Error>}
      />
      <Button
        sub
        onClick={handleSubmit(onSubmit)}
        type="button"
        kind="secondary"
        value="Reset Password"
        loading={loading}
      />
      <Footer sub width="47.5%">
        <Link to="/login" style={{ width: "50%", textAlign: "right" }}>
          Have login details?
        </Link>
      </Footer>
    </>
  );
};

export default ForgotPassword;
