import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { useMutation } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./ExpenseClaimType.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import { CREATE_EXPENSE_CLAIM_TYPE } from "../../../graphql/mutations/expenseClaimType.mutation";
import { formatGraphqlError } from "../../../utils/api";

const ExpenseClaimTypeNew = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const urlParams = new URLSearchParams(window.location.search);
  const [expenseClaimTypeData, setExpenseClaimTypeData] = useState({
    id: "",
    title: urlParams.has("title") ? urlParams.get("title") : "",
    description: urlParams.has("description")
      ? urlParams.get("description")
      : "",
    status: "ACTIVE",
  });
  const [createExpenseClaimType, { loading }] = useMutation(
    CREATE_EXPENSE_CLAIM_TYPE
  );

  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Create New Expense Claim Type");
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm();

  const changeStatus = (e) => {
    const tempExpenseClaimTypeData = { ...expenseClaimTypeData };
    tempExpenseClaimTypeData.status = e.target.value;
    setExpenseClaimTypeData(tempExpenseClaimTypeData);
  };

  const getColor = () => {
    switch (expenseClaimTypeData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new ExpenseClaimType
     * push to list all expenseClaimTypes
     */

    createExpenseClaimType({
      variables: {
        ...values,
      },
    })
      .then(() => {
        Notification.success({
          title: "Create ExpenseClaimType Succeeded",
          message: "",
        });
        history.push("/app/system-configuration/expense-claim-type");
      })
      .catch((err) =>
        Notification.error({
          title: "Create ExpenseClaimType Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <PageHeader title="Create New Expense Claim Type" back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            expenseClaimTypeData={expenseClaimTypeData}
            setExpenseClaimTypeData={setExpenseClaimTypeData}
            setValue={setValue}
            getValues={getValues}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={expenseClaimTypeData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a ExpenseClaimType ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Create Expense Claim Type"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={() => history.goBack()}
            type="button"
            value="Cancel"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default ExpenseClaimTypeNew;
