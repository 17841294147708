import gql from "graphql-tag";

export const CREATE_CMS_ENTRY = gql`
  mutation createCmsEntry(
    $title: String!
    $body: String!
    $status: GeneralStatus!
    $expiresAt: DateTime
    $publishAt: DateTime!
    $featuredImage: String!
    $type: ContentType!
  ) {
    createCmsEntry(
      data: {
        title: $title
        body: $body
        status: $status
        expiresAt: $expiresAt
        publishAt: $publishAt
        featuredImage: $featuredImage
        type: $type
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CMS_ENTRY = gql`
  mutation updateUserType(
    $title: String!
    $body: String!
    $status: GeneralStatus!
    $expiresAt: DateTime
    $publishAt: DateTime!
    $featuredImage: String!
    $type: ContentType!
    $id: ID!
  ) {
    updateCmsEntry(
      data: {
        title: $title
        body: $body
        status: $status
        expiresAt: $expiresAt
        publishAt: $publishAt
        featuredImage: $featuredImage
        type: $type
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_CMS_ENTRY = gql`
  mutation deleteCmsEntry($id: ID!) {
    deleteCmsEntry(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_CMS_ENTRY,
  UPDATE_CMS_ENTRY,
  DELETE_CMS_ENTRY,
};
