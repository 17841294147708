import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_GREY,
} from "../../common/styles/Colors";

export const StyledNavigation = styled.div`
  min-height: calc(100vh - 60px);
  width: ${({ open }) => (open ? "250px" : "60px")};
  background-color: white;
  transition: width 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 5px -2px ${COLOR_GREY};
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    width: ${({ open }) => (open ? "18%" : "5%")};
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5px;
    height: 50px;
    overflow: hidden;

    &.logout {
      flex-grow: 1;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
    a {
      text-transform: capitalize;
      padding: ${(props) => (props.icon ? "10px" : "0px")};
      color: ${COLOR_PRIMARY};
      text-decoration: none;
      /* transition: 0.3s linear; */
      border-radius: ${(props) => (props.icon ? "5px" : "0")};
      display: flex;
      justify-content: center;
      margin: 0px 10px;
      white-space: nowrap;
      svg {
        margin-right: ${(props) => (props.open ? "15px" : "0")};
      }

      @media (max-width: 768px) {
        font-size: 12px;
        text-align: left;
      }

      &:hover {
        color: black;
        box-shadow: ${(props) =>
          props.icon
            ? "0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 20px 0 rgba(0, 0, 0, 0.1)"
            : "none"};
      }
      &.active {
        color: ${COLOR_SECONDARY};
      }
    }
  }
`;

export default {
  StyledNavigation,
};
