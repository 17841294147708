import gql from "graphql-tag";

export const UPDATE_ADMIN_PROFILE = gql`
  mutation updateAdminProfile(
    $firstName: String!
    $lastName: String!
    $cellphone: String!
    $email: String!
  ) {
    updateAdminProfile(
      data: {
        firstName: $firstName
        lastName: $lastName
        cellphone: $cellphone
        email: $email
      }
    ) {
      id
    }
  }
`;

export default {
  UPDATE_ADMIN_PROFILE,
};
