import gql from "graphql-tag";

export const GET_APP_USERS = gql`
  query getAppUsers($where: AppUserWhereInput) {
    appUsers(where: $where) {
      id
    }
  }
`;

export const GET_POSITIONS = gql`
  query getPositions($where: PositionWhereInput) {
    positions(where: $where) {
      id
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents($where: EventWhereInput) {
    events(where: $where) {
      id
    }
  }
`;

export const GET_REFERRALS = gql`
  query getReferrals($where: referralWhereInput) {
    referrals(where: $where) {
      id
    }
  }
`;

export const GET_EMPLOYERS = gql`
  query getEmployers($where: EmployerProfileWhereInput) {
    employerProfiles(where: $where) {
      id
      subscriptionId
    }
  }
`;

export const GET_STAFF = gql`
  query getStaff($where: StaffProfileWhereInput) {
    staffProfiles(where: $where) {
      id
      subscriptionId
    }
  }
`;
export const GET_UPCOMING_EVENTS = gql`
  query getEvents($where: EventWhereInput, $orderBy: EventOrderByInput!) {
    events(where: $where, orderBy: $orderBy) {
      id
      title
      startDate
      endDate
      location
    }
  }
`;

export default {
  GET_APP_USERS,
  GET_POSITIONS,
  GET_EVENTS,
  GET_REFERRALS,
  GET_EMPLOYERS,
  GET_STAFF,
};
