import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { useMutation } from "@apollo/react-hooks";
import LineInput from "../../components/Form/LineInput/LineInput.component";
import Button from "../../components/Button/Button.component";
import Footer from "../../components/Footer/Footer.component";
import CheckBox from "../../components/Form/CheckBox/CheckBox.component";
import Error from "../../components/Error/ErrorMessage.component";
import Notification from "../../components/Toast/Toast.component";
import { APP_CONTEXT } from "../../utils/context";

// GQL
import LOGIN from "../../graphql/mutations/login.mutation";
import { formatGraphqlError } from "../../utils/api";
import { H4 } from "../../components/Typography/Typography.component";
const STAGE = process.env.REACT_APP_STAGE;
const pjson = require('../../../package.json');
const LoginPage = () => {
  const { register, handleSubmit, errors } = useForm();
  const [passwordType, setPasswordType] = useState("password");
  const history = useHistory();
  const [loginFunc, { loading }] = useMutation(LOGIN);
  const { setSuperAdmin, setPermissions, setUserId } = useContext(APP_CONTEXT);

  const togglePasswordReveal = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const onSubmit = (values) => {
    /**
     * login
     * save token in local storage
     * push to admin dashboard
     */
    loginFunc({
      variables: {
        ...values,
      },
    })
      .then(
        ({
          data: {
            loginAdmin: { jwt, roles, isSuperAdmin, userId },
          },
        }) => {
          localStorage.setItem("jwt", jwt);
          localStorage.setItem("superAdmin", isSuperAdmin);
          localStorage.setItem("userId", userId);
          localStorage.setItem("permissions", JSON.stringify(roles));

          setSuperAdmin(isSuperAdmin);
          setPermissions(roles);
          setUserId(userId);

          history.push("/app/dashboard");
        }
      )
      .catch((err) =>
        Notification.error({
          title: "Login Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  return (
    <>
      <LineInput
        name="email"
        type="email"
        label="Enter Email Address"
        register={register}
        icon={<FiMail style={{ width: "1.2em", height: "1.2em" }} />}
        customStyle={{ width: "95%", alignItems: "center" }}
        required={{
          required: "you must specify an email",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "wrong email format",
          },
        }}
        error={errors.email && <Error>{`*${errors.email.message}`}</Error>}
      />
      <LineInput
        name="password"
        type={passwordType}
        label="Password"
        register={register}
        icon={
          passwordType === "password" ? (
            <AiOutlineEye
              onClick={togglePasswordReveal}
              style={{ cursor: "pointer", width: "1.2em", height: "1.2em" }}
            />
          ) : (
            <AiOutlineEyeInvisible
              onClick={togglePasswordReveal}
              style={{ cursor: "pointer", width: "1.2em", height: "1.2em" }}
            />
          )
        }
        customStyle={{ width: "95%", alignItems: "center" }}
        required={{
          required: "you must specify an password",
          minLength: { value: 6, message: "password too short" },
        }}
        error={
          errors.password && <Error>{`*${errors.password.message}`}</Error>
        }
      />
      <Button
        onClick={handleSubmit(onSubmit)}
        type="button"
        kind="secondary"
        value="Log in"
        loading={loading}
      />
      <Footer sub width="47.5%">
        <CheckBox
          type="checkbox"
          defaultChecked={false}
          name="rememberMe"
          register={register}
          label="Remember me"
        />
        <Link
          to="/forgot-password"
          style={{ width: "50%", textAlign: "right" }}
        >
          Forgot password?
        </Link>
        <H4 style={{position:'absolute', bottom:0, right:0}}>version {pjson.version} - {STAGE}</H4>
      </Footer>
    </>
  );
};

export default LoginPage;
