import styled from "styled-components";

export const Table = styled.table`
  min-width: 100%;
  background-color: white;
  border-collapse: separate;
  border-spacing: 0;
`;
export const TableHeader = styled.th`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #393b3c;
  text-align: left;
  height: 50px;
  background: white;
  border-bottom: 2px solid #e3e3e3;
`;
export const TableRow = styled.tr`
  border-bottom: 2px solid #e3e3e3;
  min-width: 100%;
  transition: background-color 0.3s ease-in-out;
  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const TableCol = styled.td`
  margin: 0;
  font-size: 14px;
  color: #707172;
  line-height: 16px;
  height: 46px;
  width: 33.33%;
  border-bottom: 2px solid #e3e3e3;
  word-wrap: break-word;
  white-space: normal;
`;

export default {
  Table,
  TableHeader,
  TableRow,
  TableCol,
};
