import React from "react";
import { string } from "prop-types";
import { ErrorMessageWrapper } from "./Errors.style";

const ErrorMessage = ({ message }) => {
  return (
    <ErrorMessageWrapper>
      <p>{`*${message}`}</p>
    </ErrorMessageWrapper>
  );
};

ErrorMessage.propTypes = {
  message: string.isRequired,
};

export default ErrorMessage;
