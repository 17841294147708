import React, { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
// GQL
import { GET_PAYMENT_HISTORY } from "../../../graphql/queries/finances.query";
import Modal from "../../../components/Modal/Modal.component";
import {
  H1,
  H2,
  Span,
} from "../../../components/Typography/Typography.component";
import { Row, Col } from "../../../components/Grid/Grid.new.component";

const Card = styled.div`
  border: 1px solid #393b3c;
  border-radius: 4px;
  padding: 15px;
`;

const PaymentHistory = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(
    GET_PAYMENT_HISTORY,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [variables, setVariables] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [activePayment, setActiveClaim] = useState(false);

  useEffect(() => {
    setActiveSideNav("billing");
    setPageTitle("View Payment History");
  });

  const HeaderInfo = [
    {
      Header: "Payment Date",
      id: "createdAt",
      accessor: "createdAt",
      Cell: ({ row }) =>
        moment(row.original.createdAt).format("YYYY/MM/DD | HH:mm"),
    },
    {
      Header: "Staff",
      accessor: (row) =>
        row.staff.appUser
          ? `${row.staff.appUser.firstName} ${row.staff.appUser.lastName}`
          : "Not set",
      id: "staff",
      search: true,
    },
    {
      Header: "Payment Amount",
      id: "amount",
      accessor: "amount",
    },
    {
      Header: "Proof Of Payment",
      id: "proofOfPayment",
      accessor: (row) => (
        <a
          href={row.proofOfPayment}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          {row.fileName}
        </a>
      ),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <AiOutlineEye
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            onClick={() => {
              setShowModal(true);
              setActiveClaim(row.original);
            }}
          />
        </TableIcons>
      ),
    },
  ];

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
  ];

  return (
    <>
      <PageHeader title="View Payment History" />
      <Table
        columns={HeaderInfo}
        data={(data && data.payments) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.paymentsCount.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Payment History"
        tableOptions={["columns", "filter"]}
        filters={filters}
        fileName="PaymentHistory"
      />
      <Modal
        open={showModal}
        showClose
        close={() => {
          setShowModal(false);
        }}
        width="75%"
      >
        <Row style={{ marginBottom: "0" }}>
          <Col sm={12} md={12} lg={12} sub>
            <H1>Payment Breakdown</H1>
          </Col>
        </Row>
        {activePayment && (
          <>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={6} md={6} lg={6} sub>
                <H2 style={{ marginBottom: "7.5px" }}>App User</H2>
                <Span>
                  {`${activePayment.staff.appUser.firstName} ${activePayment.staff.appUser.lastName}`}
                </Span>
              </Col>
              <Col sm={6} md={6} lg={6} sub>
                <H2 style={{ marginBottom: "7.5px" }}>Amount Paid</H2>
                <Span>{activePayment.amount}</Span>
              </Col>
            </Row>
            <Row style={{ marginBottom: "0" }}>
              <Col sm={4} md={4} lg={4} sub>
                <Row style={{ marginLeft: "-30px", marginRight: "-30px" }}>
                  <Col sm={12} md={12} lg={12} sub>
                    <H2 style={{ marginBottom: "7.5px" }}>All Rates</H2>
                  </Col>

                  {activePayment.ratePayments &&
                  activePayment.ratePayments.length > 0 ? (
                    activePayment.ratePayments.map((rate) => (
                      <Col
                        sm={12}
                        md={12}
                        lg={12}
                        sub
                        key={rate.id}
                        padding="8px 17px"
                      >
                        <Card>
                          <Row style={{ marginBottom: "0" }}>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Company:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {rate.serviceAgreement.employer &&
                                rate.serviceAgreement.employer.companyName}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Position:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {rate.serviceAgreement.position.title}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Status:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {rate.status}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Amount:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {rate.amount}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col sm={12} md={12} lg={12} sub>
                      No rates were paid
                    </Col>
                  )}
                </Row>
              </Col>
              <Col sm={4} md={4} lg={4} sub>
                <Row style={{ marginLeft: "-30px", marginRight: "-30px" }}>
                  <Col sm={12} md={12} lg={12} sub>
                    <H2 style={{ marginBottom: "7.5px" }}>All Claims</H2>
                  </Col>
                  {activePayment.expenseClaims &&
                  activePayment.expenseClaims.length > 0 ? (
                    activePayment.expenseClaims.map((claim) => (
                      <Col
                        sm={12}
                        md={12}
                        lg={12}
                        sub
                        key={claim.id}
                        padding="8px 17px"
                      >
                        <Card>
                          <Row style={{ marginBottom: "0" }}>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Company:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {claim.employer && claim.employer.companyName}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Amount:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {claim.amount}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Status:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {claim.status}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Position:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {claim.position && claim.position.title}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Invoice:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              <Link to={claim.invoice} target="_blank">
                                View Invoice
                              </Link>
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Submition Date:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {moment(claim.createdAt).format(
                                "YYYY/MM/DD HH:mm:SS"
                              )}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col sm={12} md={12} lg={12} sub>
                      No expense claims were paid
                    </Col>
                  )}
                </Row>
              </Col>
              <Col sm={4} md={4} lg={4} sub>
                <Row style={{ marginLeft: "-30px", marginRight: "-30px" }}>
                  <Col sm={12} md={12} lg={12} sub>
                    <H2 style={{ marginBottom: "7.5px" }}>All Referrals</H2>
                  </Col>
                  {activePayment.referrals &&
                  activePayment.referrals.length > 0 ? (
                    activePayment.referrals.map((referral) => (
                      <Col
                        sm={12}
                        md={12}
                        lg={12}
                        sub
                        key={referral.id}
                        padding="8px 17px"
                      >
                        <Card>
                          <Row style={{ marginBottom: "0" }}>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Referee:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {`${referral.referee.firstName} ${referral.referee.lastName}`}
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              Status:
                            </Col>
                            <Col
                              sm={6}
                              md={6}
                              lg={6}
                              sub
                              padding="0"
                              style={{ marginBottom: "5px" }}
                            >
                              {referral.status}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col sm={12} md={12} lg={12} sub>
                      No referrals were paid
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default PaymentHistory;
