import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

export const MountTransition = ({ children, slide = 0, slideUp = 0 }) => (
  <motion.div
    exit={{ opacity: 0, x: slide, y: slideUp }}
    initial={{ opacity: 0, x: slide, y: slideUp }}
    animate={{ opacity: 1, x: 0, y: 0 }}
    className="routeHolder"
  >
    {children}
  </motion.div>
);

MountTransition.defaultProps = {
  slide: 0,
  slideUp: 0,
};

MountTransition.propTypes = {
  slide: PropTypes.number,
  slideUp: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MountTransition;
