import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 40vw;
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 7.5%;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const Logo = styled.img`
  position: relative;
  width: 45%;
  height: auto;
  margin: 0 auto;
`;
