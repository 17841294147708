import PropTypes from "prop-types";
import React from "react";
import { Icon, StyledButton } from "./Button.style";
/**
 * Button component for generic use in project.
 */

const Button = ({
  icon,
  onClick,
  value,
  kind,
  loading,
  width,
  margin,
  color,
  disabled,
}) => {
  // TODO!: Add loader to the loading state of the button
  const canClick = () => {
    if (!loading) {
      if (onClick) {
        onClick();
      }
    }
  };
  if (kind === "secondary") {
    return (
      <StyledButton
        onClick={canClick}
        value={value}
        secondary
        disabled={loading || disabled}
        width={width}
        margin={margin}
        color={color}
      >
        {icon ? <Icon>{icon}</Icon> : null}
        {!loading && value}
        {loading && "Loading ..."}
      </StyledButton>
    );
  }
  return (
    <StyledButton
      onClick={canClick}
      value={value}
      disabled={loading || disabled}
      width={width}
      margin={margin}
      color={color}
    >
      {icon ? <Icon>{icon}</Icon> : null}
      {!loading && value}
      {loading && "Loading ..."}
    </StyledButton>
  );
};

Button.propTypes = {
  icon: PropTypes.element,
  width: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  kind: PropTypes.oneOf(["primary", "secondary"]),
  value: PropTypes.string.isRequired,
  /**
   * Display a loader on the button to display a action is in progress.
   */
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  kind: "primary",
  color: undefined,
  onClick: undefined,
  loading: false,
  disabled: false,
  icon: undefined,
  width: undefined,
  margin: undefined,
};

export default Button;
