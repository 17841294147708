import gql from "graphql-tag";

export const CREATE_USER_TYPE = gql`
  mutation createUserRole(
    $title: String!
    $description: String!
    $permissions: Json!
    $status: UserRoleStatus!
  ) {
    createUserRole(
      data: {
        title: $title
        description: $description
        permissions: $permissions
        status: $status
      }
    ) {
      id
    }
  }
`;

export const UPDATE_USER_TYPE = gql`
  mutation updateUserType(
    $title: String!
    $description: String!
    $permissions: Json!
    $status: UserRoleStatus!
    $id: ID!
  ) {
    updateUserRole(
      data: {
        title: $title
        description: $description
        permissions: $permissions
        status: $status
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_USER_TYPE = gql`
  mutation deleteUserRole($id: ID!) {
    deleteUserRole(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_USER_TYPE,
  UPDATE_USER_TYPE,
  DELETE_USER_TYPE,
};
