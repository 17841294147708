import { createGlobalStyle } from "styled-components";

import { COLOR_PRIMARY } from "./Colors";

export const GlobalStyles = createGlobalStyle`
    html, body{
        margin: 0;
        padding: 0;
    }

    *, *::after, *::before{
        box-sizing: border-box
    }

    body{
        width: 100vw;
        height: 100vh;
        font-family: Lato, sans-serif;
        background: #F2F2F2;
  			overflow-x: hidden;
		}
		a{
			color: #090031;
		}
		input{
			outline-color: ${COLOR_PRIMARY};
		}
    .routeHolder{
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      flex-direction: column;
      z-index: 9;
      overflow: hidden;
    }

    .swal2-container, .toastComponent{
      z-index: 99999999999999999999999999999;
    }
`;

export default {
  GlobalStyles,
};
