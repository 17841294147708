import gql from "graphql-tag";

export const GET_EVENTS = gql`
  query getEvents($order: EventOrderByInput, $where: EventWhereInput) {
    events(orderBy: $order, where: $where) {
      id
      title
      startDate
      endDate
      eventType
      status
      description
      location
      sportType {
        id
        title
      }
      sportCategory {
        id
        title
      }
      positions {
        id
        title
      }
      createdByAdmin {
        id
        firstName
        lastName
      }
      createdByStaff {
        id
        appUser {
          firstName
          lastName
        }
      }
      createdByEmployer {
        id
        appUser {
          firstName
          lastName
        }
      }
    }
  }
`;

export default {
  GET_EVENTS,
};
