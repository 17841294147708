import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./PositionRole.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  UPDATE_POSITION_ROLE,
  DELETE_POSITION_ROLE,
} from "../../../graphql/mutations/positionRole.mutation";
import { GET_POSITION_ROLE } from "../../../graphql/queries/positionRole.query";

import { formatGraphqlError } from "../../../utils/api";

const getChangesMadeToArray = (arrToFilter, arrToCheck) => {
  return arrToFilter.filter(
    (arr) =>
      typeof arrToCheck.find((subArr) => subArr.value === arr.value) ===
      "undefined"
  );
};

const PositionRoleEdit = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [positionRoleData, setPositionRoleData] = useState({
    id: "",
    title: "",
    description: "",
    sportCategories: [],
    sportTypes: [],
    status: "ACTIVE",
  });
  const [updatePositionType, { loading }] = useMutation(UPDATE_POSITION_ROLE);
  const [deletePositionType] = useMutation(DELETE_POSITION_ROLE);
  const { id } = useParams();
  const positionRole = useQuery(GET_POSITION_ROLE, {
    fetchPolicy: "no-cache",
    variables: { id },
  });

  const { data } = positionRole;
  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Manage Position Role");
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
  } = useForm();

  const changeStatus = (e) => {
    const tempPositionTypeData = { ...positionRoleData };
    tempPositionTypeData.status = e.target.value;
    setPositionRoleData(tempPositionTypeData);
  };

  const setSkills = (skills) => {
    const tempPositionTypeData = { ...positionRoleData };
    tempPositionTypeData.baseSkills = skills;
    setPositionRoleData(tempPositionTypeData);
  };

  const getColor = () => {
    switch (positionRoleData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Position Role
     * push to list all position Types
     */

    const submitValues = values;
    submitValues.id = positionRoleData.id;
    if (
      !submitValues.sportCategories ||
      submitValues.sportCategories.connect.length === 0
    ) {
      Notification.error({
        title: "At least one sport category needs to be selected",
        message: "",
      });
      return;
    }
    if (
      !submitValues.sportTypes ||
      submitValues.sportTypes.connect.length === 0
    ) {
      Notification.error({
        title: "At least one sport type needs to be selected",
        message: "",
      });
      return;
    }
    const defaultSelectedSportCategories = data.positionRole.sportCategories.map(
      (cat) => {
        return {
          value: cat.id,
          label: cat.title,
        };
      }
    );
    const selectedSportCategories = submitValues.sportCategories.connect;

    const sportCategoriesConnectIds = getChangesMadeToArray(
      selectedSportCategories,
      defaultSelectedSportCategories
    ).map((cat) => {
      return {
        id: cat.value,
      };
    });
    const sportCategoriesDisconnectIds = getChangesMadeToArray(
      defaultSelectedSportCategories,
      selectedSportCategories
    )
      .filter((cat) => {
        return (
          typeof selectedSportCategories.find(
            (scat) => scat.value === cat.value
          ) === "undefined"
        );
      })
      .map((cat) => {
        return {
          id: cat.value,
        };
      });
    submitValues.sportCategories = {
      connect: sportCategoriesConnectIds,
      disconnect: sportCategoriesDisconnectIds,
    };

    const defaultSelectedSportTypes = data.positionRole.sportTypes.map(
      (cat) => {
        return {
          value: cat.id,
          label: cat.title,
        };
      }
    );
    const selectedSportTypes = submitValues.sportTypes.connect;

    const sportTypesDisconnectIds = getChangesMadeToArray(
      defaultSelectedSportTypes,
      selectedSportTypes
    ).map((cat) => {
      return {
        id: cat.value,
      };
    });
    const sportTypesConnectIds = getChangesMadeToArray(
      selectedSportTypes,
      defaultSelectedSportTypes
    ).map((cat) => {
      return {
        id: cat.value,
      };
    });
    submitValues.sportTypes = {
      connect: sportTypesConnectIds,
      disconnect: sportTypesDisconnectIds,
    };

    console.log(submitValues);

    updatePositionType({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({
          title: "Saved successfully",
          message: "You updated a positionRole",
        });
        history.push("/app/system-configuration/position-role");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Position Role Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deletePositionType({
          variables: {
            id: positionRoleData.id,
          },
        })
          .then(() => {
            Swal.fire(
              "Deleted!",
              "Position Role has been deleted.",
              "success"
            ).then(() => {
              history.push("/app/system-configuration/position-role");
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Position Role Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  if (positionRole.loading) {
    return "Loading...";
  }

  return (
    <>
      <PageHeader
        title={`Edit Position Role: ${positionRoleData.title}`}
        back
      />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            positionRoleData={positionRoleData}
            setPositionRoleData={setPositionRoleData}
            positionRoleEditData={data && data.positionRole}
            setValue={setValue}
            getValues={getValues}
            setSkills={setSkills}
            control={control}
            watch={watch}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={positionRoleData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a Position Role ID",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={() => handleDelete()}
            type="button"
            value="Archive"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default PositionRoleEdit;
