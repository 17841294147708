import { css } from "glamor";
import { string } from "prop-types";
import React from "react";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { AiOutlineWarning } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "./Toast.style";

const Icon = ({ type }) => {
  switch (type) {
    case "error":
      return <FiAlertCircle size={30} color="#D00029" />;
    case "success":
      return <FiCheckCircle size={30} color="#66C272" />;
    case "warning":
      return <AiOutlineWarning size={30} color="#af8e0b" />;
    case "info":
      return <BsInfoCircle size={30} color="#246692" />;
    default:
      return null;
  }
};

Icon.propTypes = {
  type: string.isRequired,
};

const Toast = ({ title, message, type }) => {
  return (
    <Container>
      <Icon type={type} />
      <Container sub>
        <span>{title}</span>
        <p>{message}</p>
      </Container>
    </Container>
  );
};

Toast.defaultProps = {
  message: "",
};

Toast.propTypes = {
  title: string.isRequired,
  message: string,
  type: string.isRequired,
};

const Notification = {
  error: ({ title, message }) => {
    toast.error(<Toast title={title} message={message} type="error" />, {
      className: css({
        borderLeft: "5px solid #D00029",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "black",
        padding: "5px 0",
      }),
    });
    return Notification.error;
  },
  warning: ({ title, message }) => {
    toast.warn(<Toast title={title} message={message} type="warning" />, {
      className: css({
        borderLeft: "5px solid #af8e0b",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "black",
        padding: "5px 0",
      }),
    });
    return Notification.warning;
  },
  info: ({ title, message }) => {
    toast.info(<Toast title={title} message={message} type="info" />, {
      className: css({
        borderLeft: "5px solid #246692",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "black",
        padding: "5px 0",
      }),
    });
    return Notification.info;
  },
  success: ({ title, message }) => {
    toast.success(<Toast title={title} message={message} type="success" />, {
      className: css({
        borderLeft: "5px solid #66C272",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "black",
        padding: "5px 0",
      }),
    });
    return Notification.success;
  },
};

Notification.propTypes = {
  message: string.isRequired,
  title: string.isRequired,
};

export default Notification;
