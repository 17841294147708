/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  InputHeader,
  Validation,
  Input,
  DayOfTheWeek,
  CheckBox,
  Button,
} from "./WeekDayPicker.style";

const WeekDayPicker = ({
  error,
  validationLabel,
  header,
  defaultChecked,
  name,
  register,
  required,
}) => {
  return (
    <Container>
      <InputHeader>{header}</InputHeader>
      <Input>
        <DayOfTheWeek>
          <CheckBox
            type="checkbox"
            defaultChecked={defaultChecked.SU}
            name={`${name}.SU`}
            ref={register ? register(required) : undefined}
          />
          <Button className="button">S</Button>
        </DayOfTheWeek>
        <DayOfTheWeek>
          <CheckBox
            type="checkbox"
            defaultChecked={defaultChecked.MO}
            name={`${name}.MO`}
            ref={register ? register(required) : undefined}
          />
          <Button className="button">M</Button>
        </DayOfTheWeek>
        <DayOfTheWeek>
          <CheckBox
            type="checkbox"
            defaultChecked={defaultChecked.TU}
            name={`${name}.TU`}
            ref={register ? register(required) : undefined}
          />
          <Button className="button">T</Button>
        </DayOfTheWeek>
        <DayOfTheWeek>
          <CheckBox
            type="checkbox"
            defaultChecked={defaultChecked.WE}
            name={`${name}.WE`}
            ref={register ? register(required) : undefined}
          />
          <Button className="button">W</Button>
        </DayOfTheWeek>
        <DayOfTheWeek>
          <CheckBox
            type="checkbox"
            defaultChecked={defaultChecked.TH}
            name={`${name}.TH`}
            ref={register ? register(required) : undefined}
          />
          <Button className="button">T</Button>
        </DayOfTheWeek>
        <DayOfTheWeek>
          <CheckBox
            type="checkbox"
            defaultChecked={defaultChecked.FR}
            name={`${name}.FR`}
            ref={register ? register(required) : undefined}
          />
          <Button className="button">F</Button>
        </DayOfTheWeek>
        <DayOfTheWeek>
          <CheckBox
            type="checkbox"
            defaultChecked={defaultChecked.SA}
            name={`${name}.SA`}
            ref={register ? register(required) : undefined}
          />
          <Button className="button">S</Button>
        </DayOfTheWeek>
      </Input>
      {!error && validationLabel && <Validation>*{validationLabel}</Validation>}
      <div>{error}</div>
    </Container>
  );
};

WeekDayPicker.defaultProps = {
  error: undefined,
  validationLabel: "",
  header: "",
  register: undefined,
  required: {},
};

WeekDayPicker.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  validationLabel: PropTypes.string,
  header: PropTypes.string,
  defaultChecked: PropTypes.object.isRequired,
  register: PropTypes.func,
  required: PropTypes.object,
};

export default WeekDayPicker;
