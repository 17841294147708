import styled from "styled-components";

export const Counter = styled.div`
  display: block;
`;

export const Left = styled.div`
  display: inline-block;
  width: 70%;
`;
export const Right = styled.div`
  display: inline-flex;
  width: 30%;
  text-align: right;
  align-items: center;
  justify-items: flex-end;
`;

export const EventsHolder = styled.div`
  display: block;
  width: 100%;
`;

export const Event = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid rgb(7 0 47 / 0.2);
  svg {
    margin-right: 10px;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export default {
  Counter,
  Left,
  Right,
};
