import styled, { keyframes } from "styled-components";

export const LoadingHolder = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export const SVGHolder = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  .svgContainer {
    width: 150px;
    height: 150px;
    display: flex;
    place-content: center;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    padding: 20px;
  }
`;

const breatheAnimation = keyframes`
 0% { opacity: 0 }
 50% { opacity: 1 }
 100% { opacity: 0 }
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
  animation-name: ${breatheAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export default { LoadingHolder, SVGHolder, Logo };
