import React, { useState, useEffect } from "react";
import { Redirect, BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import { ApolloProvider } from "react-apollo";
import { ToastContainer } from "react-toastify";
import Routes from "../routes/index";
import { createClient } from "../utils/api";
import { AppContextProvider } from "../utils/context";
import {
  AnimatedRoutes,
  RouteTransition,
} from "../components/Animation/AnimatedRoutes/AnimatedRoutes.component";

const client = createClient();

const Div = styled.div`
  height: 100%;
`;

const AppWrapper = () => {
  const [pageTitle, setPageTitle] = useState("");
  const [activeSideNav, setActiveSideNav] = useState("");
  const [permissions, setPermissions] = useState({});
  const [superAdmin, setSuperAdmin] = useState(false);
  const [userId, setUserId] = useState(null);
  const contextValue = {
    pageTitle,
    setPageTitle,
    activeSideNav,
    setActiveSideNav,
    permissions,
    setPermissions,
    superAdmin,
    setSuperAdmin,
    userId,
    setUserId,
  };

  useEffect(() => {
    document.title = `Pro Touch Staff ${
      pageTitle !== "" ? ` | ${pageTitle}` : ""
    }`;
  }, [pageTitle]);

  return (
    <ApolloProvider client={client}>
      <AppContextProvider value={contextValue}>
        <Div>
          <ToastContainer className="toastComponent" />
          <Router>
            <AnimatedRoutes exitBeforeEnter initial={false}>
              {Routes.map((prop, index) => {
                const key = `route-${index}`;
                if (prop.redirect) {
                  return (
                    <RouteTransition
                      path={prop.path}
                      key={key}
                      exact
                      slide={25}
                    >
                      <Redirect to={prop.redirectTo} />
                    </RouteTransition>
                  );
                }
                return (
                  <RouteTransition path={prop.path} key={key} slide={25}>
                    <prop.component />
                  </RouteTransition>
                );
              })}
            </AnimatedRoutes>
          </Router>
        </Div>
      </AppContextProvider>
    </ApolloProvider>
  );
};

export default AppWrapper;
