import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEdit, FiTrash } from "react-icons/fi";
import { GoPrimitiveDot } from "react-icons/go";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import Swal from "sweetalert2";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import Button from "../../../components/Button/Button.component";
import Table, { TableIcons } from "../../../components/Table/Table.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_EXPORT,
} from "../../../graphql/queries/adminUser.query";
import { GET_ALL_ACTIVE_USER_ROLES } from "../../../graphql/queries/userType.query";
import { DELETE_ADMIN_USER } from "../../../graphql/mutations/adminUser.mutation";
import { formatGraphqlError } from "../../../utils/api";

const UserAdminAll = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [refetch, { data, loading, error }] = useLazyQuery(GET_ADMIN_USERS, {
    variables: { order: "createdAt_DESC" },
    fetchPolicy: "no-cache",
  });
  const roleData = useQuery(GET_ALL_ACTIVE_USER_ROLES, {
    fetchPolicy: "no-cache",
  });
  const [getAdminUsersExport, adminUsersExport] = useLazyQuery(
    GET_ADMIN_USERS_EXPORT
  );
  const [variables, setVariables] = useState({
    where: { AND: [{ NOT: { status: "ARCHIVED" } }] },
  });
  const [deleteAdminUser] = useMutation(DELETE_ADMIN_USER);

  useEffect(() => {
    setActiveSideNav("userManagement");
    setPageTitle("Manage Admin Users");
  });

  const handleDelete = (e, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteAdminUser({
          variables: {
            id: row.original.id,
          },
        })
          .then(() => {
            Swal.fire("Deleted!", "Admin has been deleted.", "success");
            refetch({
              variables: {
                ...variables,
                first: 50,
                skip: 0,
              },
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Admin Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "#39C568";
      case "NEW":
        return "#1a0388";
      case "ARCHIVE":
        return "#FF003A";
      default:
        return "#707172";
    }
  };

  const HeaderInfo = [
    {
      Header: "Name",
      accessor: "firstName",
      search: true,
      id: "firstName",
    },
    {
      Header: "Surname",
      accessor: "lastName",
      search: true,
      id: "lastName",
    },
    {
      Header: "Type",
      id: "type",
      accessor: "type",
      Cell: ({ row }) => {
        if (row.original.superAdmin) {
          return "Super Admin";
        }

        if (row.original.roles) {
          return row.original.roles.title;
        }

        return "";
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      search: true,
      id: "email",
    },
    {
      Header: "Mobile Number",
      accessor: "cellphone",
      search: true,
      id: "cellphone",
    },
    {
      Header: "Status",
      id: "status",
      accessor: "status",
      Cell: ({ row }) => (
        <TableIcons>
          <GoPrimitiveDot
            style={{ fontSize: "25px", color: getColor(row.original.status) }}
          />
          <span>{row.original.status.toLowerCase()}</span>
        </TableIcons>
      ),
    },
    {
      Header: "Action",
      id: "actions",
      Cell: ({ row }) => (
        <TableIcons>
          <Link to={`/app/user-management/admin/${row.original.id}`}>
            <FiEdit
              style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
            />
          </Link>
          <FiTrash
            onClick={(e) => handleDelete(e, row)}
            style={{ fontSize: "20px", color: "#07002F", cursor: "pointer" }}
          />
        </TableIcons>
      ),
    },
  ];

  const getUserRoles = (roles) => {
    const retVal = [{ value: "superAdmin", text: "Super Admin" }];
    return retVal.concat(
      roles.map((role) => ({ value: role.id, text: role.title }))
    );
  };

  const filters = [
    {
      header: "Date Created:",
      accessor: "createdAt",
      type: "daterange",
    },
    {
      header: "Status:",
      accessor: "status",
      placeholder: "Please select a Status",
      type: "select",
      options: [
        { value: "ACTIVE", text: "Active" },
        { value: "NEW", text: "New" },
      ],
    },
    {
      header: "User Role:",
      accessor: "userRole",
      placeholder: "Please select a user role",
      type: "select",
      options: roleData.data ? getUserRoles(roleData.data.userRoles) : [],
      customFilter: (role) => {
        let retVal = null;
        if (role.value !== "") {
          if (role.value === "superAdmin") {
            retVal = {};
            retVal.superAdmin = true;
          } else {
            retVal = {};
            retVal.roles = {
              id: role.value,
            };
          }
        }
        return retVal;
      },
    },
  ];

  return (
    <>
      <PageHeader title="Manage Admin Users">
        <Link to="/app/user-management/admin/new">
          <Button
            onClick={() => {}}
            kind="secondary"
            value="New Admin User"
            width="177px"
            margin="10px 0 10px 10px"
          />
        </Link>
      </PageHeader>
      <Table
        columns={HeaderInfo}
        data={(data && data.adminUsers) || []}
        loading={loading}
        error={error}
        refetch={refetch}
        variables={variables}
        dataCount={(data && data.adminUsersCount.length) || 0}
        pageCount={5}
        setVariables={setVariables}
        tableTitle="Manage Admin Users"
        tableOptions={["search", "download", "columns", "filter"]}
        filters={filters}
        downloadData={adminUsersExport}
        getDownloadData={getAdminUsersExport}
        downloadAccessor={
          adminUsersExport.data ? adminUsersExport.data.adminUsers : []
        }
        fileName="AdminUsers"
      />
    </>
  );
};

export default UserAdminAll;
