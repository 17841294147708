import styled from "styled-components";

export const CompanyHolder = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const CompanyName = styled.div`
  width: 100%;
  text-align: center;
`;

export const CompanyRating = styled.div`
  width: 100%;
  text-align: center;
  margin: 7.5px 0;
  justify-content: center;
  display: flex;
`;

export const CompanyNameProfile = styled.div`
  width: 100%;
  margin-bottom: 34px;
  text-align: center;
  margin-top: 7px;
`;

export const ImageHolder = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
  align-self: center;
`;
export const SmallImageHolder = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Image = styled.img`
  width: auto;
  height: 100%;
`;

export const SmallImage = styled.img`
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  padding: 18px;
`;

export const Holder = styled.div`
  position: relative;
  width: 100%;
`;
