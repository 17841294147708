import { bool, func } from "prop-types";
import React from "react";
import { StyledBurger, Line } from "./Burger.style";

const Burger = ({ open, setOpen }) => {
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <Line />
        <Line />
        <Line />
      </StyledBurger>
    </>
  );
};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
