import { array, bool, string } from "prop-types";
import React from "react";
// import { FiCreditCard, FiImage, FiLock, FiLogOut, FiUsers, FiZap, FiPieChart } from 'react-icons/fi';
import { FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
import { StyledNavigation as SideNav } from "./SideNavigation.style";

const StyledNavigation = ({ open, navlinks, activeSideNav }) => {
  return (
    <SideNav open={open} icon>
      {navlinks.map((navLink) => {
        return (
          <div key={`large-nav-${navLink.title}`}>
            <Link
              to={navLink.path}
              className={activeSideNav === navLink.active ? "active" : ""}
            >
              <navLink.icon size={20} />
              {open ? <span>{navLink.title}</span> : null}
            </Link>
          </div>
        );
      })}
      <div className="logout">
        <Link
          to="/login"
          onClick={() => {
            localStorage.removeItem("jwt");
            localStorage.removeItem("superAdmin");
            localStorage.removeItem("permissions");
          }}
        >
          <FiLogOut size={20} />
          {open ? <span>Logout</span> : null}
        </Link>
      </div>
    </SideNav>
  );
};

StyledNavigation.propTypes = {
  open: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  navlinks: array.isRequired,
  activeSideNav: string.isRequired,
};

export default StyledNavigation;
