import gql from "graphql-tag";

export const CREATE_FAQ = gql`
  mutation createFaq(
    $question: String!
    $answer: String!
    $status: GeneralStatus!
  ) {
    createFaq(data: { question: $question, answer: $answer, status: $status }) {
      id
    }
  }
`;

export const UPDATE_FAQ = gql`
  mutation updateFaq(
    $question: String!
    $answer: String!
    $status: GeneralStatus!
    $id: ID!
  ) {
    updateFaq(
      data: { question: $question, answer: $answer, status: $status }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFaq($id: ID!) {
    deleteFaq(where: { id: $id }) {
      id
    }
  }
`;

export default {
  CREATE_FAQ,
  UPDATE_FAQ,
  DELETE_FAQ,
};
