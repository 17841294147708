import gql from "graphql-tag";

export const GET_STAFF_PROFILES = gql`
  query getAppUsers(
    $order: AppUserOrderByInput
    $first: Int!
    $skip: Int!
    $where: AppUserWhereInput
  ) {
    appUsers(orderBy: $order, first: $first, skip: $skip, where: $where) {
      id
      firstName
      email
      lastName
      verifiedEmail
      pushToken
      pushNotification
      staff {
        id
        profileImageUrl
        subscriptionId
        sportCategories{
          id
          title
        }
        positionTypeLookingFor{
          id
          title
        }
        skills {
          id
          title
        }
      }
    }
    appUsersCount: appUsers(where: $where) {
      id
    }
  }
`;

export const GET_STAFF_PROFILES_EXPORT = gql`
  query getAppUsers($order: AppUserOrderByInput, $where: AppUserWhereInput) {
    appUsers(orderBy: $order, where: $where) {
      id
      firstName
      email
      lastName
      verifiedEmail
      staff {
        id
        profileImageUrl
        usedName
        descriptionBio
        gender
        sportTypes {
          id
          title
        }
        sportCategories {
          id
          title
        }
        languages
        nationality
        fullAddress
        city
        country
        countryCode
        employmentStatus
        birthDate
        mobile
        positionTypeLookingFor {
          id
          title
        }
        workExperience
        countryResidence
        countryCitizenship
        education
        ownCar
        preferredAirports
        skills {
          id
          title
        }
        civilStatus
        religion
        subscriptionId
        suspended
        completedProfile
        bankingDetails {
          id
          accountName
          notOwnName
          addressOfAccountHolder
          accountCurrency
          bankName
          bankAddress
          bankCountry
          IBAN
          BICSWIFT
          accountNumber
          ABACode
          sortCode
          additionalInfo
        }
      }
    }
  }
`;

export const GET_STAFF_PROFILE = gql`
  query getAppUser($id: ID) {
    appUser(where: { id: $id }) {
      id
      firstName
      email
      lastName
      verifiedEmail
      staff {
        id
        profileImageUrl
        usedName
        descriptionBio
        gender
        sportTypes {
          id
          title
        }
        sportCategories {
          id
          title
        }
        languages
        nationality
        fullAddress
        city
        country
        countryCode
        employmentStatus
        birthDate
        mobile
        positionTypeLookingFor {
          id
          title
        }
        workExperience
        countryResidence
        countryCitizenship
        education
        ownCar
        preferredAirports
        skills {
          id
          title
        }
        civilStatus
        religion
        subscriptionId
        suspended
        completedProfile
        bankingDetails {
          id
          accountName
          notOwnName
          addressOfAccountHolder
          accountCurrency
          bankName
          bankAddress
          bankCountry
          IBAN
          BICSWIFT
          accountNumber
          ABACode
          sortCode
          additionalInfo
        }
      }
    }
  }
`;

export const GET_POSITIONS = gql`
  query getPostitions($where: PositionWhereInput) {
    positions(where: $where) {
      positionStatus
      id
    }
  }
`;

export const GET_SERVICE_AGREEMENTS = gql`
  query getServiceAgreements($where: ServiceAgreementWhereInput) {
    serviceAgreements(where: $where) {
      status
      id
    }
  }
`;

export const GET_RATINGS = gql`
  query getRatings($where: RatingWhereInput) {
    ratings(where: $where) {
      id
      numberRating
      ratingText
      author {
        id
        firstName
        lastName
        employer {
          companyName
        }
      }
    }
  }
`;

export const GET_EXPENSE_CLAIMS = gql`
  query getExpenseClaims($where: ExpenseClaimWhereInput) {
    expenseClaims(where: $where) {
      status
      id
    }
  }
`;

export default {
  GET_STAFF_PROFILES,
  GET_STAFF_PROFILES_EXPORT,
  GET_STAFF_PROFILE,
  GET_SERVICE_AGREEMENTS,
  GET_EXPENSE_CLAIMS,
  GET_RATINGS,
  GET_POSITIONS,
};
