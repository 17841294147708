import gql from "graphql-tag";

const UPDATE_PRIVACY_POLICY = gql`
  mutation managePrivacyPolicy($body: String!) {
    managePrivacyPolicy(data: { body: $body }) {
      id
      body
      updatedAt
    }
  }
`;

export default UPDATE_PRIVACY_POLICY;
