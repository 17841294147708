import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { APP_CONTEXT } from "../../../utils/context";
import PageHeader from "../../../components/PageHeader/PageHeader.component";
import { Row, Column } from "../../../components/Grid/Grid.component";
import Form from "./SportType.form.component";
import Button from "../../../components/Button/Button.component";
import Error from "../../../components/Error/ErrorMessage.component";
import BorderSelect from "../../../components/Form/BorderSelect/BorderSelect.component";
import Notification from "../../../components/Toast/Toast.component";

// GQL
import {
  UPDATE_SPORT_TYPE,
  DELETE_SPORT_TYPE,
} from "../../../graphql/mutations/sportType.mutation";
import { GET_SPORT_TYPE } from "../../../graphql/queries/sportType.query";

import { formatGraphqlError } from "../../../utils/api";

const SportTypeEdit = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [sportTypeData, setSportTypeData] = useState({
    id: "",
    title: "",
    description: "",
    sportCategory: "",
    status: "ACTIVE",
  });
  const [updateSportType, { loading }] = useMutation(UPDATE_SPORT_TYPE);
  const [deleteSportType] = useMutation(DELETE_SPORT_TYPE);
  const { id } = useParams();
  const sportType = useQuery(GET_SPORT_TYPE, {
    fetchPolicy: "no-cache",
    variables: { id },
  });

  const { data } = sportType;
  const history = useHistory();

  useEffect(() => {
    setActiveSideNav("sysConf");
    setPageTitle("Manage Sport Type");
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
  } = useForm();

  const changeStatus = (e) => {
    const tempSportTypeData = { ...sportTypeData };
    tempSportTypeData.status = e.target.value;
    setSportTypeData(tempSportTypeData);
  };

  const getColor = () => {
    switch (sportTypeData.status) {
      case "ACTIVE":
        return "#39C568";
      case "ARCHIVE":
        return "#FF003A";
      case "DRAFT":
        return "#707172";
      default:
        return "#707172";
    }
  };

  const onSubmit = (values) => {
    /**
     * Create new Sport Type
     * push to list all sport types
     */

    const submitValues = values;
    submitValues.id = sportTypeData.id;

    if (!submitValues.levels) {
      submitValues.levels = {
        set: [],
      };
    }

    updateSportType({
      variables: {
        ...submitValues,
      },
    })
      .then(() => {
        Notification.success({
          title: "Saved successfully",
          message: "You updated a sport type",
        });
        history.push("/app/system-configuration/sport");
      })
      .catch((err) =>
        Notification.error({
          title: "Create Sport Type Failed",
          message: formatGraphqlError(err),
        })
      );
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteSportType({
          variables: {
            id: sportTypeData.id,
          },
        })
          .then(() => {
            Swal.fire(
              "Deleted!",
              "Sport Type has been deleted.",
              "success"
            ).then(() => {
              history.push("/app/system-configuration/sport");
            });
          })
          .catch((err) =>
            Notification.error({
              title: "Delete Sport Type Failed",
              message: formatGraphqlError(err),
            })
          );
      }
    });
  };

  if (sportType.loading) {
    return "Loading...";
  }

  return (
    <>
      <PageHeader title={`Edit Sport Type: ${sportTypeData.title}`} back />
      <Row>
        <Column width="70%" noPad>
          <Form
            register={register}
            errors={errors}
            sportTypeData={sportTypeData}
            setSportTypeData={setSportTypeData}
            sportTypeEditData={data && data.sportType}
            setValue={setValue}
            getValues={getValues}
            control={control}
          />
        </Column>
        <Column width="30%">
          <BorderSelect
            header="Status"
            name="status"
            label="Please select a status"
            value={sportTypeData.status}
            register={register}
            onChange={changeStatus}
            options={[
              { value: "ACTIVE", text: "Active" },
              { value: "ARCHIVE", text: "Archive" },
              { value: "DRAFT", text: "Draft" },
            ]}
            icon={
              <GoPrimitiveDot style={{ color: getColor(), fontSize: "25px" }} />
            }
            margin="60px 0px 120px 0px"
            required={{
              required: "please enter a status",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "field cannot be empty",
              },
            }}
            error={errors.id && <Error message={errors.id.message} />}
          />

          <Button
            width="100%"
            onClick={() => handleDelete()}
            type="button"
            value="Archive"
            loading={loading}
          />
          <Button
            width="100%"
            onClick={handleSubmit(onSubmit)}
            type="button"
            kind="secondary"
            value="Save & Update"
            loading={loading}
          />
        </Column>
      </Row>
    </>
  );
};

export default SportTypeEdit;
