import gql from "graphql-tag";

export const GET_AUTOMATIC_NOTIFICATIONS = gql`
  query getAutomaticNotifications(
    $order: AutomaticNotificationOrderByInput
    $first: Int!
    $skip: Int!
    $where: AutomaticNotificationWhereInput
  ) {
    automaticNotifications(
      orderBy: $order
      first: $first
      skip: $skip
      where: $where
    ) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      name
      description
      message
      time
      frequency
      endDate
      status
    }
    automaticNotificationsCount: automaticNotifications(where: $where) {
      id
    }
  }
`;

export const GET_AUTOMATIC_NOTIFICATIONS_EXPORT = gql`
  query getAutomaticNotifications(
    $order: AutomaticNotificationOrderByInput
    $where: AutomaticNotificationWhereInput
  ) {
    automaticNotifications(
      orderBy: $order
      first: $first
      skip: $skip
      where: $where
    ) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      name
      description
      message
      time
      frequency
      endDate
      status
    }
  }
`;

export const GET_AUTOMATIC_NOTIFICATION = gql`
  query getAutomaticNotification($id: ID) {
    automaticNotification(where: { id: $id }) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      name
      description
      message
      time
      frequency
      endDate
      status
    }
  }
`;

export default {
  GET_AUTOMATIC_NOTIFICATIONS,
  GET_AUTOMATIC_NOTIFICATIONS_EXPORT,
  GET_AUTOMATIC_NOTIFICATION,
};
