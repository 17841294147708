import gql from "graphql-tag";

export const GET_AUDIT_LOGS = gql`
  query getAuditLogs(
    $orderBy: AuditLogOrderByInput
    $first: Int!
    $skip: Int!
    $where: AuditLogWhereInput
  ) {
    auditLogs(orderBy: $orderBy, first: $first, skip: $skip, where: $where) {
      id
      resolver
      dataID
      variables
      query
      userAgent
      userType
      IP
      adminUser {
        id
        firstName
        lastName
      }
      host
      createdAt
    }
    auditLogsCount: auditLogs(where: $where) {
      id
    }
  }
`;
export const GET_AUDIT_LOGS_EXPORT = gql`
  query getAuditLogs(
    $orderBy: AuditLogOrderByInput
    $where: AuditLogWhereInput
  ) {
    auditLogs(orderBy: $orderBy, where: $where) {
      id
      resolver
      dataID
      variables
      query
      userAgent
      userType
      IP
      adminUser {
        id
        firstName
        lastName
      }
      host
      createdAt
    }
  }
`;
export const GET_AUDIT_LOG = gql`
  query auditLog($where: AuditLogWhereUniqueInput!) {
    auditLog(where: $where) {
      id
      dataID
      resolver
      createdAt
      variables
      userType
      userAgent
      host
      IP
      adminUser {
        firstName
        lastName
        id
      }
      query
    }
  }
`;

export const GET_RELATED_AUDIT_LOGS = gql`
  query getAuditLogs(
    $order: AuditLogOrderByInput
    $where: AuditLogWhereInput!
  ) {
    auditLogs(orderBy: $order, first: 9999, skip: 0, where: $where) {
      id
      resolver
      dataID
      variables
      query
      userAgent
      userType
      adminUser {
        id
        firstName
        lastName
      }
      host
      createdAt
    }
  }
`;

export default {
  GET_AUDIT_LOGS,
  GET_AUDIT_LOG,
  GET_RELATED_AUDIT_LOGS,
};
